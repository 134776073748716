import { Checkbox, Row, Col, Modal, Button, Input, Select } from "antd";
import React, { useState, useEffect } from "react";

// Component
import TimeKBCus from "../../components/WeeklyPlan/TimeKBCus";
import AddTime from "../../components/WeeklyPlan/AddTime";
import { Fragment } from "react";
import { ServiceKikanWeekly } from "../../dialogs/index";
import _, { set } from "lodash";

const { Option } = Select;

function AddMultiWeeklyModal(props) {
  const DAY_OF_WEEK = ["日", "月", "火", "水", "木", "金", "土"];
  const SERVICE_TYPE_PTOT = "訪問看護";
  const [frequency, setFrequency] = useState([false, false, false, false, false, false, false]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [serviceAddition, setServiceAddition] = useState([]);
  const [ryaku, setRyaku] = useState("");
  const [serviceNaiyou, setServiceNaiyou] = useState();
  const [jigyousyoNoServiceKikan, setJigyousyoNoServiceKikan] = useState("");
  const [ryakuServiceKikan, setRyakuServiceKikan] = useState("");
  const [visibleServiceKikan, setVisibleServiceKikan] = useState(false);
  const [sidSelected, setSidSelected] = useState(null);
  const [isErrorTime, setIsErrorTime] = useState();
  const [serviceSyuruiCd, setServiceSyuruiCd] = useState(null);

  const [listServiceNaiyou, setListServiceNaiyou] = useState([]);
  const [listServiceAddition, setListServiceAddition] = useState([]);
  const [syuruiCd, setSyuruiCd] = useState();
  const [selectOptionArr, setSelectOptionArr] = useState([]);
  const [shortType, setShortType] = useState(false);
  useEffect(() => {
    // if (props.serviceNaiyou.length > 0) {
    const serviceNaiyouNew = getServiceNaiyou(0, 0, props.serviceNaiyou);
    if (serviceNaiyouNew === "小規模" || serviceNaiyouNew === "複合型") {
      setServiceNaiyou(props.dataInit.paletteName); //ok
      setRyaku(props.dataInit.paletteName); // ok
    } else {
      setServiceNaiyou(serviceNaiyouNew);
      setRyaku(serviceNaiyouNew);
    }

    if (props.dataInit.serviceType === SERVICE_TYPE_PTOT) {
      const listServiceNaiyouNew = props.serviceNaiyou.filter(
        (item) => !item.serviceNaiyou.includes("PTOT") || item.serviceNaiyou === "定期巡回訪看",
      );
      setListServiceNaiyou(listServiceNaiyouNew);
    } else {
      setListServiceNaiyou(props.serviceNaiyou);
    }

    // set init syuruiCd for adding ServiceKikanMaster
    if (props.menu && checkYobou(props.menu)) {
      const serviceContent = _.find(props.serviceNaiyou, (e) => e.yobou === 1);
      if (serviceContent) {
        if (serviceContent.yobouServiceSyuruiCd) {
          setServiceSyuruiCd(serviceContent.yobouServiceSyuruiCd.split(";")[0]);
        } else {
          setServiceSyuruiCd("0");
        }
      }
    } else {
      const serviceContent = _.find(props.serviceNaiyou, (e) => e.kaigo === 1);
      if (serviceContent) {
        if (serviceContent.kaigoServiceSyuruiCd) {
          setServiceSyuruiCd(serviceContent.kaigoServiceSyuruiCd.split(";")[0]);
        } else {
          setServiceSyuruiCd("0");
        }
      }
    }

    if (props.serviceNaiyou.length) {
      if (props.serviceNaiyou[0].serviceType == "ショート") {
        setServiceSyuruiCd(props.menu && checkYobou(props.menu) ? "24" : "21");
        setSelectOptionArr(
          props.serviceNaiyou.filter((item) =>
            item.kaigoServiceSyuruiCd == props.menu && checkYobou(props.menu)
              ? "24"
              : "21"
          )
        );
        setShortType(true);
      }
    }
  }, [props.serviceNaiyou]);

  useEffect(() => {
    const listName = props.serviceAddition.map((item) => item.addition);
    setListServiceAddition(props.serviceAddition);
    if (listName.includes("緊急") && props.dataInit && props.dataInit.ryaku === "通院") {
      setServiceAddition(["緊急"]);
    }
  }, [props.serviceAddition]);

  useEffect(() => {
    const minutesOfStartTime = startTime ? parseInt(startTime.substring(0, 2)) * 60 + parseInt(startTime.substring(3)) : 0;
    const minutesOfEndTime = endTime ? parseInt(endTime.substring(0, 2)) * 60 + parseInt(endTime.substring(3)) : 0;
    if (minutesOfStartTime >= minutesOfEndTime) {
      setIsErrorTime(true);
    } else {
      setIsErrorTime(false);
    }
  });

  useEffect(() => {
    if (props.serviceNaiyou.length) {
      if (props.serviceNaiyou[0].serviceType == "ショート") {
        setSelectOptionArr(
          checkYobou(props.menu)
            ? props.serviceNaiyou.filter(
              (item) => item.yobouServiceSyuruiCd == syuruiCd
            )
            : props.serviceNaiyou.filter(
              (item) => item.kaigoServiceSyuruiCd == syuruiCd
            )
        );
        setShortType(true);
      }
    }
  }, [syuruiCd]);

  //サービス
  useEffect(() => {
    if (props.addServiceSet && props?.serviceKikan2?.id) {
      onSaveServiceKikan(null, props.serviceKikan2);
      props.addServiceOff();
    }
  }, [props.serviceKikan2])

  function checkYobou(menu) {
    return menu === "yobou" || menu === "houkatu" ? true : false;
  }

  function handleChangeDayOfWeek(e) {
    const frequencyNew = frequency;
    frequencyNew[e.target.value] = e.target.checked;
    setFrequency([...frequencyNew]);
  }

  function isListMinNotEmpty(list) {
    return list.some((item) => item.min);
  }

  function handleChangeStartTime(value) {
    // set serviceNaiyou when change time
    const serviceNaiyouNew = getServiceNaiyou(value, endTime, listServiceNaiyou);
    if (isListMinNotEmpty(listServiceNaiyou)) {
      setServiceNaiyou(serviceNaiyouNew);
      setRyaku(serviceNaiyouNew);
    }
    setStartTime(value);
  }

  function handleChangeEndTime(value) {
    // set serviceNaiyou when change time
    const serviceNaiyouNew = getServiceNaiyou(startTime, value, listServiceNaiyou);
    if (isListMinNotEmpty(listServiceNaiyou)) {
      setServiceNaiyou(serviceNaiyouNew);
      setRyaku(serviceNaiyouNew);
    }
    setEndTime(value);
  }

  function handleOnClickTime(value) {
    const timeAddNumber = parseInt(value);
    let totalminutes;
    let result;
    if (startTime) {
      totalminutes = parseInt(startTime.substring(0, 2)) * 60 + parseInt(startTime.substring(3)) + timeAddNumber;
    } else {
      totalminutes = timeAddNumber;
    }
    const hours = totalminutes / 60 >= 10 ? "" + (parseInt(totalminutes / 60) % 24) : "0" + parseInt(totalminutes / 60);
    const minutes = totalminutes % 60 >= 10 ? "" + parseInt(totalminutes % 60) : "0" + parseInt(totalminutes % 60);
    result = hours + ":" + minutes;
    setEndTime(result);
    // set serviceNaiyou when change time
    const serviceNaiyouNew = getServiceNaiyou(startTime, result, listServiceNaiyou);
    if (isListMinNotEmpty(listServiceNaiyou)) {
      setServiceNaiyou(serviceNaiyouNew);
      setRyaku(serviceNaiyouNew);
    }
  }

  function handleChangeServiceAddition(e) {
    let serviceAdditionNew = serviceAddition;
    if (e.target.checked) {
      serviceAdditionNew.push(e.target.value);
      // eslint-disable-next-line brace-style
    } else {
      serviceAdditionNew = serviceAdditionNew.filter((item) => item !== e.target.value);
    }
    const listServiceNaiyouNew = processListServiceNaiyou(serviceAdditionNew, listServiceAddition);

    const checkExists = listServiceNaiyouNew.some((item) => item.serviceNaiyou == serviceNaiyou);
    const listServiceNaiyouOld = listServiceNaiyou;
    const isChangeListServiceNaiyou =
      listServiceNaiyouOld.length != listServiceNaiyouNew.length;
    if (isChangeListServiceNaiyou) {
      const serviceNaiyouNew = getServiceNaiyou(startTime, endTime, listServiceNaiyouNew);
      setServiceNaiyou(serviceNaiyouNew);
      setRyaku(serviceNaiyouNew);
    }
    setListServiceNaiyou([...listServiceNaiyouNew]);
    setServiceAddition([...serviceAdditionNew]);
  }

  function handleChangeRyaku(e) {
    setRyaku(e.target.value);
  }

  function handleChangeServiceNaiyou(value) {
    setServiceNaiyou(value);
    setRyaku(value);
  }

  function clearData() {
    setFrequency([false, false, false, false, false, false, false]);
    setRyaku("");
    setServiceNaiyou("");
    setStartTime("");
    setEndTime("");
    setServiceAddition([]);
    setJigyousyoNoServiceKikan("");
    setRyakuServiceKikan("");
    setSidSelected(null);
    setSyuruiCd("");
    setShortType(false);
  }
  function handleCancel() {
    props.onHideAddMultiModal();
    clearData();
  }

  // モーダルOK
  function handleOk() {
    const { swColorBak, colorBak, serviceType } = props.dataInit;
    const serviceAdditionStr = serviceAddition.join(",");
    props.onOk({
      frequency,
      startTime,
      endTime,
      ryaku,
      serviceNaiyou,
      serviceAddition: serviceAdditionStr,
      swColorBak,
      colorBak,
      serviceType,
      sid: sidSelected,
      jigyousyoNoServiceKikan,
      ryakuServiceKikan,
      syuruiCd,
    });
    clearData();
  }

  // handle ServiceKikan
  const onSaveServiceKikan = (value) => {
    const kasanDefault = value?.kasanDefault;
    const startTimeArray = kasanDefault.match("<時間帯開始>(.*)</時間帯開始>");
    const endTimeArray = kasanDefault.match("<時間帯終了>(.*)</時間帯終了>");
    const serviceAdditionStr = kasanDefault.match("<加算>(.*)</加算>");
    let serviceAdditionValue = serviceAdditionStr && serviceAdditionStr[1] ? serviceAdditionStr[1].split(",") : [];
    let startTimeValue = startTimeArray && startTimeArray[1] ? startTimeArray[1].substring(0, 2) + ":" + startTimeArray[1].substring(2) : "";
    let endTimeValue = endTimeArray && endTimeArray[1] ? endTimeArray[1].substring(0, 2) + ":" + endTimeArray[1].substring(2) : "";
    const listServiceAdditionNew = getListAddition(props.serviceAddition, value.syuruiCd);

    // delete serviceAddition not in list serviceAddition
    const listName = listServiceAdditionNew.map((item) => item.addition);
    serviceAdditionValue = serviceAdditionValue.filter((item) =>
      listName.includes(item)
    );
    const listServiceNaiyouNew = processListServiceNaiyou(
      serviceAdditionValue,
      listServiceAdditionNew
    );
    if (
      (startTimeValue == "" || startTimeValue == "00:00") &&
      (endTimeValue == "" || endTimeValue == "00:00")
    ) {
      startTimeValue = startTime;
      endTimeValue = endTime;
    }
    const countMinute = countMinutes(startTimeValue, endTimeValue);
    const serviceNaiyouNew = getServiceNaiyouByTime(countMinute, listServiceNaiyouNew);
    setSyuruiCd(value.syuruiCd);
    if (isListMinNotEmpty(listServiceNaiyouNew)) {
      setServiceNaiyou(serviceNaiyouNew ? serviceNaiyouNew.serviceNaiyou : "");
      setRyaku(serviceNaiyouNew ? serviceNaiyouNew.serviceNaiyou : "");
    }
    setListServiceNaiyou(listServiceNaiyouNew);
    setStartTime(startTimeValue);
    setEndTime(endTimeValue);
    // setServiceAddition(serviceAdditionValue);
    setJigyousyoNoServiceKikan(value.jigyousyoNo);
    setRyakuServiceKikan(value.ryaku);
    setSidSelected(value.id);
    setVisibleServiceKikan(false);
    setListServiceAddition(listServiceAdditionNew);
    props.onShowAddMultiModal();
  };

  function processListServiceNaiyou(serviceAdditionSelected, serviceAdditionList) {
    // check PTOT
    const isPTOT = serviceAdditionList.some((item) => (item.addition ? item.addition.includes("PTOT") : false));
    let listSerViceNaiyouNew = props.serviceNaiyou;
    if (isPTOT) {
      let isPTOTSelected = false;
      serviceAdditionSelected.forEach((item) => {
        if (item.includes("PTOT")) {
          isPTOTSelected = true;
        }
      });

      if (isPTOTSelected) {
        listSerViceNaiyouNew = props.serviceNaiyou.filter(
          (item) =>
            item.serviceNaiyou.includes("PTOT") ||
            item.serviceNaiyou === "定期巡回訪看"
        );
      } else {
        listSerViceNaiyouNew = props.serviceNaiyou.filter(
          (item) =>
            !item.serviceNaiyou.includes("PTOT") ||
            item.serviceNaiyou === "定期巡回訪看"
        );
      }
    }
    // eslint-disable-next-line no-undef
    return [...listSerViceNaiyouNew];
  }

  function countMinutes(startTime, endTime) {
    const minutesOfStartTime = startTime ? parseInt(startTime.substring(0, 2)) * 60 + parseInt(startTime.substring(3)) : 0;
    const minutesOfEndTime = endTime ? parseInt(endTime.substring(0, 2)) * 60 + parseInt(endTime.substring(3)) : endTime;
    return minutesOfEndTime - minutesOfStartTime;
  }

  function getServiceNaiyouByTime(minutes, listServiceNaiyou) {
    // let serviceNaiyouSelected = listServiceNaiyou.find(
    //   (item) => item.min == null
    // );
    // if (serviceNaiyouSelected) {
    //   return serviceNaiyouSelected;
    // }
    const listServiceNaiyouSorted = _.orderBy(listServiceNaiyou, "min", "asc");
    const serviceNaiyouSelected = listServiceNaiyouSorted.find((item) => item.min >= minutes);
    return serviceNaiyouSelected;
  }

  function getServiceNaiyou(startTime, endTime, listServiceNaiyou) {
    const minutes = countMinutes(startTime, endTime);
    const serviceNaiyouObj = getServiceNaiyouByTime(minutes, listServiceNaiyou);
    return serviceNaiyouObj ? serviceNaiyouObj.serviceNaiyou : "";
  }

  function handleShowServiceKikan() {
    props.onHideAddMultiModal();
    setVisibleServiceKikan(true);
  }

  function getListAddition(list, syuruiCd) {
    if (syuruiCd == null) {
      return list;
    }
    let listSyuruiCd = [];
    return list.filter((item) => {
      if (checkYobou(props.menu)) {
        listSyuruiCd = item.yobouServiceSyuruiCd
          ? item.yobouServiceSyuruiCd.split(";")
          : [];
      } else {
        listSyuruiCd = item.kaigoServiceSyuruiCd
          ? item.kaigoServiceSyuruiCd.split(";")
          : [];
      }
      if (listSyuruiCd.includes(syuruiCd)) return item;
    });
  }

  return (
    (<Fragment>
      <Modal
        open={props.visible}
        title={props.title}
        onCancel={() => handleCancel()}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        footer={[
          <Button
            key="back"
            type="primary"
            style={{
              backgroundColor: "#ffffff",
              color: "#616161",
              border: "1px solid #dbdbdb",
            }}
            onClick={() => handleCancel()}
          >
            キャンセル
          </Button>,
          <Button
            key="back"
            type="primary"
            onClick={handleOk}
            disabled={
              frequency.every((element) => element === false) ||
              (jigyousyoNoServiceKikan.trim().length === 0 && ryakuServiceKikan.trim().length === 0) ||
              isErrorTime
            }
          >
            {props.isClickAddBulk ? "追加する" : "修正する"}
          </Button>,
        ]}
        width={900}
      >
        <div>
          <div>
            <Row>
              <Col span={1}>{/* <Checkbox></Checkbox> */}</Col>
              <Col span={4} style={{ marginLeft: -8 }}>
                曜日
              </Col>
              <Col span={19}>
                {frequency
                  .map((item, index) => (
                    <Checkbox key={index} value={index} checked={item} onChange={handleChangeDayOfWeek}>
                      {DAY_OF_WEEK[index]}
                    </Checkbox>
                  ))
                  .filter((item, index) => index !== 0)}
                <Checkbox key={0} value={0} checked={frequency[0]} onChange={handleChangeDayOfWeek}>
                  {DAY_OF_WEEK[0]}
                </Checkbox>
              </Col>
            </Row>
            {/* <Row>{isErrorTime ? <div>hihi</div> : ""}</Row> */}
            <Row style={{ marginTop: 10, display: "flex", alignItems: "center" }}>
              <Col span={1}>{/* <Checkbox></Checkbox> */}</Col>
              <Col span={3} style={{ marginLeft: -8 }}>
                開始時間
              </Col>
              <Col span={4}>
                <TimeKBCus timeString={startTime} handleInput={handleChangeStartTime} />
              </Col>
              <Col span={16} style={{ display: "flex", alignItems: "center" }}>
                <div style={{ marginRight: 10 }}>終了時間</div>
                <TimeKBCus timeString={endTime} handleInput={handleChangeEndTime} />
                <AddTime timeSetting={props.timeSetting} onClickTime={handleOnClickTime} />
                {isErrorTime ? <div style={{ marginLeft: 20, color: "red" }}>{"開始時間 < 終了時間"}</div> : ""}
              </Col>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <Col span={14}>
                <Row style={{ display: "flex", alignItems: "center" }}>
                  <Col span={1}></Col>
                  <Col span={6}>サービス種類</Col>
                  <Col span={9}>
                    <Input
                      readOnly={true}
                      style={{
                        width: 150,
                        cursor: "not-allowed",
                        backgroundColor: props.dataInit.swColorBak || "white",
                      }}
                      value={props.dataInit.serviceType || ""}
                    />
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Col span={1}>{/* <Checkbox></Checkbox> */}</Col>
                  <Col span={6}>サービス内容</Col>
                  <Col span={9}>
                    <Select value={serviceNaiyou} style={{ width: 150 }} onChange={handleChangeServiceNaiyou}>
                      {shortType &&
                        selectOptionArr.map((item) => (
                          <Option key={item.id} value={item.serviceNaiyou}>
                            {item.serviceNaiyou}
                          </Option>
                        ))}
                      {!shortType &&
                        props.serviceNaiyou.map((item) => (
                          <Option key={item.id} value={item.serviceNaiyou}>
                            {item.serviceNaiyou}
                          </Option>
                        ))}
                    </Select>
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Col span={1}>{/* <Checkbox></Checkbox> */}</Col>
                  <Col span={6}>サービス事業所</Col>
                  <Col span={17}>
                    <Input
                      style={{ width: 280, backgroundColor: "#f5f5f5" }}
                      value={(jigyousyoNoServiceKikan || "") + "   " + (ryakuServiceKikan || "")}
                      onClick={handleShowServiceKikan}
                    />
                    <Button type="primary" onClick={handleShowServiceKikan}>
                      事
                    </Button>
                  </Col>
                </Row>
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Col span={1}>{/* <Checkbox></Checkbox> */}</Col>
                  <Col span={6}>文字入力</Col>
                  <Col span={9}>
                    <Input style={{ width: 150 }} value={ryaku} onChange={handleChangeRyaku} />
                  </Col>
                </Row>
              </Col>
              <Col span={10} style={{ display: "flex" }}>
                <div style={{ marginRight: 10 }}>加算</div>
                <div
                  style={{
                    border: "2px solid #ccc",
                    width: "300px",
                    height: "400px",
                    overflowY: "scroll",
                  }}
                >
                  {listServiceAddition.map((item) => (
                    // eslint-disable-next-line react/jsx-key
                    (<div style={{ marginLeft: 2 }}>
                      <Checkbox
                        key={item.id}
                        value={item.addition}
                        checked={serviceAddition.includes(item.addition)}
                        onChange={handleChangeServiceAddition}
                      >
                        {item.addition}
                      </Checkbox>
                    </div>)
                  ))}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
      <ServiceKikanWeekly
        visible={visibleServiceKikan}
        serviceType={props.dataInit.serviceType}
        hideModal={() => {
          props.onShowAddMultiModal();
          setVisibleServiceKikan(false);
        }}
        onSaveServiceKikan={onSaveServiceKikan}
        syuruiCd={serviceSyuruiCd}
        className="break-word-office"
      />
    </Fragment>)
  );
}

export { AddMultiWeeklyModal };
