import { notification } from "antd";
import React, { memo, useEffect } from "react";
import { connect } from "react-redux";

import "./assets/less/styles.less";
import Layout from "./layouts";

function App({ authlife, error }) {
  useEffect(() => {
    if (error.message) {
      notification.error({
        message: "Error",
        description: error.message,
        placement: "bottomLeft",
        duration: 3,
      });
    }
  }, [error]);

  return (
    <Layout
      isUserLoggedIn={authlife.isUserLoggedIn}
      sessionTimeout={authlife.sessionTimeout}
      jwtTokenType={authlife.jwtTokenType}
    />
  );
}

const mapStateToProps = () => (state) => ({
  authlife: state.authlife,
  error: state.error,
});

export default connect(mapStateToProps)(
  memo(App, (props, nextProps) => {
    if (props.authlife.isUserLoggedIn !== nextProps.authlife.isUserLoggedIn) {
      return false;
    }
    if (props.error.mesasge !== nextProps.error.mesasge) {
      return false;
    }
    return true;
  })
);
