import { Button, Checkbox, Col, DatePicker, Divider, Input, Modal, Row, Select, Spin, Table } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toKatakana } from "wanakana";
import { zipMasterSearchCreator } from "../../actions/zipMasterActions/actionCreators";
import TimeKBCus from "../../components/WeeklyPlan/TimeKBCus";
import * as addServicesContants from "../../constants/AddServicesModal/addServicesModalWithConstant";
import { ServiceKikanMasterActions } from "../../redux/actions/serviceKikanMaster";

const { Option } = Select;
const { MonthPicker } = DatePicker;
const { TextArea } = Input;
const SYURUI_CD_SISETU_KUBUN = ["23", "26", "2A", "2B", "53", "55"];
const SYURUI_CD_JIGYOUSYO_KUBUN_5 = [
  "71",
  "72",
  "78",
  "73",
  "74",
  "75",
  "38",
  "39",
  "76",
  "28",
  "77",
  "32",
  "37",
  "36",
  "54",
  "68",
  "69",
  "79",
  "78",
];
const SYURUI_CD_JIGYOUSYO_KUBUN_6 = ["A1", "A2", "A3", "A4", "A5", "A6", "A7", "A8", "AF"];
const CHECK_TAISEI_MASTER = ["id", "sid", "tekiyouYears", "tikiKubun", "taniTanka"]
const ADD = 1;
const EDIT = 2;
const DUPLICATE = 3;
const TIME_DEFAULT = addServicesContants.TIME_DEFAULT;
function ServiceKikanMasterModal(props) {
  const { startTime, endTime, setStartTime, setEndTime, changeStartTime, changeEndTime } = props;
  const [serviceMaster, setServiceMaster] = useState({});
  const [serviceMonthly, setServiceMonthly] = useState({});
  const [kasanDefault, setKasanDefault] = useState({});
  const [hojinInfo, setHojinInfo] = useState({});
  const [serviceMonthlyList, setServiceMonthlyList] = useState([]);
  const [serviceMonthlyIndex, setServiceMonthlyIndex] = useState();
  const [taiseiMasters, setTaiseiMasters] = useState([]);

  // zip search modal
  const [inputZipSearch, setInputZipSearch] = useState("");
  const [rowZipActive, setRowZipActive] = useState(null);
  const [zipRecord, setZipRecord] = useState(null);
  const [visibleZip, setVisibleZip] = useState(null);

  // addition setting modal
  const [visibleAdditionSetting, setVisibleAdditionSetting] = useState(false);
  const [additions, setAdditions] = useState([]);

  // hojin modal
  const [visibleHojinInfo, setVisibleHojinInfo] = useState(false);
  const [inputHojinSearch, setInputHojinSearch] = useState("");
  const [rowHojinIndex, setRowHojinIndex] = useState(null);
  const [hojinRecord, setHojinRecord] = useState(null);

  // memo modal
  const [visibleMemo, setVisibleMemo] = useState(false);
  const [memo, setMemo] = useState(null);

  // year month modal
  const visibleYmType = { HIDE: 0, ADD: 1, EDIT: 2 };
  const [visibleYearMonth, setVisibleYearMonth] = useState(visibleYmType.HIDE);
  const [yearMonth, setYearMonth] = useState(null);

  // enable/disable item
  const [disabled, setDisabled] = useState({});

  // delete service kikan monthly
  const [visibleDelete, setVisibleDelete] = useState();
  const [confirmDelete, setConfirmDelete] = useState();

  const [isValueChange, setIsValueChange] = useState();
  const [visibleConfirm, setVisibleConfirm] = useState();
  const [isRenderFirst, setIsRenderFirst] = useState();
  const [visibleConfirmHojinMaster, setVisibleConfirmHojinMaster] = useState();
  const [hojinExists, setHojinExists] = useState();
  const [isLoadListHojinMaster, setIsLoadListHojinMaster] = useState();
  const [isDisableAddOkYear, setIsDisableOkYear] = useState(false);

  const [isSaveCheckValue, setIsSaveCheckValue] = useState({});
  const [serviceMonthlyFirst, setServiceMonthlyFirst] = useState([]);
  const [isValueFist, setIsValueFirst] = useState(true);

  useEffect(() => {
    props.checkHandleChangeData && props.checkHandleChangeData(isValueChange);
  }, [isValueChange]);

  useEffect(() => {
    const arrServiceMonthlyNull = [];
    if (taiseiMasters.length > 0) {
      if (isValueFist) {
        taiseiMasters.map((item) => {
          const camelCaseItem = _.camelCase(item.columnName);
          arrServiceMonthlyNull.push(camelCaseItem);
        });
        setIsValueFirst(false);
        setServiceMonthlyFirst(arrServiceMonthlyNull);
      }
    }
  }, [taiseiMasters]);

  useEffect(() => {
    setIsValueFirst(true);
  }, [props.visible]);

  const [startTimeKikan, setStartTimeKikan] = useState(null);
  const [endTimeKikan, setEndTimeKikan] = useState(null);

  const handleCheckStartTime = (time) => {
    setStartTimeKikan(time);
    setKasanDefault((prev) => ({ ...prev, startTime: time }));
  };

  const handleCheckEndTime = (time) => {
    setEndTimeKikan(time);
    setKasanDefault((prev) => ({ ...prev, endTime: time }));
  };

  // get service kikan master by id when props.visible change
  useEffect(() => {
    // handle open modal サービス機関マスタ編集モーダルが開いているとき
    if (props.visible) {
      setIsRenderFirst(true);
      setIsSaveCheckValue({});
      props.getServiceSyuruiMasters();
      props.searchHojinInfoMasters();
      if (props.id) {
        props.getServiceKikanById(props.id);
        props.checkLinkedWeeklySchedule(props.id);
        if (props.modeModify === EDIT) props.getListServiceKikanMonthly(props.id);
        // if (props.modeModify === DUPLICATE)
        //   createYearMonthInit();
        setDisabled({ jigyousyoNo: true, syuruiCd: true, syuruiChangeButton: true });
      } else {
        createYearMonthInit();
        props.getInitialServiceKikan();
        setServiceMaster({});
        setServiceMonthly({});
        setKasanDefault({});
        setDisabled({});
      }
    }
    // handle close modal 
    // clear state モーダルがoffのときに初期化
    else {
      setServiceMonthlyList([]);
      setServiceMonthlyIndex(null);
    }
  }, [props.visible]);

  // set enable/disable when service kikan master is linked with weekly schedule
  useEffect(() => {
    if (props.linkedWeeklySchedule && props.linkedWeeklySchedule.isLinked) {
      setDisabled((prevValue) => ({ ...prevValue, syuruiChangeButton: true }));
    } else {
      setDisabled((prevValue) => ({ ...prevValue, syuruiChangeButton: false }));
    }
  }, [props.linkedWeeklySchedule]);

  // set service kikan master and kasan default when props.serviceMaster change
  useEffect(() => {
    if (props.visible) {
      setServiceMaster(props.serviceMaster || {});
      if (!props.id && props.syuruiCd) {
        setServiceMaster((prevValue) => ({ ...prevValue, syuruiCd: props.syuruiCd }));
      }
      if (props.modeModify === DUPLICATE) {
        props.getMaxSortNum();
        createYearMonthInit();
      }
      setKasanDefault(
        props.serviceMaster
          ? extractKasanDefault(props.serviceMaster.kasanDefault)
          : {}
      );
    }
  }, [props.serviceMaster]);

  useEffect(() => {
    if (props.sortNumCheck) {
      if (props.serviceKikanSortNum) {
        setServiceMaster((prevValue) => ({ ...prevValue, id: null, sort_num: props.serviceKikanSortNum }));
      } else {
        setServiceMaster((prevValue) => ({ ...prevValue, id: null }));
      }
    }
  }, [props.sortNumCheck]);

  // set linked hojin info master
  useEffect(() => {
    setHojinInfo(props.hojinInfo || {});
    if (props.hojinInfo != undefined && !_.isEmpty(props.hojinInfo))
      setServiceMaster((prevValue) => ({ ...prevValue, hojinInfoMasterId: props.hojinInfo.id }));
    else setServiceMaster((prevValue) => ({ ...prevValue, hojinInfoMasterId: null }));
  }, [props.hojinInfo]);

  // get linked hojin info master
  useEffect(() => {
    if (props.visible) {
      props.getHojinInfoMasterById(serviceMaster.hojinInfoMasterId);
    }
  }, [serviceMaster.hojinInfoMasterId]);

  // Check error when save
  useEffect(() => {
    if (props.isReload) {
      props.onOk();
      setIsValueChange(false);
    }
  }, [props.isReload]);

  // handle when zip search result change
  // if there is only 1 result and zip search dialog is close, set value to serviceMaster
  // if number of result is other than 1 and zip search dialog is close, open it
  useEffect(() => {
    const zipSearch = props.zipSearch;
    if (zipSearch.length === 1 && visibleZip === false) {
      let record = zipSearch[0];
      if (record) {
        setServiceMaster((prevValue) => ({
          ...prevValue,
          zip: record.zip,
          address1: record.address,
        }));
        // change tiki kubun
        if (serviceMaster.syuruiCd) {
          if (record.address) {
            props.getTikibetuTikikubun({
              tekiyouYears: serviceMonthly.tekiyouYears,
              address: record.address,
            });
          } else {
            setServiceMonthly((prevValue) => ({ ...prevValue, tikiKubun: 5 }));
          }
        }
      }
      setZipRecord(null);
    } else if (visibleZip === false) {
      setVisibleZip(true);
    }
  }, [props.zipSearch]);

  // set service monthly list
  // 体制情報の月ごとの情報 新規はない
  useEffect(() => {
    setServiceMonthlyList(props.serviceMonthlyList || []);
  }, [props.serviceMonthlyList]);

  // change service monthly list
  useEffect(() => {
    const index = serviceMonthlyList.length
      ? serviceMonthlyIndex
        ? serviceMonthlyIndex
        : 0
      : null;
    setServiceMonthlyIndex(index);
    setServiceMonthly(serviceMonthlyList[index] || {});
  }, [serviceMonthlyList]);

  // change selected service kikan monthly
  useEffect(() => {
    setServiceMonthly(serviceMonthlyList[serviceMonthlyIndex] || {});
  }, [serviceMonthlyIndex]);

  useEffect(() => {
    if (
      props.visible &&
      serviceMaster.syuruiCd &&
      !_.isNil(serviceMonthlyList[serviceMonthlyIndex])
    ) {
      const isSisetuKubunExists = !!serviceMonthly.sisetuKubun;
      if (
        SYURUI_CD_SISETU_KUBUN.includes(serviceMaster.syuruiCd) &&
        isSisetuKubunExists &&
        !_.isEmpty(serviceMonthly.sisetuKubun)
      ) {
        const bunruiCd = serviceMaster.syuruiCd + serviceMonthly.sisetuKubun;
        if (!serviceMonthly.sid) {
          setServiceMonthly((prevValue) => ({ ...prevValue, sisetuKubun: null }));
        }
        props.getTaiseiMaster({
          tekiyouYears: serviceMonthly.tekiyouYears,
          syuruiCd: serviceMaster.syuruiCd,
          bunruiCd,
        });
      } else {
        props.getTaiseiMaster({
          tekiyouYears: serviceMonthly.tekiyouYears,
          syuruiCd: serviceMaster.syuruiCd,
        });
      }
    }
  }, [serviceMonthly.id, serviceMonthly.tekiyouYears, serviceMaster.syuruiCd]);

  useEffect(() => {
    if (props.tikibetuTikikubun) {
      setServiceMonthly((prevValue) => ({ ...prevValue, tikiKubun: props.tikibetuTikikubun.tikiKubun }));
      // change tani tanka
      if (
        serviceMaster.syuruiCd &&
        serviceMonthly.tekiyouYears &&
        props.tikibetuTikikubun.tikiKubun
      ) {
        props.getTikiKubunMaster({
          tikiKubun: props.tikibetuTikikubun.tikiKubun,
          tekiyouYears: serviceMonthly.tekiyouYears,
          seidoKubun: "介護保険",
          syuruiCd: serviceMaster.syuruiCd,
        });
      }
    }
  }, [props.tikibetuTikikubun]);

  useEffect(() => {
    if (props.visible && props.tikiKubunMaster) {
      setServiceMonthly((prevValue) => ({ ...prevValue, taniTanka: props.tikiKubunMaster.taniTanka, tikiKubun: props.tikiKubunMaster.tikiKubun }));

      if (serviceMonthlyIndex != null && serviceMonthlyIndex != undefined) {
        setServiceMonthlyList((prevList) => {
          prevList[serviceMonthlyIndex] = {
            ...prevList[serviceMonthlyIndex],
            taniTanka: props.tikiKubunMaster.taniTanka,
            tikiKubun: props.tikiKubunMaster.tikiKubun,
          };
          return prevList;
        });
      }
    }
  }, [props.tikiKubunMaster]);

  useEffect(() => {
    if (props.taiseiMasters) {
      let taiseimaster = props.taiseiMasters;
      if (serviceMonthly && !serviceMonthly.sid) {
        let serviceMonthlyClone = serviceMonthly;

        if (SYURUI_CD_SISETU_KUBUN.includes(serviceMaster.syuruiCd)) {
          for (let i = 0; i < taiseimaster.length; i++) {
            if (
              _.camelCase(taiseimaster[i].columnName) == "sisetuKubun" &&
              serviceMonthly.sisetuKubun == null
            ) {
              let sisetuKubunIndex = setFirstIndexNaiyou(
                taiseimaster[i].naiyou
              );
              if (!_.isEmpty(sisetuKubunIndex)) {
                const bunruiCd = serviceMaster.syuruiCd + sisetuKubunIndex;
                props.getTaiseiMaster({
                  tekiyouYears: serviceMonthly.tekiyouYears,
                  syuruiCd: serviceMaster.syuruiCd,
                  bunruiCd,
                });
              } else {
                props.getTaiseiMaster({
                  tekiyouYears: serviceMonthly.tekiyouYears,
                  syuruiCd: serviceMaster.syuruiCd,
                });
              }

              serviceMonthlyClone.sisetuKubun = setFirstIndexNaiyou(
                taiseimaster[i].naiyou
              );
              setServiceMonthly(serviceMonthlyClone);

              break;
            }
            if (_.camelCase(taiseimaster[i].columnName) != "sisetuKubun") {
              serviceMonthlyClone[_.camelCase(taiseimaster[i].columnName)] =
                setFirstIndexNaiyou(taiseimaster[i].naiyou);
            }
          }
        } else {
          for (let i = 0; i < taiseimaster.length; i++) {
            serviceMonthlyClone[_.camelCase(taiseimaster[i].columnName)] =
              setFirstIndexNaiyou(taiseimaster[i].naiyou);
          }
        }

        setServiceMonthly(serviceMonthlyClone);
      }

      setTaiseiMasters([...props.taiseiMasters]);
    }
  }, [props.taiseiMasters]);

  useEffect(() => {
    // change tani tanka
    if (
      serviceMaster.syuruiCd &&
      serviceMonthly.tekiyouYears &&
      serviceMonthly.tikiKubun &&
      isRenderFirst != undefined &&
      !isRenderFirst
    ) {
      props.getTikiKubunMaster({
        tikiKubun: serviceMonthly.tikiKubun,
        tekiyouYears: serviceMonthly.tekiyouYears,
        seidoKubun: "介護保険",
        syuruiCd: serviceMaster.syuruiCd,
      });
      setIsRenderFirst(false);
    }
  }, [serviceMaster.syuruiCd]);

  useEffect(() => {
    if (isLoadListHojinMaster) {
      onOpenHojinInfo();
      setIsLoadListHojinMaster(false);
    }
  }, [props.hojinInfoMasters]);

  const setFirstIndexNaiyou = (naiyou) => {
    let index = 1;
    if (naiyou) {
      let taiseiNaiyou = naiyou.split(",");
      for (let i = 0; i < taiseiNaiyou.length; i++) {
        if (taiseiNaiyou[i]) {
          if (i < 9) {
            index = i + 1;
          }
          // from 10 → A, 11 → B...
          else {
            index = String.fromCharCode(65 + index - 9);
          }

          break;
        }
      }
    }

    return index + "";
  };

  const changeServiceMonthlyIndex = (newIndex) => {
    // save current serviceMonthly to serviceMonthlyList
    setServiceMonthlyList((prevList) => {
      prevList[serviceMonthlyIndex] = { ...serviceMonthly };
      return prevList;
    });
    // set index
    setServiceMonthlyIndex(newIndex);
  };

  // START ADDITION DIALOG 加算設定モーダル
  const onOkAdditionSetting = () => {
    setVisibleAdditionSetting(false);

    // delete serviceAddition not in list serviceAddition
    const listName = props.serviceAdditions.map((item) => item.addition);
    const serviceAdditionValue = additions.filter((item) => listName.includes(item));
    setKasanDefault((prevValue) => ({ ...prevValue, addition: serviceAdditionValue.join(",") }));
    setIsValueChange(true);
  };

  const onCancelAdditionSetting = () => {
    setVisibleAdditionSetting(false);
  };

  const onOpenAdditionSetting = () => {
    props.getServiceAdditions(serviceMaster.syuruiCd);
    if (kasanDefault.addition) {
      setAdditions([...kasanDefault.addition.split(",").filter((o) => o)]);
    } else {
      setAdditions([]);
    }
    setVisibleAdditionSetting(true);
  };

  const onRowAdditionClick = (record) => {
    const additionClones = [...additions];
    let checked = true;

    for (let i = 0; i < additionClones.length; i++) {
      if (additionClones[i] === record.addition) {
        additionClones.splice(i, 1);
        checked = false;
        break;
      }
    }

    if (checked) {
      additionClones.push(record.addition);
    }

    setAdditions([...additionClones]);
  };

  // 22-1 加算設定
  const renderAdditionSettingDialog = () => {
    return (
      <Modal
        destroyOnClose={true}
        closable={false}
        maskClosable={false}
        zIndex={1001}
        getContainer={document.body}
        open={visibleAdditionSetting}
        onCancel={onCancelAdditionSetting}
        title={"選択してください。"}
        footer={[
          <Row key={1} type="flex" justify="end">
            <div style={{ paddingRight: 8 }}>
              <Button onClick={onCancelAdditionSetting}>{"戻る"}</Button>
            </div>
            <div>
              <Button type={"primary"} onClick={onOkAdditionSetting}>
                {"保存"}
              </Button>
            </div>
          </Row>,
        ]}
      >
        <Table
          columns={[
            {
              title: "id",
              dataIndex: "id",
              key: "id",
              width: 30,
              render: (text, record) => {
                return (
                  <Checkbox
                    value={text}
                    checked={
                      _.find(
                        additions,
                        (addition) => addition === record.addition
                      ) !== undefined
                    }
                    onChange={() => onRowAdditionClick(record)}
                  />
                );
              },
            },
            {
              title: "addition",
              dataIndex: "addition",
            },
          ]}
          dataSource={props.serviceAdditions}
          loading={props.loading}
          size="small"
          pagination={{ pageSize: 8 }}
          showHeader={false}
          rowKey={(record) => record.id}
          onRow={(record) => {
            return {
              onClick: () => {
                onRowAdditionClick(record);
              }, // click row
            };
          }}
        />
      </Modal>
    );
  };
  // END ADDITION DIALOG

  // START HOJIN DIALOG
  const onOkHojinInfo = () => {
    if (hojinRecord) {
      setServiceMaster((prevValue) => ({ ...prevValue, hojinInfoMasterId: hojinRecord.id }));
    }
    setVisibleHojinInfo(false);
    setIsValueChange(true);
  };

  const onCancelHojinInfo = () => {
    setVisibleHojinInfo(false);
  };

  const onOpenHojinInfo = () => {
    if (props.hojinInfoMasters && serviceMaster.hojinInfoMasterId) {
      const index = _.findIndex(
        props.hojinInfoMasters,
        (hojin) => hojin.id === serviceMaster.hojinInfoMasterId
      );
      setRowHojinIndex(index);
      setHojinRecord(props.hojinInfoMasters[index]);
    } else {
      setInputHojinSearch("");
      setRowHojinIndex(null);
      setHojinRecord(null);
    }
    setVisibleHojinInfo(true);
  };

  function loadListHojinMaster() {
    props.searchHojinInfoMasters();
    setIsLoadListHojinMaster(true);
  }

  const onChangeInputHojinSearch = (e) => {
    setInputHojinSearch(e.target.value);
  };

  const onRowHojinClick = (record) => {
    setHojinRecord(record);
  };

  // 12-1
  const renderHojinInfoDialog = () => {
    const defaultCurrent = Math.ceil(rowHojinIndex / 10);
    return (
      <Modal
        closable={false}
        maskClosable={false}
        zIndex={1001}
        open={visibleHojinInfo}
        onCancel={onCancelHojinInfo}
        getContainer={document.body}
        title={
          <div>
            <h3 style={{ display: "inline", paddingRight: 16 }}>検索文字</h3>
            <Input onChange={onChangeInputHojinSearch} value={inputHojinSearch} style={{ maxWidth: 280, marginRight: 8 }} />
            <Button type="primary" onClick={() => props.searchHojinInfoMasters(inputHojinSearch)}>
              検索
            </Button>
          </div>
        }
        footer={[
          <Button key={"cancel"} onClick={onCancelHojinInfo}>
            {"戻る"}
          </Button>,
          <Button key={"ok"} type={"primary"} onClick={onOkHojinInfo}>
            {"OK"}
          </Button>,
        ]}
      >
        <Table
          columns={[
            {
              title: "ryaku",
              dataIndex: "ryaku",
            },
          ]}
          dataSource={props.hojinInfoMasters}
          loading={props.loading}
          size="small"
          pagination={{ pageSize: 10, defaultCurrent }}
          showHeader={false}
          rowKey={(record) => record.id}
          onRow={(record) => {
            return {
              onClick: () => {
                onRowHojinClick(record);
              }, // click row
            };
          }}
          rowClassName={(record) =>
            hojinRecord && record.id === hojinRecord.id
              ? "pljp-table-row-hover"
              : ""
          }
        />
      </Modal>
    );
  };
  // END HOJIN DIALOG

  // START MEMO DIALOG
  const onOkMemo = () => {
    setVisibleMemo(false);
    setServiceMaster((prevValue) => ({
      ...prevValue,
      comment: memo,
    }));
  };

  const onCancelMemo = () => {
    setVisibleMemo(false);
  };

  const onOpenMemo = () => {
    setMemo(serviceMaster.comment);
    setVisibleMemo(true);
  };

  // 4-1
  const renderMemoDialog = () => {
    return (
      <Modal
        closable={false}
        maskClosable={false}
        zIndex={1001}
        open={visibleMemo}
        onCancel={onCancelMemo}
        title={"コメントを入力してください。"}
        getContainer={document.body}
        footer={[
          <Row key={1} type="flex" justify="end">
            <div style={{ paddingRight: 8 }}>
              <Button onClick={onCancelMemo}>{"戻る"}</Button>
            </div>
            <div>
              <Button type={"primary"} onClick={onOkMemo}>
                {"保存"}
              </Button>
            </div>
          </Row>,
        ]}
      >
        <TextArea
          autoSize={{ minRows: 5 }}
          value={memo}
          onChange={(e) => {
            setMemo(e.target.value);
          }}
        />
      </Modal>
    );
  };
  // END MEMO DIALOG

  // START YEAR MONTH DIALOG
  const onOkYearMonth = () => {
    const tekiyouYears = yearMonth.replace("-", "");
    let serviceMonthlyClone,
      serviceMonthlyListClone = [...serviceMonthlyList],
      serviceMonthlyIndexClone = serviceMonthlyIndex;
    // case add
    if (visibleYearMonth === visibleYmType.ADD) {
      serviceMonthlyClone = { sid: serviceMaster.id, tekiyouYears };

      taiseiMasters.map(
        (item, index) =>
        (serviceMonthlyClone[_.camelCase(item.columnName)] =
          serviceMonthly[_.camelCase(item.columnName)])
      );
      // change tiki kubun
      if (serviceMaster.syuruiCd) {
        if (serviceMaster.address1) {
          props.getTikibetuTikikubun({
            tekiyouYears: tekiyouYears,
            address: serviceMaster.address1,
          });
        } else {
          serviceMonthlyClone.tikiKubun = 5;
          serviceMonthlyClone.taniTanka = 10;
          // get tani tanka
          // props.getTikiKubunMaster({
          //   tikiKubun: serviceMonthlyClone.tikiKubun,
          //   tekiyouYears: tekiyouYears,
          //   seidoKubun: "介護保険",
          //   syuruiCd: serviceMaster.syuruiCd
          // });
        }
      }

      // case edit
    } else if (visibleYearMonth === visibleYmType.EDIT) {
      serviceMonthlyClone = { ...serviceMonthly, tekiyouYears };
      serviceMonthlyListClone.splice(serviceMonthlyIndexClone, 1);

      if (serviceMaster.syuruiCd) {
        serviceMonthlyListClone.splice(serviceMonthlyIndexClone, 1);
        if (serviceMaster.address1) {
          props.getTikibetuTikikubun({
            tekiyouYears: tekiyouYears,
            address: serviceMaster.address1,
          });
        } else {
          serviceMonthlyClone.tikiKubun = 5;
          serviceMonthlyClone.taniTanka = 10;
        }
      }
    }

    serviceMonthlyIndexClone = _.findIndex(
      serviceMonthlyListClone,
      (e) => e.tekiyouYears <= tekiyouYears
    );
    if (serviceMonthlyIndexClone === -1) {
      serviceMonthlyIndexClone = serviceMonthlyListClone.length;
    }
    // insert serviceMonthlyClone at serviceMonthlyIndexClone
    serviceMonthlyListClone.splice(
      serviceMonthlyIndexClone,
      0,
      serviceMonthlyClone
    );
    // set state
    changeServiceMonthlyIndex(serviceMonthlyIndexClone);
    setServiceMonthlyList(serviceMonthlyListClone);
    // close dialog
    setVisibleYearMonth(visibleYmType.HIDE);
  };

  // Create Year Month init
  const createYearMonthInit = () => {
    const tekiyouYears = "202104";
    let serviceMonthlyClone,
      serviceMonthlyListClone = [],
      serviceMonthlyIndexClone;
    // case add
    serviceMonthlyClone = { sid: null, tekiyouYears };
    // change tiki kubun
    if (props.syuruiCd) {
      if (props.modeModify === DUPLICATE && props.serviceMaster && props.serviceMaster.address1) {
        props.getTikibetuTikikubun({
          tekiyouYears: tekiyouYears,
          address: props.serviceMaster.address1,
        });
      } else {
        serviceMonthlyClone.tikiKubun = 5;
        serviceMonthlyClone.taniTanka = 10;
      }

      // setIsRenderFirst(true);
      // get tani tanka
      // props.getTikiKubunMaster({
      //   tikiKubun: serviceMonthlyClone.tikiKubun,
      //   tekiyouYears: tekiyouYears,
      //   seidoKubun: "介護保険",
      //   syuruiCd: props.syuruiCd
      // });
    }
    // find index to insert serviceMonthlyClone
    serviceMonthlyIndexClone = _.findIndex(
      serviceMonthlyListClone,
      (e) => e.tekiyouYears <= tekiyouYears
    );
    if (serviceMonthlyIndexClone === -1) {
      serviceMonthlyIndexClone = serviceMonthlyListClone.length;
    }
    // insert serviceMonthlyClone at serviceMonthlyIndexClone
    serviceMonthlyListClone.splice(
      serviceMonthlyIndexClone,
      0,
      serviceMonthlyClone
    );
    // set state
    changeServiceMonthlyIndex(serviceMonthlyIndexClone);
    setServiceMonthlyList(serviceMonthlyListClone);
  };

  const onCancelYearMonth = () => {
    setVisibleYearMonth(visibleYmType.HIDE);
    setIsDisableOkYear(false);
  };

  const onOpenYearMonth = (visible) => {
    setYearMonth(serviceMonthly.tekiyouYears);
    setVisibleYearMonth(visible);
    setIsDisableOkYear(true);
  };

  function handleChangeYearMonth(date, dateString) {
    const formatDate = dateString.substr(0, 4) + dateString.substr(5, 7);
    setIsDisableOkYear(serviceMonthlyList.some((item) => item.tekiyouYears == formatDate));
    setYearMonth(dateString);
  }

  // 24-1
  const renderYearMonthDialog = () => {
    return (
      <Modal
        closable={false}
        maskClosable={false}
        width={350}
        open={!!visibleYearMonth}
        onCancel={onCancelYearMonth}
        title={"年月を指定してください。"}
        getContainer={document.body}
        zIndex={1001}
        footer={[
          <Row key={1} type="flex" justify="end">
            <div style={{ paddingRight: 8 }}>
              <Button onClick={onCancelYearMonth}>{"戻る"}</Button>
            </div>
            <div>
              <Button
                type={"primary"}
                onClick={onOkYearMonth}
                disabled={isDisableAddOkYear}
              >
                {"保存"}
              </Button>
            </div>
          </Row>,
        ]}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <MonthPicker
            value={yearMonth ? dayjs(yearMonth) : dayjs()}
            onChange={handleChangeYearMonth}
            style={{ width: "120px" }}
            popupClassName="date-picker-new-option"
            renderExtraFooter={() => (
              <div>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setYearMonth("");
                    setIsDisableOkYear(true);
                  }}
                >
                  クリア
                </span>
              </div>
            )}
          />
        </div>
      </Modal>
    );
  };
  // END YEAR MONTH DIALOG

  // START ZIP DIALOG
  const searchZip = (zip, address1) => {
    if (!zip && address1) {
      setVisibleZip(!!visibleZip);
      props.searchYubinBango(address1);
    } else {
      if (!zip) {
        zip = serviceMaster.zip;
      }
      setInputZipSearch(zip);
      setVisibleZip(!!visibleZip);
      props.searchYubinBango(zip);
    }
  };

  const closeSearchYubinBango = () => {
    setVisibleZip(false);
  };

  const onChangeInputZipSearch = (e) => {
    setInputZipSearch(e.target.value);
  };

  const handleSelectRowZip = () => {
    if (zipRecord) {
      setServiceMaster((prevValue) => ({
        ...prevValue,
        zip: zipRecord.zip,
        address1: zipRecord.address,
      }));
      // set tiki kubun
      if (serviceMaster.syuruiCd) {
        if (zipRecord.address) {
          props.getTikibetuTikikubun({
            tekiyouYears: serviceMonthly.tekiyouYears,
            address: zipRecord.address,
          });
        } else {
          setServiceMonthly((prevValue) => ({ ...prevValue, tikiKubun: 5 }));
        }
      }
    }
    setZipRecord(null);
    setVisibleZip(null);
    setIsValueChange(true);
  };

  const onRowZipClick = (record) => {
    setZipRecord(record);
  };

  // 15-1
  const renderZipSearchDialog = () => {
    return (
      <Modal
        maskClosable={false}
        zIndex={1001}
        getContainer={document.body}
        title={
          <div>
            <h3 style={{ display: "inline", paddingRight: 16 }}>〒郵便番号</h3>
            <Input onChange={onChangeInputZipSearch} value={inputZipSearch} style={{ maxWidth: 280, marginRight: 8 }} />
            <Button type="primary" onClick={() => searchZip(inputZipSearch)}>
              検索
            </Button>
          </div>
        }
        open={visibleZip}
        onOk={handleSelectRowZip}
        onCancel={closeSearchYubinBango}
        footer={[
          <Button key="back" onClick={closeSearchYubinBango}>
            キャンセル
          </Button>,
          <Button key="submit" type="primary" onClick={handleSelectRowZip}>
            OK
          </Button>,
        ]}
        closable={false}
        className="modal-search-zip"
      >
        <Table
          columns={[
            {
              title: "zip",
              dataIndex: "zip",
            },
            {
              title: "address",
              dataIndex: "address",
            },
          ]}
          dataSource={props.zipSearch}
          loading={props.zipLoading}
          size="small"
          pagination={{ pageSize: 6 }}
          showHeader={false}
          rowKey={(record) => record.id}
          onRow={(record) => {
            return {
              onClick: () => {
                onRowZipClick(record);
              }, // click row
            };
          }}
          rowClassName={(record) =>
            zipRecord && record.id === zipRecord.id
              ? "pljp-table-row-hover"
              : ""
          }
        />
      </Modal>
    );
  };
  // END ZIP DIALOG

  // START DELETE CONFIRM DIALOG
  const showDeleteConfirm = () => {
    setVisibleDelete(true);
  };

  const cancelDeleteConfirm = () => {
    setVisibleDelete(false);
    setConfirmDelete(false);
  };

  const handleConfirmDelete = () => {
    setVisibleDelete(false);
    if (confirmDelete) {
      setConfirmDelete(false);
      if (serviceMonthly.id) {
        props.deleteServiceKikanMonthly(serviceMonthly.id);
      }
      const serviceMonthlyListClone = [...serviceMonthlyList];
      serviceMonthlyListClone.splice(serviceMonthlyIndex, 1);
      setServiceMonthlyIndex(
        serviceMonthlyIndex > 0
          ? serviceMonthlyIndex - 1
          : serviceMonthlyList.length > 0
            ? 0
            : null
      );
      setServiceMonthlyList(serviceMonthlyListClone);
    }
  };

  const handleChangeCheckbox = () => {
    setConfirmDelete(!confirmDelete);
  };

  const renderDeleteConfirmDialog = () => {
    return (
      <Modal
        open={visibleDelete}
        title="削除確認（同意するにチェックしてOKボタンを押してください）"
        onCancel={cancelDeleteConfirm}
        onOk={handleConfirmDelete}
        okButtonProps={{ disabled: !confirmDelete }}
        getContainer={document.body}
        zIndex={1001}
      >
        <div style={{ backgroundColor: "aliceblue" }}>
          <span style={{ display: "block" }}>
            {serviceMonthly.tekiyouYears}
            {"のデータを削除してもよろしいですか？"}
          </span>
          <Checkbox checked={confirmDelete} onChange={handleChangeCheckbox}>
            {"削除に同意する"}
          </Checkbox>
        </div>
      </Modal>
    );
  };
  // END DELETE CONFIRM DIALOG

  const handleSave = () => {
    props.checkIsSaveDataKikan && props.checkIsSaveDataKikan(true);
    setIsSaveCheckValue({
      jigyousyoNo: serviceMaster.jigyousyoNo ? true : false,
      jigyousyoName: serviceMaster.jigyousyoName ? true : false,
      syuruiCd: serviceMaster.syuruiCd ? true : false,
    });

    if (
      serviceMaster.jigyousyoNo &&
      serviceMaster.jigyousyoName &&
      serviceMaster.syuruiCd
    ) {
      // save current serviceMonthly to serviceMonthlyList
      const serviceMonthyListClone = [...serviceMonthlyList];
      serviceMonthyListClone[serviceMonthlyIndex] = { ...serviceMonthly };
      setTimeout(() => {
        props.save({
          serviceKikanMaster: {
            ...serviceMaster,
            kasanDefault: joinKasanDefault(),
            info: joinInfo(serviceMonthyListClone),
          },
          serviceKikanMonthlyList: serviceMonthyListClone,
        });
      }, 0);
    } else {
      const columnNameNull = [];
      const columnNameTaiseiasters = taiseiMasters.map((item) => _.camelCase(item.columnName));
      serviceMonthlyFirst.map((itemNull) => {
        if (!columnNameTaiseiasters.includes(itemNull)) {
          columnNameNull.push(itemNull);
        }
      });
      const serviceMonthlyClone = JSON.parse(JSON.stringify(serviceMonthly));
      columnNameNull.map((item) => {
        serviceMonthlyClone[item] = null;
      });
      // save current serviceMonthly to serviceMonthlyList
      const serviceMonthyListClone = [...serviceMonthlyList];
      serviceMonthyListClone[serviceMonthlyIndex] = { ...serviceMonthlyClone };
      setTimeout(() => {
        props.save({
          serviceKikanMaster: {
            ...serviceMaster,
            kasanDefault: joinKasanDefault(),
            info: joinInfo(serviceMonthyListClone),
          },
          serviceKikanMonthlyList: serviceMonthyListClone,
        });
      }, 0);
    }
    if (props.isEditDialog) {
      props.changeData(serviceMaster, kasanDefault, isValueChange);
    }

    if (startTimeKikan || endTimeKikan) {
      if (startTime === TIME_DEFAULT && endTimeKikan === TIME_DEFAULT) {
        startTimeKikan && changeStartTime(startTimeKikan);
        endTimeKikan && changeEndTime(endTimeKikan);
      }
      setStartTimeKikan(null);
      setEndTimeKikan(null);
    } else {
      changeStartTime && changeStartTime(startTime);
      changeEndTime && changeEndTime(endTime);
    }
  };

  function handleCancel() {
    if (isValueChange) {
      setVisibleConfirm(true);
    } else {
      setStartTimeKikan(null);
      setEndTimeKikan(null);
      props.onCancel();
      props.turnOffReload();
      setIsRenderFirst(undefined);
    }
  }

  const onChangeInputServiceMaster = (e, field) => {
    e.preventDefault();
    const newValue = e.target.value;
    setServiceMaster((prevValue) => ({ ...prevValue, [field]: newValue }));
    // change tiki kubun
    // if (field === "address1" && serviceMaster.syuruiCd) {
    //   if (newValue) {
    //     props.getTikibetuTikikubun({
    //       tekiyouYears: serviceMonthly.tekiyouYears,
    //       address: newValue,
    //     });
    //   } else {
    //     setServiceMonthly(prevValue => ({...prevValue, tikiKubun: 5}));
    //   }
    // }
    setIsValueChange(true);
  };

  const onChangeInputNumberServiceMaster = (e, field) => {
    e.preventDefault();
    const newValue = e.target.value;
    // input change number => string
    // if (/^\d*$/.test(newValue)) {
    setServiceMaster((prevValue) => ({ ...prevValue, [field]: newValue }));
    // }

    setIsValueChange(true);
  };

  const onChangeSelectServiceMaster = (value, field) => {
    if (field === "syuruiCd" && SYURUI_CD_JIGYOUSYO_KUBUN_5.includes(value)) {
      setServiceMaster((prevValue) => ({ ...prevValue, jigyousyoKubun: 5, [field]: value }));
    } else if (field === "syuruiCd" && SYURUI_CD_JIGYOUSYO_KUBUN_6.includes(value)) {
      setServiceMaster((prevValue) => ({ ...prevValue, jigyousyoKubun: 6, [field]: value }));
    } else {
      setServiceMaster((prevValue) => ({ ...prevValue, jigyousyoKubun: 1, [field]: value }));
    }
    setIsValueChange(true);
  };

  const onChangeCheckboxServiceMaster = (e, field) => {
    // use stopPropagation instead of preventDefault to avoid checkbox click twice
    e.stopPropagation();
    const newValue = e.target.checked ? 1 : 0;
    setServiceMaster((prevValue) => ({ ...prevValue, [field]: newValue }));
    if (field === "holidayf") {
      setKasanDefault((prevValue) => ({ ...prevValue, holiday: newValue }));
    }
    setIsValueChange(true);
  };

  const onChangeTimeKasanDefault = (timeString, field) => {
    setKasanDefault({ ...kasanDefault, [field]: timeString });
    setIsValueChange(true);
  };

  const onChangeInputServiceMonthly = (e, field) => {
    e.preventDefault();
    const newValue = e.target.value;
    setServiceMonthly((prevValue) => ({ ...prevValue, [field]: newValue }));
    setIsValueChange(true);
    //変更が施設区分で特定の条件だと体制マスターが読み込み
    if (field == "sisetuKubun" && SYURUI_CD_SISETU_KUBUN.includes(serviceMaster.syuruiCd)) {
      if (!_.isEmpty(newValue)) {
        const bunruiCd = serviceMaster.syuruiCd + newValue;
        props.getTaiseiMaster({
          tekiyouYears: serviceMonthly.tekiyouYears,
          syuruiCd: serviceMaster.syuruiCd,
          bunruiCd,
        });
      } else {
        props.getTaiseiMaster({
          tekiyouYears: serviceMonthly.tekiyouYears,
          syuruiCd: serviceMaster.syuruiCd,
        });
      }
    }
  };

  function isNumberic(value) {
    return !isNaN(value) && !isNaN(parseInt(value));
  }

  const onChangeInputNumberServiceMonthly = (e, field) => {
    e.preventDefault();
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue)) {
      setServiceMonthly((prevValue) => ({ ...prevValue, [field]: newValue }));
    }
    // change tani tanka
    // if (field === "tikiKubun" && serviceMaster.syuruiCd && serviceMonthly.tekiyouYears && serviceMonthly.tikiKubun) {
    //   props.getTikiKubunMaster({
    //     tikiKubun: newValue,
    //     tekiyouYears: serviceMonthly.tekiyouYears,
    //     seidoKubun: "介護保険",
    //     syuruiCd: serviceMaster.syuruiCd
    //   });
    // }
    setIsValueChange(true);
  };

  const onChangeInputDecimalServiceMonthly = (e, field) => {
    e.preventDefault();
    const newValue = e.target.value;
    if (/^\d*\.?\d*$/.test(newValue)) {
      setServiceMonthly((prevValue) => ({ ...prevValue, [field]: newValue }));
    }
    setIsValueChange(true);
  };

  const joinKasanDefault = () => {
    return (
      `<時間帯開始>${kasanDefault.startTime ? kasanDefault.startTime.replace(":", "") : ""
      }</時間帯開始>` +
      `<時間帯終了>${kasanDefault.endTime ? kasanDefault.endTime.replace(":", "") : ""
      }</時間帯終了>` +
      `<加算>${kasanDefault.addition || ""}</加算>` +
      `<祝祭日>${kasanDefault.holiday ? "休" : ""}</祝祭日>`
    );
  };

  const joinInfo = (list) => {
    const NINCHISHO = "認知症",
      DELIMITER = "_",
      latestServiceMonthly = list[0];
    let sisetuKubunNaiyou = "",
      jininKubunNaiyou = "",
      daikiboJigyoushoNaiyou = "",
      info = null;
    if (!latestServiceMonthly) {
      return info;
    }
    // get naiyou of sisetu_kubun
    if (latestServiceMonthly.sisetuKubun) {
      const index = _.findIndex(
        taiseiMasters,
        (e) => e.columnName === "sisetu_kubun"
      );
      if (index > -1) {
        sisetuKubunNaiyou =
          taiseiMasters[index].naiyou.split(",")[
          convertValueToIndex(latestServiceMonthly.sisetuKubun) - 1
          ] || "";
      }
    }
    // get naiyou of jinin_kubun
    if (latestServiceMonthly.jininKubun) {
      const index = _.findIndex(
        taiseiMasters,
        (e) => e.columnName === "jinin_kubun"
      );
      if (index > -1) {
        jininKubunNaiyou =
          taiseiMasters[index].naiyou.split(",")[
          convertValueToIndex(latestServiceMonthly.jininKubun) - 1
          ] || "";
      }
    }
    // get naiyou of daikibo_jigyousho
    if (latestServiceMonthly.daikiboJigyousho) {
      const index = _.findIndex(
        taiseiMasters,
        (e) => e.columnName === "daikibo_jigyousho"
      );
      if (index > -1) {
        daikiboJigyoushoNaiyou =
          taiseiMasters[index].naiyou.split(",")[
          convertValueToIndex(latestServiceMonthly.daikiboJigyousho) - 1
          ] || "";
      }
    }
    // check naiyou and join info
    if (sisetuKubunNaiyou) {
      info = sisetuKubunNaiyou;
    }
    if (jininKubunNaiyou) {
      info = info ? info + DELIMITER + jininKubunNaiyou : jininKubunNaiyou;
    }
    if (daikiboJigyoushoNaiyou) {
      info = info
        ? info + DELIMITER + daikiboJigyoushoNaiyou
        : daikiboJigyoushoNaiyou;
    }
    if (_.includes(["72", "74"], serviceMaster.syuruiCd)) {
      info = info ? info + DELIMITER + NINCHISHO : NINCHISHO;
    }
    return info;
  };

  const convertValueToIndex = (value) => {
    return value.charCodeAt(0) < 65
      ? Number.parseInt(value)
      : value.charCodeAt(0) - 65 + 10;
  };

  const extractKasanDefault = (kasanDefault) => {
    if (kasanDefault) {
      const additionMatch = kasanDefault.match("<加算>(.*)</加算>");
      const holidayMatch = kasanDefault.match("<祝祭日>(.*)</祝祭日>");
      const serviceAdditionValue = additionMatch && additionMatch[1] ? additionMatch[1] : null;
      const holidayMatchValue = holidayMatch && holidayMatch[1] ? holidayMatch[1] : null;

      const startTimeArray = kasanDefault.match("<時間帯開始>(.*)</時間帯開始>");
      const endTimeArray = kasanDefault.match("<時間帯終了>(.*)</時間帯終了>");
      let startTimeValue = startTimeArray && startTimeArray[1] ? startTimeArray[1].substring(0, 2) + ":" + startTimeArray[1].substring(2) : "";
      let endTimeValue = endTimeArray && endTimeArray[1] ? endTimeArray[1].substring(0, 2) + ":" + endTimeArray[1].substring(2) : "";

      return {
        startTime: startTimeValue,
        endTime: endTimeValue,
        addition: serviceAdditionValue,
        holiday: holidayMatchValue ? 1 : 0,
      };
    }
    return {};
  };

  // async function createAndLinkHojinInfo() { //oldメソッド
  //   let hojinMasterExist;
  //   await props.getHojinInfoMasterByName(serviceMaster.jigyousyoName)
  //           .then(data => hojinMasterExist = {
  //             id: data.id,
  //             name: data.name,
  //             furigana: data.furigana,
  //             ryaku: data.ryaku,
  //           })
  //           .catch(error => hojinMasterExist = null);
  //   if (hojinMasterExist == null) {
  //     const hojinInfoClone = {
  //       name: serviceMaster.jigyousyoName,
  //       furigana: serviceMaster.furigana,
  //       ryaku: serviceMaster.ryaku,
  //     };

  //     props.createHojinInfoMaster(hojinInfoClone);
  //     setIsValueChange(true);
  //   } else {
  //     setVisibleConfirmHojinMaster(true);
  //     setHojinExists(hojinMasterExist);
  //   }
  // }

  function createAndLinkHojinInfo() {
    let hojinMasterExist;
    props.getHojinInfoMasterByName(serviceMaster.jigyousyoName, (data) => {
      try {
        hojinMasterExist = {
          id: data.id,
          name: data.name,
          furigana: data.furigana,
          ryaku: data.ryaku,
        };
      } catch (e) {
        console.log(e);
        hojinMasterExist = null;
      }
    });
    if (hojinMasterExist == null) {
      const hojinInfoClone = {
        name: serviceMaster.jigyousyoName,
        furigana: serviceMaster.furigana,
        ryaku: serviceMaster.ryaku,
      };
      props.createHojinInfoMaster(hojinInfoClone);
      setIsValueChange(true);
    } else {
      setVisibleConfirmHojinMaster(true);
      setHojinExists(hojinMasterExist);
    }
  }

  const onChangeTekiyouYears = (index) => {
    changeServiceMonthlyIndex(index);
    setIsValueChange(true);
  };

  const numberTaiseiMasterNaiyou = (naiyou) => {
    return naiyou
      .split(",")
      .map((item, index) => {
        if (item) {
          // Số từ 1 đến 9
          if (index < 9) {
            return `${index + 1}.${item}`;
          }
          // Từ 10 → A, 11 → B, bỏ qua I và O và Q
          else {
            let charCode = 65 + index - 9; // Tính mã ASCII (A: 65)
            // Bỏ qua I (73) và O (79)
            if (charCode >= 73) charCode++; // Bỏ qua I
            if (charCode >= 79) charCode++; // Bỏ qua O
            if (charCode >= 81) charCode++; // Bỏ qua Q
            return `${String.fromCharCode(charCode)}.${item}`;
          }
        } else {
          return "";
        }
      })
      .join(",");
  };

  function handleBlurTikiKubun(e) {
    e.preventDefault();
    const newValue = e.target.value;
    if (newValue == 5) {
      setServiceMonthly((prevValue) => ({ ...prevValue, taniTanka: 10 }));
      return;
    }
    if (
      serviceMaster.syuruiCd &&
      serviceMonthly.tekiyouYears &&
      serviceMonthly.tikiKubun
    ) {
      props.getTikiKubunMaster({
        tikiKubun: newValue,
        tekiyouYears: serviceMonthly.tekiyouYears,
        seidoKubun: "介護保険",
        syuruiCd: serviceMaster.syuruiCd,
      });
    }
  }

  function handleBlurJigyousyaName(e) {
    e.preventDefault();
    const value = e.target.value;
    if (!serviceMaster.ryaku)
      setServiceMaster(prevValue => ({ ...prevValue, ryaku: value }));
  }

  function handleFurigana(e) {
    e.preventDefault();
    const value = toKatakana(e.target.value);
    setServiceMaster((prevValue) => ({ ...prevValue, furigana: value }));
  }

  function handleBlurAddress1(e) {
    e.preventDefault();
    const value = e.target.value;
    if (serviceMaster.syuruiCd) {
      if (value) {
        props.getTikibetuTikikubun({
          tekiyouYears: serviceMonthly.tekiyouYears,
          address: value,
        });
      } else {
        setServiceMonthly((prevValue) => ({ ...prevValue, tikiKubun: 5, taniTanka: 10 }));
      }
    }
  }

  return (
    <Fragment>
      <Modal
        destroyOnClose={true}
        closable={false}
        open={props.visible}
        onOk={handleSave}
        okText={"保存"}
        onCancel={handleCancel}
        cancelText={"戻る"}
        maskClosable={false}
        width={1040}
        centered
      >
        <Spin spinning={props.loading}>
          <div>
            {/*row 1 - refactor by 169*/}
            <Row className={"serviceKikanMasterContainer"} type={"flex"} gutter={8} style={{ marginBottom: '10px' }}>
              <Col span={1}>
                <label>ID</label>
              </Col>
              <Col span={4}>
                <label>{serviceMaster.id}</label>
              </Col>
              <Col span={19}>
                <Row key={1} type="flex" justify="end">
                  <div style={{ paddingRight: 8 }}>
                    <Button onClick={handleCancel}>{"戻る"}</Button>
                  </div>
                  <div>
                    <Button type={"primary"} onClick={handleSave}>
                      {"保存"}
                    </Button>
                  </div>
                </Row>
              </Col>
            </Row>
            <Divider style={{ margin: 0 }} />
            {/*row 2 - refactor by 169*/}
            <Row className="servicesKikan-checkbox" type={"flex"} gutter={8} style={{ padding: '7px', alignItems: 'center' }}>
              <Col span={8}>
                <Checkbox type={"checkbox"} checked={serviceMaster.corporatef} onChange={(e) => onChangeCheckboxServiceMaster(e, "corporatef")}>
                  同一法人
                </Checkbox>
                <Checkbox type={"checkbox"} checked={serviceMaster.stop} onChange={(e) => onChangeCheckboxServiceMaster(e, "stop")}>
                  利用中止
                </Checkbox>
                <Checkbox type={"checkbox"} checked={serviceMaster.satellite} onChange={(e) => onChangeCheckboxServiceMaster(e, "satellite")}>
                  サテライト
                </Checkbox>
              </Col>
              <Col span={4}>
                <Button type={"primary"} onClick={onOpenMemo}>
                  メモ
                </Button>
              </Col>
              <Col span={8} />
              <Col span={2}>
                <label>順番</label>
              </Col>
              <Col span={2}>
                <Input value={serviceMaster.sort_num}
                  onChange={(e) => onChangeInputNumberServiceMaster(e, "sort_num")} />
              </Col>
            </Row>
            <fieldset style={{ border: "1px solid threedface" }}>
              {/*row 3*/}
              <Row type={"flex"} justify={"start"} align={"middle"} gutter={8}
                style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                <Col span={2}>
                  <label>事業所番号</label>
                </Col>
                <Col span={5} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Input
                    value={serviceMaster.jigyousyoNo}
                    maxLength={10}
                    onChange={(e) => onChangeInputNumberServiceMaster(e, "jigyousyoNo")}
                    disabled={disabled.jigyousyoNo}
                    className={"pljp-select-disabled-custom"}
                    style={
                      props.isReload === false || isSaveCheckValue.jigyousyoNo === false
                        ? { width: "calc(100% - 72px)", border: "solid 1px red" }
                        : { width: "calc(100% - 72px)" }
                    }
                  />
                  <Button type={"primary"} onClick={() => setDisabled((prevValue) => ({ ...prevValue, jigyousyoNo: false }))}>
                    変更
                  </Button>
                </Col>
                <Col span={2}>
                  <label>事業所区分</label>
                </Col>
                <Col span={1}>
                  <Input value={serviceMaster.jigyousyoKubun} maxLength={1}
                    onChange={(e) => onChangeInputNumberServiceMaster(e, "jigyousyoKubun")} />
                </Col>
                <Col span={10}>
                  <pre style={{ marginBottom: 0 }}>{"1:指定事業所,　2:基準該当事業所,　3:相当ｻｰﾋﾞｽ事業所,\n4:その他,　5:地域密着,　6:総合事業"}</pre>
                </Col>
                <Col span={2}>
                  <label>割引率</label>
                </Col>
                <Col span={2}>
                  <Input value={serviceMaster.waribiki} maxLength={3}
                    onChange={(e) => onChangeInputNumberServiceMaster(e, "waribiki")} />
                </Col>
              </Row>
              {/*row 4*/}
              <Row type={"flex"} justify={"start"} align={"middle"} gutter={8}
                style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                <Col span={2}>
                  <label>事業所名</label>
                </Col>
                <Col span={16}>
                  <Input value={serviceMaster.jigyousyoName}
                    onChange={(e) => onChangeInputServiceMaster(e, "jigyousyoName")}
                    onBlur={(e) => handleBlurJigyousyaName(e)}
                    style={isSaveCheckValue.jigyousyoName === false ? { border: "solid 1px red" } : {}}
                  />
                </Col>
              </Row>
              {/*row 5*/}
              <Row type={"flex"} justify={"start"} align={"middle"} gutter={8}
                style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                <Col span={2}>
                  <label>フリガナ</label>
                </Col>
                <Col span={10}>
                  <Input
                    value={serviceMaster.furigana}
                    onChange={(e) => onChangeInputServiceMaster(e, "furigana")}
                    onBlur={(e) => handleFurigana(e)}
                  />
                </Col>
                <Col span={2}>
                  <label>郵便番号</label>
                </Col>
                <Col span={4} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Input value={serviceMaster.zip} maxLength={8}
                    onChange={(e) => onChangeInputServiceMaster(e, "zip")}
                    style={{ width: "calc(100% - 56px)" }} />
                  <Button type={"primary"} onClick={() => searchZip(serviceMaster.zip, serviceMaster.address1)}>〒</Button>
                </Col>
              </Row>
              {/*row 6*/}
              <Row type={"flex"} justify={"start"} align={"middle"} gutter={8}
                style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                <Col span={2}>
                  <label>略称</label>
                </Col>
                <Col span={10}>
                  <Input value={serviceMaster.ryaku} onChange={(e) => onChangeInputServiceMaster(e, "ryaku")} />
                </Col>
                <Col span={2}>
                  <label>住所1</label>
                </Col>
                <Col span={10}>
                  <Input
                    value={serviceMaster.address1}
                    onChange={(e) => onChangeInputServiceMaster(e, "address1")}
                    onBlur={(e) => handleBlurAddress1(e)}
                  />
                </Col>
              </Row>
              {/*row 7*/}
              <Row type={"flex"} justify={"start"} align={"middle"} gutter={8}
                style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                <Col span={2}>
                  <label>種類コード</label>
                </Col>
                <Col span={10} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Input
                    value={serviceMaster.syuruiCd}
                    style={
                      props.isReload === false || isSaveCheckValue.syuruiCd === false
                        ? { width: "calc((100% - 80px)*0.15)", border: "solid 1px red" }
                        : { width: "calc((100% - 80px)*0.15)" }
                    }
                    readOnly
                    disabled
                    className={"pljp-select-disabled-custom"}
                  />
                  <Select
                    value={serviceMaster.syuruiCd || ""}
                    onChange={(value) => onChangeSelectServiceMaster(value, "syuruiCd")}
                    disabled={disabled.syuruiCd}
                    className={"pljp-select-disabled-custom"}
                    style={
                      props.isReload === false || isSaveCheckValue.syuruiCd === false
                        ? { width: "calc((100% - 80px)*0.85)", border: "solid 1px red" }
                        : { width: "calc((100% - 80px)*0.85)" }
                    }
                  >
                    {props.serviceSyuruiMasters
                      ? props.serviceSyuruiMasters.map((item, i) => (
                        <Option key={i} value={item.serviceSyuruiCd}>
                          {item.serviceSyuruiCd + "_" + item.ryaku}
                        </Option>
                      ))
                      : null}
                  </Select>
                  <Button
                    type={"primary"}
                    onClick={() => setDisabled((prevValue) => ({ ...prevValue, syuruiCd: false }))}
                    disabled={disabled.syuruiChangeButton}
                  >
                    変更
                  </Button>
                </Col>
                <Col span={2}>
                  <label>住所2</label>
                </Col>
                <Col span={10}>
                  <Input value={serviceMaster.address2} onChange={(e) => onChangeInputServiceMaster(e, "address2")} />
                </Col>
              </Row>
              {/*row 8*/}
              <Row type={"flex"} justify={"start"} align={"middle"} gutter={8}
                style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                <Col span={2}>
                  <label>法人情報</label>
                </Col>
                <Col span={10} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Input value={hojinInfo ? hojinInfo.ryaku : null}
                    style={{ width: "calc(100% - 144px)" }} readOnly disabled
                    className={"pljp-select-disabled-custom"} />
                  <Button type={"primary"} onClick={loadListHojinMaster}>検索</Button>
                  <Button type={"primary"} onClick={createAndLinkHojinInfo}>追加</Button>
                </Col>
                <Col span={2}>
                  <label>電話</label>
                </Col>
                <Col span={4}>
                  <Input value={serviceMaster.tel} onChange={(e) => onChangeInputServiceMaster(e, "tel")} />
                </Col>
                <Col span={2} style={{ display: "flex", justifyContent: "center" }}>
                  <label>ＦＡＸ</label>
                </Col>
                <Col span={4}>
                  <Input value={serviceMaster.fax} onChange={(e) => onChangeInputServiceMaster(e, "fax")} />
                </Col>
              </Row>
            </fieldset>
          </div>
          <div>
            <fieldset style={{ border: "1px solid threedface" }}>
              <legend style={{ fontSize: "1em", width: "auto", marginBottom: 0 }}>初期値</legend>
              <Row type={"flex"} justify={"start"} align={"middle"} gutter={8}
                style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                <Col span={2} style={{ display: "flex", justifyContent: "center" }}>
                  <label>時間帯</label>
                </Col>
                <Col span={5} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <TimeKBCus
                    timeString={
                      // startTimeKikan
                      //   ? startTimeKikan
                      //   : props?.startTime === addServicesContants.TIME_DEFAULT && props?.endTime === addServicesContants.TIME_DEFAULT && kasanDefault
                      //   ? kasanDefault?.startTime?.length > 2
                      //     ? kasanDefault?.startTime
                      //     : addServicesContants.TIME_DEFAULT
                      //   : props?.startTime

                      startTimeKikan
                        ? startTimeKikan
                        : kasanDefault?.startTime?.length > 2
                          ? kasanDefault?.startTime
                          : addServicesContants.TIME_DEFAULT
                    }
                    isKikanMasterModal={true}
                    handleCheckStartTime={handleCheckStartTime}
                    handleInput={changeStartTime}
                  />
                  <p>～</p>
                  <TimeKBCus
                    timeString={
                      // endTimeKikan
                      //   ? endTimeKikan
                      //   : props?.startTime === addServicesContants.TIME_DEFAULT && props?.endTime === addServicesContants.TIME_DEFAULT && kasanDefault
                      //   ? kasanDefault?.endTime?.length > 2
                      //     ? kasanDefault?.endTime
                      //     : addServicesContants.TIME_DEFAULT
                      //   : props?.endTime

                      endTimeKikan ? endTimeKikan : kasanDefault?.endTime?.length > 2 ? kasanDefault?.endTime : addServicesContants.TIME_DEFAULT
                    }
                    isKikanMasterModal={true}
                    handleCheckEndTime={handleCheckEndTime}
                    handleInput={changeEndTime}
                  />
                </Col>
                <Col span={1} style={{ display: "flex", justifyContent: "center" }}>
                  <label>加算</label>
                </Col>
                <Col span={13} style={{ display: "flex", justifyContent: "space-between" }}>
                  <Input title={kasanDefault.addition}
                    value={kasanDefault.addition}
                    style={{ width: "calc(100% - 96px)" }}
                    className={"pljp-select-disabled-custom"}
                    readOnly disabled />
                  <Button type={"primary"} onClick={onOpenAdditionSetting}>加算設定</Button>
                </Col>
                <Col span={3}>
                  <Checkbox type={"checkbox"} checked={serviceMaster.holidayf}
                    onChange={(e) => onChangeCheckboxServiceMaster(e, "holidayf")}>祝祭日判断</Checkbox>
                </Col>
              </Row>
            </fieldset>
          </div>
          <div>
            <fieldset style={{ border: "1px solid threedface", minHeight: 400 }}>
              <legend style={{ fontSize: "1em", width: "auto", marginBottom: 0 }}>体制情報</legend>
              <Row type={"flex"} justify={"start"} align={"middle"} gutter={8}
                style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                <Col span={3}>
                  <Select value={serviceMonthlyIndex}
                    onChange={onChangeTekiyouYears}
                    style={{ width: "100%" }}>
                    {
                      serviceMonthlyList.map((item, i) =>
                        <Option key={i} value={i}>{item.tekiyouYears}</Option>
                      )
                    }
                  </Select>
                </Col>
                <Col span={5} style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button type={"primary"} onClick={() => onOpenYearMonth(visibleYmType.ADD)}>追加</Button>
                  <Button type={"primary"} onClick={() => onOpenYearMonth(visibleYmType.EDIT)}
                    disabled={!serviceMonthlyList.length}>修正</Button>
                  <Button type={"primary"} onClick={showDeleteConfirm} disabled={!serviceMonthlyList.length}>削除</Button>
                </Col>
                <Col span={2} style={{ display: "flex", justifyContent: "center" }}>
                  <label>地域区分</label>
                </Col>
                <Col span={2}>
                  <Input
                    value={serviceMonthly.tikiKubun}
                    onChange={(e) =>
                      onChangeInputNumberServiceMonthly(e, "tikiKubun")
                    }
                    onBlur={(e) => handleBlurTikiKubun(e)}
                    maxLength={1}
                  />
                </Col>
                <Col>
                  <pre className="name-content-kikan" style={{ marginBottom: 0 }}>{"1：１級地、　6：２級地、　7：３級地、　2：４級地、　3：５級地、\n4：６級地、　9：７級地、　5：その他"}</pre>
                </Col>
              </Row>
              <Row type={"flex"} justify={"start"} align={"middle"} gutter={8}
                style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                <Col span={8} />
                <Col span={2} style={{ display: "flex", justifyContent: "center" }}>
                  <label>単位数単価</label>
                </Col>
                <Col span={2}>
                  <Input value={serviceMonthly.taniTanka}
                    onChange={(e) => onChangeInputDecimalServiceMonthly(e, "taniTanka")} />
                </Col>
              </Row>
              {
                //ここで体制マスターをmapで作成 nullとundefinedを判定
                !_.isNil(serviceMonthlyIndex) && taiseiMasters.map((item, index) =>
                  <Row key={index} type={"flex"} justify={"start"} align={"middle"} gutter={8}
                    style={{ paddingTop: "4px", paddingBottom: "4px" }}>
                    <Col span={5}>
                      <label>{item.name}</label>
                    </Col>
                    <Col span={2} style={{ display: "flex", justifyContent: "center" }}>
                      <Input
                        value={serviceMonthly[_.camelCase(item.columnName)]}
                        onChange={(e) => onChangeInputServiceMonthly(e, _.camelCase(item.columnName))}
                        maxLength={1}
                        style={{ width: 50 }}
                      />
                    </Col>
                    <Col span={17}>
                      <label>{numberTaiseiMasterNaiyou(item.naiyou)}</label>
                    </Col>
                  </Row>
                )}
            </fieldset>
          </div>
        </Spin>
      </Modal>
      <Modal
        open={visibleConfirm}
        title="キャンセル確認"
        onCancel={() => setVisibleConfirm(false)}
        getContainer={document.body}
        onOk={() => {
          setVisibleConfirm(false);
          props.onCancel();
          setIsValueChange(false);
          props.turnOffReload();
          setIsRenderFirst(undefined);
        }}
      >
        <span style={{ display: "block" }}>{"キャンセルしてもよろしいですか？"}</span>
      </Modal>
      <Modal
        open={visibleConfirmHojinMaster}
        title="確認"
        getContainer={document.body}
        onCancel={() => {
          setVisibleConfirmHojinMaster(false);
        }}
        onOk={() => {
          setVisibleConfirmHojinMaster(false);
          setServiceMaster((prevValue) => ({ ...prevValue, hojinInfoMasterId: hojinExists.id }));
          setIsValueChange(true);
        }}
      >
        <span style={{ display: "block" }}>{`既に${serviceMaster.jigyousyoName}は登録されています。その法人をセットしますか？`}</span>
      </Modal>
      {renderAdditionSettingDialog()}
      {renderHojinInfoDialog()}
      {renderMemoDialog()}
      {renderYearMonthDialog()}
      {renderZipSearchDialog()}
      {renderDeleteConfirmDialog()}
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    serviceMaster: state.serviceKikanMaster.serviceKikanMaster,
    serviceMonthly: state.serviceKikanMaster.serviceMonthly,
    serviceAdditions: state.serviceKikanMaster.serviceAdditions,
    hojinInfoMasters: state.serviceKikanMaster.hojinInfoMasters,
    serviceSyuruiMasters: state.serviceKikanMaster.serviceSyuruiMasters,
    linkedWeeklySchedule: state.serviceKikanMaster.linkedWeeklySchedule,
    hojinInfo: state.serviceKikanMaster.hojinInfo,
    loading: state.serviceKikanMaster.loading,
    zipLoading: state.zipMaster.loading,
    zipSearch: state.zipMaster.zip,
    serviceMonthlyList: state.serviceKikanMaster.serviceMonthlyList,
    taiseiMasters: state.serviceKikanMaster.taiseiMasters,
    tikibetuTikikubun: state.serviceKikanMaster.tikibetuTikikubun,
    tikiKubunMaster: state.serviceKikanMaster.tikiKubunMaster,
    serviceKikanSortNum: state.serviceKikanMaster.serviceKikanSortNum,
    sortNumCheck: state.serviceKikanMaster.sortNumCheck,
    isReload: state.serviceKikanMaster.isReload,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchYubinBango: (text) => dispatch(zipMasterSearchCreator(text)),
    getServiceKikanById: (id) =>
      dispatch(ServiceKikanMasterActions.getById(id)),
    getInitialServiceKikan: () =>
      dispatch(ServiceKikanMasterActions.getInitialServiceKikan()),
    getServiceSyuruiMasters: () =>
      dispatch(ServiceKikanMasterActions.getServiceSyuruiMasters()),
    getServiceAdditions: (serviceType) =>
      dispatch(ServiceKikanMasterActions.getServiceAdditions(serviceType)),
    searchHojinInfoMasters: (text) =>
      dispatch(ServiceKikanMasterActions.searchHojinInfoMasters(text)),
    createServiceKikanMaster: (data) =>
      dispatch(ServiceKikanMasterActions.createServiceKikanMaster(data)),
    updateServiceKikanMaster: (data) =>
      dispatch(ServiceKikanMasterActions.updateServiceKikanMaster(data)),
    checkLinkedWeeklySchedule: (id) =>
      dispatch(ServiceKikanMasterActions.checkLinkedWeeklySchedule(id)),
    createHojinInfoMaster: (data) =>
      dispatch(ServiceKikanMasterActions.createHojinInfoMaster(data)),
    getHojinInfoMasterById: (id) =>
      dispatch(ServiceKikanMasterActions.getHojinInfoMasterById(id)),
    getServiceKikanMonthlyById: (id) =>
      dispatch(ServiceKikanMasterActions.getServiceKikanMonthlyById(id)),
    createServiceKikanMonthly: (data) =>
      dispatch(ServiceKikanMasterActions.createServiceKikanMonthly(data)),
    updateServiceKikanMonthly: (data) =>
      dispatch(ServiceKikanMasterActions.updateServiceKikanMonthly(data)),
    deleteServiceKikanMonthly: (id) =>
      dispatch(ServiceKikanMasterActions.deleteServiceKikanMonthly(id)),
    getListServiceKikanMonthly: (sid) =>
      dispatch(ServiceKikanMasterActions.getListServiceKikanMonthly(sid)),
    getTaiseiMaster: (query) =>
      dispatch(ServiceKikanMasterActions.getTaiseiMaster(query)),
    getTikibetuTikikubun: (query) =>
      dispatch(ServiceKikanMasterActions.getTikibetuTikikubun(query)),
    getTikiKubunMaster: (query) =>
      dispatch(ServiceKikanMasterActions.getTikiKubunMaster(query)),
    save: (data) => dispatch(ServiceKikanMasterActions.save(data)),
    turnOffReload: () => dispatch(ServiceKikanMasterActions.turnOffReload()),
    getMaxSortNum: () => dispatch(ServiceKikanMasterActions.getMaxSortNum()),
    getHojinInfoMasterByName: (jigyousyoName, callback) =>
      dispatch(
        ServiceKikanMasterActions.getHojinInfoMasterByName(
          jigyousyoName,
          callback
        )
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceKikanMasterModal);
