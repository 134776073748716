/* eslint-disable no-unused-vars */
/* eslint-disable no-var */
/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */

import {
  SOCKET_CONNECTION_CLOSED,
  SOCKET_CONNECTION_ERROR,
  SOCKET_CONNECTION_INIT,
  SOCKET_CONNECTION_SUCESS,
  SOCKET_DISCONNECT,
  SOCKET_MESSAGE,
  SOCKET_SEND,
} from "../../actions/websocketActions/actionName";
import { apiUrls } from "../../common/configs/common-url";
import { getValueLocalstorage } from "../../common/function_common/functionCommon";

/*
 * middleware
 * log action when action is dispatched
 * websocket has been set here
 *
 *
 */
let socket;

/* eslint-disable indent */
/* eslint-disable brace-style */
const websocket =
  ({ getState, dispatch }) =>
    (next) =>
      (action) => {
        switch (action.type) {
          case SOCKET_CONNECTION_INIT:
            // check http vs https
            // eslint-disable-next-line no-var
            var ssl = window.location.protocol;
            const loginInfo = JSON.parse(localStorage.getItem("swpspsMNGT"));
            // eslint-disable-next-line no-var
            var userInit = loginInfo ? loginInfo.doubleLogin : Date.now();

            const isLocalhost = Boolean(
              window.location.hostname === "localhost" ||
              // [::1] is the IPv6 localhost address.
              window.location.hostname === "[::1]" ||
              // 127.0.0.1/8 is considered localhost for IPv4.
              window.location.hostname.match(
                /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
              )
            );

            if (!socket) {
              if (process.env.NODE_ENV === "development") {
                socket = new WebSocket(
                  // eslint-disable-next-line no-nested-ternary
                  `${ssl != "https:" ? "ws:" : (isLocalhost ? "ws:" : "wss:")}` +
                  window.location.hostname +
                  ":" +
                  apiUrls.API_PORT +
                  "/websocket/room/socket" +
                  "?user=" +
                  getValueLocalstorage("tid") +
                  "-" +
                  userInit,
                );
              }
              else {
                // production
                socket = new WebSocket(
                  // eslint-disable-next-line no-nested-ternary
                  `${ssl != "https:" ? "ws:" : (isLocalhost ? "ws:" : "wss:")}` +
                  window.location.hostname +
                  (window.location.port ? ":" + window.location.port : "") +
                  "/websocket/room/socket" +
                  "?user=" +
                  getValueLocalstorage("tid") +
                  "-" +
                  userInit,
                );
              }

              dispatch(socketConnectionInit(socket));

              socket.onopen = function () {
                dispatch(socketConnectionSuccess());
              };

              socket.onerror = function () {
                dispatch(socketConnectionError());
              };

              socket.onmessage = function (event) {
                dispatch(socketMessage(event.data));
              };

              socket.onclose = function () {
                dispatch(socketConnectionClosed());
              };
            }
            break;

          // User request to send a message
          case SOCKET_SEND:
            dispatch(socketSendMessage(socket, action.payload));
            break;

          // User request to disconnect
          case SOCKET_DISCONNECT:
            if (socket) {
              socket.close();
            }
            break;

          default:
            // We don't really need the default but ...
            break;
        }
        // let state = getState();
        //   if (state.messageList.preventLogin) {
        //     dispatch(logoutCreators());
        //   }
        next(action);
      };

/**
 *
 * @param {*} socket
 * @param {*} data
 */
function socketSendMessage(socket, data) {
  return (dispatch) => {
    socket.send(data, (res) => {
      dispatch(socketSend());
    });
  };
}

/**
 *
 * @param {*} socket
 */
function socketConnectionInit(socket) {
  return {
    type: SOCKET_CONNECTION_INIT,
    socket,
  };
}

/**
 *
 *
 */
function socketConnectionSuccess() {
  return {
    type: SOCKET_CONNECTION_SUCESS,
  };
}

/**
 *
 *
 */
function socketConnectionError() {
  return {
    type: SOCKET_CONNECTION_ERROR,
  };
}

/**
 *
 *
 */
function socketConnectionClosed() {
  return {
    type: SOCKET_CONNECTION_CLOSED,
  };
}

/**
 *
 * @param {*} data
 */
function socketMessage(data) {
  return {
    type: SOCKET_MESSAGE,
    wsData: data,
  };
}

/**
 *
 * @param {*} data
 */
function socketSend() {
  return {
    type: SOCKET_SEND,
  };
}

export default websocket;
