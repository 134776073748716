export const NyutaisyoActionTypes = {
  LOAD_SPIN_REQUEST: "LOAD_SPIN_REQUEST",

  GET_FIRST_DATA_REQUEST: "GET_FIRST_DATA_REQUEST",
  GET_FIRST_DATA_SUCCESS: "GET_FIRST_DATA_SUCCESS",
  GET_FIRST_DATA_ERROR: "GET_FIRST_DATA_ERROR",

  GET_RIYOUSYA_LIST_SUCCESS: "GET_RIYOUSYA_LIST_SUCCESS",
  GET_RIYOUSYA_LIST_ERROR: "GET_RIYOUSYA_LIST_ERROR",
  GET_RIYOUSYA_LIST_EMPTY: "GET_RIYOUSYA_LIST_EMPTY",

  GET_ROOMS_SUCCESS: "GET_ROOMS_SUCCESS",
  GET_ROOMS_ERROR: "GET_ROOMS_ERROR",

  RESET_RIYOUSYA_DATA: "RESET_RIYOUSYA_DATA",
};
