/* eslint-disable no-nested-ternary */
import React, { Suspense, memo } from "react";
import { Redirect, Route } from "react-router-dom";

const PublicLayout = ({
  component: Component,
  isUserLoggedIn,
  jwtTokenType,
  ...other
}) => (
  <Route
    {...other}
    render={() =>
      !isUserLoggedIn ? (
        <div className="public-layout">
          <Suspense fallback={null}>
            <main>
              <Component />
            </main>
          </Suspense>
        </div>
      ) : jwtTokenType == "UserAdmin" ? (
        <Redirect to="/mobile/userkanri/user" />
      ) : (
        <Redirect to={`/mobile/main${localStorage.getItem("urlMoPrefix")}`} />
      )
    }
  />
);

const _PublicLayout = memo(PublicLayout);
export { _PublicLayout as PublicLayout };
