export const HaisetuActionTypes = {
  LOAD_SPIN_REQUEST: "LOAD_SPIN_REQUEST",

  GET_HAISETU_RECORDS_REQUEST: "GET_HAISETU_RECORDS_REQUEST",
  GET_HAISETU_RECORDS_SUCCESS: "GET_HAISETU_RECORDS_SUCCESS",
  GET_HAISETU_RECORDS_ERROR: "GET_HAISETU_RECORDS_ERROR",

  GET_HAISETU_YOUGO_MASTER_REQUEST: "GET_HAISETU_YOUGO_MASTER_REQUEST",
  GET_HAISETU_YOUGO_MASTER_SUCCESS: "GET_HAISETU_YOUGO_MASTER_SUCCESS",
  GET_HAISETU_YOUGO_MASTER_ERROR: "GET_HAISETU_YOUGO_MASTER_ERROR",

  GET_HAISETU_SETTING_MASTER_REQUEST: "GET_HAISETU_SETTING_MASTER_REQUEST",
  GET_HAISETU_SETTING_MASTER_SUCCESS: "GET_HAISETU_SETTING_MASTER_SUCCESS",
  GET_HAISETU_SETTING_MASTER_ERROR: "GET_HAISETU_SETTING_MASTER_ERROR",

  SAVE_HAISETU_REQUEST: "SAVE_HAISETU_REQUEST",
  SAVE_HAISETU_SUCCESS: "SAVE_HAISETU_SUCCESS",
  SAVE_HAISETU_ERROR: "SAVE_HAISETU_ERROR",

  GET_HAISETU_LAST_SHIT_REQUEST: "GET_HAISETU_LAST_SHIT_REQUEST",
  GET_HAISETU_LAST_SHIT_SUCCESS: "GET_HAISETU_LAST_SHIT_SUCCESS",
  GET_HAISETU_LAST_SHIT_ERROR: "GET_HAISETU_LAST_SHIT_ERROR",

  GET_HAISETU_PREVIOUS_DATA_REQUEST: "GET_HAISETU_PREVIOUS_DATA_REQUEST",
  GET_HAISETU_PREVIOUS_DATA_SUCCESS: "GET_HAISETU_PREVIOUS_DATA_SUCCESS",
  GET_HAISETU_PREVIOUS_DATA_ERROR: "GET_HAISETU_PREVIOUS_DATA_ERROR",
  GET_HAISETU_PREVIOUS_DATA_NOT_FOUND: "GET_HAISETU_PREVIOUS_DATA_NOT_FOUND",

  RESET_HAISETU_DATA: "RESET_HAISETU_DATA",
};
