import { openNotificationWithIcon } from "../../common/function_common/functionCommon";
import { AdlActionTypes } from "../action_types/adl";

// const initialState = {
//   data: null,
//   loading: false,
//   error: null,
// };

// function adl(state = initialState, action) {
  function adl(state = {}, action) {
  switch (action.type) {

    case AdlActionTypes.GET_ADL_DATA_REQUEST: {
      return {
        ...state,
        loading: true,
        error: null,
        data: [],
      }
    }

    case AdlActionTypes.GET_ADL_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      }
    }

    case AdlActionTypes.SAVE_ADL_DATA_REQUEST:
      return { 
        ...state, 
        loading: true, 
        error: null 
      };

    case AdlActionTypes.SAVE_ADL_DATA_SUCCESS:
      openNotificationWithIcon("success", "保存しました");
      return { 
        ...state, 
        loading: false, 
        data: action.payload 
      };

    case AdlActionTypes.REMOVE_ADL_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }

    case AdlActionTypes.REMOVE_ADL_SUCCESS: {
      openNotificationWithIcon("success", "削除しました");
      return {
        ...state,
        loading: false,
      }
    }
    
    case AdlActionTypes.REMOVE_ADL_ERROR: {
      openNotificationWithIcon("error", "エラー");
      return {
        ...state,
        loading: false,
      }
    }

    case AdlActionTypes.LOAD_ADL_TO_QUOTE_REQUEST: {
      return {
        ...state,
        adlDetails: action.data,
        loading: true,
        error: null,
      };
    }
    
    case AdlActionTypes.LOAD_ADL_TO_QUOTE_SUCCESS: {
      openNotificationWithIcon("success", "前回引用を実施しました");
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    }

    case AdlActionTypes.CHECK_TAISHO_REQUEST: {
      return {
        ...state,
        taishoData: null, 
        loading: true,
        error: null,
      }
    }

    case AdlActionTypes.CHECK_TAISHO_SUCCESS: {
      // openNotificationWithIcon("success", "");
      return {
        ...state,
        taishoData: action.payload,
        loading: false,
        error: null,
      };
    }
    

    // -------------------------

    case AdlActionTypes.SET_NEW_DATA: {
      return {
        ...state,
        data: {},
      };
    }

    case AdlActionTypes.GET_FIRST_LOAD_DATA: {
      return {
        ...state,
        fistLoadData: action.data,
      }
    }

    default: {
      return { ...state };
    }
  }
}

export default adl;