import { combineReducers } from "redux";
import auth from "./auth";
import config from "./config";
import document from "./document";
import error from "./error";
import facesheet from "./facesheet";
import hyoukahyou from "./hyoukahyou";
import inquiryRequest from "./inquiryRequest";
import itakuTantoMaster from "./itakuTantoMaster";
import kihonCheck from "./kihonCheck";
import meeting from "./meeting";
import monitoringMNGT from "./monitoring";
import monitoringManagement from "./monitoringManagement";
import plan1 from "./plan1";
import plan2 from "./plan2";
import progressMNGT from "./progress";
import serviceKikanMaster from "./serviceKikanMaster";
import tantoMaster from "./tantoMaster";
import task from "./task";
import usageTicketManagement from "./usageTicketManagement";
import useslip from "./useslip";
import weeklyPlan from "./weeklyPlan";
import yobousienPlan from "./yobousienPlan";
import subsidyManagement from "./subsidyManagement";
import subsidyDetailReducer from "./subsidy";
//
import accountReducers from "../../reducers/accountReducers/accountReducers";
import allMasterReducers from "../../reducers/allMasterReducers/allMasterReducers";
import dengonSendToReducers from "../../reducers/dengonSendToReducers/dengonSendToReducers";
import doctorMasterReducers from "../../reducers/doctorMasterReducers/doctorMasterReducers";
import documentListReducers from "../../reducers/documentListReducers/DocumentListReducers";
import drugMasterReducers from "../../reducers/drugMasterReducers/drugMasterReducers";
import dutyReportReducers from "../../reducers/dutyReportReducers/dutyReportReducers";
import eventPlanReducers from "../../reducers/eventPlanReducers/eventPlanReducers";
import getAvatarReducers from "../../reducers/getAvatarReducers/getAvatarReducers";
import ioStatusReducers from "../../reducers/ioStatusReducers/ioStatusReducers";
import kaigoHokenMasterReducers from "../../reducers/kaigoHokenMasterReducers/kaigoHokenMasterReducers";
import kaigoYougoMasterReducers from "../../reducers/kaigoYougoMasterReducers/kaigoYougoMasterReducers";
import kanriReducers from "../../reducers/kanriReducers/kanriReducers";
import kohifutanMasterReducers from "../../reducers/kohifutanMasterReducers/kohifutanMasterReducers";
import kokyakuReducers from "../../reducers/kokyakuReducers/kokyakuReducers";
import kyotakuPlan1Reducers from "../../reducers/kyotakuPlan1Reducers/kyotakuPlan1Reducers";
import kyotakuPlan2Reducers from "../../reducers/kyotakuPlan2Reducers/kyotakuPlan2Reducers";
import mediSyoubyoumeiMasterReducers from "../../reducers/mediSyoubyoumeiMasterReducers/mediSyoubyoumeiMasterReducers";
import messageListReducers from "../../reducers/messageListReducers/messageListReducers";
import monitoringReducers from "../../reducers/monitoringReducers/monitoringReducer";
import planReducers from "../../reducers/planReducers/planReducers";
import progressReducers from "../../reducers/progressReducers/progressReducers";
import referenceNumberReducers from "../../reducers/referenceNumberReducers/referenceNumberReducers";
import reportReducers from "../../reducers/reportReducers/reportReducers";
import reportUserReducers from "../../reducers/reportUserReducers/reportUserReducers";
import reportWeeklyAddReducers from "../../reducers/reportWeeklyAddReducers/reportWeeklyAddReducers";
import reportWeeklyReducers from "../../reducers/reportWeeklyReducers/reportWeeklyReducers";
import riyouKaigohokenReducers from "../../reducers/riyouKaigohokenReducers/riyouKaigohokenReducers";
import riyousyaReducers from "../../reducers/riyousyaReducers/riyousyaReducers";
import roomMasterReducers from "../../reducers/roomMasterReducers/roomMasterReducers";
import sendGroupReducers from "../../reducers/sendGroupReducers/sendGroupReducers";
import SettingMasterReducers from "../../reducers/SettingMasterReducers/SettingMasterReducers";
import sisetuDailyPlanReducers from "../../reducers/sisetuDailyPlanReducers/sisetuDailyPlanReducers";
import sisetuKaigoNaiyouMasterReducers from "../../reducers/sisetuKaigoNaiyouMasterReducers/sisetuKaigoNaiyouMasterReducers";
import syokichiReducers from "../../reducers/syokichiReducers/syokichiReducers";
import systemNameListReducers from "../../reducers/systemControlReducers/systemControlReducers";
import tantoMasterListReducers from "../../reducers/tantoMasterListReducers/tantoMasterListReducers";
import testingReducers from "../../reducers/testingReducers/testingReducers";
import userListReducers from "../../reducers/userListReducers/userListReducers";
import yougoMasterNaiyouReducers from "../../reducers/yougoMasterNaiyouReducers/yougoMasterNaiyouReducers";
import ZipMasterReducers from "../../reducers/zipMasterReducers/zipMasterReducers";
// keikaKirokuReducer management
import fileManagementReducers from "../../reducers/fileManagementReducers/fileManagementReducers";
import keikaKirukuReducers from "../../reducers/keikaKirokuReducers/keikaKirokuReducer";
import tantoKaigiReducers from "../../reducers/tantoKaigiReducers/tantoKaigiReducer";
// 共有
import iryoKougakuFutanMasterReducers from "../../../reducers/iryoKougakuFutanMasterReducers/iryoKougakuFutanMasterReducers";
import iryouhokenMasterReducers from "../../../reducers/iryouhokenMasterReducers/iryouhokenMasterReducers";
import reportAdl from "../../../reducers/reportAdlReducers/reportAdlReducer";
import riyousyaHokenReducers from "../../../reducers/riyousyaHokenReducers/riyousyaHokenReducers";
// Assessment
import AssessmentReducers from "../../reducers/assessmentReducers/assessmentReducers";
import basicActionReducers from "../../reducers/basicActionReducers/basicActionReducer";
import CognitiveFunctionReducers from "../../reducers/cognitivefunction/cognitivefunctionReducers";
import DoctorOpinionReducers from "../../reducers/doctoropinion/doctoropinionReducers";
import FaceSheetReducers from "../../reducers/faceSheetReducers/faceSheetReducers";
import FamilySuportStatusReducers from "../../reducers/familySuportStatusReducers/familySuportStatusReducers";
import HealthStatusReducers from "../../reducers/healthStatusReducers/healthStatusReducers";
import housingStatusReducers from "../../reducers/housingStatusReducers/housingStatusReducers";
import kaigochosaAnswerReducers from "../../reducers/kaigochosaAnswerReducers/kaigochosaAnswerReducers";
import kaigochosaReducers from "../../reducers/kaigochosaReducers/kaigochosaReducers";
import LifeFunctionReducers from "../../reducers/lifeFunctionReducers/lifeFunctionReducers";
import MedicalHealthReducers from "../../reducers/medicalHealthReducers/medicalHealthReducers";
import OverviewAllSummaryReducers from "../../reducers/overviewallsummaryReducers/overviewallsummaryReducers";
import ScheduleReducers from "../../reducers/scheduleReducers/schedule";
import serviceStatusReducers from "../../reducers/serviceStatusReducers/serviceStatusReducers";
import socialLifeReducers from "../../reducers/socialLifeReducer/socialLifeReducers";

const rootReducer = combineReducers({
  error,
  config,
  auth,
  document,
  plan1,
  plan2,
  inquiryRequest,
  tantoMaster,
  monitoringManagement,
  // clone code
  dataLogin: accountReducers,
  userList: userListReducers,
  sendGroup: sendGroupReducers,
  messageList: messageListReducers,
  dengonSendTo: dengonSendToReducers,
  kokyaku: kokyakuReducers,
  testing: testingReducers,
  getAvatar: getAvatarReducers,
  report: reportReducers,
  reportUser: reportUserReducers,
  reportWeekly: reportWeeklyReducers,
  reportWeeklyAdd: reportWeeklyAddReducers,
  tantoMasterList: tantoMasterListReducers,
  yougoMasterNaiyou: yougoMasterNaiyouReducers,
  drugMaster: drugMasterReducers,
  allMaster: allMasterReducers,
  syokichi: syokichiReducers,
  plan: planReducers,
  progress: progressReducers,
  system: systemNameListReducers,
  eventPlan: eventPlanReducers,
  settingMaster: SettingMasterReducers,
  zipMaster: ZipMasterReducers,
  dutyReport: dutyReportReducers,
  ioStatusReducers,
  documentList: documentListReducers,
  kyotakuPlan1: kyotakuPlan1Reducers,
  kyotakuPlan2: kyotakuPlan2Reducers,
  sisetuDailyPlan: sisetuDailyPlanReducers,
  riyouKaigohokens: riyouKaigohokenReducers,
  riyousya: riyousyaReducers,
  riyousyaHoken: riyousyaHokenReducers,
  kaigoYougoMaster: kaigoYougoMasterReducers,
  sisetuKaigoNaiyouMaster: sisetuKaigoNaiyouMasterReducers,
  roomMaster: roomMasterReducers,
  kanri: kanriReducers,
  doctorMaster: doctorMasterReducers,
  mediSyoubyoumeiMaster: mediSyoubyoumeiMasterReducers,
  referenceNumber: referenceNumberReducers,
  monitoring: monitoringReducers,
  keikaKiroku: keikaKirukuReducers,
  tantoKaigi: tantoKaigiReducers,
  fileManagement: fileManagementReducers,
  kaigoHokenMaster: kaigoHokenMasterReducers,
  kohifutanMaster: kohifutanMasterReducers,
  reportAdl,
  weeklyPlan,
  monitoringMNGT,
  meeting,
  progressMNGT,
  serviceKikanMaster,
  itakuTantoMaster,
  task,
  useslip,
  facesheet,
  kihonCheck,
  yobousienPlan,
  hyoukahyou,
  iryouhokenMaster: iryouhokenMasterReducers,
  iryoKougakuFutanMaster: iryoKougakuFutanMasterReducers,
  usageTicketManagement,
  subsidyManagement,
  // Assessment
  family: FamilySuportStatusReducers,
  faceSheet: FaceSheetReducers,
  housing: housingStatusReducers,
  serviceStatus: serviceStatusReducers,
  healthStatus: HealthStatusReducers,
  assessment: AssessmentReducers,
  overviewallsummary: OverviewAllSummaryReducers,
  doctoropinion: DoctorOpinionReducers,
  lifeFunction: LifeFunctionReducers,
  cognitive: CognitiveFunctionReducers,
  schedule: ScheduleReducers,
  medicalHealth: MedicalHealthReducers,
  basicAction: basicActionReducers,
  socialLife: socialLifeReducers,
  kaigochosa: kaigochosaReducers,
  chosaAnswer: kaigochosaAnswerReducers,
  subsidy: subsidyDetailReducer
});

export default rootReducer;
