export const Sort = {
  Title: "並び替え",
  Row1: "第1キー",
  Row2: "第2キー",
  Row3: "第3キー",
  Row4: "第4キー",
  SortBy: [
    { display: "降順", value: "desc" },
    { display: "昇順", value: "asc" },
  ],
  LifeList: [
    { display: "利用中", value: "riyouHoldingF" },
    { display: "利用者", value: "riyousya" },
  ],
  kagaku: [
    { display: "利用中", value: "riyouHoldingF" },
    { display: "利用者", value: "riyousya" },
    { display: "確定", value: "kaga_kakuteif" },
    { display: "評価日", value: "kaga_hyoukaDate" },
    // { display: "年月", value: "kaga_nengetu" },
  ],
  kobetu: [
    { display: "利用中", value: "riyouHoldingF" },
    { display: "利用者", value: "riyousya" },
    { display: "個別Ⅱ確定", value: "kk2_kakuteif" },
    { display: "個別Ⅱ作成日", value: "kk2_createDate" },
    { display: "生活確定", value: "skc_kakuteif" },
    { display: "生活評価日", value: "skc_evaluateDate" },
  ],
  JokusoPlan: [
    { display: "利用中", value: "riyouHoldingF" },
    { display: "利用者", value: "riyousya" },
    { display: "確定", value: "jp_kakuteif" },
    { display: "評価日", value: "jp_hyoukaDate" },
  ],
  haisetu: [
    { display: "利用中", value: "riyouHoldingF" },
    { display: "利用者", value: "riyousya" },
    { display: "確定", value: "hsp_kakuteif" },
    { display: "評価日", value: "hsp_hyoukaDate" },
  ],
  jirituSokusin: [
    { display: "利用中", value: "riyouHoldingF" },
    { display: "利用者", value: "riyousya" },
    { display: "確定", value: "js_kakuteif" },
    { display: "評価日", value: "js_hyoukaDate" },
  ],
  koku: [
    { display: "利用中", value: "riyouHoldingF" },
    { display: "利用者", value: "riyousya" },
    { display: "確定", value: "koku_kakuteif" },
    { display: "作成日", value: "koku_makeDate" },
  ],
  yakuzaiChange: [
    { display: "利用中", value: "riyouHoldingF" },
    { display: "利用者", value: "riyousya" },
    { display: "確定", value: "yc_kakuteif" },
    { display: "記録日", value: "yc_makeDate" },
  ],
  adl: [
    { display: "利用中", value: "riyouHoldingF" },
    { display: "利用者", value: "riyousya" },
    { display: "確定", value: "adl_kakuteif" },
    { display: "記録日", value: "adl_makeDate" },
  ],
  DefaultValue: {
    key1: "0",
    value1: "asc",
    key2: "0",
    value2: "asc",
    key3: "0",
    value3: "asc",
    key4: "0",
    value4: "asc",
  },
 
};

export const DefaultObjectListDataPDF = {
  // offerTime: "",
  // officeName: "",
  // serviceName: "",
  listActual : [0],
  listPlan: [0],
  sumListFActual: null,
  sumListFPlan: null,
  sumListPlan: 0,
  sumListFPlan: 0,
}

export const objectDefaultListDataAttachedPDF = {
  "事業所名": "",
  "事業所番号": "",
  "サービス内容/種類": "",
  "サービスコード": "",
  "単位数": "",
  "割引適用後率": "",
  "割引適用後単位数": "",
  "回数": "",
  "サービス単位/金額": "",
  "給付管理単位数": "",
  "種類支給限度基準を超える単位数": "",
  "種類支給限度基準内単位数": "",
  "区分支給限度基準を超える単位数": "",
  "区分支給限度基準内単位数": "",
  "単位数単価":"",
  "費用総額": "",
  "給付率": "",
  "保険給付額": "",
  "保険対象分": "",
  "全額負担分": ""
}

export const ListTanisikibetu = ["03","07","08","09","13"];
