import { AuthActionTypes } from "../action_types";

function authmobile(state = { loadMode: false, error: "" }, action) {
  switch (action.type) {
    // signin
    case AuthActionTypes.MOBILE_SIGNIN_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case AuthActionTypes.MOBILE_SIGNIN_SUCCESS: {
      return {
        ...state,
        isUserLoggedIn: true,
        loading: false,
        gmk: action.gmk,
        menu: action.menu,
        mfs: action.mfs,
        sessionTimeout: action.sessionTimeout,
        jwtTokenType: action.jwtTokenType,
        error: "",
      };
    }
    case AuthActionTypes.MOBILE_SIGNIN_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    }

    // signout
    case AuthActionTypes.SIGNOUT: {
      return {
        ...state,
        isUserLoggedIn: false,
        gmk: "",
        menu: {},
        mfs: "",
        sessionTimeout: "",
        error: "",
      };
    }

    // maintainance mode
    case AuthActionTypes.MOBILE_MAINTAINANCE_MODE_REQUEST: {
      return {
        ...state,
        loadMode: true,
      };
    }
    case AuthActionTypes.MOBILE_MAINTAINANCE_MODE_SUCCESS: {
      return {
        ...state,
        dataMode: action.dataMode,
        systemTitle: action.systemTitle,
        loadMode: false,
      };
    }
    case AuthActionTypes.MOBILE_MAINTAINANCE_MODE_FAILURE: {
      return {
        ...state,
        dataMode: "",
        loadMode: false,
      };
    }
    default: {
      return { ...state };
    }
  }
}

export default authmobile;
