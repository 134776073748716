import { ErrorActionTypes, LifeYougoMasterActionTypes } from "../action_types";

// utils
import { RequestUtils } from "../../../management/utils";
import { localStorageClearCus } from "../../common/function_common/functionCommon";
import { ApiPaths } from "../../constants";
import { LifeYougoMasterNaiyouApi } from "../../reuse/api/lifeYougoMasterNaiyou";

export function getLifeYougoMaster(kubun, listName) {
  return (dispatch) => {
    if (kubun && listName) {
      LifeYougoMasterNaiyouApi.lifeYougoMasterNaiyou(
        kubun,
        listName ? listName : ""
      ).then(
        (data) => {
          dispatch({
            type: LifeYougoMasterActionTypes.GET_LIFE_YOUGO_MASTER_SUCCESS,
            naiyou: data ? data.naiyou : null,
          }) 
          console.log("test2 data.naiyou: ", data ? data.naiyou : "")
        },
        (err) => {
          checkError(err);
          dispatch({
            type: LifeYougoMasterActionTypes.LOAD_LIFE_YOUGO_ATTRIBUTE_ERROR,
            error:
              err.response.status === 400
                ? err.response.data.error
                : { message: err.response.statusText },
          });
        }
      );
    } else {
      LifeYougoMasterNaiyouApi.lifeYougoMasterNaiyou(
        "個別機能II",
        "達成度"
      ).then(
        (data) =>
          dispatch({
            type: LifeYougoMasterActionTypes.GET_LIFE_YOUGO_MASTER_BUNRUI1,
            naiyou: data ? data.naiyou : null,
          }),
        (err) => {
          checkError(err);
          dispatch({
            type: LifeYougoMasterActionTypes.LOAD_LIFE_YOUGO_ATTRIBUTE_ERROR,
            error:
              err.response.status === 400
                ? err.response.data.error
                : { message: err.response.statusText },
          });
        }
      );
    }
  };
}

export function saveLifeYougoMaster(kubun, listName, naiyou) {
  return (dispatch) => {
    LifeYougoMasterNaiyouApi.updateLifeYougoMasterApi(
      kubun,
      listName,
      naiyou
    ).then(
      (data) =>
        dispatch({
          type: LifeYougoMasterActionTypes.SAVE_LIFE_YOUGO_MASTER_SUCCESS,
          naiyou: data.naiyou,
        }),
      (err) => {
        if (
          err.response.status === 400 &&
          err.response.data.error.code === "YOUGOMASTER_NOT_FOUND"
        ) {
          LifeYougoMasterNaiyouApi.createLifeYougoMaster(
            kubun,
            listName,
            naiyou
          ).then(
            (data) =>
              dispatch({
                type: LifeYougoMasterActionTypes.SAVE_LIFE_YOUGO_MASTER_SUCCESS,
                naiyou: data.naiyou,
              }),
            (err2) => {
              checkError(err2);
              dispatch({
                type: LifeYougoMasterActionTypes.SAVE_LIFE_YOUGO_MASTER_ERROR,
                error:
                  err2.response.status === 400
                    ? err2.response.data.error
                    : { message: err2.response.statusText },
              });
            }
          );
        } else {
          checkError(err);
          dispatch({
            type: LifeYougoMasterActionTypes.SAVE_LIFE_YOUGO_MASTER_ERROR,
            error:
              err.response.status === 400
                ? err.response.data.error
                : { message: err.response.statusText },
          });
        }
      }
    );
  };
}

function getLifeYougoMasterNew(kubun, listName, callback) {
  return async (dispatch) => {
    try {
      // dispatch({ type: KobetsuActionTypes.REMOVE_KOBETSU_REQUEST });

      // Call API
      var query = { kubun: kubun, listName: listName };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.getLifeYougoMaster,
        query,
      });

      dispatch({ type: LifeYougoMasterActionTypes.GET_LIFE_YOUGO_MASTER_SUCCESS, data: data ? data.naiyou : "" });

      if (callback && typeof callback === "function") {
        callback(data ? data.naiyou : "")
      }

      callback(data ? data.naiyou : "");
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getIcfMaster(typeIcfCd, mokuhyou, textSearch, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: LifeYougoMasterActionTypes.GET_ICF_MASTER_REQUEST });

      // Call API
      var query = {
        typeIcfCd: typeIcfCd,
        mokuhyou: mokuhyou,
        textSearch: textSearch,
      };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.getIcfMaster,
        query,
      });

      dispatch({ type: LifeYougoMasterActionTypes.GET_ICF_MASTER_SUCESS, data });

      if (callback && typeof callback === "function") {
        callback()
      }

    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getSienNaiyoMaster(saiKomoku) {
  return async (dispatch) => {
    try {
      // Call API
      var query = { saiKomoku: saiKomoku };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.getSienNaiyoMaster,
        query,
      });

      dispatch({
        type: LifeYougoMasterActionTypes.GET_SIENNAIYO_MASTER_SUCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getIcfMasterByIcfCd(icfCd, callback) {
  return async (dispatch) => {
    try {
      // Call API
      var query = { icfCd: icfCd };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.getIcfMasterByIcfCd,
        query,
      });

      dispatch({ type: LifeYougoMasterActionTypes.GET_ICF_MASTER_KOUI, data });
      if (callback && typeof callback === "function") {
        callback(data);
      }

    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getSienNaiyoMasterBySienCd(sienCd, callback) {
  return async (dispatch) => {
    try {
      // Call API
      var query = { sienCd: sienCd };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.getSienNaiyoMasterBySienCd,
        query,
      });

      dispatch({ type: LifeYougoMasterActionTypes.GET_SIENNAIYO_MASTER_SAIKOMOKU, data });

      if (callback && typeof callback === "function") {
        callback(data);
      }

    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getSettingMaster(settingName, callback) {
  return async (dispatch) => {
    try {
      // Call API
      var query = { settingName: settingName };
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.LifeList.getSettingMaster,
        query,
      });

      dispatch({ type: LifeYougoMasterActionTypes.GET_SETTING_MASTER_SUCESS, data });

      if (callback && typeof callback === "function") {
        callback(data);
      }

    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getSeikatuKinouAdlMaster() {
  return async (dispatch) => {
    try {
      dispatch({
        type: LifeYougoMasterActionTypes.LOAD_SEIKATU_ADL_MASTER_REQUEST,
      });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.SeikatuKinouCheck.GetSeikatuKinouAdlMaster}`,
      });
      dispatch({
        type: LifeYougoMasterActionTypes.LOAD_SEIKATU_ADL_MASTER_SUCCESS,
        seikatuKinouAdlMasters: data,
      });
    } catch (error) {
      dispatch({
        type: LifeYougoMasterActionTypes.LOAD_SEIKATU_ADL_MASTER_ERROR,
        error,
      });
    }
  };
}

function checkError(err) {
  // Check Token Exist
  if (err.response.status === 403) {
    localStorageClearCus();
    window.location.replace("/life/login");
  }
}

export const LifeYougoMasterActions = {
  getLifeYougoMasterNew,
  getIcfMaster,
  getSienNaiyoMaster,
  getIcfMasterByIcfCd,
  getSienNaiyoMasterBySienCd,
  getSettingMaster,
  getSeikatuKinouAdlMaster,
};
