export const YakuzaiActionTypes = {
  LOAD_SPIN_REQUEST: "LOAD_SPIN_REQUEST",

  YAKUZAI_LIFE_YOUGO_SUCCESS: "YAKUZAI_LIFE_YOUGO_SUCCESS",
  YAKUZAI_LIFE_YOUGO_ERROR: "YAKUZAI_LIFE_YOUGO_ERROR",

  LOAD_YAKUZAI_SUCCESS: "LOAD_YAKUZAI_SUCCESS",
  LOAD_YAKUZAI_ERROR: "LOAD_YAKUZAI_ERROR",

  REMOVE_YAKUZAI_REQUEST: "REMOVE_YAKUZAI_REQUEST",
  REMOVE_YAKUZAI_SUCCESS: "REMOVE_YAKUZAI_SUCCESS",
  REMOVE_YAKUZAI_ERROR: "REMOVE_YAKUZAI_ERROR",

  SAVE_YAKUZAI_SUCCESS: "SAVE_YAKUZAI_SUCCESS",
  SAVE_YAKUZAI_ERROR: "SAVE_YAKUZAI_ERROR",

  LOAD_YAKUZAI_TO_QUOTE_SUCCESS: "LOAD_YAKUZAI_TO_QUOTE_SUCCESS",
  LOAD_YAKUZAI_TO_QUOTE: "LOAD_YAKUZAI_TO_QUOTE",

  SET_NEW_DATA: "SET_NEW_DATA",
  GET_LIST_IDS_YAKUZAI: "GET_LIST_IDS_YAKUZAI",

  LOAD_YAKUZAI_BY_IDS_REQUEST: "LOAD_YAKUZAI_BY_IDS_REQUEST",
  LOAD_YAKUZAI_BY_IDS_SUCCESS: "LOAD_YAKUZAI_BY_IDS_SUCCESS",
  LOAD_YAKUZAI_BY_IDS_ERROR: "LOAD_YAKUZAI_BY_IDS_ERROR",
  CLEAR_YAKUZAI_BY_IDS: "CLEAR_YAKUZAI_BY_IDS",

  FORM_INPUT_ADD: "FORM_INPUT_ADD",
  FORM_INPUT_DELETE: "FORM_INPUT_DELETE",

  GET_INITIAL_DATA_SUCCESS: "GET_INITIAL_DATA_SUCCESS",
};
