import dayjs from "dayjs";
import { ConstSet } from "../../../common/configs/constset";
import { ConfigName } from "../../../config-name";
import { ApiPaths } from "../../constants";
import { CommonUtils, RequestUtils, StorageUtils } from "../../utils";
import { ErrorActionTypes, YakuzaiActionTypes } from "../action_types";

function getYakuzaiChangeLifeYougo() {
  return async (dispatch) => {
    try {
      dispatch({ type: YakuzaiActionTypes.LOAD_SPIN_REQUEST });

      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.YakuzaiChange.getYakuzaiChangeLifeYougo, // ここで取得
      });

      dispatch({ type: YakuzaiActionTypes.YAKUZAI_LIFE_YOUGO_SUCCESS, data });
    }
    catch (error) {
      dispatch({
        type: YakuzaiActionTypes.YAKUZAI_LIFE_YOUGO_ERROR,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getYakuzaiChangeById(id) {
  return async (dispatch) => {
    try {
      dispatch({ type: YakuzaiActionTypes.LOAD_SPIN_REQUEST });

      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.YakuzaiChange.getYakuzaiChangeById}?id=${id}`,
      });

      dispatch({ type: YakuzaiActionTypes.LOAD_YAKUZAI_SUCCESS, data });
    }
    catch (error) {
      dispatch({
        type: YakuzaiActionTypes.LOAD_YAKUZAI_ERROR,
      });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function deleteYakuzaiChange(id, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: YakuzaiActionTypes.REMOVE_YAKUZAI_REQUEST });

      const data = await RequestUtils.callApi({
        method: "delete",
        path: `${ApiPaths.YakuzaiChange.delYakuzaiChange}?id=${id}`,
      });

      dispatch({ type: YakuzaiActionTypes.REMOVE_YAKUZAI_SUCCESS, data });
      return callback(data);
    }
    catch (error) {
      dispatch({ type: YakuzaiActionTypes.REMOVE_YAKUZAI_ERROR });
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function createUpYakuzai(body, del, callback) {
  return async (dispatch) => {
    try {
      dispatch({ type: YakuzaiActionTypes.LOAD_SPIN_REQUEST });
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.YakuzaiChange.saveYakuzaiChange,
        body: {
          body,
          delYcd: del.delYcd ? del.delYcd : null,
          delYck: del.delYck ? del.delYck : null,
        },
      });
      dispatch({
        type: YakuzaiActionTypes.SAVE_YAKUZAI_SUCCESS,
        data,
      });
      callback(data);
    }
    catch (error) {
      dispatch({
        type: YakuzaiActionTypes.SAVE_YAKUZAI_ERROR,
        error,
      });
    }
  };
}

function getListYakuzaiChangeToQuote(riyousyaId, id) {
  return async (dispatch) => {
    try {
      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const query = { riyousyaId, id, systemControlName: menu.scn, systemControlId: menu.si };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: `${ApiPaths.YakuzaiChange.getListYakuzaiChangeToQuote}`,
        query,
      });
      dispatch({
        type: YakuzaiActionTypes.LOAD_YAKUZAI_TO_QUOTE_SUCCESS,
        data,
      });
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getListIdsYakuzai(params) {
  const SWLIFEListRiyousya = StorageUtils.getValue(
    ConfigName.SWLIFE_LISTRIYOUSYA
  );
  const yakuzaiSort =
    SWLIFEListRiyousya.sort && SWLIFEListRiyousya.sort.yakuzai
      ? SWLIFEListRiyousya.sort.yakuzai
      : {};
  return async (dispatch) => {
    try {
      dispatch({ type: YakuzaiActionTypes.LOAD_SPIN_REQUEST });

      const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);
      const query = {
        sid: menu.si || null,
        inUsed: SWLIFEListRiyousya.isUsed ? 1 : 0,
        searchName: params.searchName,
        fromDate:
          !params.riyousyaId > 0
            ? dayjs(new Date(SWLIFEListRiyousya.monthSelect))
              .startOf("month")
              .format(ConstSet.DATE_FORMAT)
            : null,
        toDate:
          !params.riyousyaId > 0
            ? dayjs(new Date(SWLIFEListRiyousya.monthSelect))
              .endOf("month")
              .format(ConstSet.DATE_FORMAT)
            : null,
        sort: JSON.stringify(CommonUtils.convertSortDataToParams(yakuzaiSort)),
        riyousyaId: params.riyousyaId,
      };

      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.YakuzaiChange.getListIdsYakuzai,
        query,
      });

      dispatch({
        type: YakuzaiActionTypes.GET_LIST_IDS_YAKUZAI,
        ids: data,
      });
    }
    catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function addNewFormCreator(type, data) {
  return (dispatch) => {
    dispatch({
      type: YakuzaiActionTypes.FORM_INPUT_ADD,
      params: { type, data },
    });
  };
}
function handleDeleteCreator(type, index, data) {
  return (dispatch) => {
    dispatch({
      type: YakuzaiActionTypes.FORM_INPUT_DELETE,
      params: { type, index, data },
    });
  };
}

function getInitialData(riyousyaId, firstDayOfMonth, lastDayOfMonth) {
  return async (dispatch) => {
    try {
      const data = await RequestUtils.callApi({
        method: "get",
        path: ApiPaths.YakuzaiChange.getInitialData,
        query: {
          riyousyaId,         // 利用者 ID
          firstDayOfMonth,    // 月初の日付
          lastDayOfMonth,     // 月末の日付
        },
      });

      dispatch({
        type: YakuzaiActionTypes.GET_INITIAL_DATA_SUCCESS,
        payload: data, // API のレスポンスデータを payload に保存
      });

    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  }
}


export const YakuzaiActions = {
  getYakuzaiChangeLifeYougo,
  getYakuzaiChangeById,
  deleteYakuzaiChange,
  createUpYakuzai,
  getListYakuzaiChangeToQuote,
  addNewFormCreator,
  handleDeleteCreator,
  getListIdsYakuzai,
  getInitialData,
};
