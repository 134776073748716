import React, { memo } from "react";
import { Modal } from "antd-mobile";
import { useTranslation } from "react-i18next";

function ConfirmSignoutDialog(props) {
  const { t } = useTranslation();

  const handleConfirm = () => {
    if (props.onOk) {
      props.onOk();
    }
  };

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  return (
    <Modal
      visible={props.visible}
      content={
        <div>
          <p>{t("signin.ログアウトします。よろしいですか？")}</p>
        </div>
      }
      closeOnAction
      actions={[
        {
          key: "confirm",
          text: t("button.はい"),
          primary: true,
          onClick: handleConfirm,
        },
        {
          key: "cancel",
          text: t("button.いいえ"),
          onClick: handleCancel,
        },
      ]}
    />
  );
}

const _ConfirmSignoutDialog = memo(ConfirmSignoutDialog, (props, nextProps) => props.visible === nextProps.visible);

export { _ConfirmSignoutDialog as ConfirmSignoutDialog };
