import React, { Component } from "react";
import { CheckOutlined } from '@ant-design/icons';
import { Row, Col, Button, List, Avatar, Popover } from "antd";
import jwtDecode from "jwt-decode";

import "react-dates/initialize";
import "./Dengon.css";
import {
  getValueLocalstorage,
  isLife,
  isManagement,
} from "../../common/function_common/functionCommon";
import { StorageUtils } from "../../management/utils";
import { ConfigName } from "../../config-name";

class DengonList extends Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);
    const token = isManagement()
      ? StorageUtils.getValue("tokens") && StorageUtils.getValue("tokens").token
      : isLife()
      ? StorageUtils.getValue(ConfigName.SWLIFE_TOKEN) &&
        StorageUtils.getValue(ConfigName.SWLIFE_TOKEN).token
      : localStorage.getItem("jwtToken");

    const decoded = jwtDecode(token);
    this.state = {
      tid: decoded.userInfo.tidCopy
        ? decoded.userInfo.tidCopy
        : parseInt(getValueLocalstorage("tid")),
      pageNo: 0,
    };
  }

  componentDidMount() {
    document.addEventListener("scroll", this.trackScrolling);
  }

  componentWillUnmount = () => {
    document.removeEventListener("scroll", this.trackScrolling);
  };

  /**
   *
   * @param {*} el
   */
  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById("message-scroll");
    if (this.isBottom(wrappedElement)) {
      this.setState({
        pageNo: this.state.pageNo + 1,
      }, () => {
        this.props.loadMore(this.state.tid, this.state.pageNo);
      });
    }
  };

  rely = (tantoId, readf, id) => {
    this.props.rely(tantoId, readf, id);

    window.scrollTo(0, 0);
    document.getElementById("message-text").focus();
  };

  mitayo = (id) => {
    this.props.mitayo(id.toString());
  };

  render() {
    const { messages, loading } = this.props;

    return (
      <div className={"dengon-mess-list"}>
        <Row
          className={"maxw-960 dg-bd-message"}
          id={"message-scroll"}
          onScroll={this.trackScrolling}
        >
          <Col span={24}>
            <List
              itemLayout="horizontal"
              dataSource={messages}
              renderItem={(item) => (
                <List.Item>
                  {
                    // if it own message
                    this.state.tid ===
                    (item.sendTantoMaster ? item.sendTantoMaster.id : 0) ? (
                      <div
                        className={"messageOwn"}
                        style={{ textAlign: "right" }}
                      >
                        <List.Item.Meta
                          description={item.dateString + " " + item.timeString}
                        />
                        <div className="dp-block dg-bd-m-text">
                          <span>
                            <span style={{ padding: "0px 5px" }}>
                              {item.readfOnCount ? (
                                <Popover
                                  placement="bottom"
                                  content={item.readfOnCountList
                                    .split(",")
                                    .map((e, index) => {
                                      return (
                                        <p key={index}>
                                          {e.split("/").map((c, i) => {
                                            return <span key={i}>{c}</span>;
                                          })}
                                        </p>
                                      );
                                    })}
                                  trigger="click"
                                >
                                  {"既読 " + item.readfOnCount}
                                </Popover>
                              ) : (
                                ""
                              )}
                            </span>
                            <span style={{ padding: "0px 5px" }}>
                              {item.readfOffCount ? (
                                <Popover
                                  placement="bottom"
                                  content={item.readfOffCountList
                                    .split(",")
                                    .map((e, index) => {
                                      return <p key={index}>{e}</p>;
                                    })}
                                  trigger="click"
                                >
                                  {"未読 " + item.readfOffCount}
                                </Popover>
                              ) : (
                                ""
                              )}
                            </span>
                            <p style={{ background: "#AED581" }}>
                              {item.naiyo}
                            </p>
                          </span>
                        </div>
                      </div>
                    ) : (
                      // if it reference message
                      <div className={"messageRefe"}>
                        <List.Item.Meta
                          avatar={<Avatar src={item.userImage} />}
                          title={
                            <span>
                              {item.sendTantoMaster
                                ? item.sendTantoMaster.name
                                : ""}
                            </span>
                          }
                          description={item.dateString + " " + item.timeString}
                        />
                        <div className="dp-block dg-bd-m-text">
                          <p>{item.naiyo}</p>
                        </div>
                        <div className="dp-block">
                          <span>
                            <span>
                              {item.readf ? (
                                <span style={{ paddingRight: "5px" }}>
                                  {"既読"}
                                </span>
                              ) : (
                                <a
                                  style={{ color: "red" }}
                                  onClick={() => this.mitayo(item.id)}
                                >
                                  <CheckOutlined />
                                  {"みたよ！"}
                                </a>
                              )}
                            </span>
                            <Button
                              type="primary"
                              onClick={() =>
                                this.rely(
                                  item.sendTantoMaster
                                    ? item.sendTantoMaster.id
                                    : "",
                                  item.readf,
                                  item.id
                                )
                              }
                            >
                              返信
                            </Button>
                          </span>
                        </div>
                      </div>
                    )
                  }
                </List.Item>
              )}
            />
            {loading ? (
              <div className="loading">
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
                <div className="loading-bar"></div>
              </div>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export default DengonList;
