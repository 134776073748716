import { Icon } from "@ant-design/compatible";
import {
  Button,
  Calendar,
  Col,
  Popover,
  Row,
  Select
} from "antd";
import moment from "moment";
import React from "react";
import {
  convert_wareki,
  convert_wareki_2,
  convert_wareki_3,
} from "../../../common/function_common/functionCommon";
import dayjs from "dayjs";

const { Option } = Select;

const gengou = ["令和", "平成", "昭和", "大正", "明治"];
const gengounen = {
  令和: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
    "60",
    "61",
    "62",
    "63",
    "64",
    "65",
    "66",
    "67",
    "68",
    "69",
    "70",
    "71",
    "72",
    "73",
    "74",
    "75",
    "76",
    "77",
    "78",
    "79",
    "80",
    "81",
    "82",
    "83",
    "84",
    "85",
    "86",
    "87",
    "88",
    "89",
    "90",
    "91",
    "92",
    "93",
    "94",
    "95",
    "96",
    "97",
    "98",
    "99",
  ],
  平成: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ],
  昭和: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
    "60",
    "61",
    "62",
    "63",
    "64",
  ],
  大正: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
  ],
  明治: [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
  ],
};

const monthS = [
  "1月",
  "2月",
  "3月",
  "4月",
  "5月",
  "6月",
  "7月",
  "8月",
  "9月",
  "10月",
  "11月",
  "12月",
];
const monthSDate = {
  "01月": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ],
  "0月": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ],
  "1月": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ],
  "2月": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
  ],
  "3月": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ],
  "4月": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ],
  "5月": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ],
  "6月": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ],
  "7月": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ],
  "8月": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ],
  "9月": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ],
  "10月": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ],
  "11月": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
  ],
  "12月": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
  ],
};

const format = "YYYY-MM-DD";

export default class JPDateSelectPicker extends React.Component {
  constructor(props) {
    super(props);

    const { value } = this.props;
    // let y = value && value.format('YYYY') ? value.format('YYYY') : moment(new Date(), format).format('YYYY');
    // let m = value && value.format('YYYY') ? value.format('MM') : moment(new Date(), format).format('MM');
    // let d = value && value.format('YYYY') ? value.format('DD') : moment(new Date(), format).format('DD');
    const y =
      value && value.format("YYYY") !== "0001"
        ? value.format("YYYY")
        : value.format("YYYY");
    const m =
      value && value.format("YYYY") !== "0001"
        ? value.format("M")
        : value.format("MM");
    const d =
      value && value.format("YYYY") !== "0001"
        ? value.format("D")
        : value.format("DD");

    const tmp1 = convert_wareki(y, m, d);
    const tmp2 = convert_wareki_2(tmp1.t, tmp1.n, y, m, d);
    const dateF = moment(`${tmp2.y}-${tmp2.m}-${tmp2.d}`, format);

    this.state = {
      gengouv: tmp1.t,
      gengouD: gengounen[tmp1.t],
      gengouD2: tmp1.n,

      monthSDV: m,
      monthSD: monthSDate[parseInt(m) + "月"],
      monthSD2: d,

      isOpenDatePicker: false,
      year: y,
      month: m,
      day: d,
      dateF,

      // checkDate:
    };

    // bind place
  }

  /**
   * keyId -1: data.id無し, -2:新規追加
   * @param {*} preProps
   * @param {*} preState
   */
  componentDidUpdate(preProps) {
    const { keyId, value } = this.props;

    if (preProps.value != value && value) {
      // let y = value && value.format('YYYY') ? value.format('YYYY') : moment(new Date(), format).format('YYYY');
      // let m = value && value.format('YYYY') ? value.format('MM') : moment(new Date(), format).format('MM');
      // let d = value && value.format('YYYY') ? value.format('DD') : moment(new Date(), format).format('DD');
      const y =
        value && value.format("YYYY") !== "0001"
          ? value.format("YYYY")
          : value.format("YYYY");
      const m =
        value && value.format("YYYY") !== "0001"
          ? value.format("M")
          : value.format("MM");
      const d =
        value && value.format("YYYY") !== "0001"
          ? value.format("D")
          : value.format("DD");

      const tmp1 = convert_wareki(y, m, d);
      const tmp2 = convert_wareki_2(tmp1.t, tmp1.n, y, m, d); // y,m,dで日付を
      let dateF;
      if (value.format("YYYY-MM-DD") !== "0001-01-01") {
        dateF = moment(`${tmp2.y}-${tmp2.m}-${tmp2.d}`, format);
      }
      else {
        dateF = moment("0001-01-01", format);
      }
      // let dateF = moment(`${tmp2.y}-${tmp2.m}-${tmp2.d}`, format);

      this.setState({
        gengouv: tmp1.t,
        gengouD: gengounen[tmp1.t],
        gengouD2: tmp1.n,

        monthSDV: m,
        monthSD: monthSDate[parseInt(m) + "月"],
        monthSD2: d,

        year: y,
        month: m,
        day: d,
        dateF,
      });
    }
    // 呼び出し時
    if (preProps.keyId !== keyId) {
      const y =
        value && value.format("YYYY") !== "0001"
          ? value.format("YYYY")
          : value.format("YYYY");
      const m =
        value && value.format("YYYY") !== "0001"
          ? value.format("M")
          : value.format("MM");
      const d =
        value && value.format("YYYY") !== "0001"
          ? value.format("D")
          : value.format("DD");

      const tmp1 = convert_wareki(y, m, d);
      const tmp2 = convert_wareki_2(tmp1.t, tmp1.n, y, m, d);
      const dateF = moment(`${tmp2.y}-${tmp2.m}-${tmp2.d}`, format);

      this.setState({
        gengouv: tmp1.t,
        gengouD: gengounen[tmp1.t],
        gengouD2: tmp1.n,

        monthSDV: m,
        monthSD: monthSDate[parseInt(m) + "月"],
        monthSD2: d,

        year: y,
        month: m,
        day: d,
        dateF,
      });
    }
    // 新規追加時
    if (keyId == -2 && preProps.keyId !== keyId) {
      const y =
        value && value.format("YYYY") !== "0001"
          ? value.format("YYYY")
          : value.format("YYYY");
      const m =
        value && value.format("YYYY") !== "0001"
          ? value.format("M")
          : value.format("MM");
      const d =
        value && value.format("YYYY") !== "0001"
          ? value.format("D")
          : value.format("DD");

      const tmp1 = convert_wareki(y, m, d);
      const tmp2 = convert_wareki_2(tmp1.t, tmp1.n, y, m, d);
      const dateF = moment(`${tmp2.y}-${tmp2.m}-${tmp2.d}`, format);

      this.setState({
        gengouv: tmp1.t,
        gengouD: gengounen[tmp1.t],
        gengouD2: tmp1.n,

        monthSDV: m,
        monthSD: monthSDate[parseInt(m) + "月"],
        monthSD2: d,

        year: y,
        month: m,
        day: d,
        dateF,
      });
    }
  }

  /**
   * trigger dirrectly from input
   *
   */
  select = (record) => {
    const { value } = this.props;
    let tpm = "";

    if (record && record.naiyou) {
      if (value) {
        tpm = value + "、" + record.naiyou;
      }
      else {
        tpm = record.naiyou;
      }
    }

    this.triggerChange(tpm);
  };

  /**
   * triiger from table select row (yougo_master)
   *
   */
  handleSelectChange = (value) => {
    this.triggerChange(value);
  };

  /**
   * Should provide an event to pass value to Form.
   *
   */
  triggerChange = (changedValue) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(changedValue);
    }
  };

  // 元号変更
  handleGengouChange = (value) => {
    // console.log(value + "/" + gengounen[value] + "/" + gengounen[value][0]);
    const tmp2 = convert_wareki_2(value, 1);
    const dateF = moment(`${tmp2.y}-${tmp2.m}-${tmp2.d}`, format);
    this.setState({
      gengouv: tmp2.t,
      gengouD: gengounen[tmp2.jt],
      gengouD2: tmp2.jn,

      monthSDV: tmp2.m + "月",
      monthSD: monthSDate[tmp2.m + "月"],
      monthSD2: tmp2.d,

      year: tmp2.y,
      month: tmp2.m,
      day: tmp2.d,
      dateF,
    });

    this.triggerChange(dateF);
  };

  // 年の変更
  onGengouchange = (value) => {
    const { year, month, day } = this.state;
    const tmp1 = convert_wareki(year, month, day);
    const tmp2 = convert_wareki_2(tmp1.t, value, year, month, day);
    const dateF = moment(`${tmp2.y}-${tmp2.m}-${tmp2.d}`, format);
    this.setState({
      gengouv: tmp2.t,
      gengouD: gengounen[tmp2.jt],
      gengouD2: tmp2.jn,

      monthSDV: tmp2.m + "月",
      monthSD: monthSDate[parseInt(tmp2.m) + "月"],
      monthSD2: tmp2.d,

      year: tmp2.y,
      month: tmp2.m,
      day: tmp2.d,
      dateF,
    });

    this.triggerChange(dateF);
  };

  // 月の変更
  handleMonthChange = (value) => {
    const { year, day } = this.state;
  
    let adjustedDay = day;
    
    if (value === "2月") {
      const numericYear = parseInt(year, 10);
      const isLeapYear = (numericYear % 4 === 0 && numericYear % 100 !== 0) || (numericYear % 400 === 0);
      const maxDays = isLeapYear ? 29 : 28;
  
      if (day > maxDays) {
        adjustedDay = maxDays;
      }
    }
  
    const tmp1 = convert_wareki(year, value, adjustedDay);
    const tmp2 = convert_wareki_2(tmp1.t, tmp1.n, year, value.slice(0, -1), adjustedDay);
    const dateF = moment(`${tmp2.y}-${tmp2.m}-${tmp2.d}`, format);
  
    this.setState({
      gengouv: tmp2.t,
      gengouD: gengounen[tmp2.jt],
      gengouD2: tmp2.jn,
  
      monthSDV: tmp2.m + "月",
      monthSD: monthSDate[parseInt(tmp2.m) + "月"],
      monthSD2: tmp2.d,
  
      year: tmp2.y,
      month: tmp2.m,
      day: tmp2.d,
      dateF,
    });
  
    this.triggerChange(dateF);
  };

  // 日付変更
  onMonthChange = (value) => {
    console.log("🚀 ~ JPDateSelectPicker ~ value:", value)
    const { year, month } = this.state;
    console.log("🚀 ~ JPDateSelectPicker ~ this.state:", this.state)
    const tmp1 = convert_wareki(year, month, value);
    const tmp2 = convert_wareki_2(tmp1.t, tmp1.n, year, month, value);
    // dataFがthis.triggerChange()で送るデータ
    const dateF = moment(`${tmp2.y}-${tmp2.m}-${tmp2.d}`, format);

    this.setState({
      gengouv: tmp2.t,
      gengouD: gengounen[tmp2.jt],
      gengouD2: tmp2.jn,

      monthSDV: tmp2.m + "月",
      monthSD: monthSDate[parseInt(tmp2.m) + "月"],
      monthSD2: tmp2.d,

      year: tmp2.y,
      month: tmp2.m,
      day: tmp2.d,
      dateF,
    });

    this.triggerChange(dateF);
  };

  // カレンダーボタンクリック時
  onPenDatepicker = (open) => {
    this.setState({
      isOpenDatePicker: open,
    });

    if (this.state.year !== "0001" && this.state.year !== "1901") {
      this.triggerChange(this.state.dateF);
    }
    else {
      this.triggerChange(moment(new Date()));
    }
  };

  onDatePickerChange = (date) => {
    const y = date.format("YYYY");
    const m = date.format("M");
    const d = date.format("D");

    const tmp1 = convert_wareki(y, m, d);
    const tmp2 = convert_wareki_2(tmp1.t, tmp1.n, y, m, d);
    const dateF = moment(`${tmp2.y}-${tmp2.m}-${tmp2.d}`, format);

    this.setState({
      gengouv: tmp1.t,
      gengouD: gengounen[tmp1.t],
      gengouD2: tmp1.n,

      monthSDV: m + "月",
      monthSD: monthSDate[m + "月"],
      monthSD2: d,

      isOpenDatePicker: false,
      year: y,
      month: m,
      day: d,
      dateF,
    });

    this.triggerChange(dateF);
  };

  handleClickChange = (visible) => {
    this.setState({
      isOpenDatePicker: visible,
    });
  };

  // 年と月を入れ替えた時
  onPanelChange = (date) => {
    const y = date.format("YYYY");
    const m = date.format("MM");
    const d = date.format("DD");

    const tmp1 = convert_wareki(y, m, d);
    const tmp2 = convert_wareki_2(tmp1.t, tmp1.n, y, m, d);
    const dateF = moment(`${tmp2.y}-${tmp2.m}-${tmp2.d}`, format);

    this.setState({
      gengouv: tmp1.t,
      gengouD: gengounen[tmp1.t],
      gengouD2: tmp1.n,

      monthSDV: m + "月",
      monthSD: monthSDate[parseInt(m) + "月"],
      monthSD2: d,

      year: y,
      month: m,
      day: d,
      dateF,
    });

    this.triggerChange(dateF);

    // if(this.state.year !== "0001"){
    //     this.triggerChange(dateF);
    // }else {
    //     this.triggerChange(this.props.value);
    // }
  };

  onSelectCalendar = (date) => {
    // console.log(value.format(format));

    const y = date.format("YYYY");
    const m = date.format("M");
    const d = date.format("D");

    const tmp1 = convert_wareki(y, m, d);
    const tmp2 = convert_wareki_2(tmp1.t, tmp1.n, y, m, d);
    const dateF = moment(`${tmp2.y}-${tmp2.m}-${tmp2.d}`, format);

    this.setState({
      gengouv: tmp1.t,
      gengouD: gengounen[tmp1.t],
      gengouD2: tmp1.n,

      monthSDV: m + "月",
      monthSD: monthSDate[m + "月"],
      monthSD2: d,

      isOpenDatePicker: false,
      year: y,
      month: m,
      day: d,
      dateF,
    });

    this.triggerChange(dateF);
  };

  onCloseChange = () => {
    this.triggerChange(moment("0001-01-01"));

    this.setState({
      isOpenDatePicker: false,
    });
  };

  render() {
    const {
      gengouD,
      monthSD,
      isOpenDatePicker,
      year,
      month,
      day,
      dateF,
      monthSDV,
    } = this.state;
    const { error, placement } = this.props;
    // console.log(dateF)

    const content = (
      <div>
        <div
          style={{ width: 300, border: "2px solid gray", borderRadius: 4 }}
        >
          <Calendar
            className="calender"
            fullscreen={false}
            value={dayjs(dateF)} // ここでカレンダーで表示する日付
            // ヘッダー
            headerRender={({ value, onChange }) => {
              const y = value.format("YYYY");
              const m = value.format("M");
              const d = value.format("D");
              const tmp = convert_wareki(y, m, d); // 空だと全部0のtmpになる

              const start = 0;
              const end = 12;
              const monthOptions = [];
              const newDate = moment(value.toDate());

              const current = newDate.clone();
              const localeData = newDate.localeData();
              const months = [];
              for (let i = 0; i < 12; i++) {
                current.month(i);
                months.push(localeData.monthsShort(current));
              }

              for (let index = start; index < end; index++) {
                monthOptions.push(
                  <Select.Option className="month-item" key={`${index}`}>
                    {months[index]}
                  </Select.Option>
                );
              }

              const options1 = [];
              for (let i = 0; i < gengou.length; i++) {
                options1.push(
                  <Select.Option
                    key={i}
                    value={gengou[i]}
                    className="year-item"
                  >
                    {gengou[i]}
                  </Select.Option>
                );
              }

              // 1~98
              // 元号から何年分かをlistにプッシュ
              const options2 = [];
              for (let i = 0; i < gengounen[tmp.t].length; i++) {
                options2.push(
                  <Select.Option
                    key={i}
                    value={gengounen[tmp.t][i]}
                    className="year-item"
                  >
                    {gengounen[tmp.t][i]}
                  </Select.Option>
                );
              }

              return (
                <div style={{ padding: 10 }} className="header">
                  <div style={{ marginBottom: "10px", textAlign: "center" }}>
                    {convert_wareki_3(dateF.format(format)).dis}
                  </div>
                  <Row type="flex">
                    {/* 年と月入れ替え */}
                    {/* <Col>
                      <Group
                        size="small"
                        onChange={(e) => onTypeChange(e.target.value)}
                        value={type}
                      >
                        <Radio.Button value="month">月</Radio.Button>
                        <Radio.Button value="year">年</Radio.Button>
                      </Group>
                    </Col> */}
                    <Col style={{ marginRight: 0 }}>
                      <Select
                        size="small"
                        popupMatchSelectWidth={false}
                        className="my-year-select"
                        onChange={(t) => {
                          const tmp2 = convert_wareki_2(t, 1);
                          // const now = value.clone().year(tmp2.y).month(tmp2.m).date(tmp2.d);
                          const now2 = moment(
                            "" + tmp2.y + "-" + tmp2.m + "-" + tmp2.d,
                            format
                          );
                          onChange(now2);
                        }}
                        value={String(tmp.t)}
                      >
                        {options1}
                      </Select>
                    </Col>
                    <Col style={{ marginRight: 25 }}>
                      <Select
                        size="small"
                        popupMatchSelectWidth={false}
                        className="my-year-select"
                        onChange={(n) => {
                          // let tmp3 = convert_wareki_2(tmp.t, tmp.n); //令和、年
                          const tmp3 = convert_wareki_2(tmp.t, n);

                          // moment(date)オブジェクトのコピー
                          // const now = value.clone().year(tmp3.y).month(tmp3.m).date(tmp3.d);
                          const now2 = moment(
                            "" + tmp3.y + "-" + tmp3.m + "-" + tmp3.d,
                            format
                          );
                          onChange(now2);
                        }}
                        value={String(tmp.n + "年")}
                      >
                        {options2}
                      </Select>
                    </Col>
                    <Col>
                      <Select
                        size="small"
                        popupMatchSelectWidth={false}
                        // value={tmp.m}
                        value={String(tmp.m + "月")}
                        onChange={(selectedMonth) => {
                          const newDate = moment(value.toDate());
                          const newValue = newDate.clone();
                          newValue.month(parseInt(selectedMonth, 10));
                          onChange(newValue);
                        }}
                      >
                        {monthOptions}
                      </Select>
                    </Col>
                  </Row>
                </div>
              );
            }}
            onPanelChange={this.onPanelChange}
            onSelect={this.onSelectCalendar}
          />
        </div>
        <div style={{ textAlign: "right", marginTop: 5 }}>
          <span style={{ cursor: "pointer" }} onClick={this.onCloseChange}>
            クリア
          </span>
        </div>
      </div>
    );

    return (
      <div className="JPDateSelectPicker" style={this.props.style}>
        <Select
          value={year !== "0001" ? convert_wareki(year, month, day).t : "令和"}
          style={error ? { border: "1px solid red", width: 68, borderRadius: 5 } : { width: 75 }}
          onChange={this.handleGengouChange}
          disabled={this.props.disabled}
          className="year_select_jp"
        >
          {gengou.map((t) => (
            <Option key={t}>{t}</Option>
          ))}
        </Select>
        {/* <DatePicker
                    value={dateF}
                    open={isOpenDatePicker}
                    style={{ visibility: "hidden", width: 0 }}
                    onOpenChange={open => this.onPenDatepicker(open)}
                    onChange={this.onDatePickerChange}

                /> */}
        <Select
          style={error ? { border: "1px solid red", borderRadius: 5, width: 60 } : { width: 60 }}
          value={year !== "0001" ? convert_wareki(year, month, day).n : ""}
          onChange={this.onGengouchange}
          disabled={this.props.disabled}
          className="date_select_jp"
        >
          {gengouD.map((n) => (
            <Option key={n}>{n}</Option>
          ))}
        </Select>
        年
        <Select
          value={month !== "01" ? parseInt(monthSDV) : ""}
          style={error ? { border: "1px solid red", borderRadius: 5, width: 60, marginLeft: 4 } : { width: 60, marginLeft: 4 }}
          onChange={this.handleMonthChange}
          disabled={this.props.disabled}
          className="date_select_jp"
        >
          {monthS.map((m) => (
            <Option key={m}>{m.slice(0, -1)}</Option>
          ))}
        </Select>
        月
        <Select
          style={error ? { border: "1px solid red", borderRadius: 5, width: 60, marginLeft: 4 } : { width: 60, marginLeft: 4 }}
          // value={year !== "0001" ? day : ""}
          value={day !== "01" ? parseInt(day) : ""}
          onChange={this.onMonthChange}
          disabled={this.props.disabled}
          className="date_select_jp"
        >
          {(month === "2"
            ? moment(year, "YYYY").isLeapYear()
              ? monthSD
              : monthSD?.slice(0, -1)
            : monthSD
          )?.map((d) => (
            <Option key={d}>{d}</Option>
          ))}
        </Select>
        日
        <Popover
          // overlayStyle={{ width: "320px" }}
          placement={placement ? placement : "rightTop"}
          // title={title}
          content={content}
          trigger="click"
          onOpenChange={this.handleClickChange}
          open={isOpenDatePicker}
        >
          <Button
            type="primary"
            icon={<Icon type="calendar" />}
            onClick={() => this.onPenDatepicker(true)}
            style={{ marginLeft: 4 }}
            disabled={this.props.disabled}
          />
          {/* <Button disabled={disabled ? disabled : false} type="primary" style={{ margin: '0 10px' }}>選択</Button> */}
        </Popover>

        {/* <Button type="primary" icon="calendar" onClick={() => this.onPenDatepicker(true)} /> */}
      </div>
    );
  }
}
