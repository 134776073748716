import { subsidyDetailAction } from "../action_types";

const initialState = {
  subsidyDetail: {},
  loading: false,
  syuruiMasterList: [],
  serviceKikanMasterList: []
};

const subsidyDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case subsidyDetailAction.GET_DETAIL_SUBSIDY:
      return { ...state, loading: true };
    case subsidyDetailAction.GET_DETAIL_SUBSIDY_SUCCESS:
      return { ...state, subsidyDetail: action.data, loading: false };
    case subsidyDetailAction.GET_DETAIL_SUBSIDY_FAILURE:
      return { ...state, loading: false };
    case subsidyDetailAction.SAVE_DETAIL_SUBSIDY:
      return { ...state, loading: true };
    case subsidyDetailAction.GET_DETAIL_SUBSIDY_SUCCESS:
      return { ...state, loading: false };
    case subsidyDetailAction.GET_DETAIL_SUBSIDY_FAILURE:
      return { ...state, loading: false };
    case subsidyDetailAction.GET_DATA_SUBSIDY:
      return { ...state, subsidyByRiyousyaId: action };
    case subsidyDetailAction.GET_DATA_SUBSIDY_SUCCESS:
      return { ...state, subsidyByRiyousyaId: action };
    case subsidyDetailAction.GET_DATA_SUBSIDY_FAILURE:
      return { ...state, subsidyByRiyousyaId: action };
    case subsidyDetailAction.GET_ALL_SYURUI_MASTER:
      return { ...state };
    case subsidyDetailAction.GET_ALL_SYURUI_MASTER_SUCCESS:
      return { ...state, syuruiMasterList: action.data };
    case subsidyDetailAction.GET_ALL_SYURUI_MASTER_FAILURE:
      return { ...state };
    case subsidyDetailAction.GET_SERVICE_KIKAN_MASTER_ALL:
      return { ...state };
    case subsidyDetailAction.GET_SERVICE_KIKAN_MASTER_ALL_SUCCESS: action
      return { ...state, serviceKikanMasterList: action.data };
    case subsidyDetailAction.GET_SERVICE_KIKAN_MASTER_ALL_FAILURE:
      return { ...state };
    case subsidyDetailAction.QUOTE_BUTTON:
      return { ...state, loading: true };
    case subsidyDetailAction.QUOTE_BUTTON_SUCCESS:
      return {
        ...state, loading: false,
        subsidyDetail: {
          ...state.subsidyDetail,
          rezKyufuKanriDetails: action.data,
          totalTani: action.totalTani,
          tantoId: action.tantoId,
          tantoMaster: action.tantoMaster,
          tantoSienSenmoninNum: !!action.tantoMaster ? action.tantoMaster.sienSenmoninNum : 0
        }
      };
    case subsidyDetailAction.QUOTE_BUTTON_FAILURE:
      return { ...state, loading: false };
    case subsidyDetailAction.REDIRECT_TO_TICKET:
      return { ...state, loading: true };
    case subsidyDetailAction.REDIRECT_TO_TICKET_SUCCESS:
      return { ...state, loading: false };
    case subsidyDetailAction.REDIRECT_TO_TICKET_FAILURE:
      return { ...state, loading: false };
    case subsidyDetailAction.SAVE_DETAIL_SUBSIDY:
      return { ...state, loading: true };
    case subsidyDetailAction.SAVE_DETAIL_SUBSIDY_SUCCESS:
      return { ...state, loading: false };
    case subsidyDetailAction.SAVE_DETAIL_SUBSIDY_FAILURE:
      return { ...state, loading: false };
    case subsidyDetailAction.GET_RIYOU_KAIGO_UPDATE:
      return { ...state, loading: true };
    case subsidyDetailAction.GET_RIYOU_KAIGO_UPDATE_SUCCESS:
      return {
        ...state, loading: false, subsidyDetail:
        {
          ...state.subsidyDetail, gendoDateFrom: action.data.yukoDateFrom,
          gendoDateTo: action.data.yukoDateTo,
          kaigodo: action.data.kaigodo,
          kaigodoMaster: action.data.kaigodoMaster,
          gendogaku: action.data.kubunsikyu,
          hihokenNo: action.data.hihokenNo,
          hokensyaNo: action.data.hokensyaNo,
          riyousya: action.data.riyousya,
          riyousyaId: action.data.riyousya.id,
          genderCd: action.data.riyousya.gender
        }
      };
    case subsidyDetailAction.GET_RIYOU_KAIGO_UPDATE_FAILURE:
      return { ...state, loading: false };
    case subsidyDetailAction.UPDATE_SUBSIDY_DETAIL:
      return { ...state, subsidyDetail: action.data };
    default:
      return state;
  }
};

export default subsidyDetailReducer;