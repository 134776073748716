import { MealActionTypes } from "../action_types";
import { showToast } from "../../common/function_common/functionCommon";
import i18n from "../../i18n/config";

const initState = {
  loading: false,
  listDataLoading: false,
  settingLoading: false,
  formLoading: false,
  listDatas: [],
  formData: null,
  settingMaster: null,
};

function meal(state = initState, action) {
  const { t } = i18n;

  switch (action.type) {
    case MealActionTypes.LOAD_SPIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case MealActionTypes.GET_MEAL_RECORDS_REQUEST: {
      return {
        ...state,
        listDataLoading: true,
      };
    }
    case MealActionTypes.GET_MEAL_RECORDS_SUCCESS: {
      return {
        ...state,
        listDataLoading: false,
        listDatas: action.data,
      };
    }
    case MealActionTypes.GET_MEAL_RECORDS_ERROR: {
      return {
        ...state,
        listDataLoading: false,
        listDatas: [],
      };
    }

    case MealActionTypes.GET_MEAL_SETTING_MASTER_REQUEST: {
      return {
        ...state,
        settingLoading: true,
        settingMaster: null,
      };
    }
    case MealActionTypes.GET_MEAL_SETTING_MASTER_SUCCESS: {
      return {
        ...state,
        settingLoading: false,
        settingMaster: action.data,
      };
    }
    case MealActionTypes.GET_MEAL_SETTING_MASTER_ERROR: {
      return {
        ...state,
        settingLoading: false,
        settingMaster: null,
      };
    }

    case MealActionTypes.CREATE_MEAL_REQUEST: {
      return {
        ...state,
        formLoading: true,
        formData: null,
      };
    }
    case MealActionTypes.CREATE_MEAL_SUCCESS: {
      showToast("success", t("message.保存されました"));
      return {
        ...state,
        formLoading: false,
        formData: action.data,
      };
    }
    case MealActionTypes.CREATE_MEAL_DUPLICATE: {
      showToast("fail", t("message.同じ時間帯の記録が既に存在します"));
      return {
        ...state,
        formLoading: false,
        formData: null,
      };
    }
    case MealActionTypes.CREATE_MEAL_ERROR: {
      showToast("fail", t("message.保存に失敗しました"));
      return {
        ...state,
        formLoading: false,
        formData: null,
      };
    }

    case MealActionTypes.UPDATE_MEAL_REQUEST: {
      return {
        ...state,
        formLoading: true,
        formData: null,
      };
    }
    case MealActionTypes.UPDATE_MEAL_SUCCESS: {
      showToast("success", t("message.保存されました"));
      return {
        ...state,
        formLoading: false,
        formData: action.data,
      };
    }
    case MealActionTypes.UPDATE_MEAL_ERROR: {
      showToast("fail", t("message.保存に失敗しました"));
      return {
        ...state,
        formLoading: false,
        formData: null,
      };
    }

    case MealActionTypes.RESET_MEAL_DATA: {
      return {
        ...state,
        formData: null,
        listDatas: [],
      };
    }

    default: {
      return { ...state };
    }
  }
}

export default meal;
