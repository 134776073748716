import React, { useState, useRef, useEffect, memo } from "react";
import { Button, Table, Modal, Checkbox } from "antd";
import _ from "lodash";
import { getHolidayJPInMonth } from "../../common/function_common/functionCommon";

import { ApiPaths } from "../../constants";
import { RequestUtils } from "../../utils";
import {
  getDateOfMonth as getDateOfMonthFromModule,
  checkColor as checkColorNengetu,
  handleFormatDataWeekly,
} from "../../pages/UseSlip/function";
import WeeklyQuoteUseSlipModal from "../UseSlip/WeeklyQuoteUseSlipModal";
import { connect } from "react-redux";
import { UseSlipActions } from "../../redux/actions";

const DAY_OF_WEEK = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
function DatePickerUseSlipModal(props) {
  const [data, setData] = useState([]);
  const [listType, setListType] = useState([]);
  const [datePicker, setDatePicker] = useState([]);
  const [rowPicker, setRowPicker] = useState([]);
  const [allPick, setAllPick] = useState(false);
  const [dateInMonth, setDateInMonth] = useState([]);
  const [colPicker, setColPicker] = useState([]);
  const [countDate, setCountDate] = useState(0);
  const [holidayInMonth, setHolidateInMonth] = useState([]);
  const [isListCheckType, setIsListCheckType] = useState([]);
  const [isKeepFirst, setIsKeepFirst] = useState(false);
  const [isKeepLast, setIsKeepLast] = useState(false);
  const [isOk, setIsOk] = useState(false);
  const [isSelectTypeSort, setIsSelectTypeSort] = useState(true);

  const [selectedCellColorArr, setSelectedCellColorArr] = useState([]);
  const [checkDisableColor, setCheckDisableColor] = useState([]);
  const [listGrey, setListGrey] = useState([]);
  const [checkTypeQuote, setCheckTypeQuote] = useState([]);
  const addBtnRef = useRef();

  const newArrayDataForDatePicker = data.filter(obj => Object.keys(obj).length > 1);

  useEffect(() => {
    setIsSelectTypeSort(!props.isSelectTypeSort);
  }, [props.isSelectTypeSort]);

  const handleOkSonota = async (sonotaData) => {
    const sonotaDataWeekly = await Promise.all(
      sonotaData.map(async (e) => {
        const dataServiceColor = props.listColorSonota.find((i) => i.paletteName === e.serviceCodeType);
        const serviceKikanMasterData = await RequestUtils.callApi({
          method: "GET",
          path: ApiPaths.ServiceKikanMaster.GetById,
          params: { id: e.sid },
        });
        const isCheckHoliday = e.holidayf === 1 ? true : false;
        const dateHoliday = checkColorNengetu(props.nengetu);
        const listDate = isCheckHoliday
          ? getDateOfMonthFromModule([e.youbi], props.nengetu, datePicker).filter((i) => !dateHoliday.includes(i))
          : getDateOfMonthFromModule([e.youbi], props.nengetu, datePicker);
        const listDays = new Array(datePicker.length).fill(0);
        const convertToListDays = listDays.map((value, index) => (listDate.includes(index + 1) ? 1 : value));
        const sumlistDays = convertToListDays.reduce((result, value) => (result += value), 0);
        const formListDate = {
          listDate,
          countDate: datePicker.length,
          sumlistDays,
          selectedDays: convertToListDays,
        };
        return {
          id: Math.floor(Math.random() * 123871),
          defaultPaletteName: e.defaultInputRyaku,
          startTime: e.strStartTime.substring(0, 5),
          endTime: e.strEndTime.substring(0, 5),
          offerTime: e.strStartTime.substring(0, 5) + "~" + e.strEndTime.substring(0, 5),
          colorBak: dataServiceColor.colorBak,
          swColorBak: dataServiceColor.swColorBak,
          serviceKikanMasterId: e.sid ? e.sid : null,
          ryaku: dataServiceColor.ryaku,
          valueServiceSonota: serviceKikanMasterData,
          serviceKikanMaster: serviceKikanMasterData,
          formListDate,
          jigyousyoName: serviceKikanMasterData.jigyousyoName,
          serviceNaiyou: e.defaultInputRyaku,
          riyouhyouId: +props.riyouhyouId,
          paletteName: dataServiceColor.paletteName,
        };
      }),
    );
    return { sonotaDataWeekly };
  };

  function mergeSelectedDays(arr) {
    const mergedArr = [];
    for (let i = 0; i < arr.length; i++) {
      let isMerged = false;
      for (let j = 0; j < mergedArr.length; j++) {
        if (
          arr[i].defaultPaletteName === mergedArr[j].defaultPaletteName &&
          arr[i].offerTime === mergedArr[j].offerTime &&
          arr[i].serviceKikanMasterId === mergedArr[j].serviceKikanMasterId &&
          arr[i].colorBak === mergedArr[j].colorBak
        ) {
          mergedArr[j].formListDate.selectedDays = mergeArrays(mergedArr[j].formListDate.selectedDays, arr[i].formListDate.selectedDays);
          mergedArr[j].formListDate.listDate = [...mergedArr[j].formListDate.listDate, ...arr[i].formListDate.listDate];
          mergedArr[j].formListDate.sumlistDays = mergedArr[j].formListDate.sumlistDays + arr[i].formListDate.sumlistDays;
          isMerged = true;
          break;
        }
      }
      if (!isMerged) {
        mergedArr.push(arr[i]);
      }
    }

    return mergedArr;
  }

  function mergeArrays(arr1, arr2) {
    const merged = [];
    for (let i = 0; i < arr1.length; i++) {
      merged.push(arr1[i] + arr2[i]);
    }
    return merged;
  }

  function handleChangeDate(nengetu, listSelectDays) {
    const date = new Date(nengetu.substring(0, 4), parseInt(nengetu.substring(4)) - 1, 1);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const formattedDate = `${year}-${month}`;

    const startDate = new Date(formattedDate);
    const result = [];

    for (let i = 0; i < listSelectDays.length; i++) {
      const count = listSelectDays[i];
      if (count > 0) {
        const currentDate = new Date(startDate.getTime());
        currentDate.setDate(currentDate.getDate() + i);

        result.push({
          date: currentDate.toISOString().slice(0, 10),
          count: count,
        });
      }
    }
    return result;
  }

  useEffect(() => {
    if (props.visible) {
      getListDay();
      if (props.listPlanDate) {
        setDatePicker(props.listPlanDate.map((item, index) => (item ? index + 1 : 0)));
      } else {
        setDatePicker(props?.listPlan?.map((item, index) => (item ? `${index + 1}` : 0)));
        setSelectedCellColorArr(
          props?.listPlan
            ?.map((item, index) => {
              if (item && !props.visibleWeeklyQuote) {
                return { value: `${index + 1}`, color: "pink" };
              }
            })
            ?.filter((item) => typeof item == "object"),
        );
        setCheckDisableColor(props?.listPlan?.map((item, index) => (item ? `${index + 1}` : 0))?.filter((item) => typeof item == "string"));
      }
    }
  }, [props.visible]);

  useEffect(() => {
    if (props.visibleListOfficeActual) {
      setDatePicker(getListDateInMonth());
    }
  }, [props.visibleListOfficeActual]);

  useEffect(() => {
    if (props.nengetu) {
      checkColor();
    }
  }, [props.nengetu]);

  useEffect(() => {
    const fetchData = async () =>{

    if (props.weeklySchedule && isOk) {
      const sonotaDataGroup = props.weeklySchedule.filter((e) => e.serviceCodeType.includes("その他"));
      const { sonotaDataWeekly } = await handleOkSonota(sonotaDataGroup);
      const sonotaDataAfterShort = mergeSelectedDays(sonotaDataWeekly);
      const newSonotaDateAfterShort = sonotaDataAfterShort.map((e) => {
        return {
          ...e,
          countList: handleChangeDate(props.nengetu, e.formListDate.selectedDays),
        };
      });
      props.setListDataServiceSonota(newSonotaDateAfterShort);
      props.setRiyouhyouForm({ listDataServiceSonota: newSonotaDateAfterShort });
      const groupData = _.groupBy(props.weeklySchedule, (e) => [e.id, e.serviceContent, e.strStartTime, e.strEndTime]);
      const groupDataKeys = Object.keys(groupData);
      const newData = groupDataKeys.map((i) => {
        groupData[i][0].youbi = [groupData[i][0].youbi];
        return groupData[i][0];
      });

      props.weeklySchedule.forEach((item) => {
        newData.forEach((i, index) => {
          if (i.id === item.id && i.youbi !== item.youbi) newData[index].youbi.push(item.youbi);
          if (i.id === item.id && i.additionList !== item.additionList) {
            item.additionList.forEach((e) => newData[index].additionList.push(e));
          }
          const itemFilter = item.additionCodeList.filter((e) => e.id !== null);
          const iFilter = i.additionCodeList.filter((e) => e.id !== null);
          itemFilter.forEach((e) => {
            iFilter.forEach((c) => {
              if (e.id === c.id && e.youbi !== c.youbi) {
                if (c.youbiList) {
                  const isCheckDuplicateEYoubi = c.youbiList.some((el) => el.youbi === e.youbi && el.serviceAddition === e.serviceAddition?.length);
                  const isCheckDuplicateCYoubi = c.youbiList.some((el) => el.youbi === c.youbi && el.serviceAddition === c.serviceAddition?.length);
                  if (!isCheckDuplicateEYoubi) {
                    c.youbiList.push({ youbi: e.youbi, serviceAddition: e.serviceAddition?.length });
                  }
                  if (!isCheckDuplicateCYoubi) {
                    c.youbiList.push({ youbi: c.youbi, serviceAddition: c.serviceAddition?.length });
                  }
                } else {
                  c.youbiList = [
                    { youbi: e.youbi, serviceAddition: e.serviceAddition?.length },
                    { youbi: c.youbi, serviceAddition: c.serviceAddition?.length },
                  ];
                }
              }
              if (e.id === c.id && e.youbi === c.youbi) {
                c.youbiList = [{ youbi: c.youbi, serviceAddition: c.serviceAddition?.length }];
              }
              if (i.id === item.id && e.serviceCode !== c.serviceCode) {
                newData[index].additionCodeList.push(e);
              }
            });
          });
        });
      });

      const listDataRecord = [];
      newData.forEach((e) => {
        props.handleGetAdditionByOffice({
          nengetu: props.nengetu,
          requiredTime: 30,
          serviceCodeType: e.serviceCodeType,
          serviceContent: e.serviceContent,
          shuiruiCode: e.shuruiCode,
          sid: e.sid,
        });
        const newAdditionList = [...e.additionCodeList].filter((e) => e.id !== null);
        const uniquerAdditionList = newAdditionList.filter(
          (item, index, self) => index === self.findIndex((t) => t.serviceCode === item.serviceCode),
        );
        let result = [];
        uniquerAdditionList.forEach((item) => {
          const existingItem = result.find((o) => o.serviceCode === item.serviceCode && o.serviceName === item.serviceName);
          if (existingItem) {
            existingItem.serviceFillDataList = existingItem.serviceFillDataList.concat(item.serviceFillDataList);
            existingItem.serviceAddition = Array.from(new Set(existingItem.serviceAddition.concat(item.serviceAddition)));
          } else {
            result.push(item);
          }
        });
        result.sort((a, b) => {
          return parseInt(a.serviceCode, 10) - parseInt(b.serviceCode, 10);
        });
        e.additionCodeList = [...result];
        let minutes = null;
        let startTimeStr = "00:00";
        let endTimeStr = "00:00";
        let isGetDataWeekly = true;
        if (e.id) {
          if (e.strStartTime != null && e.strEndTime != null) {
            startTimeStr = e.strStartTime.substring(0, 5);
            endTimeStr = e.strEndTime.substring(0, 5);
            minutes = countMinutes(startTimeStr, endTimeStr);
          }
          const formValues = {
            shuiruiCode: e.shuruiCode,
            serviceCodeType: e.serviceCodeType,
            endTime: e.endTimeDB,
            additionList: e.additionList,
            code: null,
            sid: e.sid,
            riyouhyouId: null,
            serviceContent: e.serviceContent,
            kaigodoName: props.kaigodoName,
            riyouhyouDate: null,
            nengetu: props.nengetu,
            requiredTime: e.strStartTime != null && e.strEndTime != null ? minutes : null,
            contentRental: e.name,
            productName: e.productName ? e.productName : null,
            startTimeStr: startTimeStr,
            endTimeStr: endTimeStr,
            ryakuServiceKikan: e.ryakuServiceKikan,
            jigyousyoNoServiceKikan: e.jigyousyoNoServiceKikan,
            productId: null,
            tani: e.tani,
            totalLikeRental: e.totalLikeRental,
            youbi: e.youbi,
            holidayf: e.holidayf,
            serviceContentType: e.serviceContentType
          };
          const additionListMain = props.serviceAddition
            ? props.serviceAddition.filter((x) => formValues.additionList.includes(x.addition) && x.additionType == 0)
            : [];
          listDataRecord.push({
            listDate: datePicker,
            countDate,
            formValues,
            mainCode: { ...e, idService: e.id },
            expandCode: e.additionCodeList,
            additionListMain,
            isKeepFirst,
            isKeepLast,
            isGetDataWeekly,
          });
        }
      });
      setIsOk(false);
      props.onOkAddMultiModal(listDataRecord, false);
    }
  } 
fetchData();
  }, [props.weeklySchedule]);

  useEffect(() => {
    if (props.listWeeklyQuote && isOk) {
      props.handleCoppyDataActual(true);
      const listDataRecord = [];
      const { formatNewDataWeekly } = handleFormatDataWeekly({ dataWeekly: props.listWeeklyQuote });
      formatNewDataWeekly.forEach((e, _index) => {
        props.handleGetAdditionByOffice({
          nengetu: props.nengetu,
          requiredTime: 30,
          serviceCodeType: e.serviceCodeType,
          serviceContent: e.serviceContent,
          shuiruiCode: e.shuruiCode,
          sid: e.sid,
        });
        let minutes = null;
        let startTimeStr = "00:00";
        let endTimeStr = "00:00";
        let isGetDataWeekly = false;
        if (e.id) {
          if (e.strStartTime !== null && e.strEndTime !== null) {
            startTimeStr = e.strStartTime.substring(0, 5);
            endTimeStr = e.strEndTime.substring(0, 5);
            minutes = countMinutes(startTimeStr, endTimeStr);
          }
          const formValues = {
            shuiruiCode: e.shuruiCode,
            serviceCodeType: e.productName,
            endTime: e.endTimeDB,
            additionList: e.additionList,
            code: null,
            sid: e.sid,
            riyouhyouId: null,
            serviceContent: e.serviceContent,
            kaigodoName: props.kaigodoName,
            riyouhyouDate: null,
            nengetu: props.nengetu,
            requiredTime: e.strStartTime != null && e.strEndTime != null ? minutes : null,
            contentRental: e.name,
            productName: e.productName ? e.productName : null,
            startTimeStr: startTimeStr,
            endTimeStr: endTimeStr,
            ryakuServiceKikan: e.ryakuServiceKikan,
            jigyousyoNoServiceKikan: e.jigyousyoNoServiceKikan,
            productId: null,
            tani: e.tani,
            totalLikeRental: e.totalLikeRental,
            youbi: e.youbiList,
            holidayf: e.holidayf,
            serviceContentType: e.serviceCodeType,
            isQuoteActual: true,
            is30DayOver: false,
          };

          const additionListMain = props.serviceAddition
            ? props.serviceAddition.filter((x) => formValues.additionList.includes(x.addition) && x.additionType == 0)
            : [];
          const filteredYoubiList = e.youbiList.filter((item) => datePicker.includes(item));
          e.additionCodeList.forEach((element) => {
            element.youbiList = element.youbiList.filter((item) => datePicker.includes(item));
          });
          listDataRecord.push({
            listDate: filteredYoubiList,
            countDate,
            formValues,
            mainCode: { ...e, idService: e.id },
            expandCode: e.additionCodeList,
            additionListMain,
            isKeepFirst,
            isKeepLast,
            isGetDataWeekly,
          });
        }
      });
      props.onOkAddMultiModal(listDataRecord, false, isOk, checkTypeQuote);
      setIsOk(false);
    }
  }, [props.listWeeklyQuote]);

  useEffect(() => {
    setIsKeepFirst(false);
    setIsKeepLast(false);
    setAllPick(false);
  }, [props.visible]);

  useEffect(() => {
    if (props.visibleWeeklyQuote) {
      let listData;
      const sonotaWeekly = [{ kaigoServiceSyuruiCd: "0", ryaku: "その他", serviceType: "その他" }];
      if (props.data) {
        listData = [...props.data, ...sonotaWeekly].map((e) => e.ryaku);
        setListType([...props.data, ...sonotaWeekly]);
      }
      setIsListCheckType(listData);
      onClickDateAll();
      //set calendar when open 週間引用
      setRowPicker([0, 1, 2, 3, 4]);
      setColPicker([0, 1, 2, 3, 4, 5, 6]);
      setDatePicker(() => {
        let datePick = [];
        for (let i = 1; i <= countDate; i++) {
          datePick.push(i);
        }
        return datePick;
      });
    }
  }, [props.visibleWeeklyQuote, countDate]);

  function handleDateOk() {
    props?.setIsVisibleDialogConfirm && props.setIsVisibleDialogConfirm(true);
    if (addBtnRef.current.disabled) {
      return;
    } else {
      addBtnRef.current.disabled = true;
    }
    if (!props.visibleWeeklyQuote) {
      props.handleDateOk(datePicker, countDate, isKeepFirst, isKeepLast);
      setListGrey([]);
      //setDatePicker([]);
      setRowPicker([]);
      setColPicker([]);
      setAllPick(false);
      if (props.isDoubleDialog) {
        props.clearCheckBox();
      }
    } else {
      const currentDate = new Date().toISOString().split("T")[0];
      const isValid = props.riyousyaId && props.riyouhyouId && props.riyouhyouDate && props.kaigodoName && props.tekiyouDate && props.nengetu;
      if ((isValid || props.riyouhyouId === "new") && isListCheckType && !props.visibleListOfficeActual) {
        props?.handleWeeklySchedule({
          riyousyaId: props.riyousyaId,
          isListCheckType: isListCheckType,
          riyouhyouId: props.riyouhyouId,
          riyouhyouDate: props.riyouhyouDate ? props.riyouhyouDate : currentDate,
          kaigodoName: props.kaigodoName,
          tekiyouDate: props.tekiyouDate,
          nengetu: props.nengetu,
        });
        props.handleClearDataWeekly();
      } else if (props.visibleListOfficeActual) {
        const newArrListCheckType = [];
        for (let i = 0; i < props.listOfficeActual.length; i++) {
          for (let j = 0; j < props.listOfficeActualChecked.length; j++) {
            if (props.listOfficeActual[i].id === props.listOfficeActualChecked[j]) {
              const newObjectType = {
                serviceType: Number(props.listOfficeActual[i].syuruiCd),
                sid: props.listOfficeActual[i].sID,
              };
              newArrListCheckType.push(newObjectType);
            }
          }
        }
        setCheckTypeQuote(newArrListCheckType);
        const newObjectType = {
          riyousyaId: +props.riyousyaId,
          isListCheckType: newArrListCheckType,
          riyouhyouId: props.riyouhyouId === "new" ? "new" : +props.riyouhyouId,
          riyouhyouDate: props.riyouhyouDate ? props.riyouhyouDate : currentDate,
          kaigodoName: props.kaigodoName,
          nengetu: props.nengetu,
        };

        if (props?.isManagementActual && props?.handleDateOk) {
          props.handleDateOk(newArrListCheckType, datePicker);
        } else {
          props.handleUpdateQuoteActualByType(newObjectType);
          props.getLoading();
        }
      }
      props?.turnOffDialog();
      // fillDataInDB();
      setIsOk(true);
    }
    props?.clearCheckBox();
    setIsKeepFirst(false);
    setIsKeepLast(false);
    setTimeout(() => {
      if (addBtnRef.current && addBtnRef.current.disabled) addBtnRef.current.disabled = false;
    }, 1000);
  }

  function handelListChecked(data) {
    setIsListCheckType(data);
  }
  function countMinutes(startTime, endTime) {
    const minutesOfStartTime = startTime ? parseInt(startTime.substring(0, 2)) * 60 + parseInt(startTime.substring(3)) : 0;
    const minutesOfEndTime = endTime ? parseInt(endTime.substring(0, 2)) * 60 + parseInt(endTime.substring(3)) : endTime;
    return minutesOfEndTime - minutesOfStartTime;
  }

  function handleDateCancel() {
    props.handleDateCancel();
    //setDatePicker([]);
    setColPicker([]);
    setRowPicker([]);
  }

  function checkColor() {
    let listHoliday = props.nengetu
      ? getHolidayJPInMonth(parseInt(props.nengetu.substring(4)), props.nengetu.substring(0, 4))
      : getHolidayJPInMonth(new Date().getMonth() + 1, new Date().getFullYear());
    setHolidateInMonth(listHoliday);
  }

  function onPickCell(date, record) {
    if (!record[date]) {
      return;
    }
    if (checkDisableColor.includes(`${record[date]}`)) {
      setSelectedCellColorArr(
        selectedCellColorArr.map((item) => {
          if (item.value == `${record[date]}`) {
            return item.color === "pink" ? { ...item, color: "grey" } : { ...item, color: "pink" };
          }
          return item;
        }),
      );
    }
    if (listGrey.includes(record[date])) {
      setListGrey([...listGrey.filter((item) => item != record[date])]);
    }
    if (checkDisableClickOnCell(record[date])) {
      if (datePicker && datePicker.includes(`${record[date]}`)) {
        setDatePicker([
          ...datePicker.map((x) => {
            if (x === `${record[date]}`) x = 0;
            return x;
          }),
        ]);
      } else {
        setDatePicker([...datePicker, `${record[date]}`]);
      }
      setListGrey((prev) => [...prev, record[date]]);

      return;
    }
    if (datePicker && datePicker.includes(record[date])) {
      setDatePicker([...datePicker.filter((x) => x != record[date])]);
    } else {
      setDatePicker([...datePicker, record[date]]);
    }
  }

  function checkDisableClickOnCell(valueCell) {
    const datePickerConvert = datePicker.filter((data) => {
      if (data && typeof data === "string") {
        return data;
      }
    });
    const valueCellString = `${valueCell}`;
    return datePickerConvert.includes(valueCellString);
  }

  function onPickRow(record, row) {
    let datePickerNew = datePicker;
    const datePickerConvert = datePicker.filter((data) => {
      if (data && typeof data === "string") {
        return data;
      }
    });
    if (!rowPicker.includes(row)) {
      DAY_OF_WEEK.forEach((e) => {
        if (!datePickerConvert.includes(`${record[e]}`)) {
          datePickerNew = [...datePickerNew.filter((x) => x != record[e])];
          if (record[e]) {
            datePickerNew = [...datePickerNew, record[e]];
          }
        }
      });
      setDatePicker(filterGreyCell(selectedCellColorArr, datePickerNew));
      setRowPicker([...rowPicker, row]);
    } else {
      DAY_OF_WEEK.forEach((e) => {
        if (!datePickerConvert.includes(`${record[e]}`)) {
          datePickerNew = [...datePickerNew.filter((x) => x != record[e])];
        }
      });
      setDatePicker(filterGreyCell(selectedCellColorArr, datePickerNew));
      setRowPicker([...rowPicker.filter((x) => x != row)]);
    }
  }

  function onPickCol(date) {
    const dayColumOfMonth = getDateOfMonth(date);
    let datePickerN = datePicker;
    const datePickerConvert = datePicker.filter((data) => {
      if (data && typeof data === "string") {
        return data;
      }
    });
    if (colPicker && colPicker.includes(date)) {
      setColPicker(colPicker.filter((x) => x != date));
      dayColumOfMonth.forEach((e) => {
        if (!datePickerConvert.includes(`${e}`)) {
          datePickerN = [...datePickerN.filter((x) => x != e)];
        }
      });
      setDatePicker(filterGreyCell(selectedCellColorArr, datePickerN));
    } else {
      setColPicker((prev) => [...prev, date]);
      dayColumOfMonth.forEach((e) => {
        if (!datePickerConvert.includes(`${e}`)) {
          datePickerN = [...datePickerN.filter((x) => x != e)];
          datePickerN = [...datePickerN, e];
        }
      });
      setDatePicker(filterGreyCell(selectedCellColorArr, datePickerN));
    }
  }

  function onClickDateAll() {
    const listDate = getListDateInMonth();
    const listDateDisableCell = setAllDateWithCellDisable(!allPick, listDate);
    setDatePicker(listDateDisableCell);
    setAllPick(!allPick);
    if (listDateDisableCell.length) {
      setRowPicker([0, 1, 2, 3, 4]);
      setColPicker([0, 1, 2, 3, 4, 5, 6]);
    } else {
      setRowPicker([]);
      setColPicker([]);
    }
  }

  function setAllDateWithCellDisable(allPick, listDate) {
    const datePickerConvert = datePicker.filter((data) => {
      if (data && typeof data === "string") {
        return data;
      }
    });
    if (!allPick) {
      return datePickerConvert;
    }
    const listAllDisableCell = listDate.map((data) => {
      if (data && datePickerConvert.includes(`${data}`)) {
        return `${data}`;
      }
      return data;
    });
    return filterGreyCell(selectedCellColorArr, listAllDisableCell);
  }

  const filterGreyCell = (selectedCellColorArr, listAllDisableCell) => {
    let filteredGreyArr = [];
    if (selectedCellColorArr.length) {
      const greyCellArr = [];
      selectedCellColorArr.filter((item) => item.color == "grey").forEach((item) => greyCellArr.push(item.value));
      filteredGreyArr = listAllDisableCell.filter((item) => !greyCellArr.includes(`${item}`));
    }
    return filteredGreyArr.length ? filteredGreyArr : listAllDisableCell;
  };

  function getListDateInMonth() {
    const dateOfMonth = props.nengetu
      ? new Date(props.nengetu.substring(0, 4), parseInt(props.nengetu.substring(4)) - 1, 1)
      : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    let firstDateOfMonth = props.nengetu
      ? new Date(props.nengetu.substring(0, 4), parseInt(props.nengetu.substring(4)) - 1, 1)
      : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    let days = [];
    while (firstDateOfMonth.getMonth() == dateOfMonth.getMonth()) {
      days.push(firstDateOfMonth.getDate());
      firstDateOfMonth.setDate(firstDateOfMonth.getDate() + 1);
    }
    return days;
  }

  function getDateOfMonth(date) {
    const dateMonth = props.nengetu
      ? new Date(props.nengetu.substring(0, 4), parseInt(props.nengetu.substring(4)) - 1, 1)
      : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    let DaysOfMonth = props.nengetu
      ? new Date(props.nengetu.substring(0, 4), parseInt(props.nengetu.substring(4)) - 1, 1)
      : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    let listdays = [];
    while (DaysOfMonth.getMonth() == dateMonth.getMonth()) {
      if (DaysOfMonth.getDay() == date) {
        listdays.push(DaysOfMonth.getDate());
      }
      DaysOfMonth.setDate(DaysOfMonth.getDate() + 1);
    }
    return listdays;
  }

  function getListDay() {
    let days = [];
    const dateOfMonth = props.nengetu
      ? new Date(props.nengetu.substring(0, 4), parseInt(props.nengetu.substring(4)) - 1, 1)
      : new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    let firstDateOfMonth = props.nengetu
      ? new Date(props.nengetu.substring(0, 4), parseInt(props.nengetu.substring(4)) - 1, 1)
      : new Date(new Date().getFullYear(), new Date().getMonth(), 1);

    let lastDateOfMonth = new Date(dateOfMonth.getFullYear(), dateOfMonth.getMonth() + 1, 0);
    let day = {};

    var lastDay = new Date(dateOfMonth.getFullYear(), dateOfMonth.getMonth() + 1, 0);
    setCountDate(lastDay.getDate());

    while (firstDateOfMonth.getMonth() == dateOfMonth.getMonth()) {
      setDateInMonth([...dateInMonth, firstDateOfMonth.getDate()]);
      switch (firstDateOfMonth.getDay()) {
        case 0:
          day = { ...day, sunday: firstDateOfMonth.getDate() };
          break;
        case 1:
          day = { ...day, monday: firstDateOfMonth.getDate() };
          break;
        case 2:
          day = { ...day, tuesday: firstDateOfMonth.getDate() };
          break;
        case 3:
          day = { ...day, wednesday: firstDateOfMonth.getDate() };
          break;
        case 4:
          day = { ...day, thursday: firstDateOfMonth.getDate() };
          break;
        case 5:
          day = { ...day, friday: firstDateOfMonth.getDate() };
          break;
        case 6:
          day = { ...day, saturday: firstDateOfMonth.getDate() };
          break;

        default:
          day = { ...day, sunday: firstDateOfMonth.getDate() };
          break;
      }

      if (firstDateOfMonth.getDay() == 0) {
        days.push(day);
        day = {};
      }
      if (firstDateOfMonth.getDate() == lastDateOfMonth.getDate()) {
        days.push(day);
      }
      firstDateOfMonth.setDate(firstDateOfMonth.getDate() + 1);
    }
    let dataList = [];
    days.forEach((element, index) => {
      let item = { ...element, number: "#" + index };
      dataList.push(item);
    });
    setData(dataList);
  }

  function checkStyleCell(text) {
    let result = false;
    if (!text || !selectedCellColorArr) {
      return false;
    }
    if (checkDisableColor.includes(`${text}`)) {
      selectedCellColorArr.forEach((item) => {
        if (item.value == text) {
          return item.color == "pink" ? (result = "#f8a5c2") : (result = "#808080");
        }
      });
    }
    return result;
  }

  function handleTypeCheckAll() {
    let listData;
    const sonotaWeekly = { kaigoServiceSyuruiCd: "0", ryaku: "その他", serviceType: "その他" };
    if (props.data) {
      listData = [...props.data, sonotaWeekly].map((e) => e.ryaku);
    }
    setIsListCheckType(listData);
    props.setListOfficeActualChecked(props.listOfficeActual.map((item) => item.id));
  }

  function handleTypeUnCheckAll() {
    setIsListCheckType([]);
    props.setListOfficeActualChecked([]);
  }

  const columns = [
    {
      title: <div style={{ cursor: "pointer", textAlign: 'center' }}>全</div>,
      dataIndex: "number",
      onHeaderCell: () => ({
        onClick: () => {
          onClickDateAll();
        },
      }),
      render: (text, row, index) => {
        const obj = {
          children: text,
          props: {
            style: {
              backgroundColor: "#dce6f1",
              border: "1px solid #bebebe",
              cursor: "pointer",
              textAlign: "center",
            },
          },
        };

        return obj;
      },
      onCell: (record, row) => ({
        onClick: () => {
          onPickRow(record, row);
        },
        render: (record) => (
          <a data-id={record} href="#">
            {record}
          </a>
        ),
      }),
    },
    {
      title: <div style={{ cursor: "pointer", textAlign: 'center' }}>月</div>,
      dataIndex: "monday",
      onHeaderCell: () => ({
        onClick: () => {
          onPickCol(1);
        },
      }),
      onCell: (record) => ({
        onClick: () => {
          onPickCell("monday", record);
        },
        render: (record) => (
          <a data-id={record} href="#">
            {record}
          </a>
        ),
      }),
      render: (text, row, index) => {
        const obj = {
          children: text,
          props: {
            style: {
              backgroundColor: checkStyleCell(text) || (datePicker.includes(text) ? "#a7ffa0" : "white"),
              border: "1px solid #bebebe",
              cursor: "pointer",
              textAlign: "center",
              color: holidayInMonth.includes(text) ? "red" : "black",
            },
          },
        };
        return obj;
      },
    },
    {
      title: <div style={{ cursor: "pointer", textAlign: 'center' }}>火</div>,
      dataIndex: "tuesday",
      onHeaderCell: () => ({
        onClick: () => {
          onPickCol(2);
        },
      }),
      onCell: (record) => ({
        onClick: () => {
          onPickCell("tuesday", record);
        },
        render: (record) => (
          <a data-id={record} href="#">
            {record}
          </a>
        ),
      }),
      render: (text, row, index) => {
        const obj = {
          children: text,
          props: {
            style: {
              backgroundColor: checkStyleCell(text) || (datePicker.includes(text) ? "#a7ffa0" : "white"),
              border: "1px solid #bebebe",
              cursor: "pointer",
              textAlign: "center",
              color: holidayInMonth.includes(text) ? "red" : "black",
            },
          },
        };
        return obj;
      },
    },
    {
      title: <div style={{ cursor: "pointer", textAlign: 'center' }}>水</div>,
      dataIndex: "wednesday",
      onHeaderCell: () => ({
        onClick: () => {
          onPickCol(3);
        },
      }),
      onCell: (record) => ({
        onClick: () => {
          onPickCell("wednesday", record);
        },
        render: (record) => (
          <a data-id={record} href="#">
            {record}
          </a>
        ),
      }),
      render: (text, row, index) => {
        const obj = {
          children: text,
          props: {
            style: {
              backgroundColor: checkStyleCell(text) || (datePicker.includes(text) ? "#a7ffa0" : "white"),
              border: "1px solid #bebebe",
              cursor: "pointer",
              textAlign: "center",
              color: holidayInMonth.includes(text) ? "red" : "black",
            },
          },
        };
        return obj;
      },
    },
    {
      title: <div style={{ cursor: "pointer", textAlign: 'center' }}>木</div>,
      dataIndex: "thursday",
      onHeaderCell: () => ({
        onClick: () => {
          onPickCol(4);
        },
      }),
      onCell: (record) => ({
        onClick: () => {
          onPickCell("thursday", record);
        },
        render: (record) => (
          <a data-id={record} href="#">
            {record}
          </a>
        ),
      }),
      render: (text, row, index) => {
        const obj = {
          children: text,
          props: {
            style: {
              backgroundColor: checkStyleCell(text) || (datePicker.includes(text) ? "#a7ffa0" : "white"),
              border: "1px solid #bebebe",
              cursor: "pointer",
              textAlign: "center",
              color: holidayInMonth.includes(text) ? "red" : "black",
            },
          },
        };
        return obj;
      },
    },
    {
      title: <div style={{ cursor: "pointer", textAlign: 'center' }}>金</div>,
      dataIndex: "friday",
      onHeaderCell: () => ({
        onClick: () => {
          onPickCol(5);
        },
      }),
      onCell: (record) => ({
        onClick: () => {
          onPickCell("friday", record);
        },
        render: (record) => (
          <a data-id={record} href="#">
            {record}
          </a>
        ),
      }),
      render: (text, row, index) => {
        const obj = {
          children: text,
          props: {
            style: {
              backgroundColor: checkStyleCell(text) || (datePicker.includes(text) ? "#a7ffa0" : "white"),
              border: "1px solid #bebebe",
              cursor: "pointer",
              textAlign: "center",
              color: holidayInMonth.includes(text) ? "red" : "black",
            },
          },
        };
        return obj;
      },
    },
    {
      title: <div style={{ cursor: "pointer", color: "blue", textAlign: 'center' }}>土</div>,
      dataIndex: "saturday",
      onHeaderCell: () => ({
        onClick: () => {
          onPickCol(6);
        },
      }),
      onCell: (record) => ({
        onClick: () => {
          onPickCell("saturday", record);
        },
        render: (record) => (
          <a data-id={record} href="#">
            {record}
          </a>
        ),
      }),
      render: (text, row, index) => {
        const obj = {
          children: text,
          props: {
            style: {
              backgroundColor: checkStyleCell(text) || (datePicker.includes(text) ? "#a7ffa0" : "white"),
              border: "1px solid #bebebe",
              cursor: "pointer",
              textAlign: "center",
              color: holidayInMonth.includes(text) ? "red" : "blue",
            },
          },
        };
        return obj;
      },
    },

    {
      title: <div style={{ cursor: "pointer", color: "red", textAlign: 'center' }}>日</div>,
      dataIndex: "sunday",
      onHeaderCell: () => ({
        onClick: () => {
          onPickCol(0);
        },
      }),
      onCell: (record) => ({
        onClick: () => {
          onPickCell("sunday", record);
        },
        render: (record) => (
          <a data-id={record} href="#">
            {record}
          </a>
        ),
      }),
      render: (text, row, index) => {
        const obj = {
          children: text,
          props: {
            style: {
              backgroundColor: checkStyleCell(text) || (datePicker.includes(text) ? "#a7ffa0" : "white"),
              border: "1px solid #bebebe",
              cursor: "pointer",
              textAlign: "center",
              color: "red",
            },
          },
        };
        return obj;
      },
    },
  ];

  return (
    <Modal
      open={props.visible}
      closable={false}
      width={540}
      className={`table-date-weekly ${props?.visibleListOfficeActual ? `table-date-useslip ${newArrayDataForDatePicker.length >= 6 && 'table-date-record-lengther'}` : ''}`}
      centered
      footer={
        !props.visibleWeeklyQuote
          ? [
            <Button key="ok" ref={addBtnRef} type="primary" onClick={handleDateOk}>
              {/* {props.isClickAddBulk ? "追加する" : "修正する"} */}
              追加する
            </Button>,
            <Button
              key="back"
              style={{
                backgroundColor: "#ffffff",
                color: "#616161",
                border: "1px solid #dbdbdb",
              }}
              onClick={() => handleDateCancel()}
            >
              キャンセル
            </Button>,
          ]
          : [
            <Button
              style={{
                backgroundColor: "#A9A9A9",
                color: "#ffffff",
                border: "1px solid #dbdbdb",
              }}
              onClick={() => handleTypeCheckAll()}
            >
              全部にチェック
            </Button>,
            <Button
              style={{
                backgroundColor: "#A9A9A9",
                color: "#ffffff",
                border: "1px solid #dbdbdb",
              }}
              onClick={() => handleTypeUnCheckAll()}
            >
              全てのチェックを外す
            </Button>,
            <Button key="ok" ref={addBtnRef} type="primary" onClick={handleDateOk}>
              OK
            </Button>,
            <Button
              key="back"
              style={{
                backgroundColor: "#ffffff",
                color: "#616161",
                border: "1px solid #dbdbdb",
              }}
              onClick={() => handleDateCancel()}
            >
              キャンセル
            </Button>,
          ]
      }
    >
      <div className="table-date-useslip-content">
        <div className="text-align-center">日付を選択してください</div>
        <div className="text-align-center" style={{ fontWeight: "bold" }}>
          {props.nengetuStr}
        </div>
        <Table columns={columns} dataSource={newArrayDataForDatePicker} bordered={true} size={"small"} pagination={false} className="time-table-row-select headercursor" />
        <div style={{ display: !props.visibleWeeklyQuote || props?.visibleListOfficeActual ? "none" : "block" }}>
          <div style={{ marginTop: "15px" }}>引用するサービス種類をチェックして下さい。</div>
          <WeeklyQuoteUseSlipModal
            data={listType}
            loading={false}
            className="color-master-useslip"
            isListCheckType={isListCheckType}
            handelListChecked={handelListChecked}
            dataTable={props.listOfficeActual}
            visibleListOfficeActual={props.visibleListOfficeActual}
          ></WeeklyQuoteUseSlipModal>
        </div>
        <div style={{ display: props.visibleWeeklyQuote || props?.visibleListOfficeActual ? "none" : "block" }}>
          <div style={{ marginTop: "15px" }}>緑が選択された状態です。</div>
          <div>日付クリックすると、白くなり選択解除になります。</div>
          <div>グレーの各ボタンをクリックすると解除したり選択したりを一括で行えます。</div>
          <div style={{ backgroundColor: "#d8e2f3", textAlign: "center", margin: "4px 0px" }}>
            <b>ショートの追加時 開始日と終了日の他サービスの削除</b>
          </div>
          <div style={{ textAlign: "center" }}>
            <Checkbox checked={isKeepFirst} disabled={isSelectTypeSort} onChange={() => setIsKeepFirst(!isKeepFirst)}>
              開始日は削除しない
            </Checkbox>
            <Checkbox checked={isKeepLast} disabled={isSelectTypeSort} onChange={() => setIsKeepLast(!isKeepLast)}>
              終了日は削除しない
            </Checkbox>
          </div>
        </div>

        {/* List office actual */}
        <div style={{ display: props?.visibleListOfficeActual ? "block" : "none" }} className="useslip_quote_weekly">
          <div style={{ marginTop: "15px" }}>引用する事業所をチェックして下さい。</div>
          <WeeklyQuoteUseSlipModal
            data={listType}
            loading={false}
            isListCheckType={isListCheckType}
            handelListChecked={handelListChecked}
            dataTable={props.listOfficeActual}
            visibleListOfficeActual={props.visibleListOfficeActual}
          ></WeeklyQuoteUseSlipModal>
        </div>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    listOfficeActual: state.useslip.listOfficeActual,
    listOfficeActualChecked: state.useslip.listOfficeActualChecked,
    listWeeklyQuote: state.useslip.listWeeklyQuote,
    isQuoteWeekly: state.useslip.isQuoteWeekly,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setListOfficeActualChecked: (list) => dispatch(UseSlipActions.setListOfficeActualChecked(list)),
    handleUpdateQuoteActualByType: (body) => dispatch(UseSlipActions.handleUpdateQuoteActualByType(body)),
    handleCoppyDataActual: (data) => dispatch(UseSlipActions.handleCoppyDataActual(data)),
  };
};

const memoizedDatePickerUseSlipModal = memo(DatePickerUseSlipModal);

export default connect(mapStateToProps, mapDispatchToProps)(memoizedDatePickerUseSlipModal);
