import { ApiPaths } from "../../constants";
import { RequestUtils, StorageUtils } from "../../utils";
import { ConfigName } from "../../../config-name";
import { CommonActionTypes, ErrorActionTypes } from "../action_types";

function getRoomByUrl(room) {
  return async (dispatch) => {
    try {
      const query = { roomUrl: room };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.common.getRoom,
        query,
      });
      localStorage.setItem("roomData", data);
      dispatch({
        type: CommonActionTypes.GET_ROOM_BY_URL,
        data,
      });
      return data;
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}

function getYougoMaster(listName) {
  return async (dispatch) => {
    try {
      dispatch({ type: CommonActionTypes.GET_YOUGO_MASTER_REQUEST });
      const query = { listName };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.common.getYougoMaster,
        query,
      });
      dispatch({
        type: CommonActionTypes.GET_YOUGO_MASTER_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: ErrorActionTypes.SET_ERROR,
        error,
      });
    }
  };
}
function updateYougoMaster(listName, naiyou) {
  return async (dispatch) => {
    try {
      dispatch({ type: CommonActionTypes.UPDATE_YOUGO_MASTER_REQUEST });
      const query = { listName, naiyou };
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.common.updateYougoMaster,
        // query,
        body: query,
      });
      dispatch({
        type: CommonActionTypes.UPDATE_YOUGO_MASTER_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: CommonActionTypes.UPDATE_YOUGO_MASTER_ERROR,
        error,
      });
    }
  };
}

function saveYougoMaster(listName, naiyou) {
  return async (dispatch) => {
    try {
      dispatch({ type: CommonActionTypes.UPDATE_YOUGO_MASTER_REQUEST });
      const query = { listName, naiyou };
      const data = await RequestUtils.callApi({
        method: "PUT",
        path: ApiPaths.common.updateYougoMaster,
        body: query,
      });
      dispatch({
        type: CommonActionTypes.UPDATE_YOUGO_MASTER_SUCCESS,
        data,
      });
    } catch (error) {
      if (error.response?.status === 400 && error.response?.data?.error?.code === "YOUGOMASTER_NOT_FOUND") {
        return createYougoMaster(listName, naiyou)(dispatch); // 新規作成を試みる
      }
      dispatch({
        type: CommonActionTypes.UPDATE_YOUGO_MASTER_ERROR,
        error,
      });
    }
  };
}

function createYougoMaster(listName, naiyou) {
  return async (dispatch) => {
    try {
      const query = { listName, naiyou };
      const data = await RequestUtils.callApi({
        method: "POST",
        path: ApiPaths.common.createYougoMaster,
        body: query,
      });
      dispatch({
        type: CommonActionTypes.UPDATE_YOUGO_MASTER_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: CommonActionTypes.UPDATE_YOUGO_MASTER_ERROR,
        error,
      });
    }
  };
}

function getSettingMasterAll() {
  return async (dispatch) => {
    try {
      dispatch({ type: CommonActionTypes.GET_SETTING_MASTER_ALL_REQUEST });
      const data = await RequestUtils.callApi({
        method: "GET",
        path: ApiPaths.common.getSettingMaster,
      });
      StorageUtils.setValue(ConfigName.SWMOBILE_SETTING_MASTER, { ...data });
      dispatch({
        type: CommonActionTypes.GET_SETTING_MASTER_ALL_SUCCESS,
        data,
      });
    } catch (error) {
      dispatch({
        type: CommonActionTypes.GET_SETTING_MASTER_ALL_ERROR,
        error,
      });
    }
  };
}

export const CommonActions = {
  getRoomByUrl,
  getYougoMaster,
  updateYougoMaster,
  saveYougoMaster,
  getSettingMasterAll,
};
