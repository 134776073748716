/* eslint-disable no-nested-ternary */
import { Form } from "@ant-design/compatible";
import { ArrowRightOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Spin,
  Table,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { deleteDataInOut, editDataInOut, editDataKaihakuKubun, getDataInOut, getDataInOutPaging, resetStatus } from "../../../actions/ioStatusActions/actionCreators";
import { yougoMasterNaiyouCreator } from "../../../actions/yougoMasterNaiyouActions/actionCreators";
import RoomMasterModal from "./RoomMasterModal";
import TableRowButtonIO from "./TableRowButtonIO";
import YougoPopover from "./YougoPopover";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};


const layout1 = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};


const layout2 = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
};

const expandedRowRender = (record) => (
  <p style={{ margin: "0" }}>{record.description}</p>
);
const showHeader = true;
const pagination = { position: "bottom" };

class IORecordTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editingState: false,
      id: null,
      isChangeForm: false,
      isChangeFormGaihaku: false,
      visibleConfirm: false,
      bordered: true,
      loading: false,
      pagination,
      size: "small",
      expandedRowRender,
      showHeader,
      dataFake: [],
      openStartDate: false,
      openEndDate: false,
      footer: undefined,
      listName: "",
      metaPaging: {
        page: 0,
        pageSize: 0,
        total: 0,
      },
      textNavigation: "",
      editData: {
        id: "0",
        name: "",
        gender: 0,
        bunrui: "",
        comment: "",
        gaihakuKubun: null,
        gei: "0",
        kubun: "",
        nyusyoDate: "",
        nyusyoRiyu: "",
        riyousyaId: "0",
        room: "",
        sou: "0",
        taishoDate: "",
        taishoRiyu: "",
      },
      disabledNext: false,
      disabledPrev: false,

      // rowSelection: {},
      scroll: undefined,
      hasData: true,
      expandIconAsCell: false,
      expandIconColumnIndex: -1,
      expandRowClick: [],

      activeListIndex: null,
      activeRowKey: null,
      shubetsuTitle: "",

      rowActive: null,

      deleteVisible: false,
      checked: false,
      expandedRowKeys: [],

      addUpdateVisible: false,
      sleepOutsideVisible: false,

      roomVisible: false, // 部屋マスタ
    };
    // bind
    this.onRowClick = this.onRowClick.bind(this);
    this.onKaihakuKubunChange = this.onKaihakuKubunChange.bind(this);
    this.onOpenStartDateStatus = this.onOpenStartDateStatus.bind(this);
    this.onOpenEndDateStatus = this.onOpenEndDateStatus.bind(this);
  }

  handleExpand(expanded, record) {
    this.setState({
      expandedRowKeys: expanded ? [record.reportCaseId] : [],
    });
  }

  showDeleteConfirm(reportCaseId) {
    this.setState({
      deleteVisible: true,
      expandedRowKeys: [reportCaseId],
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      selectedDate,
      pagination,
      filterObj,
      sortObj,
      searchName,
      getDataIoStatus,
      data,
    } = this.props;
    const { textNavigation, dataFake, id, metaPaging } = this.state;
    const date = selectedDate;
    const time = `${date.get("year")}-${date.get("month") + 1}-${date.get("date")}`;
    if (nextProps.editing !== this.props.editing) {
      this.setState({
        editingState: nextProps.editing,
      });
    }
    if (
      nextProps.notifyContentEdit &&
      nextProps.notifyContentEdit.type === "success"
    ) {
      getDataIoStatus(time, filterObj, sortObj, searchName, pagination.current);
      this.setState({
        addUpdateVisible: textNavigation === "submit" ? false : (!!(textNavigation === "prev" || textNavigation === "next")),
        sleepOutsideVisible: textNavigation === "submitGaihakuKubun" ? false : (!!(textNavigation === "prevGaihakuKubun" || textNavigation === "nextGaihakuKubun")),
        isChangeForm: false,
        isChangeFormGaihaku: false,
      });
      if (textNavigation === "prev") {
        data.forEach((item, index) => {
          if (item.id === parseInt(id)) {
            if (
              index > 0 &&
              metaPaging.page <=
              Math.ceil(metaPaging.total / metaPaging.pageSize)
            ) {
              const nyuDate = dayjs(data[index - 1].nyusyoDate);
              const taiDate = dayjs(data[index - 1].taishoDate);
              this.setState({
                isChangeForm: false,
                disabledNext: false,
                editData: {
                  id: `${data[index - 1].id}`,
                  name: data[index - 1].name,
                  gender: data[index - 1].gender,
                  bunrui: data[index - 1].bunrui,
                  comment: data[index - 1].comment,
                  gaihakuKubun: data[index - 1].gaihakuKubun,
                  gei: `${data[index - 1].gei}`,
                  kubun: data[index - 1].kubun,
                  nyusyoDate: data[index - 1].nyusyoDate,
                  nyusyoRiyu: data[index - 1].nyusyoRiyu,
                  riyousyaId: `${data[index - 1].riyousyaId}`,
                  room: data[index - 1].room,
                  sou: `${data[index - 1].sou}`,
                  taishoDate: data[index - 1].taishoDate,
                  taishoRiyu: data[index - 1].taishoRiyu,
                },
              });
              this.onRowClick(index - 1);
              if (data[index - 1].nyusyoDate) {
                this.onStartChange(nyuDate);
              }
              if (data[index - 1].taishoDate) {
                this.onEndChange(taiDate);
              }
            }
            if (
              0 <
              metaPaging.page <
              Math.ceil(metaPaging.total / metaPaging.pageSize) &&
              index === 0
            ) {
              getDataIoStatus(
                time,
                filterObj,
                sortObj,
                searchName,
                metaPaging.page - 1
              );
              this.setState({
                disabledNext: false,
              });
            }
            if (metaPaging.page === 1 && index - 1 === 0) {
              this.setState({
                disabledPrev: true,
              });
            }
            this.setState({
              isChangeForm: false,
            });
          }
        });
      }
      if (textNavigation === "next") {
        data.forEach((item, index) => {
          if (item.id === parseInt(id)) {
            if (
              metaPaging.page <=
              Math.ceil(metaPaging.total / metaPaging.pageSize) &&
              index < dataFake.length - 1
            ) {
              const nyuDate = dayjs(data[index + 1].nyusyoDate);
              const taiDate = dayjs(data[index + 1].taishoDate);
              this.setState({
                isChangeForm: false,
                disabledPrev: false,
                editData: {
                  id: `${data[index + 1].id}`,
                  name: data[index + 1].name,
                  gender: data[index + 1].gender,
                  bunrui: data[index + 1].bunrui,
                  comment: data[index + 1].comment,
                  gaihakuKubun: data[index + 1].gaihakuKubun,
                  gei: `${data[index + 1].gei}`,
                  kubun: data[index + 1].kubun,
                  nyusyoDate: data[index + 1].nyusyoDate,
                  nyusyoRiyu: data[index + 1].nyusyoRiyu,
                  riyousyaId: `${data[index + 1].riyousyaId}`,
                  room: data[index + 1].room,
                  sou: `${data[index + 1].sou}`,
                  taishoDate: data[index + 1].taishoDate,
                  taishoRiyu: data[index + 1].taishoRiyu,
                },
              });
              this.onRowClick(index + 1);
              if (data[index + 1].nyusyoDate) {
                this.onStartChange(nyuDate);
              }
              if (data[index + 1].taishoDate) {
                this.onEndChange(taiDate);
              }
            }
            if (
              metaPaging.page <
              Math.ceil(metaPaging.total / metaPaging.pageSize) &&
              index === data.length - 1
            ) {
              getDataIoStatus(
                time,
                filterObj,
                sortObj,
                searchName,
                metaPaging.page + 1
              );
              this.setState({
                disabledPrev: false,
              });
            }
            if (
              metaPaging.page ===
              Math.ceil(metaPaging.total / metaPaging.pageSize) &&
              index + 1 === data.length - 1
            ) {
              this.setState({
                disabledNext: true,
              });
            }
            this.setState({
              isChangeForm: false,
            });
          }
        });
      }
    }
    // end success
    if (
      nextProps.InOutEditStatus &&
      nextProps.data &&
      nextProps.data.length !== this.props.data.length
    ) {
      const idArr = nextProps.data.map((itemData) => {
        return itemData.id;
      });
      if (idArr.indexOf(nextProps.InOutEditStatus.id) === -1) {
        this.setState({
          addUpdateVisible: false,
        });
      }
    }
    if (nextProps.data && dataFake !== nextProps.data) {
      this.setState({
        dataFake: nextProps.data,
        metaPaging: {
          page: nextProps.meta.page,
          pageSize: nextProps.meta.pageSize,
          total: nextProps.meta.total,
        },
      });
      if (
        nextProps.meta.page ===
        Math.ceil(nextProps.meta.total / nextProps.meta.pageSize) &&
        nextProps.data.length === 1
      ) {
        this.setState({
          disabledNext: true,
        });
      }
      if (
        nextProps.notifyContentEdit &&
        nextProps.notifyContentEdit.type === "success" &&
        textNavigation === "next"
      ) {
        const nyuDate = dayjs(nextProps.data[0].nyusyoDate);
        const taiDate = dayjs(nextProps.data[0].taishoDate);
        this.onRowClick(0);
        this.setState({
          editData: {
            id: `${nextProps.data[0].id}`,
            name: nextProps.data[0].name,
            gender: nextProps.data[0].gender,
            bunrui: nextProps.data[0].bunrui,
            comment: nextProps.data[0].comment,
            gaihakuKubun: nextProps.data[0].gaihakuKubun,
            gei: `${nextProps.data[0].gei}`,
            kubun: nextProps.data[0].kubun,
            nyusyoDate: nextProps.data[0].nyusyoDate,
            nyusyoRiyu: nextProps.data[0].nyusyoRiyu,
            riyousyaId: `${nextProps.data[0].riyousyaId}`,
            room: nextProps.data[0].room,
            sou: `${nextProps.data[0].sou}`,
            taishoDate: nextProps.data[0].taishoDate,
            taishoRiyu: nextProps.data[0].taishoRiyu,
          },
        });
        if (nextProps.data[0].nyusyoDate) {
          this.onStartChange(nyuDate);
        }
        if (nextProps.data[0].taishoDate) {
          this.onEndChange(taiDate);
        }
      }
      else if (
        nextProps.notifyContentEdit &&
        nextProps.notifyContentEdit.type === "success" &&
        textNavigation === "prev"
      ) {
        const lengths = nextProps.data.length - 1;
        const nyuDate = dayjs(nextProps.data[lengths].nyusyoDate);
        const taiDate = dayjs(nextProps.data[lengths].taishoDate);
        this.onRowClick(lengths);
        this.setState({
          editData: {
            id: `${nextProps.data[lengths].id}`,
            name: nextProps.data[lengths].name,
            gender: nextProps.data[lengths].gender,
            bunrui: nextProps.data[lengths].bunrui,
            comment: nextProps.data[lengths].comment,
            gaihakuKubun: nextProps.data[lengths].gaihakuKubun,
            gei: `${nextProps.data[lengths].gei}`,
            kubun: nextProps.data[lengths].kubun,
            nyusyoDate: nextProps.data[lengths].nyusyoDate,
            nyusyoRiyu: nextProps.data[lengths].nyusyoRiyu,
            riyousyaId: `${nextProps.data[lengths].riyousyaId}`,
            room: nextProps.data[lengths].room,
            sou: `${nextProps.data[lengths].sou}`,
            taishoDate: nextProps.data[lengths].taishoDate,
            taishoRiyu: nextProps.data[lengths].taishoRiyu,
          },
        });
        if (nextProps.data[lengths].nyusyoDate) {
          this.onStartChange(nyuDate);
        }
        if (nextProps.data[lengths].taishoDate) {
          this.onEndChange(taiDate);
        }
      }
    }
    // this.setState({
    //   isChangeForm: false,
    //   isChangeFormGaihaku: false
    // });
  }

  componentDidUpdate() {
    const { notifyContentEdit } = this.props;
    if (notifyContentEdit && notifyContentEdit.type) {
      this.openNotificationWithIcon(
        notifyContentEdit.type,
        notifyContentEdit.message
      );
      this.props.resetStatus();
    }
  }
  onHideConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }
  onCancelConfirm() {
    this.setState({
      visibleConfirm: false,
    });
  }

  async onOkConfirm() {
    await this.setState({
      addUpdateVisible: false,
      visibleConfirm: false,
      isChangeForm: false,
      isChangeFormGaihaku: false,
      sleepOutsideVisible: false,
    });
  }
  showAddUpdateConfirm(item) {
    const { metaPaging } = this.state;
    const datas = this.props.data;
    const meta = this.props.meta;
    const nyuDate = dayjs(item.nyusyoDate).format("YYYY-MM-DD");
    const taiDate = dayjs(item.taishoDate).format("YYYY-MM-DD");
    datas.forEach((itemData, index) => {
      if (itemData.id === parseInt(item.id)) {
        metaPaging.page === Math.ceil(metaPaging.total / metaPaging.pageSize) &&
          index === datas.length - 1
          ? this.setState({
            disabledNext: true,
          })
          : this.setState({
            disabledNext: false,
          });

        metaPaging.page === 1 && index === 0
          ? this.setState({
            disabledPrev: true,
          })
          : this.setState({
            disabledPrev: false,
          });
      }
    });
    this.setState({
      addUpdateVisible: true,
      dataFake: datas,
      metaPaging: {
        page: meta.page,
        pageSize: meta.pageSize,
        total: meta.total,
      },
      editData: {
        id: `${item.id}`,
        name: item.name,
        gender: item.gender,
        bunrui: item.bunrui,
        comment: item.comment,
        gaihakuKubun: item.gaihakuKubun,
        gei: `${item.gei}`,
        kubun: item.kubun,
        nyusyoDate: item.nyusyoDate,
        nyusyoRiyu: item.nyusyoRiyu,
        riyousyaId: `${item.riyousyaId}`,
        room: item.room,
        sou: `${item.sou}`,
        taishoDate: item.taishoDate,
        taishoRiyu: item.taishoRiyu,
      },
    });
    if (item.nyusyoDate) {
      this.onStartChange(nyuDate);
    }
    if (item.taishoDate) {
      this.onEndChange(taiDate);
    }
    this.setState({
      isChangeForm: false,
    });
  }

  showSleepOutsideConfirm(data) {
    // const date = this.props.selectedDate;
    const { metaPaging } = this.state;
    const datas = this.props.data;
    // const time = `${date.get('year')}-${date.get('month') + 1}-${date.get('date')}`;
    datas.forEach((itemData, index) => {
      if (itemData.id === parseInt(data.id)) {
        metaPaging.page === Math.ceil(metaPaging.total / metaPaging.pageSize) &&
          index === datas.length - 1
          ? this.setState({
            disabledNext: true,
          })
          : this.setState({
            disabledNext: false,
          });

        metaPaging.page === 1 && index === 0
          ? this.setState({
            disabledPrev: true,
          })
          : this.setState({
            disabledPrev: false,
          });
      }
    });
    this.setState((prevState) => ({
      sleepOutsideVisible: true,
      editData: {
        ...prevState.editData,
        riyousyaId: data.riyousyaId,
        gaihakuKubun: data.gaihakuKubun,
        name: data.name,
      },
    }));
  }

  closeSleepOutsideConfirm() {
    this.setState({
      sleepOutsideVisible: false,
    });
  }

  closeAddUpdateConfirm() {
    const { isChangeForm } = this.state;
    if (isChangeForm == true) {
      this.setState({
        visibleConfirm: true,
      });
    }
    else {
      this.setState({
        addUpdateVisible: false,
        openStartDate: false,
        openEndDate: false,
      });
    }
  }

  closeDeleteConfirm() {
    this.setState({
      deleteVisible: false,
      checked: false,
    });
  }

  openNotificationWithIcon = (type, notifyContent) => {
    notification[type]({
      message: notifyContent,
      placement: "topRight",
      duration: type === "success" ? 1 : 3,
      prefixCls: "pljp-notification",
    });
  };

  handleConfirmDelete() {
    // const { selectedDate } = this.props;
    // const date = selectedDate;
    // const time = `${date.get('year')}-${date.get('month') + 1}-${date.get('date')}`;
    const id = this.state.expandedRowKeys;
    this.props.deleteDataItemIoStatus(id[0]);
    this.setState({
      deleteVisible: false,
      checked: false,
    });
  }

  handleChangeCheckbox() {
    this.setState({
      checked: !this.state.checked,
    });
  }

  onRowClick(key) {
    this.setState({
      rowActive: key,
    });

    const keys = [];
    if (key) {
      keys.push(key); // I have set my record.id as row key. Check the documentation for more details.
    }

    if (key === this.state.activeRowKey) {
      this.setState({
        expandRowClick: [],
        activeRowKey: null,
      });
    }
    else {
      this.setState({
        expandRowClick: [key],
        activeRowKey: key,
      });
    }
  }

  onKubunValueChange(event) {
    this.setState((prevState) => ({
      editData: {
        ...prevState.editData,
        kubun: event.target.value,
      },
      isChangeForm: true,
    }));
  }

  onChange = (field, value) => {
    this.setState((prevState) => ({
      editData: {
        ...prevState.editData,
        [field]: value,
      },
    }));
  };

  onStartChange = (value) => {
    this.onChange("nyusyoDate", value);
    this.setState({
      isChangeForm: true,
    });
  };

  onEndChange = (value) => {
    this.onChange("taishoDate", value);
    this.setState({
      isChangeForm: true,
    });
  };

  disabledStartDate = (startValue) => {
    const { editData } = this.state;
    if (!startValue || !editData.taishoDate) {
      return false;
    }
    return startValue.valueOf() > editData.taishoDate.valueOf();
  };

  disabledEndDate = (endValue) => {
    const { editData } = this.state;
    if (!endValue || !editData.nyusyoDate) {
      return false;
    }
    return endValue.valueOf() <= editData.nyusyoDate.valueOf();
  };

  onRoomChange(event) {
    const values = event.currentTarget.value;
    this.setState((prevState) => ({
      editData: {
        ...prevState.editData,
        room: values.length > 0 ? values : null,
      },
      isChangeForm: true,
    }));
  }

  onNyusyoRiyuChange(event) {
    const values = event.currentTarget.value;
    this.setState((prevState) => ({
      editData: {
        ...prevState.editData,
        nyusyoRiyu: values,
      },
      isChangeForm: true,
    }));
  }

  onTaishoRiyuChange(event) {
    const values = event.currentTarget.value;
    this.setState((prevState) => ({
      editData: {
        ...prevState.editData,
        taishoRiyu: values,
      },
      isChangeForm: true,
    }));
  }

  onBunruiChange(event) {
    const values = event.currentTarget.value;
    this.setState((prevState) => ({
      editData: {
        ...prevState.editData,
        bunrui: values.length > 0 ? values : null,
      },
      isChangeForm: true,
    }));
  }

  onCommentChange(event) {
    const values = event.currentTarget.value;
    this.setState((prevState) => ({
      editData: {
        ...prevState.editData,
        comment: values,
      },
      isChangeForm: true,
    }));
  }

  onGeiChange(event) {
    const checked = event.target.checked ? "1" : "0";
    this.setState((prevState) => ({
      editData: {
        ...prevState.editData,
        gei: checked,
      },
      isChangeForm: true,
    }));
  }

  onSouChange(event) {
    const checked = event.target.checked ? "1" : "0";
    this.setState((prevState) => ({
      editData: {
        ...prevState.editData,
        sou: checked,
      },
      isChangeForm: true,
    }));
  }

  onKaihakuKubunChange(event) {
    const valueRadio = event.target.value;
    this.setState((prevState) => ({
      editData: {
        ...prevState.editData,
        gaihakuKubun: valueRadio,
      },
      isChangeFormGaihaku: true,
    }));
  }

  clearEndDate() {
    this.setState((prevState) => ({
      editData: {
        ...prevState.editData,
        taishoDate: null,
      },
    }), () => {
      this.setState({
        openEndDate: false,
        isChangeForm: true,
      });
    });
  }

  clearStartDate() {
    this.setState((prevState) => ({
      editData: {
        ...prevState.editData,
        nyusyoDate: null,
      },
      openStartDate: false,
      isChangeForm: true,
    }));
  }

  onOpenStartDateStatus(status) {
    this.setState({
      openStartDate: status,
    });
  }

  onOpenEndDateStatus(status) {
    this.setState({
      openEndDate: status,
    });
  }

  haneleRoomVisible() {
    this.setState({
      roomVisible: true,
    });
  }
  onRoomCancel() {
    this.setState({
      roomVisible: false,
    });
  }

  render() {
    const {
      expandedRowKeys,
      expandRowClick,
      hasData,
      // rowActive,
      deleteVisible,
      checked,
      addUpdateVisible,
      sleepOutsideVisible,
      editData,
      disabledNext,
      disabledPrev,
      // listName,
      metaPaging,
      dataFake,
      openStartDate,
      openEndDate,
      visibleConfirm,
      isChangeForm,
      isChangeFormGaihaku,
    } = this.state;
    const {
      data,
      isMealStatus,
      onEditSubmit,
      getDataIoStatus,
      selectedDate,
      editKaihakuKubun,
      isDisLongText,
      filterObj,
      sortObj,
      searchName,
      // page,
      loading,
    } = this.props;
    let tmp = [];

    // alert(JSON.stringify(editData));

    const time = `${selectedDate.get("year")}-${selectedDate.get("month") + 1}-${selectedDate.get("date")}`;
    const columsMealStatus = [
      {
        title: "No",
        dataIndex: "no",
        key: "no",
        width: 40,
        fixed: "left",
      },
      {
        title: "状況",
        dataIndex: "status",
        key: "status",
        width: 70,
        fixed: "left",
        render: (text) => text,
        className: "joutaiCol",
      },
      {
        title: "利用者名",
        dataIndex: "user_name",
        key: "user_name",
        width: 152,
        fixed: "left",
      },
      {
        title: "部屋",
        dataIndex: "room",
        key: "room",
        width: 110,
      },
      {
        title: "食事状況",
        dataIndex: "meal_status",
        key: "meal_status",
        width: 100,
      },
      {
        title: "食事開始日",
        dataIndex: "meal_start_date",
        key: "meal_start_date",
        width: 100,
      },
      {
        title: "食事終了日",
        dataIndex: "end_date_of_meal",
        key: "end_date_of_meal",
        width: 100,
      },
      {
        title: "開始終了区分",
        dataIndex: "start_end_indicator",
        key: "start_end_indicator",
        width: 150,
      },
      {
        title: "主食",
        dataIndex: "staple_food",
        key: "staple_food",
        width: 100,
      },
      {
        title: "副食",
        dataIndex: "non_staple_food",
        key: "non_staple_food",
        width: 100,
      },
      {
        title: "食事区分",
        dataIndex: "meal_division",
        key: "meal_division",
        width: 100,
      },
      {
        title: "備考",
        dataIndex: "remarks",
        key: "remarks",
      },
    ];

    const columns = [
      {
        title: "No",
        dataIndex: "no",
        key: "no",
        width: 40,
        fixed: "left",
      },
      {
        title: "状況",
        dataIndex: "status",
        key: "status",
        width: 70,
        fixed: "left",
        render: (text) => text,
        className: "joutaiCol",
      },
      {
        title: "利用者名",
        dataIndex: "user_name",
        key: "user_name",
        width: 152,
        fixed: "left",
      },
      {
        title: "入所日",
        dataIndex: "entrance_date",
        key: "entrance_date",
        width: 100,
      },
      {
        title: "迎",
        dataIndex: "welcome",
        key: "welcome",
        width: 50,
      },
      {
        title: "入所転帰",
        dataIndex: "entrance_outcome",
        key: "entrance_outcome",
        width: 150,
      },
      {
        title: "部屋",
        dataIndex: "room",
        key: "room",
        width: 80,
      },
      {
        title: "外泊(出)",
        dataIndex: "overnight",
        key: "overnight",
        width: 100,
      },
      {
        title: "退所日",
        dataIndex: "departure_date",
        key: "departure_date",
        width: 100,
      },
      {
        title: "送",
        dataIndex: "give_away",
        key: "give_away",
        width: 50,
      },
      {
        title: "退所転帰",
        dataIndex: "exit_outcome",
        key: "exit_outcome",
        width: 150,
      },
      {
        title: "入退所分類",
        dataIndex: "classification_of_entry_and_exit",
        key: "classification_of_entry_and_exit",
        width: 150,
      },
      {
        title: "コメント",
        dataIndex: "comment",
        key: "comment",
      },
    ];

    if (isMealStatus) {
      tmp = columsMealStatus;
    }
    else {
      tmp = columns;
    }

    const tableData = [];
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let color = "nuytaisho-yellow";
        if (data[i].kubun === "ショート") {
          color = "nuytaisho-blue";
        }

        let gender = "女";
        let colorGender = "nts-gender-red";
        if (data[i].gender === 1) {
          gender = "男";
          colorGender = "nts-gender-blue";
        }

        let wellcome = "";
        if (data[i].gei === 1) {
          wellcome = "迎";
        }

        let give_away = "";
        if (data[i].sou === 1) {
          give_away = "送";
        }
        const nyusyoTime = dayjs(data[i].nyusyoDate);
        const taishoTime = dayjs(data[i].taishoDate);
        const currentTime = selectedDate ? dayjs(selectedDate) : dayjs();

        tableData.push({
          key: i,
          no: i + 1,
          status: (
            <div
              className={"ntk-riyousya-status " + color}
              onClick={() => this.onRowClick(i)}
            >
              {taishoTime.isBefore(currentTime, "day") || taishoTime.isSame(currentTime, "day") ? (
                  "退"
                ) : nyusyoTime.isSame(currentTime, "day") ? (
                  "入"
                ) : (
                  <ArrowRightOutlined />
              )}
            </div>
          ),
          user_name: (
            <span className={"ntk-riyousya-colum"}>
              <span title={data[i].name}>{data[i].name}</span>
              <span className={"ntl-riyousya-gender " + colorGender}>
                {gender}
              </span>
            </span>
          ),
          entrance_date: <span>{data[i].nyusyoDate}</span>,
          welcome: <span>{wellcome}</span>,
          entrance_outcome: (
            <span title={data[i].nyusyoRiyu}>{data[i].nyusyoRiyu}</span>
          ),
          room: <span title={data[i].room}>{data[i].room}</span>,
          overnight: (
            <span title={data[i].gaihakuKubun}>{data[i].gaihakuKubun}</span>
          ),
          departure_date: (
            <span title={data[i].taishoDate}>{data[i].taishoDate}</span>
          ),
          give_away: <span>{give_away}</span>,
          exit_outcome: (
            <span title={data[i].taishoRiyu}>{data[i].taishoRiyu}</span>
          ),
          classification_of_entry_and_exit: (
            <span title={data[i].bunrui}>{data[i].bunrui}</span>
          ),
          comment: <span title={data[i].comment}>{data[i].comment}</span>,

          meal_status: <span title={data[i].joukyou}>{data[i].joukyou}</span>,
          meal_start_date: (
            <span title={data[i].startDate}>{data[i].startDate}</span>
          ),
          end_date_of_meal: (
            <span title={data[i].endDate}>{data[i].endDate}</span>
          ),
          start_end_indicator: (
            <span title={data[i].kunbunSho}>{data[i].kunbunSho}</span>
          ),
          staple_food: (
            <span title={data[i].stapleFood}>{data[i].stapleFood}</span>
          ),
          non_staple_food: (
            <span title={data[i].sideFood}>{data[i].sideFood}</span>
          ),
          meal_division: (
            <span title={data[i].syokujiKubun}>{data[i].syokujiKubun}</span>
          ),
          remarks: <span title={data[i].tokki}>{data[i].tokki}</span>,

          description: (
            <TableRowButtonIO
              id={data[i].no}
              riyousyaId={data[i].riyousyaId}
              showDeleteConfirm={() => this.showDeleteConfirm(data[i].id)}
              handleExpand={(expanded, record) =>
                this.handleExpand(expanded, record)
              }
              expandedRows={expandedRowKeys}
              isMealStatus={isMealStatus}
              selectedDate={selectedDate}
              showAddUpdateConfirm={() => this.showAddUpdateConfirm(data[i])}
              showSleepOutsideConfirm={() =>
                this.showSleepOutsideConfirm(data[i])
              }
            />
          ),
          expandable: true,
        });
      }
    }

    const onSubmitIoStatus = () => {
      // const { filterObj, sortObj, searchName } = this.props;
      this.setState({
        textNavigation: "submit",
        editingState: true,
      });
      onEditSubmit(editData);
      this.setState({
        addUpdateVisible: true,
      });
    };

    const onNextList = (id) => {
      this.setState({
        textNavigation: "next",
        id,
      });
      if (isChangeForm == true) {
        onEditSubmit(editData);
      }
      else {
        data.forEach((item, index) => {
          if (item.id === parseInt(id)) {
            if (
              metaPaging.page <=
              Math.ceil(metaPaging.total / metaPaging.pageSize) &&
              index < dataFake.length - 1
            ) {
              const nyuDate = dayjs(data[index + 1].nyusyoDate);
              const taiDate = dayjs(data[index + 1].taishoDate);
              this.setState({
                isChangeForm: false,
                disabledPrev: false,
                editData: {
                  id: `${data[index + 1].id}`,
                  name: data[index + 1].name,
                  gender: data[index + 1].gender,
                  bunrui: data[index + 1].bunrui,
                  comment: data[index + 1].comment,
                  gaihakuKubun: data[index + 1].gaihakuKubun,
                  gei: `${data[index + 1].gei}`,
                  kubun: data[index + 1].kubun,
                  nyusyoDate: data[index + 1].nyusyoDate,
                  nyusyoRiyu: data[index + 1].nyusyoRiyu,
                  riyousyaId: `${data[index + 1].riyousyaId}`,
                  room: data[index + 1].room,
                  sou: `${data[index + 1].sou}`,
                  taishoDate: data[index + 1].taishoDate,
                  taishoRiyu: data[index + 1].taishoRiyu,
                },
              });
              this.onRowClick(index + 1);
              if (data[index + 1].nyusyoDate) {
                this.onStartChange(nyuDate);
              }
              if (data[index + 1].taishoDate) {
                this.onEndChange(taiDate);
              }
            }
            if (
              metaPaging.page <
              Math.ceil(metaPaging.total / metaPaging.pageSize) &&
              index === data.length - 1
            ) {
              getDataIoStatus(
                time,
                filterObj,
                sortObj,
                searchName,
                metaPaging.page + 1
              );
              this.setState({
                disabledPrev: false,
              });
            }
            if (
              metaPaging.page ===
              Math.ceil(metaPaging.total / metaPaging.pageSize) &&
              index + 1 === data.length - 1
            ) {
              this.setState({
                disabledNext: true,
              });
            }
            this.setState({
              isChangeForm: false,
            });
          }
        });
      }
    };

    const onPrevList = (id) => {
      this.setState({
        textNavigation: "prev",
        id,
      });
      if (isChangeForm == true) {
        onEditSubmit(editData);
      }
      else {
        data.forEach((item, index) => {
          if (item.id === parseInt(id)) {
            if (
              index > 0 &&
              metaPaging.page <=
              Math.ceil(metaPaging.total / metaPaging.pageSize)
            ) {
              const nyuDate = dayjs(data[index - 1].nyusyoDate);
              const taiDate = dayjs(data[index - 1].taishoDate);
              this.setState({
                isChangeForm: false,
                disabledNext: false,
                editData: {
                  id: `${data[index - 1].id}`,
                  name: data[index - 1].name,
                  gender: data[index - 1].gender,
                  bunrui: data[index - 1].bunrui,
                  comment: data[index - 1].comment,
                  gaihakuKubun: data[index - 1].gaihakuKubun,
                  gei: `${data[index - 1].gei}`,
                  kubun: data[index - 1].kubun,
                  nyusyoDate: data[index - 1].nyusyoDate,
                  nyusyoRiyu: data[index - 1].nyusyoRiyu,
                  riyousyaId: `${data[index - 1].riyousyaId}`,
                  room: data[index - 1].room,
                  sou: `${data[index - 1].sou}`,
                  taishoDate: data[index - 1].taishoDate,
                  taishoRiyu: data[index - 1].taishoRiyu,
                },
              });
              this.onRowClick(index - 1);
              if (data[index - 1].nyusyoDate) {
                this.onStartChange(nyuDate);
              }
              if (data[index - 1].taishoDate) {
                this.onEndChange(taiDate);
              }
            }
            if (
              0 <
              metaPaging.page <
              Math.ceil(metaPaging.total / metaPaging.pageSize) &&
              index === 0
            ) {
              getDataIoStatus(
                time,
                filterObj,
                sortObj,
                searchName,
                metaPaging.page - 1
              );
              this.setState({
                disabledNext: false,
              });
            }
            if (metaPaging.page === 1 && index - 1 === 0) {
              this.setState({
                disabledPrev: true,
              });
            }
            this.setState({
              isChangeForm: false,
            });
          }
        });
      }
    };

    const onSubmitEditKaihakuKubun = () => {
      this.setState({
        textNavigation: "submitGaihakuKubun",
        sleepOutsideVisible: false,
      });
      const dataSleepOutside = {
        date: time,
        riyousyaId: editData.riyousyaId,
        gaihakuKubun: editData.gaihakuKubun,
      };
      editKaihakuKubun(dataSleepOutside);
    };

    const onCancelEditKaihakuKubun = () => {
      const { isChangeFormGaihaku } = this.state;
      if (isChangeFormGaihaku == true) {
        this.setState({
          visibleConfirm: true,
        });
      }
      else {
        this.setState({
          sleepOutsideVisible: false,
        });
      }
    };

    const onPrevGaihakuKubun = () => {
      const date = this.props.selectedDate;
      const time = `${date.get("year")}-${date.get("month") + 1}-${date.get("date")}`;
      this.setState({
        textNavigation: "prevGaihakuKubun",
      });
      const dataSleepOutside = {
        date: time,
        riyousyaId: editData.riyousyaId,
        gaihakuKubun: editData.gaihakuKubun,
      };
      if (isChangeFormGaihaku == true) {
        editKaihakuKubun(dataSleepOutside);
      }
      data.forEach((item, index) => {
        if (item.riyousyaId === parseInt(editData.riyousyaId)) {
          if (
            index > 0 &&
            metaPaging.page <= Math.ceil(metaPaging.total / metaPaging.pageSize)
          ) {
            this.setState((prevState) => ({
              disabledNext: false,
              editData: {
                ...prevState.editData,
                riyousyaId: data[index - 1].riyousyaId,
                gaihakuKubun: data[index - 1].gaihakuKubun,
                name: data[index - 1].name,
              },
            }));
            this.onRowClick(index - 1);
          }
          if (
            0 <
            metaPaging.page <
            Math.ceil(metaPaging.total / metaPaging.pageSize) &&
            index === 0
          ) {
            getDataIoStatus(
              time,
              filterObj,
              sortObj,
              searchName,
              metaPaging.page - 1
            );
            this.setState({
              disabledNext: false,
            });
          }
          if (metaPaging.page === 1 && index - 1 === 0) {
            this.setState({
              disabledPrev: true,
            });
          }
        }
      });
    };

    const onNextGaihakuKubun = () => {
      const date = this.props.selectedDate;
      const time = `${date.get("year")}-${date.get("month") + 1}-${date.get("date")}`;
      this.setState({
        textNavigation: "nextGaihakuKubun",
      });
      const dataSleepOutside = {
        date: time,
        riyousyaId: editData.riyousyaId,
        gaihakuKubun: editData.gaihakuKubun,
      };
      if (isChangeFormGaihaku == true) {
        editKaihakuKubun(dataSleepOutside);
      }
      data.forEach((item, index) => {
        if (item.riyousyaId === parseInt(editData.riyousyaId)) {
          if (
            metaPaging.page <=
            Math.ceil(metaPaging.total / metaPaging.pageSize) &&
            index < data.length - 1
          ) {
            this.setState((prevState) => ({
              disabledPrev: false,
              editData: {
                ...prevState.editData,
                riyousyaId: data[index + 1].riyousyaId,
                gaihakuKubun: data[index + 1].gaihakuKubun,
                name: data[index + 1].name,
              },
            }));
            this.onRowClick(index + 1);
          }
          if (
            metaPaging.page <
            Math.ceil(metaPaging.total / metaPaging.pageSize) &&
            index === data.length - 1
          ) {
            getDataIoStatus(
              time,
              filterObj,
              sortObj,
              searchName,
              metaPaging.page + 1
            );
            this.setState({
              disabledPrev: false,
            });
          }
          if (
            metaPaging.page ===
            Math.ceil(metaPaging.total / metaPaging.pageSize) &&
            index + 1 === data.length - 1
          ) {
            this.setState({
              disabledNext: true,
            });
          }
        }
      });
    };

    const roomValueChange = (value) => {
      this.setState((prevState) => ({
        editData: {
          ...prevState.editData,
          room: value.length > 0 ? value : null,
        },
        isChangeForm: true,
        roomVisible: false,
      }));
    };

    const nyusyoRiyuValueChange = (value) => {
      const arr = [];
      if (editData.nyusyoRiyu) {
        arr.push(editData.nyusyoRiyu);
      }
      arr.push(value);
      this.setState((prevState) => ({
        editData: {
          ...prevState.editData,
          nyusyoRiyu: arr.join("、"),
        },
        isChangeForm: true,
      }));
    };

    const taishoRiyuValueChange = (value) => {
      const arr = [];
      if (editData.taishoRiyu) {
        arr.push(editData.taishoRiyu);
      }
      arr.push(value);
      this.setState((prevState) => ({
        editData: {
          ...prevState.editData,
          taishoRiyu: arr.join("、"),
        },
        isChangeForm: true,
      }));
    };

    const bunruiValueChange = (value) => {
      const arr = [];
      if (editData.bunrui) {
        arr.push(editData.bunrui);
      }
      arr.push(value);
      this.setState((prevState) => ({
        editData: {
          ...prevState.editData,
          bunrui: value.length > 0 ? value : null,
        },
        isChangeForm: true,
      }));
    };

    const commentValueChange = (value) => {
      const arr = [];
      if (editData.comment) {
        arr.push(editData.comment);
      }
      arr.push(value);
      this.setState((prevState) => ({
        editData: {
          ...prevState.editData,
          comment: arr.join("、"),
        },
        isChangeForm: true,
      }));
    };

    const getListName = (value) => {
      this.setState({
        listName: value,
      });
    };

    return (
      (<div>
        {/* <Spin spinning={loading}>*/}
        <Table
          {...this.state}
          columns={tmp}
          dataSource={hasData ? tableData : null}
          // rowClassName={(record, index) => rowActive != null && index === rowActive ? "pljp-table-row-hover" : ""}
          rowClassName={isDisLongText ? "dis-text-long" : ""}
          expandedRowKeys={expandRowClick}
          pagination={this.props.pagination}
          className="table-kiroku-date"
          onRow={(record, rowIndex) => {
            return {
              onClick: () => {
                this.onRowClick(rowIndex);
              },
            };
          }}
          scroll={{ x: 1400 }}
        />
        <Modal
          maskClosable={false}
          mask={false}
          open={deleteVisible}
          title="削除確認（同意するにチェックしてOKボタンを押してください）"
          onCancel={() => this.closeDeleteConfirm()}
          footer={[
            <Button
              disabled={!checked}
              key="delete"
              type={"primary"}
              onClick={() => this.handleConfirmDelete()}
            >
              OK
            </Button>,
            <Button
              key="canceldelete"
              onClick={() => this.closeDeleteConfirm()}
            >
              Cancel
            </Button>,
          ]}
        >
          <div style={{ backgroundColor: "aliceblue" }}>
            <span style={{ display: "block" }}>{"この行を削除しますか？"}</span>
            <Checkbox
              checked={checked}
              onChange={() => this.handleChangeCheckbox()}
              value={checked}
            >
              {"削除に同意する"}
            </Checkbox>
          </div>
        </Modal>
        <Modal
          className={"in-out-status-m-c"}
          destroyOnClose={true}
          maskClosable={false}
          width={1000}
          open={addUpdateVisible}
          onOk={onSubmitIoStatus}
          onCancel={() => this.closeAddUpdateConfirm()}
          zIndex={500}
          footer={[
            <Row key={1} type="flex" justify="space-between">
              <div>
                <Button onClick={() => this.closeAddUpdateConfirm()}>
                  {"閉じる"}
                </Button>
              </div>
              <div>
                <Button
                  disabled={disabledPrev}
                  onClick={() => onPrevList(editData.id)}
                >
                  {"前"}
                </Button>
                <Button
                  disabled={disabledNext}
                  onClick={() => onNextList(editData.id)}
                >
                  {"次"}
                </Button>
              </div>
              <div>
                <Button
                  loading={this.state.editingState}
                  onClick={onSubmitIoStatus}
                >
                  {"適用"}
                </Button>
              </div>
            </Row>,
          ]}
        >
          <Spin spinning={loading}>
            <Form layout="inline" className="user-in-out-form">
              <Row style={{ marginBottom: 16 }}>
                <Col md={24} className={"header-form"}>
                  <Form>
                    <Form.Item label="利用者名" colon={false}>
                      <Tooltip placement="topLeft" title={editData.name}>
                        <span>{editData.name}</span>
                      </Tooltip>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={12} className={"flex-content"}>
                  <Row style={{ marginBottom: 16 }}>
                    <span style={{ marginRight: 8 }}>区分</span>
                    <Radio.Group
                      onChange={(event) => this.onKubunValueChange(event)}
                      value={editData.kubun}
                    >
                      <Radio value={"入所"}>{"入所"}</Radio>
                      <Radio value={"ショート"}>{"ショート"}</Radio>
                    </Radio.Group>
                  </Row>
                  <Form {...layout1}>
                    <Form.Item label="入所日" colon={false}>
                      <DatePicker
                        disabledDate={this.disabledStartDate}
                        value={
                          editData.nyusyoDate &&
                            dayjs(editData.nyusyoDate).isValid()
                            ? dayjs(editData.nyusyoDate)
                            : null
                        }
                        onChange={this.onStartChange}
                        style={{ width: "150px" }}
                        open={openStartDate}
                        onOpenChange={(status) =>
                          this.onOpenStartDateStatus(status)
                        }
                      ></DatePicker>
                      <Checkbox
                        checked={editData.gei === "1"}
                        onChange={(event) => this.onGeiChange(event)}
                      >
                        {"迎"}
                      </Checkbox>
                    </Form.Item>
                  </Form>
                  <Form {...layout1}>
                    <Form.Item label="退所日" colon={false}>
                      <DatePicker
                        disabledDate={this.disabledEndDate}
                        value={
                          editData.taishoDate &&
                            dayjs(editData.taishoDate).isValid()
                            ? dayjs(editData.taishoDate)
                            : null
                        }
                        onChange={this.onEndChange}
                        style={{ width: "150px" }}
                        open={openEndDate}
                        onOpenChange={(status) =>
                          this.onOpenEndDateStatus(status)
                        }
                        popupClassName="dp-footer"
                        renderExtraFooter={() => (
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => this.clearEndDate()}
                          >
                            {"クリア"}
                          </a>
                        )}
                      ></DatePicker>
                      <Checkbox
                        checked={editData.sou === "1"}
                        onChange={(event) => this.onSouChange(event)}
                      >
                        {"送"}
                      </Checkbox>
                    </Form.Item>
                  </Form>
                </Col>
                <Col xs={24} sm={24} md={12} className={"flex-content"}>
                  <Form {...layout}>
                    <Form.Item label="部屋" colon={false}>
                      <Input
                        maxLength={20}
                        value={editData.room}
                        onChange={(event) => this.onRoomChange(event)}
                      ></Input>
                      {/* <RoomPopover listName={listName} onChange={value => roomValueChange(value)}> */}
                      <Button
                        onClick={() => {
                          getListName("選択");
                          this.haneleRoomVisible();
                        }}
                        type="primary"
                      >
                        {"選択"}
                      </Button>
                      {/* </RoomPopover> */}
                    </Form.Item>

                    <Form.Item label="入所転帰" colon={false}>
                      {/* <Tooltip placement="topLeft" title={editData.nyusyoRiyu
                        }> */}
                      <Input value={editData.nyusyoRiyu} onChange={(event) => this.onNyusyoRiyuChange(event)}></Input>
                      {/* </Tooltip> */}
                      <YougoPopover
                        listName={"入所理由"}
                        onChange={(value) => nyusyoRiyuValueChange(value)}
                      >
                        <Button type="primary">{"選択"}</Button>
                      </YougoPopover>
                    </Form.Item>

                    <Form.Item label="退所転帰" colon={false}>
                      {/* <Tooltip placement="topLeft" title={editData.taishoRiyu
                        }> */}
                      <Input value={editData.taishoRiyu} onChange={(event) => this.onTaishoRiyuChange(event)}></Input>
                      {/* </Tooltip> */}
                      <YougoPopover
                        listName={"退所理由"}
                        onChange={(value) => taishoRiyuValueChange(value)}
                      >
                        <Button type="primary">{"選択"}</Button>
                      </YougoPopover>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={12} className={"flex-content"}>
                  <Form {...layout1}>
                    <Form.Item label="入退所分類" colon={false}>
                      <Input
                        maxLength={80}
                        value={editData.bunrui}
                        onChange={(event) => this.onBunruiChange(event)}
                      ></Input>
                      <YougoPopover
                        listName={"入退所分類"}
                        onChange={(value) => bunruiValueChange(value)}
                        position={"bottom"}
                      >
                        <Button type="primary">{"選択"}</Button>
                      </YougoPopover>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} className={"flex-content"}>
                  <Form {...layout2}>
                    <Form.Item label="コメント" colon={false}>
                      <Input.TextArea autoSize={{ minRows: 1, maxRows: 3 }} value={editData.comment} onChange={(event) => this.onCommentChange(event)} />
                      <YougoPopover
                        listName={"入退所コメント"}
                        onChange={value => commentValueChange(value)}>
                        <Button style={{ marginTop: "-5px" }} type="primary">{"選択"}</Button>
                      </YougoPopover>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
        <Modal
          open={sleepOutsideVisible}
          maskClosable={false}
          onCancel={onCancelEditKaihakuKubun}
          className={"sleep-outside"}
          zIndex={500}
          footer={[
            <Row key={1} type="flex" justify="space-between">
              <Button onClick={onCancelEditKaihakuKubun}>{"閉じる"}</Button>
              <Row type="flex" justify="space-between">
                <Button disabled={disabledPrev} onClick={onPrevGaihakuKubun}>
                  {"前"}
                </Button>
                <Button disabled={disabledNext} onClick={onNextGaihakuKubun}>
                  {"次"}
                </Button>
              </Row>
              <Button onClick={onSubmitEditKaihakuKubun}>{"適用"}</Button>
            </Row>,
          ]}
        >
          <Spin spinning={loading}>
            <Form className={"user-in-out-form"} layout="inline">
              <Row className={"row-1"}>
                <Col md={12} className={"header-form"}>
                  <Form.Item label="利用者名" colon={false}>
                    <Tooltip placement="topLeft" title={editData.name}>
                      {editData.name}
                    </Tooltip>
                  </Form.Item>
                </Col>
                <Col md={12} className={"header-form"}>
                  <Form.Item label="日付" colon={false}>
                    {time}
                  </Form.Item>
                </Col>
              </Row>
              <Row type="flex">
                <Col>
                  <Form.Item>
                    <Radio.Group
                      onChange={this.onKaihakuKubunChange}
                      value={editData.gaihakuKubun}
                    >
                      <Radio value={"外泊"}>{"外泊"}</Radio>
                      <Radio value={"入院"}>{"入院"}</Radio>
                      <Radio value={"外出"}>{"外出"}</Radio>
                      <Radio value={null}>{"消去"}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
        <Modal
          title={"キャンセル確認"}
          open={visibleConfirm}
          onCancel={() => this.onHideConfirm()}
          zIndex={1000}
          footer={[
            <Button key="cancel" onClick={() => this.onCancelConfirm()}>
              キャンセル
            </Button>,
            <Button key="ok" type="primary" onClick={() => this.onOkConfirm()}>
              Ok
            </Button>,
          ]}
        >
          <p>キャンセルしてもよろしいですか？</p>
        </Modal>
        <RoomMasterModal
          onChange={(value) => roomValueChange(value)}
          onCancel={() => this.onRoomCancel()}
          visible={this.state.roomVisible}
        />
      </div>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.ioStatusReducers.data,
    meta: state.ioStatusReducers.meta,
    isMealStatus: state.ioStatusReducers.isMealStatus,
    notifyContentEdit: state.ioStatusReducers.notifyContentEdit,
    yougoMasterNaiyou: state.yougoMasterNaiyou.yougoMasterNaiyou,
    dataPaging: state.ioStatusReducers.dataPaging,
    loading: state.ioStatusReducers.loading,
    InOutEditStatus: state.ioStatusReducers.InOutEditStatus,
    editing: state.ioStatusReducers.editing,
  };
};

const mapDispatchToProps = (dispatch) => {
  const dispatchDebounce = _.debounce(dispatch, 500);
  return {
    onEditSubmit: (data) => dispatchDebounce(editDataInOut(data)),
    getDataIoStatus: (value, filterObj, sort, searchName, page) =>
      dispatch(getDataInOut(value, filterObj, sort, searchName, page)),
    getDataPaging: (value, filterObj, sort, searchName, page) =>
      dispatch(getDataInOutPaging(value, filterObj, sort, searchName, page)),
    getYougoMasterNaiYou: (value) => dispatch(yougoMasterNaiyouCreator(value)),
    deleteDataItemIoStatus: (id) => dispatch(deleteDataInOut(id)),
    editKaihakuKubun: (data) => dispatch(editDataKaihakuKubun(data)),
    resetStatus: () => dispatch(resetStatus()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IORecordTable);
