import { notification } from "antd";
import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import "./assets/less/styles.less";
import Layout from "./layouts";

function App({ authmobile, error }) {
  useEffect(() => {
    if (error.message) {
      notification.error({
        message: "Error",
        description: error.message,
        placement: "bottomLeft",
        duration: 3,
      });
    }
  }, [error]);

  return (
    <Layout
      isUserLoggedIn={authmobile.isUserLoggedIn}
      sessionTimeout={authmobile.sessionTimeout}
      jwtTokenType={authmobile.jwtTokenType}
    />
  );
}

const mapStateToProps = () => (state) => ({
  authmobile: state.authmobile,
  error: state.error,
});

export default connect(mapStateToProps)(
  memo(App, (props, nextProps) => {
    if (props.authmobile.isUserLoggedIn !== nextProps.authmobile.isUserLoggedIn) {
      return false;
    }
    if (props.error.mesasge !== nextProps.error.mesasge) {
      return false;
    }
    return true;
  })
);
