import GetAvatar from "../../../reuse/api/getAvatar";
import { GET_AVATAR_SUCCESS } from "../../actions/getAvatarActions/actionName.js";

export default function getAvatar() {
  return (dispatch) => {
    GetAvatar.getAvatar().then((data) =>
      dispatch({
        type: GET_AVATAR_SUCCESS,
        getAvatar: data,
      })
    );
  };
}
