/* eslint-disable no-case-declarations */
/* eslint-disable prefer-const */
import {
  CLEAR_SELECTED_ROW,
  KANRI_DENGON_ADD_SUCCESS,
  KANRI_DENGON_DELETE_SUCCESS,
  KANRI_DENGON_LIST_SUCCESS,
  KANRI_DENGON_SELECTED_DISPLAY,
  KANRI_DENGON_SINGLE_RECORD_SUCCESS,
  KANRI_DENGON_UPDATE_SUCCESS,
  KANRI_FILE_ADD_SUCCESS,
  KANRI_FILE_DELETE_SUCCESS,
  KANRI_FILE_LIST_SUCCESS,
  KANRI_FILE_SELECTED_DISPLAY,
  KANRI_FILE_SINGLE_RECORD_SUCCESS,
  KANRI_FILE_UPDATE_SUCCESS,
  KANRI_KYM_ADD_SUCCESS,
  KANRI_KYM_DELETE_SUCCESS,
  KANRI_KYM_LIST_SUCCESS,
  KANRI_KYM_SELECTED_DISPLAY,
  KANRI_KYM_SINGLE_RECORD_SUCCESS,
  KANRI_KYM_UPDATE_SUCCESS,
  KANRI_LYM_ADD_SUCCESS,
  KANRI_LYM_DELETE_SUCCESS,
  KANRI_LYM_DISPLAY_LIST,
  KANRI_LYM_LIST_SUCCESS,
  KANRI_LYM_SELECTED_DISPLAY,
  KANRI_LYM_UPDATE_SUCCESS,
  KANRI_MOSHIOKURI_ADD_SUCCESS,
  KANRI_MOSHIOKURI_DELETE_SUCCESS,
  KANRI_MOSHIOKURI_LIST_SUCCESS,
  KANRI_MOSHIOKURI_SELECTED_DISPLAY,
  KANRI_MOSHIOKURI_SINGLE_RECORD_SUCCESS,
  KANRI_MOSHIOKURI_UPDATE_SUCCESS,
  KANRI_OPENSL_ADD_SUCCESS,
  KANRI_OPENSL_DELETE_SUCCESS,
  KANRI_OPENSL_LIST_SUCCESS,
  KANRI_OPENSL_SELECTED_DISPLAY,
  KANRI_OPENSL_SINGLE_RECORD_SUCCESS,
  KANRI_OPENSL_UPDATE_SUCCESS,
  KANRI_REQUEST,
  KANRI_REQUEST_ERROR,
  KANRI_SEARCH_DATA,
  KANRI_SM_ADD_SUCCESS,
  KANRI_SM_DELETE_SUCCESS,
  KANRI_SM_DISPLAY_LIST,
  KANRI_SM_LIST_SUCCESS,
  KANRI_SM_SELECTED_DISPLAY,
  KANRI_SM_UPDATE_SUCCESS,
  KANRI_TM_ADD_SUCCESS,
  KANRI_TM_DELETE_SUCCESS,
  KANRI_TM_DISPLAY_LIST,
  KANRI_TM_LIST_SUCCESS,
  KANRI_TM_SELECTED_DISPLAY,
  KANRI_TM_UPDATE_SUCCESS,
  KANRI_URL_ADD_SUCCESS,
  KANRI_URL_DELETE_SUCCESS,
  KANRI_URL_DISPLAY_LIST,
  KANRI_URL_LIST_SUCCESS,
  KANRI_URL_SELECTED_DISPLAY,
  KANRI_URL_UPDATE_SUCCESS,
  KANRI_USER_ADD_SUCCESS,
  KANRI_USER_DELETE_SUCCESS,
  KANRI_USER_DISPLAY_LIST,
  KANRI_USER_LIST_SUCCESS,
  KANRI_USER_SELECTED_DISPLAY,
  KANRI_USER_UPDATE_SUCCESS,
  KANRI_YM_ADD_SUCCESS,
  KANRI_YM_DELETE_SUCCESS,
  KANRI_YM_DISPLAY_LIST,
  KANRI_YM_LIST_SUCCESS,
  KANRI_YM_SELECTED_DISPLAY,
  KANRI_YM_UPDATE_SUCCESS,
  SAVE_RECORD_MARK,
  SAVE_SELECTED_ROW,
} from "../../../../actions/kanriActions/actionName";

/*
 *
 * @param state: array // substate of store for home page
 * @param action: action dispatched from Home component
 *
 */
const INITIAL_STATE = {
  loading: false, // loading request
  notifyDisplay: 0, // 0 is none display, 1 - display sucess, 2 - display error
  notifyContent: null, // notifycation content,
  searchData: [],
  menuSetting: [],
  kanriUserList: [],
  kanriFileList: [],
  kanriFile: {},
  kanriDengonList: [],
  kanriDengon: {},
  kanriMoshiokuriList: [],
  kanriMoshiokuri: {},
  kanriOpenSLList: [],
  kanriOpenSL: {},
  kanriKYMList: [],
  kanriKYM: {},
  kanriSMList: [],
  kanriYMList: [],
  kanriLYMList: [],
  kanriTMList: [],
  kanriURLList: [],

  userSelectedRows: [],
  userDisplayList: [],
  isUserSelectedDisplay: false,

  fileSelectedRows: [],
  fileDisplayList: [],
  isFileSelectedDisplay: false,

  dengonSelectedRows: [],
  dengonDisplayList: [],
  isDengonSelectedDisplay: false,

  moshiokuriSelectedRows: [],
  moshiokuriDisplayList: [],
  isMoshiokuriSelectedDisplay: false,

  openSLSelectedRows: [],
  openSLDisplayList: [],
  isOpenSLSelectedDisplay: false,

  kymSelectedRows: [],
  kymDisplayList: [],
  isKYMSelectedDisplay: false,

  smSelectedRows: [],
  smDisplayList: [],
  isSMSelectedDisplay: false,

  ymSelectedRows: [],
  ymDisplayList: [],
  isYMSelectedDisplay: false,

  lymSelectedRows: [],
  lymDisplayList: [],
  isLYMSelectedDisplay: false,

  tmSelectedRows: [],
  tmDisplayList: [],
  isTMSelectedDisplay: false,

  urlSelectedRows: [],
  urlDisplayList: [],
  isURLSelectedDisplay: false,

  currentFile: 1,
  pageSizeFile: 100,
  totalFile: 0,

  currentDengon: 1,
  pageSizeDengon: 100,
  totalDengon: 0,

  currentMoshiokuri: 1,
  pageSizeMoshiokuri: 100,
  totalMoshiokuri: 0,

  currentOpenSL: 1,
  pageSizeOpenSL: 100,
  totalOpenSL: 0,

  currentKYM: 1,
  pageSizeKYM: 100,
  totalKYM: 0,
};

export default function tantoMasterListReducers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLEAR_SELECTED_ROW:
      switch (action.params) {
        case "user":
          return {
            ...state,
            userSelectedRows: [],
            userDisplayList: [],
            isUserSelectedDisplay: false,
          };

        case "file":
          return {
            ...state,
            fileSelectedRows: [],
            fileDisplayList: [],
            isFileSelectedDisplay: false,
          };

        case "dengon":
          return {
            ...state,
            dengonSelectedRows: [],
            dengonDisplayList: [],
            isDengonSelectedDisplay: false,
          };

        case "moshiokuri":
          return {
            ...state,
            moshiokuriSelectedRows: [],
            moshiokuriDisplayList: [],
            isMoshiokuriSelectedDisplay: false,
          };

        case "open":
          return {
            ...state,
            openSLSelectedRows: [],
            openSLDisplayList: [],
            isOpenSLSelectedDisplay: false,
          };

        case "kym":
          return {
            ...state,
            kymSelectedRows: [],
            kymDisplayList: [],
            isKYMSelectedDisplay: false,
          };

        case "sm":
          return {
            ...state,
            smSelectedRows: [],
            smDisplayList: [],
            isSMSelectedDisplay: false,
          };

        case "ym":
          return {
            ...state,
            ymSelectedRows: [],
            ymDisplayList: [],
            isYMSelectedDisplay: false,
          };

        case "lym":
          return {
            ...state,
            lymSelectedRows: [],
            lymDisplayList: [],
            isLYMSelectedDisplay: false,
          };

        case "tm":
          return {
            ...state,
            tmSelectedRows: [],
            tmDisplayList: [],
            isTMSelectedDisplay: false,
          };

        case "url":
          return {
            ...state,
            urlSelectedRows: [],
            urlDisplayList: [],
            isURLSelectedDisplay: false,
          };

        default:
          return {
            ...state,
          };
      }

    case SAVE_SELECTED_ROW:
      switch (action.params) {
        case "user":
          return {
            ...state,
            userSelectedRows: action.selectedRows,
          };

        case "file":
          return {
            ...state,
            fileSelectedRows: action.selectedRows,
          };

        case "dengon":
          return {
            ...state,
            dengonSelectedRows: action.selectedRows,
          };

        case "moshiokuri":
          return {
            ...state,
            moshiokuriSelectedRows: action.selectedRows,
          };

        case "open":
          return {
            ...state,
            openSLSelectedRows: action.selectedRows,
          };

        case "kym":
          return {
            ...state,
            kymSelectedRows: action.selectedRows,
          };

        case "sm":
          return {
            ...state,
            smSelectedRows: action.selectedRows,
          };

        case "ym":
          return {
            ...state,
            ymSelectedRows: action.selectedRows,
          };

        case "lym":
          return {
            ...state,
            lymSelectedRows: action.selectedRows,
          };

        case "tm":
          return {
            ...state,
            tmSelectedRows: action.tmSelectedRows,
          };

        case "url":
          return {
            ...state,
            urlSelectedRows: action.urlSelectedRows,
          };

        default:
          return {
            ...state,
          };
      }

    case SAVE_RECORD_MARK:
      switch (action.typeF) {
        case "user":
          let userSelectedRows = state.userSelectedRows;
          // Check type add or remove
          if (action.typeA === "add") {
            if (!userSelectedRows.includes(action.id)) {
              userSelectedRows[userSelectedRows.length] = action.id;
            }
            return {
              ...state,
              userSelectedRows,
            };
          }
          // action.typeA ==="remove"
          if (userSelectedRows.indexOf(action.id) > -1) {
            userSelectedRows.splice(userSelectedRows.indexOf(action.id), 1);
          }

          return {
            ...state,
            userSelectedRows,
          };


        case "file":
          let fileSelectedRows = state.fileSelectedRows;
          // Check type add or remove
          if (action.typeA === "add") {
            if (!fileSelectedRows.includes(action.id)) {
              fileSelectedRows[fileSelectedRows.length] = action.id;
            }
            return {
              ...state,
              fileSelectedRows,
            };
          }
          // action.typeA ==="remove"
          if (fileSelectedRows.indexOf(action.id) > -1) {
            fileSelectedRows.splice(fileSelectedRows.indexOf(action.id), 1);
          }

          return {
            ...state,
            fileSelectedRows,
          };


        case "kym":
          let kymSelectedRows = state.kymSelectedRows;
          // Check type add or remove
          if (action.typeA === "add") {
            if (!kymSelectedRows.includes(action.id)) {
              kymSelectedRows[kymSelectedRows.length] = action.id;
            }
            return {
              ...state,
              kymSelectedRows,
            };
          }
          // action.typeA ==="remove"
          if (kymSelectedRows.indexOf(action.id) > -1) {
            kymSelectedRows.splice(kymSelectedRows.indexOf(action.id), 1);
          }

          return {
            ...state,
            kymSelectedRows,
          };


        case "dengon":
          let dengonSelectedRows = state.dengonSelectedRows;
          // Check type add or remove
          if (action.typeA === "add") {
            if (!dengonSelectedRows.includes(action.id)) {
              dengonSelectedRows[dengonSelectedRows.length] = action.id;
            }
            return {
              ...state,
              dengonSelectedRows,
            };
          }
          // action.typeA ==="remove"
          if (dengonSelectedRows.indexOf(action.id) > -1) {
            dengonSelectedRows.splice(
              dengonSelectedRows.indexOf(action.id),
              1
            );
          }

          return {
            ...state,
            dengonSelectedRows,
          };


        case "moshiokuri":
          let moshiokuriSelectedRows = state.moshiokuriSelectedRows;
          // Check type add or remove
          if (action.typeA === "add") {
            if (!moshiokuriSelectedRows.includes(action.id)) {
              moshiokuriSelectedRows[moshiokuriSelectedRows.length] = action.id;
            }
            return {
              ...state,
              moshiokuriSelectedRows,
            };
          }
          // action.typeA ==="remove"
          if (moshiokuriSelectedRows.indexOf(action.id) > -1) {
            moshiokuriSelectedRows.splice(
              moshiokuriSelectedRows.indexOf(action.id),
              1
            );
          }

          return {
            ...state,
            moshiokuriSelectedRows,
          };


        case "open":
          let openSLSelectedRows = state.openSLSelectedRows;
          // Check type add or remove
          if (action.typeA === "add") {
            if (!openSLSelectedRows.includes(action.id)) {
              openSLSelectedRows[openSLSelectedRows.length] = action.id;
            }
            return {
              ...state,
              openSLSelectedRows,
            };
          }
          // action.typeA ==="remove"
          if (openSLSelectedRows.indexOf(action.id) > -1) {
            openSLSelectedRows.splice(
              openSLSelectedRows.indexOf(action.id),
              1
            );
          }

          return {
            ...state,
            openSLSelectedRows,
          };


        case "sm":
          let smSelectedRows = state.smSelectedRows;
          // Check type add or remove
          if (action.typeA === "add") {
            if (!smSelectedRows.includes(action.id)) {
              smSelectedRows[smSelectedRows.length] = action.id;
            }
            return {
              ...state,
              smSelectedRows,
            };
          }
          // action.typeA ==="remove"
          if (smSelectedRows.indexOf(action.id) > -1) {
            smSelectedRows.splice(smSelectedRows.indexOf(action.id), 1);
          }

          return {
            ...state,
            smSelectedRows,
          };


        case "ym":
          let ymSelectedRows = state.ymSelectedRows;
          // Check type add or remove
          if (action.typeA === "add") {
            if (!ymSelectedRows.includes(action.id)) {
              ymSelectedRows[ymSelectedRows.length] = action.id;
            }
            return {
              ...state,
              ymSelectedRows,
            };
          }
          // action.typeA ==="remove"
          if (ymSelectedRows.indexOf(action.id) > -1) {
            ymSelectedRows.splice(ymSelectedRows.indexOf(action.id), 1);
          }

          return {
            ...state,
            ymSelectedRows,
          };


        case "lym":
          let lymSelectedRows = state.lymSelectedRows;
          // Check type add or remove
          if (action.typeA === "add") {
            if (!lymSelectedRows.includes(action.id)) {
              lymSelectedRows[lymSelectedRows.length] = action.id;
            }
            return {
              ...state,
              lymSelectedRows,
            };
          }
          // action.typeA ==="remove"
          if (lymSelectedRows.indexOf(action.id) > -1) {
            lymSelectedRows.splice(lymSelectedRows.indexOf(action.id), 1);
          }

          return {
            ...state,
            lymSelectedRows,
          };


        case "tm":
          let tmSelectedRows = state.tmSelectedRows;
          // Check type add or remove
          if (action.typeA === "add") {
            if (!tmSelectedRows.includes(action.id)) {
              tmSelectedRows[tmSelectedRows.length] = action.id;
            }
            return {
              ...state,
              tmSelectedRows,
            };
          }
          // action.typeA ==="remove"
          if (tmSelectedRows.indexOf(action.id) > -1) {
            tmSelectedRows.splice(tmSelectedRows.indexOf(action.id), 1);
          }

          return {
            ...state,
            tmSelectedRows,
          };


        case "url":
          let urlSelectedRows = state.urlSelectedRows;
          // Check type add or remove
          if (action.typeA === "add") {
            if (!urlSelectedRows.includes(action.id)) {
              urlSelectedRows[urlSelectedRows.length] = action.id;
            }
            return {
              ...state,
              urlSelectedRows,
            };
          }
          // action.typeA ==="remove"
          if (urlSelectedRows.indexOf(action.id) > -1) {
            urlSelectedRows.splice(urlSelectedRows.indexOf(action.id), 1);
          }

          return {
            ...state,
            urlSelectedRows,
          };


        default:
          return {
            ...state,
          };
      }

    case KANRI_REQUEST:
      return {
        ...state,
        loading: true,
        notifyDisplay: 0,
        notifyContent: null,
      };

    case KANRI_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        notifyDisplay: 2,
        notifyContent: action.err ? action.err : "エラーがあります",
      };

    // Setting Master
    case KANRI_SM_SELECTED_DISPLAY:
      return {
        ...state,
        kanriSMList: state.kanriSMList.filter((item) =>
          action.ids.includes(item.id)
        ),
        smSelectedRows: action.ids,
        isSMSelectedDisplay: true,
      };

    case KANRI_SM_DISPLAY_LIST:
      return {
        ...state,
        kanriSMList: state.kanriSMList.filter((item) =>
          action.ids.includes(item.id)
        ),
        smDisplayList: action.ids,
        isSMSelectedDisplay: true,
      };

    case KANRI_SM_LIST_SUCCESS:
      return {
        ...state,
        kanriSMList:
          state.smDisplayList.length > 0 && state.isSMSelectedDisplay
            ? action.payload.filter((item) =>
              state.smDisplayList.includes(item.id)
            )
            : action.payload,
        loading: false,
      };

    // add settingMaster
    case KANRI_SM_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    // update settingMaster
    case KANRI_SM_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    // delete settingMaster
    case KANRI_SM_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "削除しました。",
        kanriSMList: state.kanriSMList.filter(
          (item) => !action.params.split(",").includes(item.id.toString())
        ),
      };

    // Yougo Master
    case KANRI_YM_SELECTED_DISPLAY:
      return {
        ...state,
        kanriYMList: state.kanriYMList.filter((item) =>
          action.ids.includes(item.id)
        ),
        ymSelectedRows: action.ids,
        isYMSelectedDisplay: true,
      };

    case KANRI_YM_DISPLAY_LIST:
      return {
        ...state,
        kanriYMList: state.kanriYMList.filter((item) =>
          action.ids.includes(item.id)
        ),
        ymDisplayList: action.ids,
        isYMSelectedDisplay: true,
      };

    case KANRI_YM_LIST_SUCCESS:
      return {
        ...state,
        kanriYMList:
          state.ymDisplayList.length > 0 && state.isYMSelectedDisplay
            ? action.payload.filter((item) =>
              state.ymDisplayList.includes(item.id)
            )
            : action.payload,
        loading: false,
      };

    // add yougomaster
    case KANRI_YM_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    // update yougomaster
    case KANRI_YM_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    // delete yougomaster
    case KANRI_YM_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "削除しました。",
        kanriYMList: state.kanriYMList.filter(
          (item) => !action.params.split(",").includes(item.id.toString())
        ),
      };

    // Life Yougo Master
    case KANRI_LYM_SELECTED_DISPLAY:
      return {
        ...state,
        kanriLYMList: state.kanriLYMList.filter((item) =>
          action.ids.includes(item.id)
        ),
        lymSelectedRows: action.ids,
        isLYMSelectedDisplay: true,
      };

    case KANRI_LYM_DISPLAY_LIST:
      return {
        ...state,
        kanriLYMList: state.kanriLYMList.filter((item) =>
          action.ids.includes(item.id)
        ),
        lymDisplayList: action.ids,
        isLYMSelectedDisplay: true,
      };

    case KANRI_LYM_LIST_SUCCESS:
      // console.log(state)
      return {
        ...state,
        kanriLYMList:
          state.lymDisplayList.length > 0 && state.isLYMSelectedDisplay
            ? action.payload.filter((item) =>
              state.lymDisplayList.includes(item.id)
            )
            : action.payload,
        loading: false,
      };

    // add lifeyougomaster
    case KANRI_LYM_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    // update lifeyougomaster
    case KANRI_LYM_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    // delete lifeyougomaster
    case KANRI_LYM_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "削除しました。",
        kanriLYMList: state.kanriLYMList.filter(
          (item) => !action.params.split(",").includes(item.id.toString())
        ),
      };

    // KYM
    case KANRI_KYM_SELECTED_DISPLAY:
      return {
        ...state,
        kanriKYMList:
          action.payload && action.payload.data ? action.payload.data : [],
        kymDisplayList: action.ids,
        isKYMSelectedDisplay: true,
        currentKYM: action.payload.pageNo,
        pageSizeKYM: action.payload.pageSize,
        totalKYM: action.payload.total,
        loading: false,
      };

    case KANRI_KYM_LIST_SUCCESS:
      return {
        ...state,
        kanriKYMList: action.payload.data,
        currentKYM: action.payload.pageNo,
        pageSizeKYM: action.payload.pageSize,
        totalKYM: action.payload.total,
        loading: false,
      };

    // add
    case KANRI_KYM_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    // update
    case KANRI_KYM_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    case KANRI_KYM_SINGLE_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        kanriKYM: action.payload,
      };

    // delete
    case KANRI_KYM_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "削除しました。",
        kanriKYMList: state.kanriKYMList.filter(
          (item) => !action.params.split(",").includes(item.id.toString())
        ),
      };

    // Tanto Master
    case KANRI_TM_SELECTED_DISPLAY:
      return {
        ...state,
        kanriTMList: state.kanriTMList.filter((item) =>
          action.ids.includes(item.id)
        ),
        tmSelectedRows: action.ids,
        isTMSelectedDisplay: true,
      };

    case KANRI_TM_DISPLAY_LIST:
      return {
        ...state,
        kanriTMList: state.kanriTMList.filter((item) =>
          action.ids.includes(item.id)
        ),
        tmDisplayList: action.ids,
        isTMSelectedDisplay: true,
      };

    case KANRI_TM_LIST_SUCCESS:
      return {
        ...state,
        kanriTMList:
          state.tmDisplayList.length > 0 && state.isTMSelectedDisplay
            ? action.payload.filter((item) =>
              state.tmDisplayList.includes(item.id)
            )
            : action.payload,
        loading: false,
      };

    // add tantomaster
    case KANRI_TM_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    // update tantomaster
    case KANRI_TM_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    // delete tantomaster
    case KANRI_TM_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "削除しました。",
        kanriTMList: state.kanriTMList.filter(
          (item) => !action.params.split(",").includes(item.id.toString())
        ),
      };

    // userryousyalink
    case KANRI_URL_SELECTED_DISPLAY:
      return {
        ...state,
        kanriURLList: state.kanriURLList.filter((item) =>
          action.ids.includes(item.id)
        ),
        urlSelectedRows: action.ids,
        isURLSelectedDisplay: true,
      };

    case KANRI_URL_DISPLAY_LIST:
      return {
        ...state,
        kanriURLList: state.kanriURLList.filter((item) =>
          action.ids.includes(item.id)
        ),
        urlDisplayList: action.ids,
        isURLSelectedDisplay: true,
      };

    case KANRI_URL_LIST_SUCCESS:
      return {
        ...state,
        kanriURLList:
          state.urlDisplayList.length > 0 && state.isURLSelectedDisplay
            ? action.payload.filter((item) =>
              state.urlDisplayList.includes(item.id)
            )
            : action.payload,
        loading: false,
      };

    // add userryousyalink
    case KANRI_URL_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    // update userryousyalink
    case KANRI_URL_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    // delete userryousyalink
    case KANRI_URL_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "削除しました。",
        kanriURLList: state.kanriURLList.filter(
          (item) => !action.params.split(",").includes(item.id.toString())
        ),
      };

    // Users
    case KANRI_USER_SELECTED_DISPLAY:
      return {
        ...state,
        kanriUserList: state.kanriUserList.filter((item) =>
          action.ids.includes(item.id)
        ),
        userSelectedRows: action.ids,
        isUserSelectedDisplay: true,
      };

    case KANRI_USER_DISPLAY_LIST:
      return {
        ...state,
        kanriUserList: state.kanriUserList.filter((item) =>
          action.ids.includes(item.id)
        ),
        userDisplayList: action.ids,
        isUserSelectedDisplay: true,
      };

    case KANRI_USER_LIST_SUCCESS:
      return {
        ...state,
        kanriUserList:
          state.userDisplayList.length > 0 && state.isUserSelectedDisplay
            ? action.payload.filter((item) =>
              state.userDisplayList.includes(item.id)
            )
            : action.payload,
        loading: false,
      };

    // add riyousya
    case KANRI_USER_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    // update riyousya
    case KANRI_USER_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    // delete settingMaster
    case KANRI_USER_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "削除しました。",
        kanriUserList: state.kanriUserList.filter(
          (item) => !action.params.split(",").includes(item.id.toString())
        ),
      };

    // Files
    case KANRI_FILE_SELECTED_DISPLAY:
      return {
        ...state,
        kanriFileList:
          action.payload && action.payload.data ? action.payload.data : [],
        fileDisplayList: action.ids,
        isFileSelectedDisplay: true,
        currentFile: action.payload.pageNo,
        pageSizeFile: action.payload.pageSize,
        totalFile: action.payload.total,
        loading: false,
      };

    case KANRI_FILE_LIST_SUCCESS:
      return {
        ...state,
        kanriFileList: action.payload.data,
        currentFile: action.payload.pageNo,
        pageSizeFile: action.payload.pageSize,
        totalFile: action.payload.total,
        loading: false,
      };

    // add
    case KANRI_FILE_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    // update
    case KANRI_FILE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    case KANRI_FILE_SINGLE_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        kanriFile: action.payload,
      };

    // delete
    case KANRI_FILE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "削除しました。",
        kanriFileList: state.kanriFileList.filter(
          (item) => !action.params.split(",").includes(item.id.toString())
        ),
      };

    // Dengon
    case KANRI_DENGON_SELECTED_DISPLAY:
      return {
        ...state,
        kanriDengonList:
          action.payload && action.payload.data ? action.payload.data : [],
        dengonDisplayList: action.ids,
        isDengonSelectedDisplay: true,
        currentDengon: action.payload.pageNo,
        pageSizeDengon: action.payload.pageSize,
        totalDengon: action.payload.total,
        loading: false,
      };

    case KANRI_DENGON_LIST_SUCCESS:
      return {
        ...state,
        kanriDengonList: action.payload.data,
        currentDengon: action.payload.pageNo,
        pageSizeDengon: action.payload.pageSize,
        totalDengon: action.payload.total,
        loading: false,
      };

    // add
    case KANRI_DENGON_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    // update
    case KANRI_DENGON_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    case KANRI_DENGON_SINGLE_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        kanriDengon: action.payload,
      };

    // delete
    case KANRI_DENGON_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "削除しました。",
        kanriDengonList: state.kanriDengonList.filter(
          (item) => !action.params.split(",").includes(item.id.toString())
        ),
      };

    // Moshiokuri
    case KANRI_MOSHIOKURI_SELECTED_DISPLAY:
      return {
        ...state,
        kanriMoshiokuriList:
          action.payload && action.payload.data ? action.payload.data : [],
        moshiokuriDisplayList: action.ids,
        isMoshiokuriSelectedDisplay: true,
        currentMoshiokuri: action.payload.pageNo,
        pageSizeMoshiokuri: action.payload.pageSize,
        totalMoshiokuri: action.payload.total,
        loading: false,
      };

    case KANRI_MOSHIOKURI_LIST_SUCCESS:
      return {
        ...state,
        kanriMoshiokuriList: action.payload.data,
        currentMoshiokuri: action.payload.pageNo,
        pageSizeMoshiokuri: action.payload.pageSize,
        totalMoshiokuri: action.payload.total,
        loading: false,
      };

    // add
    case KANRI_MOSHIOKURI_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    // update
    case KANRI_MOSHIOKURI_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    case KANRI_MOSHIOKURI_SINGLE_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        kanriMoshiokuri: action.payload,
      };

    // delete
    case KANRI_MOSHIOKURI_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "削除しました。",
        kanriMoshiokuriList: state.kanriMoshiokuriList.filter(
          (item) => !action.params.split(",").includes(item.id.toString())
        ),
      };

    // OpenScreenLog
    case KANRI_OPENSL_SELECTED_DISPLAY:
      return {
        ...state,
        kanriOpenSLList:
          action.payload && action.payload.data ? action.payload.data : [],
        openSLDisplayList: action.ids,
        isOpenSLSelectedDisplay: true,
        currentOpenSL: action.payload.pageNo,
        pageSizeOpenSL: action.payload.pageSize,
        totalOpenSL: action.payload.total,
        loading: false,
      };

    case KANRI_OPENSL_LIST_SUCCESS:
      return {
        ...state,
        kanriOpenSLList: action.payload.data,
        currentOpenSL: action.payload.pageNo,
        pageSizeOpenSL: action.payload.pageSize,
        totalOpenSL: action.payload.total,
        loading: false,
      };

    // add
    case KANRI_OPENSL_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    // update
    case KANRI_OPENSL_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "保存しました。",
      };

    case KANRI_OPENSL_SINGLE_RECORD_SUCCESS:
      return {
        ...state,
        loading: false,
        kanriOpenSL: action.payload,
      };

    // delete
    case KANRI_OPENSL_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        notifyDisplay: 1,
        notifyContent: "削除しました。",
        kanriOpenSLList: state.kanriOpenSLList.filter(
          (item) => !action.params.split(",").includes(item.id.toString())
        ),
      };

    case KANRI_SEARCH_DATA:
      return {
        ...state,
        searchData: action.payload,
      };

    default:
      return state;
  }
}
