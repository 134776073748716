import { VitalActionTypes } from "../action_types";
import { showToast } from "../../common/function_common/functionCommon";
import i18n from "../../i18n/config";

const initState = {
  loading: false,
  listDataLoading: false,
  settingLoading: false,
  formLoading: false,
  listDatas: [],
  formData: null,
  settingMaster: null,
};

function vital(state = initState, action) {
  const { t } = i18n;
  switch (action.type) {
    case VitalActionTypes.LOAD_SPIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case VitalActionTypes.GET_VITAL_RECORDS_REQUEST: {
      return {
        ...state,
        listDataLoading: true,
      };
    }

    case VitalActionTypes.GET_VITAL_RECORDS_SUCCESS: {
      const updatedData = action.data.map((record) => {
        if (Number.isInteger(record.temperature)) {
          return {
            ...record,
            temperature: `${record.temperature}.0`, // 整数なら .0 を付ける
          };
        }
        return record; // それ以外のレコードはそのまま返す
      });

      return {
        ...state,
        listDataLoading: false,
        listDatas: updatedData,
      };
    }

    case VitalActionTypes.GET_VITAL_RECORDS_ERROR: {
      return {
        ...state,
        listDataLoading: false,
        listDatas: [],
      };
    }

    case VitalActionTypes.GET_VITAL_SETTING_MASTER_REQUEST: {
      return {
        ...state,
        settingLoading: true,
        settingMaster: null,
      };
    }
    case VitalActionTypes.GET_VITAL_SETTING_MASTER_SUCCESS: {
      return {
        ...state,
        settingLoading: false,
        settingMaster: action.data,
      };
    }
    case VitalActionTypes.GET_VITAL_SETTING_MASTER_ERROR: {
      return {
        ...state,
        settingLoading: false,
        settingMaster: null,
      };
    }

    case VitalActionTypes.GET_VITAL_PREVIOUS_DATA_REQUEST: {
      return {
        ...state,
        previousLoading: true,
        previousData: null,
      };
    }
    case VitalActionTypes.GET_VITAL_PREVIOUS_DATA_SUCCESS: {
      const modifiedData = {
        ...action.data,
        temperature:
          action.data.temperature && Number.isInteger(Number(action.data.temperature)) ? `${action.data.temperature}.0` : action.data.temperature,
      };

      return {
        ...state,
        previousLoading: false,
        previousData: modifiedData,
      };
    }
    case VitalActionTypes.GET_VITAL_PREVIOUS_DATA_NOT_FOUND: {
      return {
        ...state,
        previousLoading: false,
        previousData: null,
      };
    }
    case VitalActionTypes.GET_VITAL_PREVIOUS_DATA_ERROR: {
      showToast("error", t("message.前回の記録の取得に失敗しました"));
      return {
        ...state,
        previousLoading: false,
        previousData: null,
      };
    }

    case VitalActionTypes.CREATE_VITAL_REQUEST: {
      return {
        ...state,
        formLoading: true,
        formData: null,
      };
    }
    case VitalActionTypes.CREATE_VITAL_SUCCESS: {
      showToast("success", t("message.保存されました"));
      return {
        ...state,
        formLoading: false,
        formData: action.data,
      };
    }
    case VitalActionTypes.CREATE_VITAL_ERROR: {
      showToast("fail", t("message.保存に失敗しました"));
      return {
        ...state,
        formLoading: false,
        formData: null,
      };
    }

    case VitalActionTypes.UPDATE_VITAL_REQUEST: {
      return {
        ...state,
        formLoading: true,
        formData: null,
      };
    }
    case VitalActionTypes.UPDATE_VITAL_SUCCESS: {
      showToast("success", t("message.保存されました"));
      return {
        ...state,
        formLoading: false,
        formData: action.data,
      };
    }

    case VitalActionTypes.UPDATE_VITAL_ERROR: {
      showToast("fail", t("message.保存に失敗しました"));
      return {
        ...state,
        formLoading: false,
        formData: null,
      };
    }

    case VitalActionTypes.RESET_VITAL_DATA: {
      return {
        ...state,
        formData: null,
        listDatas: [],
      };
    }

    default: {
      return { ...state };
    }
  }
}

export default vital;
