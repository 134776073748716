import { combineReducers } from "redux";
import getAvatarReducers from "../../reuse/reducers/getAvatarReducers/getAvatarReducers";
import kanriReducers from "../../reuse/reducers/kanriReducers/kanriReducers";
import messageListReducers from "../../reuse/reducers/messageListReducers/messageListReducers";
import progressReducers from "../../reuse/reducers/progressReducers/progressReducers";
import userListReducers from "../../reuse/reducers/userListReducers/userListReducers";
import authmobile from "./authmobile";
import common from "./common";
import config from "./config";
import error from "./error";
import nyutaisyo from "./nyutaisyo";
import vital from "./vital";
import meal from "./meal";
import suibun from "./suibun";
import haisetu from "./haisetu";

const rootReducer = combineReducers({
  error,
  config,
  authmobile,
  common,
  nyutaisyo,
  vital,
  meal,
  suibun,
  haisetu,
  getAvatar: getAvatarReducers,
  messageList: messageListReducers,
  progress: progressReducers,
  kanri: kanriReducers,
  userList: userListReducers,
});

export default rootReducer;
