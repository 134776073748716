import { SuibunActionTypes } from "../action_types";
import { showToast } from "../../common/function_common/functionCommon";
import i18n from "../../i18n/config";

const initState = {
  loading: false,
  listDataLoading: false,
  settingLoading: false,
  formLoading: false,
  yougoLoading: false,
  listDatas: [],
  formData: null,
  settingMaster: null,
  yougoMaster: null,
};

function suibun(state = initState, action) {
  const { t } = i18n;

  switch (action.type) {
    case SuibunActionTypes.LOAD_SPIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case SuibunActionTypes.GET_SUIBUN_RECORDS_REQUEST: {
      return {
        ...state,
        listDataLoading: true,
      };
    }

    case SuibunActionTypes.GET_SUIBUN_RECORDS_SUCCESS: {
      return {
        ...state,
        listDataLoading: false,
        listDatas: action.data,
      };
    }

    case SuibunActionTypes.GET_SUIBUN_RECORDS_ERROR: {
      return {
        ...state,
        listDataLoading: false,
        listDatas: [],
      };
    }

    case SuibunActionTypes.GET_SUIBUN_SETTING_MASTER_REQUEST: {
      return {
        ...state,
        settingLoading: true,
        settingMaster: null,
      };
    }
    case SuibunActionTypes.GET_SUIBUN_SETTING_MASTER_SUCCESS: {
      return {
        ...state,
        settingLoading: false,
        settingMaster: action.data,
      };
    }
    case SuibunActionTypes.GET_SUIBUN_SETTING_MASTER_ERROR: {
      return {
        ...state,
        settingLoading: false,
        settingMaster: null,
      };
    }

    case SuibunActionTypes.GET_SUIBUN_YOUGO_MASTER_REQUEST: {
      return {
        ...state,
        yougoLoading: true,
      };
    }
    case SuibunActionTypes.GET_SUIBUN_YOUGO_MASTER_SUCCESS: {
      const yougoMaster = action.data?.naiyou?.split(";").map((value, index) => ({ key: index, naiyou: value })) || [];

      return {
        ...state,
        yougoLoading: false,
        yougoMaster,
      };
    }
    case SuibunActionTypes.GET_SUIBUN_YOUGO_MASTER_ERROR: {
      return {
        ...state,
        yougoLoading: false,
        yougoMaster: null,
      };
    }

    case SuibunActionTypes.GET_TOTAL_SUIBUN_REQUEST: {
      return {
        ...state,
        totalSuibunLoading: true,
        totalSuibun: null,
      };
    }
    case SuibunActionTypes.GET_TOTAL_SUIBUN_SUCCESS: {
      return {
        ...state,
        totalSuibunLoading: false,
        totalSuibun: action.data,
      };
    }
    case SuibunActionTypes.GET_TOTAL_SUIBUN_NOT_FOUND: {
      return {
        ...state,
        totalSuibunLoading: false,
        totalSuibun: null,
      };
    }
    case SuibunActionTypes.GET_TOTAL_SUIBUN_ERROR: {
      showToast("fail", t("message.前回の記録の取得に失敗しました"));
      return {
        ...state,
        totalSuibunLoading: false,
        totalSuibun: null,
      };
    }

    case SuibunActionTypes.CREATE_SUIBUN_REQUEST: {
      return {
        ...state,
        formLoading: true,
        formData: null,
      };
    }
    case SuibunActionTypes.CREATE_SUIBUN_SUCCESS: {
      showToast("success", t("message.保存されました"));
      return {
        ...state,
        formLoading: false,
        formData: action.data,
      };
    }
    case SuibunActionTypes.CREATE_SUIBUN_NOT_SYURUI: {
      showToast("fail", t("message.種類が未選択です"));
      return {
        ...state,
        formLoading: false,
      };
    }
    case SuibunActionTypes.CREATE_SUIBUN_ERROR: {
      showToast("fail", t("message.保存に失敗しました"));
      return {
        ...state,
        formLoading: false,
      };
    }

    case SuibunActionTypes.UPDATE_SUIBUN_REQUEST: {
      return {
        ...state,
        formLoading: true,
        formData: null,
      };
    }
    case SuibunActionTypes.UPDATE_SUIBUN_SUCCESS: {
      showToast("success", t("message.保存されました"));
      return {
        ...state,
        formLoading: false,
        formData: action.data,
      };
    }

    case SuibunActionTypes.UPDATE_SUIBUN_ERROR: {
      showToast("fail", t("message.保存に失敗しました"));
      return {
        ...state,
        formLoading: false,
        formData: null,
      };
    }

    case SuibunActionTypes.RESET_SUIBUN_DATA: {
      return {
        ...state,
        formData: null,
        listDatas: [],
      };
    }

    default: {
      return { ...state };
    }
  }
}

export default suibun;
