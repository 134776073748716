import { ConfigProvider } from "antd";
import jaJP from "antd/es/locale/ja_JP";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import React from "react";
import { Provider } from "react-redux";
import App from "../mobile/App";
import store from "../mobile/redux/store";
import "../mobile/i18n/config"; // i18n
dayjs.locale("ja");

function MobileLayout() {
  const [primary] = React.useState("#00838F");

  return (
    <Provider store={store}>
      <ConfigProvider
        prefixCls="pljp"
        locale={jaJP}
        theme={{
          hashed: false,
          token: {
            colorPrimary: primary,
            colorLink: primary,
            fontFamily: "KozGo !important",
            colorBgContainerDisabled: "#f5f5f5",
          },
          components: {
            Modal: {
              wireframe: true, // here
            },
            Table: {
              borderColor: "#cccccc",
            },
          },
        }}
      >
        <App />
      </ConfigProvider>
    </Provider>
  );
}
export default MobileLayout;
