export const AuthActionTypes = {
  MOBILE_SIGNIN_REQUEST: "MOBILE_SIGNIN_REQUEST",
  MOBILE_SIGNIN_SUCCESS: "MOBILE_SIGNIN_SUCCESS",
  MOBILE_SIGNIN_FAILURE: "MOBILE_SIGNIN_FAILURE",
  SIGNOUT: "SIGNOUT",
  MOBILE_MAINTAINANCE_MODE_REQUEST: "MOBILE_MAINTAINANCE_MODE_REQUEST",
  MOBILE_MAINTAINANCE_MODE_SUCCESS: "MOBILE_MAINTAINANCE_MODE_SUCCESS",
  MOBILE_MAINTAINANCE_MODE_FAILURE: "MOBILE_MAINTAINANCE_MODE_FAILURE",
  MOBILE_GET_MENU: "MOBILE_GET_MENU",
  MOBILE_HANDLE_MENU: "MOBILE_HANDLE_MENU",
};
