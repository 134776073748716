import { YakuzaiActionTypes } from "../action_types";
import { openNotificationWithIcon } from "../../common/function_common/functionCommon";
import { riyouAddSortDel } from "../../common/function_common/functionCommon.js";

const INITIAL_STATE = {
  // yakuzaiChangeDetail: [{ changeStatus: "11" }],
  yakuzaiChangeDetail: [{ changeStatus: "" }],
  yakuzaiChangeKioureki: [{}],
};

function yakuzai(state = {}, action) {
  switch (action.type) {
    case YakuzaiActionTypes.LOAD_SPIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case YakuzaiActionTypes.YAKUZAI_LIFE_YOUGO_SUCCESS: {
      return {
        ...state,
        loading: false,
        YakuzaiChangeLifeYougo: action.data,
      };
    }
    case YakuzaiActionTypes.YAKUZAI_LIFE_YOUGO_ERROR: {
      return {
        ...state,
        loading: false,
        YakuzaiChangeLifeYougo: null,
      };
    }

    case YakuzaiActionTypes.LOAD_YAKUZAI_SUCCESS: {
      if (action.data.yakuzaiChangeDetail.length <= 0) {
        action.data.yakuzaiChangeDetail = [{ changeStatus: "11" }];
      }
      if (action.data.yakuzaiChangeKioureki.length <= 0) {
        action.data.yakuzaiChangeKioureki = [{}];
      }
      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }
    case YakuzaiActionTypes.LOAD_YAKUZAI_ERROR: {
      return {
        ...state,
        loading: false,
        data: null,
      };
    }

    case YakuzaiActionTypes.SAVE_YAKUZAI_SUCCESS: {
      openNotificationWithIcon("success", "保存しました");
      if (action.data.yakuzaiChangeDetail) {
        if (action.data.yakuzaiChangeDetail.length <= 0) {
          action.data.yakuzaiChangeDetail = [{ changeStatus: "11" }];
        }
      } else if (!action.data.yakuzaiChangeDetail) {
        action.data.yakuzaiChangeDetail = [{ changeStatus: "11" }];
      }
      if (action.data.yakuzaiChangeKioureki) {
        if (action.data.yakuzaiChangeKioureki.length <= 0) {
          action.data.yakuzaiChangeKioureki = [{}];
        }
      } else if (!action.data.yakuzaiChangeKioureki) {
        action.data.yakuzaiChangeKioureki = [{}];
      }

      return {
        ...state,
        loading: false,
        data: action.data,
      };
    }
    case YakuzaiActionTypes.SAVE_YAKUZAI_ERROR: {
      return {
        ...state,
        loading: false,
        data: null,
      };
    }

    case YakuzaiActionTypes.LOAD_YAKUZAI_TO_QUOTE_SUCCESS: {
      return {
        ...state,
        yakuzaiChangeDetails: action.data,
      };
    }
    case YakuzaiActionTypes.LOAD_YAKUZAI_TO_QUOTE: {
      openNotificationWithIcon("success", "前回引用を実施しました");
      if (action.data.yakuzaiChangeDetail.length <= 0) {
        action.data.yakuzaiChangeDetail = [{ changeStatus: "11" }];
      }
      if (action.data.yakuzaiChangeKioureki.length <= 0) {
        action.data.yakuzaiChangeKioureki = [{}];
      }
      return {
        ...state,
        data: action.data,
      };
    }

    case YakuzaiActionTypes.SET_NEW_DATA: {
      return {
        ...state,
        data: INITIAL_STATE,
      };
    }

    case YakuzaiActionTypes.GET_LIST_IDS_YAKUZAI: {
      return {
        ...state,
        loading: false,
        yakuzaiChangeIds: action.ids,
      };
    }

    case YakuzaiActionTypes.FORM_INPUT_ADD: {
      return {
        ...state,
        data: riyouAddSortDel(
          state.data,
          action.params.type,
          "add",
          null,
          action.params.data
        ), //indexをステートデータに変える
      };
    }
    case YakuzaiActionTypes.FORM_INPUT_DELETE: {
      return {
        ...state,
        data: riyouAddSortDel(
          state.data,
          action.params.type,
          "del",
          action.params.index,
          action.params.data
        ),
      };
    }

    case YakuzaiActionTypes.GET_INITIAL_DATA_SUCCESS: {
      return {
        ...state,
        initialData: action.payload,
        loading: false,
        error: null,      
      }
    }

    default: {
      return { ...state };
    }
  }
}

export default yakuzai;
