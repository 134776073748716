import { lazy } from "react";

// Rihabili
const RihabiriContainer = lazy(() =>
  import("../pages/Rehabili/RihabiriContainer").then((module) => ({
    default: module.RihabiriContainer,
  }))
);

const RihabiriRiyouContainer = lazy(() =>
  import("../pages/Rehabili/RihabiriContainer").then((module) => ({
    default: module.RihabiriRiyouContainer,
  }))
);

const DetailLifeContainer = lazy(() =>
  import("../pages/Rehabili/RihabiriContainer").then((module) => ({
    default: module.DetailLifeContainer,
  }))
);

const DetailLifev3Container = lazy(() =>
  import("../pages/Rehabili/RihabiriContainer").then((module) => ({
    default: module.DetailLifev3Container
  }))
);

const DetailNAContainer = lazy(() =>
  import("../pages/Rehabili/RihabiriContainer").then((module) => ({
    default: module.DetailNAContainer,
  }))
);

const DetailPRContainer = lazy(() =>
  import("../pages/Rehabili/RihabiriContainer").then((module) => ({
    default: module.DetailPRContainer,
  }))
);

const DetailTKContainer = lazy(() =>
  import("../pages/Rehabili/RihabiriContainer").then((module) => ({
    default: module.DetailTKContainer,
  }))
);

const AddEditLifeContainer = lazy(() =>
  import("../pages/Rehabili/RihabiriContainer").then((module) => ({
    default: module.AddEditLifeContainer,
  }))
);

const AddEditNAContainer = lazy(() =>
  import("../pages/Rehabili/RihabiriContainer").then((module) => ({
    default: module.AddEditNAContainer,
  }))
);

const AddEditPRContainer = lazy(() =>
  import("../pages/Rehabili/RihabiriContainer").then((module) => ({
    default: module.AddEditPRContainer,
  }))
);

const AddEditTKContainer = lazy(() =>
  import("../pages/Rehabili/RihabiriContainer").then((module) => ({
    default: module.AddEditTKContainer,
  }))
);

// LIFE
const LifeList = lazy(() => import("../pages/Life/list"));
const LifeTabs = lazy(() => import("../pages/Life/tabs"));
const ErrorCSV = lazy(() => import("../pages/Life/errorCSV"));
const ViewPrintLife = lazy(() => import("../pages/Life/view-print"));

const KagakutekiAdd = lazy(() => import("../pages/KagakuTekiTab/input"));
const KagakutekiDetail = lazy(() => import("../pages/KagakuTekiTab/detail"));
const KagakutekiRiyouBetsu = lazy(() => import("../pages/KagakuTekiTab/riyou"));

const JokusoPlanInput = lazy(() => import("../pages/JokusoPlan/input"));
const JokusoPlanView = lazy(() => import("../pages/JokusoPlan/view"));
const JokusoPlanRiyouBetsu = lazy(() => import("../pages/JokusoPlan/riyou"));
const JokusoPlanViewPDFmeDesign = lazy(() => import("../common/PDFme"));
// const JokusoPlanViewPDFmeView = lazy(() => import("../common/PDFme/FormAndViewer"));

const KobetsuAdd = lazy(() => import("../pages/KobetsuTab/input"));
const KobetsuDetail = lazy(() => import("../pages/KobetsuTab/detail"));
const KobetsuRiyouBetsu = lazy(() => import("../pages/KobetsuTab/riyou"));

const HaisetsuAdd = lazy(() => import("../pages/HaisetsuTab/input"));
const HaisetsuDetail = lazy(() => import("../pages/HaisetsuTab/detail"));
const HaisetsuRiyouBetsu = lazy(() => import("../pages/HaisetsuTab/riyou"));

const KokuAdd = lazy(() => import("../pages/KoKuTab/input"));
const KokuDetail = lazy(() => import("../pages/KoKuTab/detail"));
const KokuDetailv2 = lazy(() => import("../pages/KoKuTab/detailv2"));
const KokuRiyouBetsu = lazy(() => import("../pages/KoKuTab/riyou"));

const JirituSokusinAdd = lazy(() => import("../pages/JirituSokusinTab/input"));
const JirituSokusinDetail = lazy(() =>
  import("../pages/JirituSokusinTab/detail")
);
const JirituSokusinDetailv2 = lazy(() => import("../pages/JirituSokusinTab/detailv2"));
const JirituSokusinRiyouBetsu = lazy(() =>
  import("../pages/JirituSokusinTab/riyou")
);

const YakuzaiChangeAdd = lazy(() => import("../pages/YakuzaiTab/input"));
const YakuzaiChangeDetail = lazy(() => import("../pages/YakuzaiTab/detail"));
const YakuzaiChangeRiyouBetsu = lazy(() => import("../pages/YakuzaiTab/riyou"));

// Adlのものを追加
const AdlAdd = lazy(() => import("../pages/AdlTab/input"));
const AdlDetail = lazy(() => import("../pages/AdlTab/detail"));
const AdlRiyouBetsu = lazy(() => import("../pages/AdlTab/riyou"));


// dengon
// const DengonContainer = lazy(() =>
//   import("../../components/dengon/dengonContainer")
// );

// riyousya
const RiyousyaContainer = lazy(() =>
  import("../../components/riyousya/riyousyaContainer").then((module) => ({
    default: module.RiyousyaContainer,
  }))
);
const RiyousyaDetailContainer = lazy(() =>
  import("../../components/riyousya/riyousyaContainer").then((module) => ({
    default: module.RiyousyaDetailContainer,
  }))
);
const RiyousyaUpdateContainer = lazy(() =>
  import("../../components/riyousya/riyousyaContainer").then((module) => ({
    default: module.RiyousyaUpdateContainer,
  }))
);
const RiyousyaHokenContainer = lazy(() =>
  import("../../components/riyousya/riyousyaContainer").then((module) => ({
    default: module.RiyousyaHokenContainer,
  }))
);
const RiyousyaShokujiContainer = lazy(() =>
  import("../../components/riyousya/riyousyaContainer").then((module) => ({
    default: module.RiyousyaShokujiContainer,
  }))
);
const RiyousyaShiryouContainer = lazy(() =>
  import("../../components/riyousyaShiryou/riyousyaShiryouContainer").then(
    (module) => ({
      default: module.RiyousyaShiryouContainer,
    })
  )
);
const InputRiyousyaShiryouContainer = lazy(() =>
  import("../../components/riyousyaShiryou/riyousyaShiryouContainer").then(
    (module) => ({
      default: module.InputRiyousyaShiryouContainer,
    })
  )
);

// progress
const ProgressRecordContainer = lazy(() =>
  import("../../components/progress/ProgressRecordContainer").then(
    (module) => ({
      default: module.ProgressRecordContainer,
    })
  )
);
const ProgressRecordRiyousyaContainer = lazy(() =>
  import("../../components/progress/ProgressRecordContainer").then(
    (module) => ({
      default: module.ProgressRecordRiyousyaContainer,
    })
  )
);
const InputReportCaseContainer = lazy(() =>
  import("../../components/progress/ProgressRecordContainer").then(
    (module) => ({
      default: module.InputReportCaseContainer,
    })
  )
);

// kanri
const KanriContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriContainer,
  }))
);
const KanriUserContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriUserContainer,
  }))
);
const KanriUserSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriUserSaveContainer,
  }))
);
const KanriSettingMasterContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriSettingMasterContainer,
  }))
);
const KanriSettingMasterSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriSettingMasterSaveContainer,
  }))
);
const KanriYougoMasterContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriYougoMasterContainer,
  }))
);
const KanriYougoMasterSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriYougoMasterSaveContainer,
  }))
);
const KanriLifeYougoMasterContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriLifeYougoMasterContainer,
  }))
);
const KanriLifeYougoMasterSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriLifeYougoMasterSaveContainer,
  }))
);
const KanriKaigoYougoMasterContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriKaigoYougoMasterContainer,
  }))
);
const KanriKaigoYougoMasterSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriKaigoYougoMasterSaveContainer,
  }))
);
const KanriTantoMasterContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriTantoMasterContainer,
  }))
);
const KanriTantoMasterSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriTantoMasterSaveContainer,
  }))
);
const KanriUserRiyousyaLinkContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriUserRiyousyaLinkContainer,
  }))
);
const KanriUserRiyousyaLinkSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriUserRiyousyaLinkSaveContainer,
  }))
);
const KanriFileContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriFileContainer,
  }))
);
const KanriFileSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriFileSaveContainer,
  }))
);
const KanriSendGroupContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriSendGroupContainer,
  }))
);
const KanriDengonDataContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriDengonDataContainer,
  }))
);
const KanriDengonDataSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriDengonDataSaveContainer,
  }))
);
const KanriMoshiokuriLinkContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriMoshiokuriLinkContainer,
  }))
);
const KanriMoshiokuriLinkSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriMoshiokuriLinkSaveContainer,
  }))
);
const KanriOpenScreenLogContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriOpenScreenLogContainer,
  }))
);
const KanriOpenScreenLogSaveContainer = lazy(() =>
  import("../../components/kanri/KanriContainer").then((module) => ({
    default: module.KanriOpenScreenLogSaveContainer,
  }))
);

// logout
const LogoutContainer = lazy(() =>
  import("../../components/logout/logoutContainer").then((module) => ({
    default: module.LogoutContainer,
  }))
);

// user-management
const UserManagementContainer = lazy(() =>
  import("../../components/userManagement/UserManagementContainer").then(
    (module) => ({
      default: module.UserManagementContainer,
    })
  )
);
const InputUserManagement = lazy(() =>
  import("../../components/userManagement/UserManagementContainer").then(
    (module) => ({
      default: module.InputUserManagement,
    })
  )
);
const SendGroupContainer = lazy(() =>
  import("../../components/sendGroup/SendGroupContainer").then((module) => ({
    default: module.SendGroupContainer,
  }))
);

const routes = [
  // RIHABILI
  {
    path: "/life/rehabili",
    component: RihabiriContainer,
  },

  {
    path: "/life/rehabili/:riyousyaId",
    component: RihabiriRiyouContainer,
  },

  {
    path: "/life/rehabili/detail-life/:riyousyaId/:id",
    component: DetailLifeContainer,
  },

  {
    path: "/life/rehabili/detail-life-v3/:riyousyaId/:id",
    component: DetailLifev3Container,
  },

  {
    path: "/life/rehabili/detail-na/:riyousyaId/:kyomiId",
    component: DetailNAContainer,
  },

  {
    path: "/life/rehabili/detail-pr/:riyousyaId/:processId",
    component: DetailPRContainer,
  },

  {
    path: "/life/rehabili/detail-tk/:riyousyaId/:tantoKaigiId",
    component: DetailTKContainer,
  },

  {
    path: "/life/rehabili/add-edit-life/:riyousyaId/:id",
    component: AddEditLifeContainer,
  },

  {
    path: "/life/rehabili/add-edit-na/:riyousyaId/:id",
    component: AddEditNAContainer,
  },

  {
    path: "/life/rehabili/add-edit-pr/:riyousyaId/:id",
    component: AddEditPRContainer,
  },

  {
    path: "/life/rehabili/add-edit-tk/:riyousyaId/:id",
    component: AddEditTKContainer,
  },

  // Main Life Components
  {
    path: "/life",
    component: LifeList,
  },
  {
    path: "/life/kobetsu-add-edit/:riyousyaId?/:id?/:fromDate?/:toDate?",
    component: KobetsuAdd,
  },

  {
    path: "/life/kobetsu-detail/:riyousyaId?/:id?/:fromDate?/:toDate?",
    component: KobetsuDetail,
  },

  {
    path: "/life/kobetsu/:riyousyaId",
    component: KobetsuRiyouBetsu,
  },

  // {
  //   path: "/life/dengon",
  //   component: DengonContainer,
  // },

  // List
  {
    path: "/life/list",
    component: LifeList,
  },

  {
    path: "/life/list/:riyousyaId",
    component: LifeTabs,
  },
  // csv未出力表示
  {
    path: "/life/errorCSV/:si?/:fromDate?/:toDate?/:tab?/:ids?/:yobouSid?/:idouSid?/:sn?/:kokuType?",
    component: ErrorCSV,
  },
  {
    path: "/life/viewPDF/:ids",
    component: ViewPrintLife,
  },

  {
    path: "/life/input-jokuso-plan/:riyousyaId/:jokusoPlanId",
    component: JokusoPlanInput,
  },

  {
    path: "/life/view-jokuso-plan/:riyousyaId/:jokusoPlanId",
    component: JokusoPlanView,
  },

  {
    path: "/life/:screen/:riyousyaId/:id/pdfmeDesign/:pdfId",
    component: JokusoPlanViewPDFmeDesign,
  },
  // {
  //   path: "/life/view-jokuso-plan/:riyousyaId/:jokusoPlanId/pdfmeView",
  //   component: JokusoPlanViewPDFmeView,
  // },
  {
    path: "/life/kagakuteki-add-edit/:riyousyaId?/:id?",
    component: KagakutekiAdd,
  },
  {
    path: "/life/kagakuteki-detail/:riyousyaId?/:id?",
    component: KagakutekiDetail,
  },
  {
    path: "/life/kagakuteki/:riyousyaId",
    component: KagakutekiRiyouBetsu,
  },
  {
    path: "/life/jokuso/:riyousyaId",
    component: JokusoPlanRiyouBetsu,
  },
  {
    path: "/life/haisetsu-add-edit/:riyousyaId?/:id?",
    component: HaisetsuAdd,
  },

  {
    path: "/life/haisetsu-detail/:riyousyaId?/:id?",
    component: HaisetsuDetail,
  },

  {
    path: "/life/haisetsu/:riyousyaId",
    component: HaisetsuRiyouBetsu,
  },

  // koku
  {
    path: "/life/koku-detail/:riyousyaId?/:id?",
    component: KokuDetail,
  },

  {
    path: "/life/koku-detail-v2/:riyousyaId?/:id?",
    component: KokuDetailv2,
  },

  {
    path: "/life/koku/:riyousyaId",
    component: KokuRiyouBetsu,
  },

  {
    path: "/life/koku-add-edit/:riyousyaId?/:id?",
    component: KokuAdd,
  },

  {
    path: "/life/jiritu-add-edit/:riyousyaId?/:id?",
    component: JirituSokusinAdd,
  },

  {
    path: "/life/jiritu-detail/:riyousyaId?/:id?",
    component: JirituSokusinDetail,
  },

  {
    path: "/life/jiritu-detailv2/:riyousyaId?/:id?",
    component: JirituSokusinDetailv2,
  },

  {
    path: "/life/jiritu/:riyousyaId",
    component: JirituSokusinRiyouBetsu,
  },

  {
    path: "/life/yakuzai-add-edit/:riyousyaId?/:id?",
    component: YakuzaiChangeAdd,
  },

  {
    path: "/life/yakuzai-detail/:riyousyaId?/:id?",
    component: YakuzaiChangeDetail,
  },

  {
    path: "/life/yakuzai/:riyousyaId",
    component: YakuzaiChangeRiyouBetsu,
  },

  // Adlのものを追加
  {
    path: "/life/adl-add-edit/:riyousyaId?/:id?",
    component: AdlAdd,
  },

  {
    path: "/life/adl-detail/:riyousyaId?/:id?",
    component: AdlDetail,
  },

  {
    path: "/life/adl/:riyousyaId",
    component: AdlRiyouBetsu,
  },


  // riyousya
  {
    path: "/life/riyousya",
    component: RiyousyaContainer,
  },
  {
    path: "/life/riyousya-detail/:riyousyaId",
    component: RiyousyaDetailContainer,
  },
  {
    path: "/life/riyousya-update/:riyousyaId",
    component: RiyousyaUpdateContainer,
  },
  {
    path: "/life/riyousya-hoken/:riyousyaId",
    component: RiyousyaHokenContainer,
  },
  {
    path: "/life/riyousya-shokuji/:riyousyaId",
    component: RiyousyaShokujiContainer,
  },

  // riyousya-shiryou
  {
    path: "/life/riyousya-shiryou/:riyousyaId",
    component: RiyousyaShiryouContainer,
  },
  {
    path: "/life/riyousya-shiryou-input/:riyousyaId/:fileManagementId",
    component: InputRiyousyaShiryouContainer,
  },

  // progress
  {
    path: "/life/progress",
    component: ProgressRecordContainer,
  },
  {
    path: "/life/progress/:riyousyaId",
    component: ProgressRecordRiyousyaContainer,
  },
  {
    path: "/life/input-report-case/:riyousyaId/:reportCaseId",
    component: InputReportCaseContainer,
  },

  // kanri
  {
    path: "/life/kanri",
    component: KanriContainer,
  },
  {
    path: "/life/kanri/dashboard",
    component: KanriContainer,
  },
  {
    path: "/life/kanri/user",
    component: KanriUserContainer,
  },
  {
    path: "/life/kanri/user/:userId",
    component: KanriUserSaveContainer,
  },
  {
    path: "/life/kanri/userriyousyalink",
    component: KanriUserRiyousyaLinkContainer,
  },
  {
    path: "/life/kanri/userriyousyalink/:urlId",
    component: KanriUserRiyousyaLinkSaveContainer,
  },
  {
    path: "/life/kanri/tantomaster",
    component: KanriTantoMasterContainer,
  },
  {
    path: "/life/kanri/tantomaster/:tmId",
    component: KanriTantoMasterSaveContainer,
  },
  {
    path: "/life/kanri/settingmaster",
    component: KanriSettingMasterContainer,
  },
  {
    path: "/life/kanri/settingmaster/:smId",
    component: KanriSettingMasterSaveContainer,
  },
  {
    path: "/life/kanri/yougomaster",
    component: KanriYougoMasterContainer,
  },
  {
    path: "/life/kanri/yougomaster/:ymId",
    component: KanriYougoMasterSaveContainer,
  },
  {
    path: "/life/kanri/lifeyougomaster",
    component: KanriLifeYougoMasterContainer,
  },
  {
    path: "/life/kanri/lifeyougomaster/:lymId",
    component: KanriLifeYougoMasterSaveContainer,
  },
  {
    path: "/life/kanri/kaigoyougomaster",
    component: KanriKaigoYougoMasterContainer,
  },
  {
    path: "/life/kanri/kaigoyougomaster/:kymId",
    component: KanriKaigoYougoMasterSaveContainer,
  },
  {
    path: "/life/kanri/dengondata",
    component: KanriDengonDataContainer,
  },
  {
    path: "/life/kanri/dengondata/:dengonId",
    component: KanriDengonDataSaveContainer,
  },
  {
    path: "/life/kanri/moshiokurilink",
    component: KanriMoshiokuriLinkContainer,
  },
  {
    path: "/life/kanri/moshiokurilink/:moshiokuriId",
    component: KanriMoshiokuriLinkSaveContainer,
  },
  {
    path: "/life/kanri/file",
    component: KanriFileContainer,
  },
  {
    path: "/life/kanri/file/:fileId",
    component: KanriFileSaveContainer,
  },
  {
    path: "/life/kanri/sendgroup",
    component: KanriSendGroupContainer,
  },
  {
    path: "/life/kanri/openscreenlog",
    component: KanriOpenScreenLogContainer,
  },
  {
    path: "/life/kanri/openscreenlog/:openId",
    component: KanriOpenScreenLogSaveContainer,
  },

  // logout
  {
    path: "/life/logout",
    component: LogoutContainer,
  },

  // user-management
  {
    path: "/life/user-management",
    component: UserManagementContainer,
  },
  {
    path: "/life/user-management/:userId",
    component: InputUserManagement,
  },
  {
    path: "/life/send-group",
    component: SendGroupContainer,
  },
];

export default routes.map((route) => {
  route.type = "private";
  return route;
});
