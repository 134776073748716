export const CommonActionTypes = {
  GET_ROOM_BY_URL: "GET_ROOM_BY_URL",

  GET_YOUGO_MASTER_REQUEST: "GET_YOUGO_MASTER_REQUEST",
  GET_YOUGO_MASTER_SUCCESS: "GET_YOUGO_MASTER_SUCCESS",
  GET_YOUGO_MASTER_ERROR: "GET_YOUGO_MASTER_ERROR",

  UPDATE_YOUGO_MASTER_REQUEST: "UPDATE_YOUGO_MASTER_REQUEST",
  UPDATE_YOUGO_MASTER_SUCCESS: "UPDATE_YOUGO_MASTER_SUCCESS",
  UPDATE_YOUGO_MASTER_ERROR: "UPDATE_YOUGO_MASTER_ERROR",

  GET_SETTING_MASTER_ALL_REQUEST: "GET_SETTING_MASTER_ALL_REQUEST",
  GET_SETTING_MASTER_ALL_SUCCESS: "GET_SETTING_MASTER_ALL_SUCCESS",
  GET_SETTING_MASTER_ALL_ERROR: "GET_SETTING_MASTER_ALL_ERROR",
};
