export const AdlActionTypes = {

  SET_NEW_DATA: "SET_NEW_DATA",
  GET_FIRST_LOAD_DATA: "GET_FIRST_LOAD_DATA",
  GET_LIST_IDS_KAGAKUTEKI: "GET_LIST_IDS_KAGAKUTEKI",

  // GET_NEW_DATA: "GET_NEW_DATA",

  GET_ADL_DATA_REQUEST: "GET_ADL_DATA_REQUEST",
  GET_ADL_DATA_SUCCESS: "GET_ADL_DATA_SUCCESS",
  SAVE_ADL_DATA_REQUEST: "SAVE_ADL_DATA_REQUEST",
  SAVE_ADL_DATA_SUCCESS: "SAVE_ADL_DATA_SUCCESS",
  REMOVE_ADL_REQUEST: "REMOVE_ADL_REQUEST",
  REMOVE_ADL_SUCCESS: "REMOVE_ADL_SUCCESS",
  REMOVE_ADL_ERROR: "REMOVE_ADL_ERROR",
  LOAD_ADL_TO_QUOTE_REQUEST: "LOAD_ADL_TO_QUOTE_REQUEST",
  LOAD_ADL_TO_QUOTE_SUCCESS: "LOAD_ADL_TO_QUOTE_SUCCESS",
  CHECK_TAISHO_REQUEST: "CHECK_TAISHO_REQUEST",
  CHECK_TAISHO_SUCCESS: "CHECK_TAISHO_SUCCESS",
}