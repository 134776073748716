import { STATE_SUBSIDY_SAVE_TO_LOCAL } from "../../pages/SubsidyManagement/constants";
import { StorageUtils } from "../../utils";
import { subsidyManagementActionTypes } from "../action_types";
import jwtDecode from "jwt-decode";

const initialState = () => {
    const subsidyManagementState = StorageUtils.getValue(STATE_SUBSIDY_SAVE_TO_LOCAL) || {};
    const tokens = StorageUtils.getValue("tokens") && StorageUtils.getValue("tokens").token;
    let decoded = null;
    if (tokens) {
        decoded = jwtDecode(tokens);
    }
    const tantoId = !subsidyManagementState?.tantoId ? subsidyManagementState?.tantoId : decoded?.userInfo?.tidCopy + "";

    return {
        seikyuNengetu: "",
        planNengetu: "",
        changeDate: "",
        tantoId: tantoId || null,
        mode: "",
        selectedRow: {},
        planSort: {},
        seikyuSort: {},
        searchName: ""
    }
};

const subsidyManagementReducer = (state = initialState(), action) => {
    switch (action.type) {
        case subsidyManagementActionTypes.SAVE_FILTER_TANTO_ID:
            return { ...state, tantoId: action.params };
        case subsidyManagementActionTypes.GET_LIST_SUBSIDY:
            return { ...state, loading: true };
        case subsidyManagementActionTypes.GET_LIST_SUBSIDY_SUCCESS:
            return { ...state, subsidyList: action, loading: false };
        case subsidyManagementActionTypes.GET_LIST_SUBSIDY_FAILURE:
            return { ...state, subsidyList: action, loading: false };

        case subsidyManagementActionTypes.REDIRECT_TO_TICKET:
            return { ...state };
        case subsidyManagementActionTypes.REDIRECT_TO_TICKET_SUCCESS:
            return { ...state, redirectToTicket: action };
        case subsidyManagementActionTypes.REDIRECT_TO_TICKET_FAILURE:
            return { ...state, redirectToTicket: action };

        case subsidyManagementActionTypes.UPDATE_SUBSIDY:
            return { ...state };
        case subsidyManagementActionTypes.UPDATE_SUBSIDY_SUCCESS:
            return { ...state, updateSubsidy: action };
        case subsidyManagementActionTypes.UPDATE_SUBSIDY_ERROR:
            return { ...state, errorData: action.errorData };
        case subsidyManagementActionTypes.UPDATE_SUBSIDY_FAILURE:
            return { ...state, updateSubsidy: action };
        case subsidyManagementActionTypes.CLEAR_ERROR_DATA:
            return { ...state, errorData: [] };

        case subsidyManagementActionTypes.CREATE_GRANT_DETAILS:
            return { ...state };
        case subsidyManagementActionTypes.CREATE_GRANT_DETAILS_SUCCESS:
            return { ...state, createDetails: action };
        case subsidyManagementActionTypes.CREATE_GRANT_DETAILS_FAILURE:
            return { ...state, createDetails: action };

        case subsidyManagementActionTypes.RESET_CREATE_DETAILS:
            return { ...state, createDetails: { message: "" } }

        case subsidyManagementActionTypes.CREATE_MULTI_DATE:
            return { ...state };
        case subsidyManagementActionTypes.CREATE_MULTI_DATE_SUCCESS:
            return { ...state, createMultiDate: action };
        case subsidyManagementActionTypes.CREATE_MULTI_DATE_FAILURE:
            return { ...state, createMultiDate: action };

        case subsidyManagementActionTypes.GET_LIST_KAIGOHOKEN_MASTER:
            return { ...state };
        case subsidyManagementActionTypes.GET_LIST_KAIGOHOKEN_MASTER_SUCCESS:
            return { ...state, kaigoHokenMasterApi: action.data };
        case subsidyManagementActionTypes.GET_LIST_KAIGOHOKEN_MASTER_ERROR:
            return { ...state, kaigoHokenMasterApi: action };

        case subsidyManagementActionTypes.GET_KAIGOHOKEN_MASTER_INIT:
            return { ...state };
        case subsidyManagementActionTypes.GET_KAIGOHOKEN_MASTER_INIT_SUCCESS:
            return { ...state, dataInit: action.data };
        case subsidyManagementActionTypes.GET_KAIGOHOKEN_MASTER_INIT_ERROR:
            return { ...state, dataInit: action };

        case subsidyManagementActionTypes.BENEFIT_MANAGEMENT:
            return { ...state };
        case subsidyManagementActionTypes.BENEFIT_MANAGEMENT_SUCCESS:
            return { ...state, benefitList: action };
        case subsidyManagementActionTypes.BENEFIT_MANAGEMENT_ERROR:
            return { ...state, benefitList: action };

        case subsidyManagementActionTypes.QUOTE_SUBSIDY:
            return { ...state };
        case subsidyManagementActionTypes.QUOTE_SUBSIDY_SUCCESS:
            return { ...state, quoteDataSubsidy: action };
        case subsidyManagementActionTypes.QUOTE_SUBSIDY_ERROR:
            return { ...state, quoteDataSubsidy: action };

        default:
            return state;
    }
};

export default subsidyManagementReducer;