import { showToast } from "../../common/function_common/functionCommon";
import i18n from "../../i18n/config";
import { HaisetuActionTypes } from "../action_types";

const initState = {
  loading: false,
  listDataLoading: false,
  yougoLoading: false,
  settingLoading: false,
  formLoading: false,
  lastBowelLoading: false,
  previousLoading: false,
  listDatas: [],
  formData: null,
  yougoMaster: null,
  settingMaster: null,
  lastBowelData: null,
  previousData: null,
};

function haisetu(state = initState, action) {
  const { t } = i18n;

  switch (action.type) {
    case HaisetuActionTypes.GET_HAISETU_RECORDS_REQUEST: {
      return {
        ...state,
        listDataLoading: true,
      };
    }
    case HaisetuActionTypes.GET_HAISETU_RECORDS_SUCCESS: {
      return {
        ...state,
        listDataLoading: false,
        listDatas: action.data,
      };
    }
    case HaisetuActionTypes.GET_HAISETU_RECORDS_ERROR: {
      return {
        ...state,
        listDataLoading: false,
        listDatas: [],
      };
    }

    case HaisetuActionTypes.GET_HAISETU_YOUGO_MASTER_REQUEST: {
      return {
        ...state,
        yougoLoading: true,
        yougoMaster: null,
      };
    }
    case HaisetuActionTypes.GET_HAISETU_YOUGO_MASTER_SUCCESS: {
      return {
        ...state,
        yougoLoading: false,
        yougoMaster: action.data,
      };
    }
    case HaisetuActionTypes.GET_HAISETU_YOUGO_MASTER_ERROR: {
      return {
        ...state,
        yougoLoading: false,
        yougoMaster: null,
      };
    }

    case HaisetuActionTypes.GET_HAISETU_SETTING_MASTER_REQUEST: {
      return {
        ...state,
        settingLoading: true,
        settingMaster: null,
      };
    }
    case HaisetuActionTypes.GET_HAISETU_SETTING_MASTER_SUCCESS: {
      return {
        ...state,
        settingLoading: false,
        settingMaster: action.data,
      };
    }
    case HaisetuActionTypes.GET_HAISETU_SETTING_MASTER_ERROR: {
      return {
        ...state,
        settingLoading: false,
        settingMaster: null,
      };
    }

    case HaisetuActionTypes.SAVE_HAISETU_REQUEST: {
      return {
        ...state,
        formLoading: true,
        formData: null,
      };
    }
    case HaisetuActionTypes.SAVE_HAISETU_SUCCESS: {
      showToast("success", t("message.保存されました"));
      return {
        ...state,
        formLoading: false,
        formData: action.data,
      };
    }
    case HaisetuActionTypes.SAVE_HAISETU_ERROR: {
      showToast("error", t("message.保存に失敗しました"));
      return {
        ...state,
        formLoading: false,
        formData: null,
      };
    }

    case HaisetuActionTypes.GET_HAISETU_LAST_SHIT_REQUEST: {
      return {
        ...state,
        lastBowelLoading: true,
        lastBowelData: null,
      };
    }
    case HaisetuActionTypes.GET_HAISETU_LAST_SHIT_SUCCESS: {
      if (!action.data) {
        return {
          ...state,
          lastBowelLoading: false,
        };
      }

      const lastBowelData = { date: action.data.date, amt: action.data.amt };
      return {
        ...state,
        lastBowelLoading: false,
        lastBowelData,
      };
    }
    case HaisetuActionTypes.GET_HAISETU_LAST_SHIT_ERROR: {
      return {
        ...state,
        lastBowelLoading: false,
        lastBowelData: null,
      };
    }

    case HaisetuActionTypes.GET_HAISETU_PREVIOUS_DATA_REQUEST: {
      return {
        ...state,
        previousLoading: true,
        previousData: null,
      };
    }
    case HaisetuActionTypes.GET_HAISETU_PREVIOUS_DATA_SUCCESS: {
      return {
        ...state,
        previousLoading: false,
        previousData: action.data,
      };
    }
    case HaisetuActionTypes.GET_HAISETU_PREVIOUS_DATA_NOT_FOUND: {
      return {
        ...state,
        previousLoading: false,
        previousData: null,
      };
    }
    case HaisetuActionTypes.GET_HAISETU_PREVIOUS_DATA_ERROR: {
      showToast("error", t("message.前回の記録の取得に失敗しました"));
      return {
        ...state,
        previousLoading: false,
        previousData: null,
      };
    }

    case HaisetuActionTypes.RESET_HAISETU_DATA: {
      return {
        ...state,
        formData: null,
        listDatas: [],
      };
    }

    default: {
      return { ...state };
    }
  }
}

export default haisetu;
