
export default {
  error: {
    code: null,
    message: "",
  },
  config: {
    menu: {
      // TODO set default menu here
    },
    values: {},
  },

};
