export const Routes = {
  Plan1: {
    Path: "/management/input-plan1/",
    PathView: "/management/view-plan1/",
  },
  Plan2: {
    Path: "/management/input-plan2/",
    PathView: "/management/view-plan2/",
  },
  InquiryRequest: {
    Path: "/management/input-inquiry-request/",
    PathView: "/management/view-inquiry-request/",
  },
  UseSlip: {
    Path: "/management/input-use-slip/",
    PathView: "/management/view-use-slip/",
  },
  Meeting: {
    Path: "/management/input-tanto-kaigi/",
    PathView: "/management/view-tanto-kaigi/",
  },
  Monitoring: {
    Path: "/management/input-monitoring/",
    PathView: "/management/view-monitoring/",
  },
  Progress: {
    Path: "/management/input-keika-kiroku/",
    PathView: "/management/view-keika-kiroku/",
  },
  Task: {
    Path: "/management/input-task/",
    PathView: "/management/view-task/",
    PathBrowsingScreen: "/management/browsing-screen/",
  },
  WeeklyPlan: {
    Path: "/management/input-weekly-schedule/",
    PathView: "/management/view-weekly-plan/",
  },
  Facesheet: {
    Path: "/management/input-facesheet/",
    PathView: "/management/view-facesheet/",
  },
  KihonCheck: {
    Path: "/management/input-kihon-check/",
    PathView: "/management/view-kihon-check/",
  },
  YobousienPlan: {
    Path: "/management/input-yobousien-plan/",
    PathView: "/management/view-yobousien-plan/",
  },
  Hyoukahyou: {
    Path: "/management/input-hyoukahyou/",
    PathView: "/management/view-hyoukahyou/",
  },
  DocumentTab: {
    Path: "/management/documents/",
  },
  Sonota: {
    Path: "/management/sonota/",
  },
  Kanrihyo: {
    Path: "management/subsidy-detail/"
  }
};
