/* eslint-disable no-unused-vars */
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Avatar, Button, Row } from "antd";
import jwtDecode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ConfigName } from "../../../config-name";
import { getValueLocalstorage } from "../../common/function_common/functionCommon";
import { General } from "../../constants";
import { ConfirmSignoutDialog } from "../../dialogs";
import { AuthActionTypes } from "../../redux/action_types";
import { AuthActions } from "../../redux/actions";
import { StorageUtils } from "../../utils";

function Header({ avatar, onSignout, onHandleMenu }) {
  const [visibleDialog, updateVisibledialog] = useState(false);
  const menuKiroku = StorageUtils.getValue(ConfigName.SWMOBILE_MENU);
  const formatMenu = menuKiroku && menuKiroku.menu.split(";");
  const format = formatMenu && formatMenu.map((item) => item.split(","));
  const current = StorageUtils.getValue(ConfigName.SWMOBILE_CURRENT_MENU);

  const tokensmobile = StorageUtils.getValue(ConfigName.SWMOBILE_TOKEN) && StorageUtils.getValue(ConfigName.SWMOBILE_TOKEN).token;
  let userName = "";
  if (tokensmobile && getValueLocalstorage("tid")) {
    const decoded = jwtDecode(tokensmobile);
    userName = decoded && decoded.userInfo && decoded.userInfo.name;
    const loginMode = getValueLocalstorage("mode");
    if (loginMode) {
      userName = loginMode + "_" + userName;
    }
  }

  useEffect(() => {
    if (format && current) {
      if (current != `${menuKiroku.scn}_${menuKiroku.si}`) {
        const menu = format.find((item) => item[3] == current.split("_")[0] && item[0] == current.split("_")[1]);

        if (menu) {
          onHandleMenu(menu);
        }
      }
    } else {
      StorageUtils.setValue(ConfigName.SWMOBILE_CURRENT_MENU, `${format[0][3]}_${format[0][0]}`);
    }
  }, []);

  return (
    <header className="mobile-header">
      {/* header left */}
      <div className="mobile-header__left">
        <div className="mobile-header__logo">
          <img src={General.Logo} className="mobile-header__logo" />
        </div>
      </div>

      {/* header right */}
      <div className="mobile-header__center">
        <div className="sy">{menuKiroku.sy}</div>
      </div>

      <div className="mobile-header__right">
        <div className="mobile-header__avatar">
          {avatar?.substring(0, 4) === "data" ? (
            <Avatar shape="square" size={38} icon={<UserOutlined />} src={avatar} />
          ) : (
            <Avatar shape="square" size={38} icon={<UserOutlined />} />
          )}
          <Row className="tanto-name">{userName}</Row>
        </div>

        <div className="mobile-header__logout">
          <Button icon={<LogoutOutlined />} onClick={() => updateVisibledialog(true)} />
        </div>
      </div>

      {/* confirm signout dialog ログアウトダイアログ */}
      <ConfirmSignoutDialog visible={visibleDialog} onOk={onSignout} onCancel={() => updateVisibledialog(false)} />
    </header>
  );
}
const mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSignout: () => dispatch(AuthActions.signout()),
  onHandleMenu: (values) =>
    dispatch({
      type: AuthActionTypes.MOBILE_HANDLE_MENU,
      values,
    }),
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Header));
