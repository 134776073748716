const MODE_PLAN_LIST = { SIMULTANEOUS_ACTIONS: "SIMULTANEOUS_ACTIONS_PLAN", DETAIL_OFFICE: "DETAIL_OFFICE_PLAN" };

const MODE_ACTUAL_LIST = { SIMULTANEOUS_ACTIONS: "SIMULTANEOUS_ACTIONS_ACTUAL", DETAIL_OFFICE: "DETAIL_OFFICE_ACTUAL" };

const MODE_SUPPORT_ADDITION_LIST = { SIMULTANEOUS_ACTIONS: "SIMULTANEOUS_ACTIONS_ADDITION" };

const STATUS_MODAL_LIST = {
  ON_VISIBLE_MODAL_SEARCH_OFFICE: "ON_VISIBLE_MODAL_SEARCH_OFFICE",

  ON_VISIBLE_MODAL_OFFICE_ACTION: "ON_VISIBLE_MODAL_OFFICE_ACTION",

  ON_VISIBLE_MODAL_CONFIRM_CREATE_SIMULTANEOUS_WEEK: "ON_VISIBLE_MODAL_CONFIRM_CREATE_SIMULTANEOUS_WEEK",

  ON_VISIBLE_MODAL_CONFIRM_CREATE_SIMULTANEOUS_LAST_TIME: "ON_VISIBLE_MODAL_CONFIRM_CREATE_SIMULTANEOUS_LAST_TIME",

  ON_VISIBLE_MODAL_SELECT_DATE_BY_SIMULTANEOUS_LAST_TIME: "ON_VISIBLE_MODAL_SELECT_DATE_BY_SIMULTANEOUS_LAST_TIME",

  ON_VISIBLE_MODAL_SEARCH_RIYOUSYA: "ON_VISIBLE_MODAL_SEARCH_RIYOUSYA",

  ON_VISIBLE_MODAL_CONFIRM_CREATE: "ON_VISIBLE_MODAL_CONFIRM_CREATE",

  ON_VISIBLE_MODAL_CONFIRM_DELETE: "ON_VISIBLE_MODAL_CONFIRM_DELETE",

  ON_VISIBLE_MODAL_CONFIRM_DELETE_ALL: "ON_VISIBLE_MODAL_CONFIRM_DELETE_ALL",

  ON_VISIBLE_MODAL_CONFORM_QUOTE_OF_THE_WEEK: "ON_VISIBLE_MODAL_CONFORM_QUOTE_OF_THE_WEEK",

  ON_VISIBLE_MODAL_CONFIRM_QUOTE_FROM_LAST_TIME: "ON_VISIBLE_MODAL_CONFIRM_QUOTE_FROM_LAST_TIME",

  ON_VISIBLE_MODAL_EDIT: "ON_VISIBLE_MODAL_EDIT",

  ON_VISIBLE_MODAL_SETTING: "ON_VISIBLE_MODAL_SETTING",

  ON_VISIBLE_MODAL_BATCH_CREATE: "ON_VISIBLE_MODAL_BATCH_CREATE",

  ON_VISIBLE_MODAL_SET_SUPPORT_ADDITION: "ON_VISIBLE_MODAL_SET_SUPPORT_ADDITION",

  ON_VISIBLE_MODAL_SET_SHOW_WARN: "ON_VISIBLE_MODAL_SET_SHOW_WARN",

  OFF_VISIBLE_MODAL: "OFF_VISIBLE_MODAL",

  ON_VISIBLE_MODAL_LIST_OFFICE_ACTUAL: "ON_VISIBLE_MODAL_LIST_OFFICE_ACTUAL",

  ON_VISIBLE_MODAL_PRINT: "ON_VISIBLE_MODAL_PRINT",

  OFF_VISIBLE_MODAL_PRINT: "OFF_VISIBLE_MODAL_PRINT"
};

const OPTIONS_PLAN_SORT = [
  { value: "riyousyaFurigana", display: "ふりがな" },
  { value: "riyouhyouDate", display: "作成年月日" },
  { value: "riyouhyouDouiDate", display: "同意日" },
  { value: "riyouhyouPlan", display: "給付単位数（計画）" },
  { value: "riyouhyouAdoptf", display: "採用フラグ" },
  { value: "tantoMasterFurigana", display: "担当者" },
  { value: "riyouhyouComment", display: "コメント" },
  { value: "riyouKaigohokenHokensyaNo", display: "保険者番号" },
  { value: "riyouKaigohokenHihokenNo", display: "被保険者番号" },
  { value: "riyouKaigohokenKaigodo", display: "要介護度" },
];

const OPTIONS_ACTUAL_SORT = [
  { value: "riyousyaFurigana", display: "ふりがな" },
  { value: "riyouhyouDate", display: "作成年月日" },
  { value: "riyouhyouDouiDate", display: "同意日" },
  { value: "riyouhyouPlan", display: "給付単位数（計画）" },
  {value: "jisseki", display: "給付単位数（実績)"},
  { value: "riyouhyouAdoptf", display: "採用フラグ" },
  {value: "jissekiKakuteif", display: "確定フラグ"},
  { value: "tantoMasterFurigana", display: "担当者" },
  { value: "riyouhyouCommentJisseki", display: "コメント" },
  { value: "riyouKaigohokenHokensyaNo", display: "保険者番号" },
  { value: "riyouKaigohokenHihokenNo", display: "被保険者番号" },
  { value: "riyouKaigohokenKaigodo", display: "要介護度" },
];

const OPTIONS_SUPPORT_ADDITION_LIST_SORT = [
  { value: "riyousyaFurigana", display: "ふりがな" },
  { value: "riyouhyouDate", display: "作成年月日" },
  { value: "tantoMasterFurigana", display: "担当者" },
  { value: "riyouhyouComment", display: "コメント" },
  { value: "riyouKaigohokenHokensyaNo", display: "保険者番号" },
  { value: "riyouKaigohokenHihokenNo", display: "被保険者番号" },
  { value: "riyouKaigohokenKaigodo", display: "要介護度" },
  { value: "riyouKaigohokenTodokedeDate", display: "届出月日" },
  { value: "sienhi", display: "居宅介護支援費設定" },
];
const STATE_USAGE_TICKET_SAVE_TO_LOCAL = "usageTicketManagementState";

const KEY_TAB_ACTIVE = {
  PLAN: "1",
  ACTUAL: "2",
  SUPPORT_ADDITON: "3",
};

const TAB = {
  PLAN: 0,
  ACTUAL: 1,
  SUPPORT_ADDITON: 0,
};

const isListCheckType = [
  "身体",
  "生活援助",
  "身体生活",
  "通院",
  "訪問入浴",
  "訪問看護",
  "訪問リハ",
  "通所介護",
  "通所リハ",
  "ショート",
  "訪問診療",
  "夜間訪介",
  "定期巡回",
  "レンタル",
  "その他",
];

const KAIGO_CODE_SPECIAL = ["21", "22", "23", "38", "27", "28", "68", "79", "2A"];
const RENTAL_TYPE = ["17", "71", "76", "13", "15", "78"];

const defaultWidth = {
  units300: 300,
  units240: 240,
  units220: 220,
  units200: 200,
  units190: 190,
  units180: 180,
  units150: 150,
  units140: 140,
  units120: 120,
  units110 : 110,
  units100 : 100,
  units90: 90,
  units80: 80,
  units70: 70,
  units60: 60,
  units50: 50,
  units45: 45,
  units40: 40,
  units30: 30
}

const LIST_SORT_DEFAULT = {
  key1: "0",
  value1: "asc",
  key2: "0",
  value2: "asc",
  key3: "0",
  value3: "asc",
  key4: "0",
  value4: "asc"
}

export {
  MODE_PLAN_LIST,
  STATUS_MODAL_LIST,
  OPTIONS_PLAN_SORT,
  MODE_ACTUAL_LIST,
  STATE_USAGE_TICKET_SAVE_TO_LOCAL,
  KEY_TAB_ACTIVE,
  TAB,
  MODE_SUPPORT_ADDITION_LIST,
  isListCheckType,
  KAIGO_CODE_SPECIAL,
  RENTAL_TYPE,
  defaultWidth,
  OPTIONS_ACTUAL_SORT,
  OPTIONS_SUPPORT_ADDITION_LIST_SORT,
  LIST_SORT_DEFAULT
};
