export const ConfigName = {
  // fix data storing on localhost
  ISDBTS: "isDbTs", // setting for multipe system on same domain. Allow login multiple user on same domain

  // SMILE Web Day PROJECT _ SWD
  // SWLIFE_MENU: "SWLIFE_MENU",
  SWD_IS_KEEP: "SWD_is_keep",
  SWD_DAY_KEIKA_FILTER: "SWD_dayKeikaFilter",
  SWD_LATS: "SWD_lats",
  SWD_SHOW_REPORT_LIST: "SWD_showreportlist",
  SWD_SID: "SWD_sid",
  SWD_FILTER: "SWD_filter",
  SWD_CURRENT_MENU: "SWD_currentmenu",
  SWD_RIYOU_KEIKA_FILTER: "SWD_riyouKeikaFilter",
  SWD_JWT_TOKEN: "SWD_jwtToken",
  SWD_DOUBLELOGIN: "SWD_doubleLogin",
  SWD_KIROKU_TEIKYOU_TABS: "SWD_kiroku_teikyou_tabs",
  SWD_SWPSPS: "SWD_swpsps",

  // SMILE Web Kiroku PROJECT _ SWK
  SWK_MENU: "SWK_menu",

  SWM_MENU: "menuMNGT",

  // SMILE Web LIFE PROJECT _ SWLIFE
  SWLIFE_TOKEN: "SWLIFE_token",
  SWLIFE_IS_KEEP: "SWLIFE_is_keep",
  SWLIFE_SWPSPS: "SWLIFE_swpsps",
  SWLIFE_MENU: "SWLIFE_menu",
  SWLIFE_AUTH: "SWLIFE_auth",
  SWLIFE_LISTRIYOUSYA: "SWLIFE_list_riyousya",
  SWLIFE_LISTSORTRIYOUSYA: "SWLIFE_list_sort_riyousya",
  SWLIFE_LISTROWEXPANDTAB: "SWLIFE_list_row_expand_tab",
  SWLIFE_CURRENT_MENU: "SWLIFE_currentmenu",

  // SMILE Web MOBILE PROJECT _ SWMOBILE
  SWMOBILE_TOKEN: "SWMOBILE_token",
  SWMOBILE_IS_KEEP: "SWMOBILE_is_keep",
  SWMOBILE_SWPSPS: "SWMOBILE_swpsps",
  SWMOBILE_MENU: "SWMOBILE_menu",
  SWMOBILE_AUTH: "SWMOBILE_auth",
  SWMOBILE_LISTRIYOUSYA: "SWMOBILE_list_riyousya",
  SWMOBILE_LISTSORTRIYOUSYA: "SWMOBILE_list_sort_riyousya",
  SWMOBILE_LISTROWEXPANDTAB: "SWMOBILE_list_row_expand_tab",
  SWMOBILE_CURRENT_MENU: "SWMOBILE_currentmenu",
  SWMOBILE_SETTING_MASTER: "SWMOBILE_setting_master",

  // SMILE Web Management PROJECT _ SWK
  SWL_MENU: "SWL_menu",

  ROOM_DATA: "roomData",

  DOUBLELOGIN: "doubleLogin",
};
