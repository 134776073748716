export const MealActionTypes = {
  LOAD_SPIN_REQUEST: "LOAD_SPIN_REQUEST",

  GET_MEAL_RECORDS_REQUEST: "GET_MEAL_RECORDS_REQUEST",
  GET_MEAL_RECORDS_SUCCESS: "GET_MEAL_RECORDS_SUCCESS",
  GET_MEAL_RECORDS_ERROR: "GET_MEAL_RECORDS_ERROR",

  GET_MEAL_YOUGO_MASTER_REQUEST: "GET_MEAL_YOUGO_MASTER_REQUEST",
  GET_MEAL_YOUGO_MASTER_SUCCESS: "GET_MEAL_YOUGO_MASTER_SUCCESS",
  GET_MEAL_YOUGO_MASTER_ERROR: "GET_MEAL_YOUGO_MASTER_ERROR",

  GET_MEAL_SETTING_MASTER_REQUEST: "GET_MEAL_SETTING_MASTER_REQUEST",
  GET_MEAL_SETTING_MASTER_SUCCESS: "GET_MEAL_SETTING_MASTER_SUCCESS",
  GET_MEAL_SETTING_MASTER_ERROR: "GET_MEAL_SETTING_MASTER_ERROR",

  CREATE_MEAL_REQUEST: "CREATE_MEAL_REQUEST",
  CREATE_MEAL_SUCCESS: "CREATE_MEAL_SUCCESS",
  CREATE_MEAL_DUPLICATE: "CREATE_MEAL_DUPLICATE",
  CREATE_MEAL_ERROR: "CREATE_MEAL_ERROR",

  UPDATE_MEAL_REQUEST: "UPDATE_MEAL_REQUEST",
  UPDATE_MEAL_SUCCESS: "UPDATE_MEAL_SUCCESS",
  UPDATE_MEAL_ERROR: "UPDATE_MEAL_ERROR",

  RESET_MEAL_DATA: "RESET_MEAL_DATA",
};
