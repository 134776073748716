import { lazy } from "react";

// Main
const Main = lazy(() => import("../pages/Main/Main"));

// Vital
const Vital = lazy(() => import("../pages/Vital/Vital"));

// Meal
const Meal = lazy(() => import("../pages/Meal/Meal"));

// Suibun
const Suibun = lazy(() => import("../pages/Suibun/Suibun"));

// Haisetu
const Haisetu = lazy(() => import("../pages/Haisetu/Haisetu"));

// kanri
const KanriUserContainer = lazy(() =>
  import("../components/Kanri/KanriContainer").then((module) => ({
    default: module.KanriUserContainer,
  })),
);

const routes = [
  // Main
  {
    path: "/mobile/main/:sn?/:sid?",
    component: Main,
  },
  // Vital
  {
    path: "/mobile/vital/:sn?/:sid?",
    component: Vital,
  },
  // Meal
  {
    path: "/mobile/meal/:sn?/:sid?",
    component: Meal,
  },
  // Suibun
  {
    path: "/mobile/suibun/:sn?/:sid?",
    component: Suibun,
  },
  // Haisetu
  {
    path: "/mobile/haisetu/:sn?/:sid?",
    component: Haisetu,
  },
  // kanri
  {
    path: "/mobile/userkanri/user",
    component: KanriUserContainer,
  },
];

export default routes.map((route) => {
  route.type = "private";
  return route;
});
