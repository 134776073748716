export const Label = {
  inputHeader: {
    vital: "バイタル入力",
    haisetu: "排せつ入力",
    meal: "食事摂取",
    suibun: "水分摂取",
    previousRecord: "前の記録",
    nextRecord: "後の記録",
  },
  vital: {
    time: "時間",
    transmission: "申送",
    temperature: "体温",
    temperatureUnit: "℃",
    bpressureHigh: "血圧（高）",
    bpressureLow: "血圧（低）",
    mmhg: "mmHg",
    pulse: "脈拍",
    bpm: "bpm",
    spo2: "SPO²",
    percentageUnit: "%",
    weight: "体重",
    kg: "kg",
    comment: "コメント",
    kirokuType: "vital",
  },
  haisetu: {
    lastBowelMovementDate: "最終排便日",
    time: "時間",
    transmission: "申送",
    category: "区分",
    defecation: "排便",
    urination: "排尿",
    type: "種類",
    amount: "量",
    condition: "状態",
    kirokuType: "haisetu",
  },
  meal: {
    time: "時間",
    transmission: "申送",
    category: "種類",
    breakfast: "朝",
    lunch: "昼",
    dinner: "夕",
    snack: "間食",
    enteralNutrition: "経管栄養",
    stapleFoodAmount: "主食量",
    sideDishAmount: "副食量",
    noFoodProvided: "食事提供無し",
    kirokuType: "meal",
  },
  suibun: {
    time: "時間",
    transmission: "申送",
    category: "種類",
    amount: "量",
    input: "入力",
    unitMl: "ml",
    kirokuType: "suibun",
  },
  button: {
    select: "選択",
    initialValue: "初期値",
  },
  footer: {
    goToMenu: "メニュー",
    addNew: "+追加",
    save: "保存",
  },
  Account: {
    Username: "ログインIDを入力してください",
    Password: "パスワードを入力してください",
    SigninButton: "ログイン",
    Description: "モバイル",
    Note1: "これは、プラスワン開発用テストサーバです",
    Note2: "予告なく再起動する場合がありますのでご了承ください",
  },
  General: {
    Sort: "並替",
    Cancel: "キャンセル",
    Search: "検索",
    LogoTitle: "モバイル",
  },
  Header: {
    DropdownItem: {
      Profile: "ユーザ管理",
      Signout: "ログアウト",
    },
  },
  Dialog: {
    ConfirmSignout: {
      Title: "ログアウト確認",
      Question: "ログアウトします。よろしいですか？",
    },
  },
};
