import axios from "axios/index";
import { apiUrls } from "../common/configs/common-url";
import { getMenu } from "../common/function_common/functionCommon";
import dayjs from "dayjs";

function convertSortDataToParams(sortData) {
  const sortParams = {};
  if (sortData.key1 !== "0") sortParams[sortData.key1] = sortData.value1;
  if (sortData.key2 !== "0") sortParams[sortData.key2] = sortData.value2;
  if (sortData.key3 !== "0") sortParams[sortData.key3] = sortData.value3;
  if (sortData.key4 !== "0") sortParams[sortData.key4] = sortData.value4;
  if (sortData.key5 !== "0") sortParams[sortData.key5] = sortData.value5;
  return sortParams;
}
const InOutStatusApi = {
  async loadInOutStatusApi(value, filterObj, sort, searchName, page) {
    const key = getMenu();
    const sortParams = convertSortDataToParams(sort);
    const menu = JSON.parse(localStorage.getItem(key)).sn;
    const systemControlId = JSON.parse(localStorage.getItem(key)).si;
    const res = await axios
      .post(apiUrls.NYUTAISHO_RIREKI_LIST_BYDATE, {
        date: value,
        systemControlName: menu,
        systemControlId,
        filterObj: JSON.stringify(filterObj.search),
        searchName,
        sortObj: JSON.stringify(sortParams),
        page: page ? page.toString() : "1",
      });
    return res.data;
  },
  async loadInOutPagingStatusApi(value, filterObj, sort, searchName, page) {
    const key = getMenu();
    const sortParams = convertSortDataToParams(sort);
    const menu = JSON.parse(localStorage.getItem(key)).sn;
    const systemControlId = JSON.parse(localStorage.getItem(key)).si;
    const res = await axios
      .post(apiUrls.NYUTAISHO_RIREKI_LIST_BYDATE, {
        date: value,
        systemControlName: menu,
        systemControlId,
        filterObj: JSON.stringify(filterObj.search),
        searchName,
        sortObj: JSON.stringify(sortParams),
        page: page ? page.toString() : "1",
      });
    return res.data;
  },
  async editInOutStatusApi(value) {
    const key = getMenu();
    const master_id = JSON.parse(localStorage.getItem(key)).si;

    const res = await axios
      .put(apiUrls.NYUTAISHO_RIREKI_UPDATE, {
        date: dayjs(value.nyusyoDate).isValid() ? dayjs(value.nyusyoDate).format("YYYY-MM-DD") : null,
        id: value.id,
        riyousya_id: value.riyousyaId,
        service_kikan_master_id: master_id,
        kubun: value.kubun,
        bunrui: value.bunrui,
        nyusyo_date: dayjs(value.nyusyoDate).isValid() ? dayjs(value.nyusyoDate).format("YYYY-MM-DD") : null,
        nyusyo_riyu: value.nyusyoRiyu,
        room: value.room,
        gei: value.gei,
        taisho_date: dayjs(value.taishoDate).isValid() ? dayjs(value.taishoDate).format("YYYY-MM-DD") : null,
        taisho_riyu: value.taishoRiyu,
        sou: value.sou,
        comment: value.comment,
      });
    return res.data;
  },
  async insertInOutStatusApi(value) {
    const key = getMenu();
    const master_id = JSON.parse(localStorage.getItem(key)).si;
    const response = await axios
      .post(apiUrls.NYUTAISHO_RIREKI_INSERT, {
        date: dayjs(value.nyusyo_date).isValid() ? dayjs(value.nyusyo_date).format("YYYY-MM-DD") : null,
        riyousya_id: value.riyousya_id,
        service_kikan_master_id: master_id ? master_id : null,
        kubun: value.kubun ? value.kubun : null,
        bunrui: value.bunrui ? value.bunrui : null,
        nyusyo_date: dayjs(value.nyusyo_date).isValid() ? dayjs(value.nyusyo_date).format("YYYY-MM-DD") : null,
        nyusyo_riyu: value.nyusyo_riyu ? value.nyusyo_riyu : null,
        room: value.room ? value.room : null,
        gei: value.gei ? value.gei : null,
        taisho_date: dayjs(value.taisho_date).isValid() ? dayjs(value.taisho_date).format("YYYY-MM-DD") : null,
        taisho_riyu: value.taisho_riyu ? value.taisho_riyu : null,
        sou: value.sou ? value.sou : null,
        comment: value.comment ? value.comment : null,
      });
    return response.data;
  },
  async deleteInOutStatusApi(id) {
    try {
      const response = await axios
        .delete(apiUrls.NYUTAISHO_RIREKI_DELETE, {
          params: {
            id,
          },
        });
      return response.data;
    }
    catch (err) {
      return err;
    }
  },
  async getDataSleepOutsideApi(date, id) {
    try {
      const response = await axios
        .post(apiUrls.GAIHAKU_GAISYUTU_RIREKI_BYDATE, {
          date,
          riyousyaId: `${id}`,
        });
      return response.data;
    }
    catch (err) {
      return err;
    }
  },
  async editKaihakuKubunApi(data) {
    const key = getMenu();
    const master_id = JSON.parse(localStorage.getItem(key)).si;
    try {
      const response = await axios
        .put(apiUrls.GAIHAKU_GAISYUTU_RIREKI_UPDATE, {
          date: data.date,
          riyousyaId: `${data.riyousyaId}`,
          gaihakuKubun: data.gaihakuKubun,
          sid: master_id,
        });
      return response.data;
    }
    catch (err) {
      return err;
    }
  },
  async inOutQuote(date) {
    const key = getMenu();
    const res = await axios
      .get(apiUrls.NYUTAISHO_RIREKI_QUOTE, {
        params: {
          date,
          mainSid: JSON.parse(localStorage.getItem(key)).si,
          systemName: JSON.parse(localStorage.getItem(key)).sn,
        },
      });
    return res.data;
  },
};

export { InOutStatusApi };
