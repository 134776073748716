import { lazy } from "react";

const Signin = lazy(() => import("../pages/Signin"));
const DeviceInfo = lazy(() => import("../pages/DeviceInfo"));

const LogoutContainer = lazy(() =>
  import("../../components/logout/logoutContainer").then((module) => ({
    default: module.LogoutContainer,
  })),
);

const routes = [
  {
    path: "/mobile/login/:sn?/:sid?",
    component: Signin,
  },

  {
    path: "/mobile/logout-btn",
    component: LogoutContainer,
  },

  {
    path: "/mobile/device-info",
    component: DeviceInfo,
  },
];

export default routes.map((route) => {
  route.type = "public";
  return route;
});
