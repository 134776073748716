import { Form } from "@ant-design/compatible";
import { Button, Checkbox, Col, DatePicker, Input, Modal, Radio, Row, Tooltip } from "antd";
import dayjs from "dayjs";
import React from "react";
import RoomMasterModal from "../../../components/inOutStatus/UI/RoomMasterModal";
import YougoPopover from "../../../components/inOutStatus/UI/YougoPopover";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};


const layout1 = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};


const layout2 = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
};

export default class AddRiyousyaModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      insertingState: false,
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.inserting !== this.props.inserting) {
      this.setState({
        insertingState: nextProps.inserting,
      });
    }
  }

  async clickButtonAdd() {
    await this.setState({
      insertingState: true,
    });
    this.props.onSubmitInsertIoStatus();
  }

  render() {
    const {
      data,
      onKubunValueChange,
      onGeiChange,
      onSouChange,
      onRoomChange,
      onNyusyoRiyuChange,
      onTaishoRiyuChange,
      onBunruiChange,
      onCommentChange,
      // onSubmitInsertIoStatus,
      onCancel,
      // getListName,
      // listName,
      roomValueChange,
      nyusyoRiyuValueChange,
      taishoRiyuValueChange,
      bunruiValueChange,
      commentValueChange,
      disabledStartDate,
      onStartChange,
      disabledEndDate,
      onEndChange,
      // clearStartDate,
      clearEndDate,
      openStartDate,
      onOpenStartDateStatus,
      openEndDate,
      onOpenEndDateStatus,
      // loading,
      // inserting,
      onRoomCancel,
      haneleRoomVisible,
    } = this.props;
    const { insertingState } = this.state;
    return (
      (<div>
        <Modal
          destroyOnClose={true}
          width={1000}
          maskClosable={false}
          open={this.props.visible}
          onCancel={this.props.onCancel}
          footer={[
            <Row key={1} type="flex" justify="space-between">
              <div>
                <Button onClick={onCancel}>{"閉じる"}</Button>
              </div>
              <div>
                <Button loading={insertingState} onClick={() => this.clickButtonAdd()}>{"適用"}</Button>
              </div>
            </Row>,
          ]}
        >
          <Form className="user-in-out-form user-in-out-form-add">
            <Row style={{ marginBottom: 16 }}>
              <Col md={24} className={"header-form"}>
                <Form>
                  <Form.Item label="利用者名" colon={false}>
                    <Tooltip placement="topLeft" title={data.name}>
                      <span>{data.name}</span>
                    </Tooltip>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={12} className={"flex-content"}>
                <Row style={{ marginBottom: 16 }}>
                  <span style={{ marginRight: 8 }}>区分</span>
                  <Radio.Group
                    onChange={onKubunValueChange}
                    value={data.kubun}
                  >
                    <Radio value={"入所"}>{"入所"}</Radio>
                    <Radio value={"ショート"}>{"ショート"}</Radio>
                  </Radio.Group>
                </Row>
                <Form {...layout1}>
                  <Form.Item label="入所日" colon={false}>
                    <DatePicker
                      disabledDate={disabledStartDate}
                      value={
                        data.nyusyo_date && dayjs(data.nyusyo_date).isValid()
                          ? dayjs(data.nyusyo_date, "YYYY.MM.DD")
                          : null
                      }
                      onChange={onStartChange}
                      style={{ width: "150px" }}
                      open={openStartDate}
                      onOpenChange={onOpenStartDateStatus}
                    ></DatePicker>
                    <Checkbox
                      checked={data.gei === "1"}
                      onChange={onGeiChange}
                    // style={{ float: "right" }}
                    >
                      {"迎"}
                    </Checkbox>
                  </Form.Item>
                </Form>
                <Form {...layout1}>
                  <Form.Item label="退所日" colon={false}>
                    <DatePicker
                      disabledDate={disabledEndDate}
                      value={
                        data.taisho_date && dayjs(data.taisho_date).isValid()
                          ? dayjs(data.taisho_date, "YYYY.MM.DD")
                          : null
                      }
                      onChange={onEndChange}
                      style={{ width: "150px" }}
                      popupClassName="dp-footer"
                      open={openEndDate}
                      onOpenChange={onOpenEndDateStatus}
                      renderExtraFooter={() => (
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={clearEndDate}
                        >
                          {"クリア"}
                        </a>
                      )}
                    ></DatePicker>
                    <Checkbox
                      checked={data.sou === "1"}
                      onChange={onSouChange}
                      style={{ float: "right" }}
                    >
                      {"送"}
                    </Checkbox>
                  </Form.Item>
                </Form>
              </Col>
              <Col xs={24} sm={24} md={12} className={"flex-content"}>
                <Form {...layout}>
                  <Form.Item label="部屋" colon={false}>
                    <Input
                      maxLength={20}
                      value={data.room}
                      onChange={onRoomChange}
                    ></Input>
                    {/* <RoomPopover onChange={value => roomValueChange(value)}>  */}
                    <Button
                      onClick={
                        haneleRoomVisible
                      }
                      type="primary"
                    >
                      {"選択"}
                    </Button>
                    {/* </RoomPopover> */}
                  </Form.Item>
                  <Form.Item label="入所転帰" colon={false}>
                    {/* <Tooltip placement="topLeft" title={data.nyusyo_riyu
                      }> */}
                    <Input value={data.nyusyo_riyu} onChange={onNyusyoRiyuChange}></Input>
                    {/* </Tooltip> */}
                    <YougoPopover
                      listName={"入所理由"}
                      position={"center"}
                      onChange={(value) => nyusyoRiyuValueChange(value)}
                    >
                      <Button type="primary">{"選択"}</Button>
                    </YougoPopover>
                  </Form.Item>
                  <Form.Item label="退所転帰" colon={false}>
                    {/* <Tooltip placement="topLeft" title={data.taisho_riyu
                      }> */}
                    <Input value={data.taisho_riyu} onChange={onTaishoRiyuChange}></Input>
                    {/* </Tooltip> */}
                    <YougoPopover
                      listName={"退所理由"}
                      onChange={(value) => taishoRiyuValueChange(value)}
                    >
                      <Button type="primary">{"選択"}</Button>
                    </YougoPopover>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={12} className={"flex-content"}>
                <Form {...layout1}>
                  <Form.Item label="入退所分類" colon={false}>
                    <Input
                      maxLength={80}
                      value={data.bunrui}
                      onChange={onBunruiChange}
                    ></Input>
                    <YougoPopover
                      listName={"入退所分類"}
                      onChange={(value) => bunruiValueChange(value)}
                      position={"bottom"}
                    >
                      <Button type="primary">{"選択"}</Button>
                    </YougoPopover>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} className={"flex-content"}>
                <Form {...layout2}>
                  <Form.Item label="コメント" colon={false}>
                    <Input.TextArea autoSize={{ minRows: 1, maxRows: 3 }} value={data.comment} onChange={onCommentChange} />
                    <YougoPopover listName={"入退所コメント"} onChange={value => commentValueChange(value)}>
                      <Button style={{ marginTop: "-5px" }} type="primary">{"選択"}</Button>
                    </YougoPopover>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Form>
          <RoomMasterModal
            onChange={(value) => roomValueChange(value)}
            onCancel={onRoomCancel}
            visible={this.props.roomVisible}
          />
        </Modal>
      </div>)
    );
  }
}
