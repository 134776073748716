// src/i18n/config.ts
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

// 言語jsonファイルのimport
import translation_en from "./en.json";
import translation_ja from "./ja.json";
import translation_vi from "./vi.json";
import translation_id from "./id.json";

const savedLanguage = localStorage.getItem("mobile_language") || "ja";

const resources = {
  ja: {
    translation: translation_ja,
  },
  en: {
    translation: translation_en,
  },
  vi: {
    translation: translation_vi,
  },
  id: {
    translation: translation_id,
  },
};

i18n
  .use(HttpApi) // 翻訳ファイルを非同期に読み込む
  .use(LanguageDetector) // ユーザーの言語設定を検知
  .use(initReactI18next) // i18next インスタンスを初期化
  .init({
    resources,
    fallbackLng: "ja", // フォールバック言語。指定された言語ファイルがない場合などにこの言語が使用される
    lng: savedLanguage, // 初期値
    returnEmptyString: false, // 空文字での定義を許可に
    debug: true, // true にすると開発コンソールに i18next が正しく初期化されたことを示す出力が表示される

    // デフォルトは`escapeValue: true`
    // 18next が翻訳メッセージ内のコードをエスケープし、XSS 攻撃から保護するためのもの
    // React がこのエスケープを行ってくれるので、今回はこれをオフにする
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
