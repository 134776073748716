import { formatSeireki, formatBirthday } from "../../../function_common/functionCommon";
import _ from "lodash";
import { StorageUtils } from "../../../../utils";
import { ConfigName } from "../../../../config-name";

export const life_Tantokaigi_InputFormPDF = (data) => {
  const menu = StorageUtils.getValue(ConfigName.SWLIFE_MENU);

  const GENDER_OPTIONS = [
    {
      label: "男",
      value: 1,
    },
    {
      label: "女",
      value: 2,
    },
  ];
  const generateOption = (options, field) => {
    if (options && options[field]) {
      return options[field]
        .split(";")
        .filter((item) => !!item)
        .map((item) => {
          // let temp = (item && item.split(":")[0]) ?? item;
          let temp = item.includes(":") ? item.split(":")[0] : item;
          return { label: item, value: temp };
        });
    }
    return [];
  };

  const generateGenderString = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result += item.label + " ";
      }
    });
    return result;
  };

  //{ label: item, value: temp }
  //データ
  const generateCheckBoxString = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result += "■" + item.label + "\n";
      } else {
        result += "□" + item.label + "\n";
      }
    });
    return result;
  };

  const generateCheckBoxString2 = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result += "■" + item.label.split(":")[1] + " ";
      } else {
        result += "□" + item.label.split(":")[1] + " ";
      }
    });
    return result;
  };

  const formatString10Line = (string) => {
    if (string === null) return null;
    const LENGTH_LINE = 78;
    const MAX_LINE = 10;
    let stringArr = string.split("\n");
    let count = 0;
    let countOld = 0;
    let result = "";
    if (stringArr.length > 1) {
      stringArr.forEach((item) => {
        countOld = count;
        count = count + 1 + parseInt(item.length / (LENGTH_LINE + 1));
        if (count < MAX_LINE) {
          result = result + item + "\n";
        } else {
          result = result + item.substring(0, (MAX_LINE - countOld) * LENGTH_LINE);
          return;
        }
      });
    } else {
      stringArr.forEach((item) => {
        result = result + item.substring(0, (MAX_LINE - count) * LENGTH_LINE - 3);
        return;
      });
    }
    return result;
  };

  const dateCheckFormat = (date) => {
    if (date && date !== "0001-01-01") {
      return date.replace(/-/g, "/");
    }
    return "";
  };

  const parseData = (text, index) => {
    const data = text
      .split(";")
      .map((item) => {
        const parts = item.split(",");
        if (parts.length === 3) {
          return {
            syozoku: `${parts[0]} (${parts[1]})`,
            name: parts[2],
          };
        }
        return null;
      })
      .filter((item) => item !== null); // 不正データを除外

    return data[index];
  };

  const checkSystemName = () => {
    if (menu.sn == "rehabili") {
      return "通所リハビリテーション";
    } else if (menu.sn == "visitreha") {
      return "訪問リハビリテーション";
    } else {
      return "リハビリテーション";
    }
  };

  const { rehaTantokaigi } = data;

  console.log(rehaTantokaigi);

  if (typeof rehaTantokaigi === "object" && Array.isArray(rehaTantokaigi)) {
    let sampledata = [];
    rehaTantokaigi?.map((rehaTantokaigi) => {
      sampledata.push({
        タイトル: `リハビリテーション会議録（${checkSystemName()}）`,
        作成年月日: formatSeireki(rehaTantokaigi.gijirokuDate) ?? "",
        利用者名: rehaTantokaigi?.riyousya?.name ?? "",
        会議開催日: formatSeireki(rehaTantokaigi.kaisaiDate) ?? "",
        開催場所: rehaTantokaigi?.kaisaiPlace ?? "",
        開催時間: rehaTantokaigi?.kaisaiTime ?? "",
        開催回数: rehaTantokaigi?.kaisaiCount?.toString() ?? "",
        所属1: parseData(rehaTantokaigi.attendance, 0)?.syozoku ?? "",
        氏名1: parseData(rehaTantokaigi.attendance, 0)?.name ?? "",
        所属2: parseData(rehaTantokaigi.attendance, 1)?.syozoku ?? "",
        氏名2: parseData(rehaTantokaigi.attendance, 1)?.name ?? "",
        所属3: parseData(rehaTantokaigi.attendance, 2)?.syozoku ?? "",
        氏名3: parseData(rehaTantokaigi.attendance, 2)?.name ?? "",
        所属4: parseData(rehaTantokaigi.attendance, 3)?.syozoku ?? "",
        氏名4: parseData(rehaTantokaigi.attendance, 3)?.name ?? "",
        所属5: parseData(rehaTantokaigi.attendance, 4)?.syozoku ?? "",
        氏名5: parseData(rehaTantokaigi.attendance, 4)?.name ?? "",
        所属6: parseData(rehaTantokaigi.attendance, 5)?.syozoku ?? "",
        氏名6: parseData(rehaTantokaigi.attendance, 5)?.name ?? "",
        所属7: parseData(rehaTantokaigi.attendance, 6)?.syozoku ?? "",
        氏名7: parseData(rehaTantokaigi.attendance, 6)?.name ?? "",
        所属8: parseData(rehaTantokaigi.attendance, 7)?.syozoku ?? "",
        氏名8: parseData(rehaTantokaigi.attendance, 7)?.name ?? "",
        所属9: parseData(rehaTantokaigi.attendance, 8)?.syozoku ?? "",
        氏名9: parseData(rehaTantokaigi.attendance, 8)?.name ?? "",
        所属10: parseData(rehaTantokaigi.attendance, 9)?.syozoku ?? "",
        氏名10: parseData(rehaTantokaigi.attendance, 9)?.name ?? "",
        所属11: parseData(rehaTantokaigi.attendance, 10)?.syozoku ?? "",
        氏名11: parseData(rehaTantokaigi.attendance, 10)?.name ?? "",
        所属12: parseData(rehaTantokaigi.attendance, 11)?.syozoku ?? "",
        氏名12: parseData(rehaTantokaigi.attendance, 11)?.name ?? "",
        リハビリテーションの支援方針: rehaTantokaigi?.koumoku ?? "",
        リハビリテーションの内容: rehaTantokaigi?.naiyou ?? "",
        各サービス間の提供に当たって共有すべき事項: rehaTantokaigi?.keturon ?? "",
        不参加理由: rehaTantokaigi?.fusankaRiyu ?? "",
        次回の開催予定と検討事項: rehaTantokaigi?.kadai ?? "",
      });
    });

    return sampledata;
  } else if (typeof rehaTantokaigi === "object") {
    let sampledata = [
      {
        タイトル: `リハビリテーション会議録（${checkSystemName()}）`,
        作成年月日: formatSeireki(rehaTantokaigi.gijirokuDate) ?? "",
        利用者名: rehaTantokaigi?.riyousya?.name ?? "",
        会議開催日: formatSeireki(rehaTantokaigi.kaisaiDate) ?? "",
        開催場所: rehaTantokaigi?.kaisaiPlace ?? "",
        開催時間: rehaTantokaigi?.kaisaiTime ?? "",
        開催回数: rehaTantokaigi?.kaisaiCount?.toString() ?? "",
        所属1: parseData(rehaTantokaigi.attendance, 0)?.syozoku ?? "",
        氏名1: parseData(rehaTantokaigi.attendance, 0)?.name ?? "",
        所属2: parseData(rehaTantokaigi.attendance, 1)?.syozoku ?? "",
        氏名2: parseData(rehaTantokaigi.attendance, 1)?.name ?? "",
        所属3: parseData(rehaTantokaigi.attendance, 2)?.syozoku ?? "",
        氏名3: parseData(rehaTantokaigi.attendance, 2)?.name ?? "",
        所属4: parseData(rehaTantokaigi.attendance, 3)?.syozoku ?? "",
        氏名4: parseData(rehaTantokaigi.attendance, 3)?.name ?? "",
        所属5: parseData(rehaTantokaigi.attendance, 4)?.syozoku ?? "",
        氏名5: parseData(rehaTantokaigi.attendance, 4)?.name ?? "",
        所属6: parseData(rehaTantokaigi.attendance, 5)?.syozoku ?? "",
        氏名6: parseData(rehaTantokaigi.attendance, 5)?.name ?? "",
        所属7: parseData(rehaTantokaigi.attendance, 6)?.syozoku ?? "",
        氏名7: parseData(rehaTantokaigi.attendance, 6)?.name ?? "",
        所属8: parseData(rehaTantokaigi.attendance, 7)?.syozoku ?? "",
        氏名8: parseData(rehaTantokaigi.attendance, 7)?.name ?? "",
        所属9: parseData(rehaTantokaigi.attendance, 8)?.syozoku ?? "",
        氏名9: parseData(rehaTantokaigi.attendance, 8)?.name ?? "",
        所属10: parseData(rehaTantokaigi.attendance, 9)?.syozoku ?? "",
        氏名10: parseData(rehaTantokaigi.attendance, 9)?.name ?? "",
        所属11: parseData(rehaTantokaigi.attendance, 10)?.syozoku ?? "",
        氏名11: parseData(rehaTantokaigi.attendance, 10)?.name ?? "",
        所属12: parseData(rehaTantokaigi.attendance, 11)?.syozoku ?? "",
        氏名12: parseData(rehaTantokaigi.attendance, 11)?.name ?? "",
        リハビリテーションの支援方針: rehaTantokaigi?.koumoku ?? "",
        リハビリテーションの内容: rehaTantokaigi?.naiyou ?? "",
        各サービス間の提供に当たって共有すべき事項: rehaTantokaigi?.keturon ?? "",
        不参加理由: rehaTantokaigi?.fusankaRiyu ?? "",
        次回の開催予定と検討事項: rehaTantokaigi?.kadai ?? "",
      },
    ];
    return sampledata;
  } else {
    return [{}];
  }
};
