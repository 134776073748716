export const VitalActionTypes = {
  LOAD_SPIN_REQUEST: "LOAD_SPIN_REQUEST",

  GET_VITAL_RECORDS_REQUEST: "GET_VITAL_RECORDS_REQUEST",
  GET_VITAL_RECORDS_SUCCESS: "GET_VITAL_RECORDS_SUCCESS",
  GET_VITAL_RECORDS_ERROR: "GET_VITAL_RECORDS_ERROR",

  GET_VITAL_YOUGO_MASTER_REQUEST: "GET_VITAL_YOUGO_MASTER_REQUEST",
  GET_VITAL_YOUGO_MASTER_SUCCESS: "GET_VITAL_YOUGO_MASTER_SUCCESS",
  GET_VITAL_YOUGO_MASTER_ERROR: "GET_VITAL_YOUGO_MASTER_ERROR",

  GET_VITAL_SETTING_MASTER_REQUEST: "GET_VITAL_SETTING_MASTER_REQUEST",
  GET_VITAL_SETTING_MASTER_SUCCESS: "GET_VITAL_SETTING_MASTER_SUCCESS",
  GET_VITAL_SETTING_MASTER_ERROR: "GET_VITAL_SETTING_MASTER_ERROR",

  GET_VITAL_PREVIOUS_DATA_REQUEST: "GET_VITAL_PREVIOUS_DATA_REQUEST",
  GET_VITAL_PREVIOUS_DATA_SUCCESS: "GET_VITAL_PREVIOUS_DATA_SUCCESS",
  GET_VITAL_PREVIOUS_DATA_NOT_FOUND: "GET_VITAL_PREVIOUS_DATA_NOT_FOUND",
  GET_VITAL_PREVIOUS_DATA_ERROR: "GET_VITAL_PREVIOUS_DATA_ERROR",

  CREATE_VITAL_REQUEST: "CREATE_VITAL_REQUEST",
  CREATE_VITAL_SUCCESS: "CREATE_VITAL_SUCCESS",
  CREATE_VITAL_ERROR: "CREATE_VITAL_ERROR",

  UPDATE_VITAL_REQUEST: "UPDATE_VITAL_REQUEST",
  UPDATE_VITAL_SUCCESS: "UPDATE_VITAL_SUCCESS",
  UPDATE_VITAL_ERROR: "UPDATE_VITAL_ERROR",

  RESET_VITAL_DATA: "RESET_VITAL_DATA",
};
