import { CommonActionTypes } from "../action_types";
// import { openNotificationWithIcon } from "../../../common/function_common/functionCommon";

const initState = {
  room: "",
  settingLoading: false,
  yougoLoading: false,
  yougoMaster: [],
};

function common(state = initState, action) {
  switch (action.type) {
    case CommonActionTypes.MOBILE_KAIGODO_MASTER_LIST: {
      return {
        ...state,
        kaigodo: action.data,
      };
    }

    case CommonActionTypes.GET_ROOM_BY_URL: {
      return {
        ...state,
        room: action.data,
      };
    }

    case CommonActionTypes.GET_YOUGO_MASTER_REQUEST: {
      return {
        ...state,
        yougoLoading: true,
        yougoMaster: [],
      };
    }
    case CommonActionTypes.GET_YOUGO_MASTER_SUCCESS: {
      const yougoMaster = action.data?.naiyou?.split(";").map((value, index) => ({ key: index, naiyou: value })) || [];

      return {
        ...state,
        yougoLoading: false,
        yougoMaster,
      };
    }
    case CommonActionTypes.GET_YOUGO_MASTER_ERROR: {
      return {
        ...state,
        yougoLoading: false,
        yougoMaster: [],
      };
    }

    case CommonActionTypes.UPDATE_YOUGO_MASTER_REQUEST: {
      return {
        ...state,
        yougoLoading: true,
      };
    }
    case CommonActionTypes.UPDATE_YOUGO_MASTER_SUCCESS: {
      const yougoMaster = action.data?.naiyou?.split(";").map((value, index) => ({ key: index, naiyou: value })) || [];

      return {
        ...state,
        yougoLoading: false,
        yougoMaster,
      };
    }
    case CommonActionTypes.UPDATE_YOUGO_MASTER_ERROR: {
      return {
        ...state,
        yougoLoading: false,
        yougoMaster: null,
      };
    }
    case CommonActionTypes.GET_SETTING_MASTER_ALL_REQUEST: {
      return {
        ...state,
        settingLoading: true,
      };
    }
    case CommonActionTypes.GET_SETTING_MASTER_ALL_SUCCESS: {
      return {
        ...state,
        settingLoading: false,
        settingMasterAll: action.data,
      };
    }
    case CommonActionTypes.GET_SETTING_MASTER_ALL_ERROR: {
      return {
        ...state,
        settingLoading: false,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
}

export default common;
