import {
  FILTER_GROUP,
  SEARCH_NAME,
  UPDATE_USERS_LIST,
  USER_FILTER_GROUP,
  USER_LIST,
  USER_LOGIN_DETAIL,
} from "../../actions/userListActions/actionName.js";
import UserListApi from "../../api/userList.js";

/*
 * @param: username: string
 * @param: password: string
 */
function userListCreator(isGetAll) {
  return (dispatch) => {
    UserListApi.userList(isGetAll).then((data) =>
      dispatch({
        type: USER_LIST,
        users: data,
      })
    );
  };
}

function groupFilterCreator(groupName) {
  return {
    type: FILTER_GROUP,
    groupName,
  };
}

function searchNameCreator(name) {
  return {
    type: SEARCH_NAME,
    name,
  };
}

function filterUserGroupCreator(value) {
  return {
    type: USER_FILTER_GROUP,
    value,
  };
}

function getUserInformationCreator(userId) {
  return (dispatch) => {
    UserListApi.getDetail(userId).then((data) =>
      dispatch({
        type: USER_LOGIN_DETAIL,
        payload: data,
      })
    );
  };
}

function updateListCreator(id) {
  return {
    type: UPDATE_USERS_LIST,
    id,
  };
}

export {
  filterUserGroupCreator,
  getUserInformationCreator, groupFilterCreator,
  searchNameCreator, updateListCreator, userListCreator
};

