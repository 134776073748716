/* eslint-disable no-nested-ternary */
import axios from "axios/index";
import React from "react";
import ReactDOM from "react-dom/client";
import LifeLayout from "./layouts/life";
import ManagementLayout from "./layouts/management";
import MobileLayout from "./layouts/mobile";
import UserLayout from "./layouts/user";
import { CommonUtils as CommonUtilsLife } from "./life/utils";
import { CommonUtils } from "./management/utils";
import { CommonUtils as CommonUtilsMobile } from "./mobile/utils";
import { unregister } from "./registerServiceWorker";

axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
  },
});

const systemTitle = localStorage.getItem("kirokuTitle");
const systemTitleM = localStorage.getItem("MNGTTitle");
const systemTitleL = localStorage.getItem("lifeTitle");
const systemTitleMo = localStorage.getItem("mobileTitle");

/* eslint-disable indent */
document.title =
  document.URL.indexOf("management") !== -1
    ? systemTitleM
      ? "SmileWeb+" + systemTitleM
      : "SmileWeb+居宅管理"
    : document.URL.indexOf("life") !== -1
    ? systemTitleL
      ? "SmileWeb+" + systemTitleL
      : "SmileWeb+LIFE"
    : document.URL.indexOf("mobile") !== -1
    ? systemTitleMo
      ? "SmileWeb+" + systemTitleMo
      : "SmileWeb+モバイル"
    : systemTitle
    ? "SmileWeb+" + systemTitle
    : "SmileWeb+施設記録";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  CommonUtils.isAdmin(window.location.pathname) ? (
    <ManagementLayout />
  ) : CommonUtilsLife.isAdmin(window.location.pathname) ? (
    <LifeLayout />
  ) : CommonUtilsMobile.isAdmin(window.location.pathname) ? (
    <MobileLayout />
  ) : (
    <UserLayout />
  ),
);

// registerServiceWorker();
unregister();
