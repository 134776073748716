export const SuibunActionTypes = {
  LOAD_SPIN_REQUEST: "LOAD_SPIN_REQUEST",

  GET_SUIBUN_RECORDS_REQUEST: "GET_SUIBUN_RECORDS_REQUEST",
  GET_SUIBUN_RECORDS_SUCCESS: "GET_SUIBUN_RECORDS_SUCCESS",
  GET_SUIBUN_RECORDS_ERROR: "GET_SUIBUN_RECORDS_ERROR",

  GET_SUIBUN_YOUGO_MASTER_REQUEST: "GET_SUIBUN_YOUGO_MASTER_REQUEST",
  GET_SUIBUN_YOUGO_MASTER_SUCCESS: "GET_SUIBUN_YOUGO_MASTER_SUCCESS",
  GET_SUIBUN_YOUGO_MASTER_ERROR: "GET_SUIBUN_YOUGO_MASTER_ERROR",

  GET_SUIBUN_SETTING_MASTER_REQUEST: "GET_SUIBUN_SETTING_MASTER_REQUEST",
  GET_SUIBUN_SETTING_MASTER_SUCCESS: "GET_SUIBUN_SETTING_MASTER_SUCCESS",
  GET_SUIBUN_SETTING_MASTER_ERROR: "GET_SUIBUN_SETTING_MASTER_ERROR",

  GET_TOTAL_SUIBUN_REQUEST: "GET_TOTAL_SUIBUN_REQUEST",
  GET_TOTAL_SUIBUN_SUCCESS: "GET_TOTAL_SUIBUN_SUCCESS",
  GET_TOTAL_SUIBUN_NOT_FOUND: "GET_TOTAL_SUIBUN_NOT_FOUND",
  GET_TOTAL_SUIBUN_ERROR: "GET_TOTAL_SUIBUN_ERROR",

  CREATE_SUIBUN_REQUEST: "CREATE_SUIBUN_REQUEST",
  CREATE_SUIBUN_SUCCESS: "CREATE_SUIBUN_SUCCESS",
  CREATE_SUIBUN_NOT_SYURUI: "CREATE_SUIBUN_NOT_SYURUI",
  CREATE_SUIBUN_ERROR: "CREATE_SUIBUN_ERROR",

  UPDATE_SUIBUN_REQUEST: "UPDATE_SUIBUN_REQUEST",
  UPDATE_SUIBUN_SUCCESS: "UPDATE_SUIBUN_SUCCESS",
  UPDATE_SUIBUN_ERROR: "UPDATE_SUIBUN_ERROR",

  RESET_SUIBUN_DATA: "RESET_SUIBUN_DATA",
};
