export const subsidyManagementActionTypes = {
    GET_LIST_SUBSIDY: "GET_LIST_SUBSIDY",
    GET_LIST_SUBSIDY_SUCCESS: "GET_LIST_SUBSIDY_SUCCESS",
    GET_LIST_SUBSIDY_FAILURE: "GET_LIST_SUBSIDY_FAILURE",

    REDIRECT_TO_TICKET: "REDIRECT_TO_TICKET",
    REDIRECT_TO_TICKET_SUCCESS: "REDIRECT_TO_TICKET_SUCCESS",
    REDIRECT_TO_TICKET_FAILURE: "REDIRECT_TO_TICKET_FAILURE",

    DELETE_SUBSIDY: "DELETE_SUBSIDY",
    DELETE_SUBSIDY_SUCCESS: "DELETE_SUBSIDY_SUCCESS",
    DELETE_SUBSIDY_FAILURE: "DELETE_SUBSIDY_FAILURE",

    UPDATE_SUBSIDY: "UPDATE_SUBSIDY",
    UPDATE_SUBSIDY_SUCCESS: "UPDATE_SUBSIDY_SUCCESS",
    UPDATE_SUBSIDY_FAILURE: "UPDATE_SUBSIDY_FAILURE",
    UPDATE_SUBSIDY_ERROR: "UPDATE_SUBSIDY_ERROR",
    CLEAR_ERROR_DATA: "CLEAR_ERROR_DATA",

    UPDATE_SEIKYU: "UPDATE_SEIKYU",
    UPDATE_SEIKYU_SUCCESS: "UPDATE_SEIKYU_SUCCESS",
    UPDATE_SEIKYU_FAILURE: "UPDATE_SEIKYU_FAILURE",

    CREATE_GRANT_DETAILS: "CREATE_GRANT_DETAILS",
    CREATE_GRANT_DETAILS_SUCCESS: "CREATE_GRANT_DETAILS_SUCCESS",
    CREATE_GRANT_DETAILS_ERROR: "CREATE_GRANT_DETAILS_ERROR",

    RESET_CREATE_DETAILS: "RESET_CREATE_DETAILS",

    CREATE_MULTI_DATE: "CREATE_MULTI_DATE",
    CREATE_MULTI_DATE_SUCCESS: "CREATE_MULTI_DATE_SUCCESS",
    CREATE_MULTI_DATE_ERROR: "CREATE_MULTI_DATE_ERROR",

    GET_LIST_KAIGOHOKEN_MASTER: "GET_LIST_KAIGOHOKEN_MASTER",
    GET_LIST_KAIGOHOKEN_MASTER_SUCCESS: "GET_LIST_KAIGOHOKEN_MASTER_SUCCESS",
    GET_LIST_KAIGOHOKEN_MASTER_ERROR: "GET_LIST_KAIGOHOKEN_MASTER_ERROR",

    UPDATE_LIST_KAIGOHOKEN_MASTER: "UPDATE_LIST_KAIGOHOKEN_MASTER",
    UPDATE_LIST_KAIGOHOKEN_MASTER_SUCCESS: "UPDATE_LIST_KAIGOHOKEN_MASTER_SUCCESS",
    UPDATE_LIST_KAIGOHOKEN_MASTER_ERROR: "UPDATE_LIST_KAIGOHOKEN_MASTER_ERROR",

    GET_KAIGOHOKEN_MASTER_INIT: "GET_KAIGOHOKEN_MASTER_INIT",
    GET_KAIGOHOKEN_MASTER_INIT_SUCCESS: "GET_KAIGOHOKEN_MASTER_INIT_SUCCESS",
    GET_KAIGOHOKEN_MASTER_INIT_ERROR: "GET_KAIGOHOKEN_MASTER_INIT_ERROR",

    BENEFIT_MANAGEMENT: "BENEFIT_MANAGEMENT",
    BENEFIT_MANAGEMENT_SUCCESS: "BENEFIT_MANAGEMENT_SUCCESS",
    BENEFIT_MANAGEMENT_ERROR: "BENEFIT_MANAGEMENT_ERROR",

    QUOTE_SUBSIDY: "QUOTE_SUBSIDY",
    QUOTE_SUBSIDY_SUCCESS: "QUOTE_SUBSIDY_SUCCESS",
    QUOTE_SUBSIDY_ERROR: "QUOTE_SUBSIDY_ERROR",

    SAVE_FILTER_TANTO_ID: "SAVE_FILTER_TANTO_ID",
};