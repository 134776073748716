export const LifeTabConstants = {
  IndexTab: {
    LifeTab1: "1",
    LifeTab2: "2",
    LifeTab3: "3",
    LifeTab4: "4",
    LifeTab5: "5",
    LifeTab6: "6",
    LifeTab7: "7",
    LifeTab8: "8",
    LifeTab9: "9", //LifeTab9はリハビリが使っている
  },

  TabName: {
    LifeTab1: "riyousya",
    LifeTab2: "kagakuteki",
    LifeTab3: "kobetu2",
    LifeTab4: "koku",
    LifeTab5: "jokuso",
    LifeTab6: "haisetu",
    LifeTab7: "jiritu",
    LifeTab8: "yakuzai",
    LifeTab9: "adl",
  },

  Array: [
    "lifeTab1",
    "lifeTab2",
    "lifeTab3",
    "lifeTab4",
    "lifeTab5",
    "lifeTab6",
    "lifeTab7",
    "lifeTab8",
    "lifeTab9",
  ],

  Input: [
    "input-plan1",
    "input-plan2",
    "",
    "input-monitoring",
    "input-tanto-kaigi",
    "input-inquiry-request",
    "input-keika-kiroku",
    "input-jokuso-plan",
    "",
  ],

  // kobetsu tabs key
  KobetsuTabKey: {
    KobetsuAddKey1: "1",
    KobetsuAddKey2: "2",
  },
};
