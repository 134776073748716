import { applyMiddleware, legacy_createStore as createStore } from "redux";
import { ConfigName } from "../../../config-name";
import { StorageUtils } from "../../utils";
import rootReducer from "../reducers";
import initialStates from "./initial_states";
import middlewares from "./middlewares";

const getStorageStates = () => {
  let states = {};
  const authmobile = StorageUtils.getValue(ConfigName.SWMOBILE_AUTH);

  if (authmobile) {
    states = { ...states, authmobile };
  }

  return states;
};

const storageStates = getStorageStates();
const preloadedStates = { ...initialStates, ...storageStates };
const store = createStore(rootReducer, preloadedStates, applyMiddleware(...middlewares));

export default store;
