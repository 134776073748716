import React, { memo, useEffect, useRef, useState } from "react";
import { Modal, Row, Col, Checkbox, Input, Button, Select, Radio } from "antd";
// Component
import TimeKBCus from "../../components/WeeklyPlan/TimeKBCus";
import AddTime from "../../components/WeeklyPlan/AddTime";
import { Fragment } from "react";
import { ServiceKikanWeekly } from "../../dialogs/index";
import { ServiceKikanRental, ProductMasterModal } from "../index";
import { AddtionCodeList } from "../../dialogs/UseSlip/AdditionCodeList";
import DatePickerUseSlipModal from "../../dialogs/UseSlip/DatePickerUseSlipModal";
import ServiceKikanMasterModal from "../Common/ServiceKikanMasterModal";
import _ from "lodash";
import ListServicesAddition from "./ListServicesAddition";
import * as addServicesContants from "../../constants/AddServicesModal/addServicesModalWithConstant";
const { Option } = Select;

function AddServicesModal(props) {
  const [startTime, setStartTime] = useState("00:00");
  const [listServiceNaiyou, setListServiceNaiyou] = useState([]);
  const [endTime, setEndTime] = useState("00:00");
  const [serviceNaiyou, setServiceNaiyou] = useState();
  const [jigyousyoNoServiceKikan, setJigyousyoNoServiceKikan] = useState();
  const [ryakuServiceKikan, setRyakuServiceKikan] = useState();
  const [listServiceAddition, setListServiceAddition] = useState([]);
  const [servicesChecked, setServicesChecked] = useState();
  const [isErrorTime, setIsErrorTime] = useState(false);
  const [visibleServiceKikan, setVisibleServiceKikan] = useState(false);
  const [visibleServiceKikanRental, setVisibleServiceKikanRental] = useState(false);
  const [serviceSyuruiCd, setServiceSyuruiCd] = useState(null);
  const [syuruiCd, setSyuruiCd] = useState();
  const [colorSelected, setColorSelected] = useState("white");
  // const [ryaku, setRyaku] = useState("");
  const [visibleDateModal, setVisibleDateModal] = useState(false);
  const [additionKasan, setAdditionKasan] = useState([]);
  const SERVICE_TYPE_PTOT = "訪問看護";
  const [serviceCd, setServiceCd] = useState("");
  const [productMasterModalVisible, setProductMasterModalVisible] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [rentalSyohinName, setRentalSyohinName] = useState();
  const [serviceKikanMasterVisible, setServiceKikanMasterVisible] = useState(false);
  const [sid, setSid] = useState(null);
  const [kikanEditData, setKikanEditData] = useState([]);
  const [kasanDefault, setKasanDefault] = useState([]);
  const [handleChangeData, setHandleChangeData] = useState();
  const [isSaveKikanData, setIsSaveKikanData] = useState(false);
  const [rentalSyohinId, setRentalSyohinId] = useState();
  // const [checkStartTimes, setCheckStartTimes] = useState(false);
  const [serviceTani, setServiceTani] = useState();
  const KAIGO_CODE_SPECIAL = ["21", "22", "23", "38", "27", "28", "68", "79", "2A"];
  const btnRef = useRef();
  const [isCallGetServiceCode, setIsCallGetServiceCode] = useState(true);
  const [editAddition, setEditAddition] = useState([]);
  const [additionByListSpecial, setAdditionByListSpecial] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditAddition, setIsEditAddtion] = useState(false);
  const [shouldApplyTransform, setShouldApplyTransform] = useState(false);
  const [formValues, setFormValues] = useState({
    shuiruiCode: null,
    serviceCodeType: null,
    endTime: null,
    additionList: [],
    additionByList: [],
    code: null,
    sid: null,
    riyouhyouId: null,
    serviceContent: null,
    kaigodoName: null,
    riyouhyouDate: null,
    nengetu: null,
    requiredTime: null,
    contentRental: null,
    productName: null,
    startTimeStr: "00:00",
    endTimeStr: "00:00",
    ryakuServiceKikan: null,
    jigyousyoNoServiceKikan: null,
    productId: null,
    is30DayOver: false,
    serviceContentType: null,
    furigana: null,
  });

  useEffect(() => {
    const checkHeight = () => {
      setShouldApplyTransform(window.innerHeight >= 695);
    };

    checkHeight();
    window.addEventListener("resize", checkHeight);

    return () => {
      window.removeEventListener("resize", checkHeight);
    };
  }, []);

  useEffect(() => {
    if (startTime && endTime && (startTime.length < 2 || endTime.length < 2)) {
      setStartTime(addServicesContants.TIME_DEFAULT);
      setEndTime(addServicesContants.TIME_DEFAULT);
    }
  }, [startTime, endTime]);

  const checkHandleChangeData = (isChangeData) => {
    setHandleChangeData(isChangeData);
  };

  const checkIsSaveDataKikan = (isChange) => {
    props.isEditDialog && setIsSaveKikanData(isChange);
  };

  const [isSelectTypeSort, setIsSelectTypeSort] = useState(false);

  useEffect(() => {
    setIsSelectTypeSort(props.isSelectTypeSort);
  }, [props.isSelectTypeSort]);

  useEffect(() => {
    if (props.serviceAddition) {
      const listName = props.serviceAddition.map((item) => item.addition);
      setListServiceAddition(props.serviceAddition);
      if (listName.includes("緊急") && props.dataInit && props.dataInit.ryaku === "通院" && !props.isEditDialog) {
        setFormValues({ ...formValues, additionList: ["緊急"] });
      }
    }
  }, [props.serviceAddition]);

  useEffect(() => {
    setFormValues({ ...formValues, serviceCodeType: servicesChecked });
  }, [servicesChecked]);

  useEffect(() => {
    setIsCallGetServiceCode(true);
    if (props.formOld) {
      setFormValues({
        ...formValues,
        shuiruiCode: props.formOld.shuiruiCode,
        serviceCodeType: props.formOld.serviceCodeType,
        sid: props.formOld.sid,
        additionList: props.formOld.additionList,
        serviceContent: props.formOld.serviceContent,
        contentRental: props.formOld.contentRental,
        endTime: props.formOld.endTime,
        ryakuServiceKikan: props.formOld.ryakuServiceKikan,
        nengetu: props.formOld.nengetu,
        jigyousyoNoServiceKikan: props.formOld.jigyousyoNoServiceKikan,
        productId: props.formOld.productId,
        tani: props.formOld.tani,
        kaigodoName: props.formOld.kaigodoName,
        requiredTime: props.formOld.requiredTime,
        productName: props.formOld.productName,
        startTimeStr: props.formOld.startTimeStr,
        endTimeStr: props.formOld.endTimeStr,
        is30DayOver: props.formOld.is30DayOver,
        jigyousyoId: props.formOld.jigyousyoId,
        price: props.officeInfo?.price,
        furigana: props.formOld.furigana,
      });
      setRentalSyohinName(props.formOld.productName);
      if (props.formOld.additionByList && props.formOld.additionByList.length > 0) {
        setAdditionByListSpecial(props.formOld.additionByList);
      } else {
        setAdditionByListSpecial(
          props.formOld.additionList.filter((itemA) => props.serviceAdditionList?.some((itemB) => itemB.addition.includes(itemA))),
        );
      }
      if (props.isEditDialog || props.isDoubleDialog) {
        setRyakuServiceKikan(props.formOld.ryakuServiceKikan);
        setJigyousyoNoServiceKikan(props.formOld.jigyousyoNoServiceKikan);
        setServiceNaiyou(props.formOld.serviceContent);
        setServicesChecked(props.formOld.serviceCodeType);
        setStartTime(props.formOld.startTimeStr);
        setEndTime(props.formOld.endTimeStr);
      }
      setServiceCd(props.formOld.serviceCode);
      setSid(props.formOld.sid);
    }
    if (!(props.officeInfo?.additionNameList && props.officeInfo?.additionNameList.length)) {
      if (formValues && formValues.shuiruiCode && (formValues.serviceContent || formValues.contentRental || serviceCd)) {
        props.handleGetServiceAdditions(formValues.shuiruiCode);
      }

      if (formValues && formValues.shuiruiCode && formValues.sid) {
        additionAlreadyExist(formValues);
      }
    }
  }, [props.formOld, props.officeInfo]);
  useEffect(() => {
    // if (!props.isEditDialog) {
    //   checkStartTime();
    // }
    let formValuesTemp = { ...formValues };
    formValuesTemp = checkStartTime(formValuesTemp);
    setFormValues(formValuesTemp);
    handelShowWarning();
  }, [startTime, endTime]); //, checkStartTimes

  useEffect(() => {
    setIsErrorTime(false);
  }, [props.isNewDialog]);

  useEffect(() => {
    if (props.kaigoServiceSyuruiCode) {
      setFormValues({
        ...formValues,
        shuiruiCode: props.kaigoServiceSyuruiCode,
        sid:
          KAIGO_CODE_SPECIAL.includes(props.kaigoServiceSyuruiCode) || ["15", "72", "78"].includes(props.kaigoServiceSyuruiCode)
            ? null
            : formValues.sid,
        additionList:
          KAIGO_CODE_SPECIAL.includes(props.kaigoServiceSyuruiCode) || ["15", "72", "78"].includes(props.kaigoServiceSyuruiCode)
            ? []
            : formValues.additionList,
      });
    }
  }, [props.kaigoServiceSyuruiCode]);
  useEffect(() => {
    if (!serviceKikanMasterVisible && handleChangeData && isSaveKikanData) {
      if (kikanEditData && kikanEditData.ryaku && kikanEditData.kasanDefault && kasanDefault) {
        const startTimeArray = kasanDefault.startTime;
        const endTimeArray = kasanDefault.endTime;
        const serviceAdditionStr = kikanEditData.kasanDefault.match("<加算>(.*)</加算>");

        let serviceAdditionValue = serviceAdditionStr && serviceAdditionStr[1] ? serviceAdditionStr[1].split(",") : [];
        const countMinute = countMinutes(startTimeArray, endTimeArray);
        setRyakuServiceKikan(kikanEditData.ryaku);
        setFormValues({
          ...formValues,
          additionList: [...serviceAdditionValue],
          requiredTime: countMinute,
        });
        if (formValues.sid != null) {
          const serviceNaiyouNew = getServiceNaiyouInit(startTime, endTime, props.serviceNaiyou);
          const endTimeFindByserviceCodeType = props.serviceNaiyou.find(
            (e) => e.serviceType === formValues.serviceCodeType && e.serviceNaiyou === formValues.serviceContent,
          )?.calculationTime;
          setFormValues({ ...formValues, endTime: endTimeFindByserviceCodeType });
          props.getAdditionByOffice({
            serviceCodeType: formValues.serviceCodeType,
            shuiruiCode: formValues.shuiruiCode,
            requiredTime: countMinute,
            sid: formValues.sid,
            serviceContent: formValues.serviceContent,
            nengetu: props.nengetu,
          });
          // setAdditionKasan(serviceAdditionValue);
        }
      }
    }
  }, [serviceKikanMasterVisible]);

  useEffect(() => {
    if (props.nengetu) {
      setFormValues({
        ...formValues,
        nengetu: props.nengetu,
      });
    }
  }, [props.nengetu]);

  function handleChangeServiceCdRental(value, option) {
    setServiceCd(value);
    setFormValues({
      ...formValues,
      contentRental: option.props.children,
    });
    setServiceName(option.props.children);
  }
  function handleOkProduct(id, record) {
    setFormValues({
      ...formValues,
      productName: record.name,
      contentRental: record.serviceName,
      productId: record.id,
      tani: record.serviceTani,
    });
    setRentalSyohinName(record.name);
    setRentalSyohinId(record.id);
    setServiceCd(record.serviceCd);
    setServiceName(record.serviceName);
    setServiceTani(record.serviceTani);
    setProductMasterModalVisible(false);
  }

  function checkStartTime(formValuesTemp) {
    let additionOffice = [];
    if (formValuesTemp.shuiruiCode && (formValuesTemp.shuiruiCode == 11 || formValuesTemp.shuiruiCode == 13) && formValuesTemp.shuiruiCode) {
      const minutesOfStartTime = startTime ? parseInt(startTime.substring(0, 2)) * 60 + parseInt(startTime.substring(3)) : 0;
      if ((360 <= minutesOfStartTime && minutesOfStartTime <= 479) || (1080 <= minutesOfStartTime && minutesOfStartTime <= 1319)) {
        formValuesTemp = {
          ...formValuesTemp,
          additionList: [...formValuesTemp.additionList.filter((item) => item != "深夜" && item != "夜朝"), "夜朝", ...additionOffice],
          startTimeStr: startTime,
          endTimeStr: endTime,
        };
      } else if ((0 <= minutesOfStartTime && minutesOfStartTime <= 379) || (1320 <= minutesOfStartTime && minutesOfStartTime <= 1440)) {
        formValuesTemp = {
          ...formValuesTemp,
          additionList: [...formValuesTemp.additionList.filter((item) => item != "深夜" && item != "夜朝"), "深夜", ...additionOffice],
          startTimeStr: startTime,
          endTimeStr: endTime,
        };
      } else {
        formValuesTemp = {
          ...formValuesTemp,
          additionList: [...formValuesTemp.additionList.filter((item) => item !== "深夜" && item !== "夜朝"), ...additionOffice],
          startTimeStr: startTime,
          endTimeStr: endTime,
        };
      }
    } else {
      if (formValuesTemp.additionList && formValuesTemp.additionList.length > 0) {
        formValuesTemp = {
          ...formValuesTemp,
          additionList: [...formValuesTemp.additionList, ...additionOffice],
          startTimeStr: startTime,
          endTimeStr: endTime,
        };
      }
    }
    return formValuesTemp;
  }

  function checkTimeOffice(additionNew) {
    if ((formValues.shuiruiCode && formValues.shuiruiCode == 11) || formValues.shuiruiCode == 13) {
      const minutesOfStartTime = startTime ? parseInt(startTime.substring(0, 2)) * 60 + parseInt(startTime.substring(3)) : 0;
      if ((360 <= minutesOfStartTime && minutesOfStartTime <= 479) || (1080 <= minutesOfStartTime && minutesOfStartTime <= 1319)) {
        setFormValues({
          ...formValues,
          additionList: Array.from(new Set(["夜朝", ...additionNew, ...additionKasan])),
          startTimeStr: startTime,
          endTimeStr: endTime,
        });
      } else if ((0 <= minutesOfStartTime && minutesOfStartTime <= 379) || (1320 <= minutesOfStartTime && minutesOfStartTime <= 1440)) {
        setFormValues({
          ...formValues,
          additionList: Array.from(new Set(["深夜", ...additionNew, ...additionKasan])),
          startTimeStr: startTime,
          endTimeStr: endTime,
        });
      } else {
        let additionObject = props.serviceAddition?.filter((x) => props.officeInfo?.additionNameList.includes(x.addition));
        let additionKasanDefault = props.serviceAddition?.filter((x) => additionKasan.includes(x.addition));
        let checkAddition = editAddition
          ?.filter((elementB) => {
            const found = additionObject?.some((elementA) => elementA.duplicateCheckNotPossible.split(",").includes(elementB));
            return !found;
          })
          .filter((itemA) => props.serviceAdditionList?.some((itemB) => itemB.addition.includes(itemA)));
        let kasanDefault = editAddition?.filter((elementB) => {
          const found = additionKasanDefault.some((elementA) => !elementA.duplicateCheckNotPossible.split(",").includes(elementB));
          return !found;
        });
        let additionNotInOffice = (additionByListSpecial || [])?.filter((elementB) => {
          const found = additionObject.some((elementA) => elementA.duplicateCheckNotPossible.split(",").includes(elementB));
          return !found;
        });
        const additionNotOffice = editAddition.filter((itemA) => props.serviceAdditionList?.some((itemB) => itemB.addition.includes(itemA)));
        if (!props.isEditDialog) {
          setFormValues({
            ...formValues,
            additionList: Array.from(new Set([...additionNew, ...additionKasan, ...checkAddition])),
            startTimeStr: startTime,
            endTimeStr: endTime,
          });
          if (kasanDefault?.length > 0) {
            setFormValues({
              ...formValues,
              additionList: Array.from(new Set([...additionNew, ...kasanDefault, ...checkAddition])),
              startTimeStr: startTime,
              endTimeStr: endTime,
            });
          }
        } else {
          if (editAddition && isEditAddition) {
            setFormValues({
              ...formValues,
              additionList: Array.from(new Set([...additionNew, ...additionKasan, ...checkAddition, ...additionNotOffice])),
              startTimeStr: startTime,
              endTimeStr: endTime,
            });
          } else {
            setFormValues({
              ...formValues,
              additionList: Array.from(new Set([...additionNew, ...additionKasan, ...checkAddition, ...additionNotInOffice])),
              startTimeStr: startTime,
              endTimeStr: endTime,
            });
          }
        }
      }
    } else {
      let additionObject = props.serviceAddition?.filter((x) => props.officeInfo?.additionNameList.includes(x.addition));
      let additionKasanDefault = props.serviceAddition?.filter((x) => additionKasan.includes(x.addition));
      let checkAddition = editAddition?.filter((elementB) => {
        const found = additionObject?.some((elementA) => elementA.duplicateCheckNotPossible.split(",").includes(elementB));
        return !found;
      });
      let kasanDefault = editAddition?.filter((elementB) => {
        const found = additionKasanDefault.some((elementA) => !elementA.duplicateCheckNotPossible.split(",").includes(elementB));
        return !found;
      });
      let additionNotInOffice = (additionByListSpecial || [])?.filter((elementB) => {
        const found = additionObject.some((elementA) => elementA.duplicateCheckNotPossible.split(",").includes(elementB));
        return !found;
      });
      const additionNotOffice = editAddition.filter((itemA) => props.serviceAdditionList?.some((itemB) => itemB.addition.includes(itemA)));

      if (!props.isEditDialog) {
        setFormValues({
          ...formValues,
          additionList: Array.from(new Set([...additionNew, ...additionKasan, ...checkAddition])),
          startTimeStr: startTime,
          endTimeStr: endTime,
        });
        if (kasanDefault?.length > 0) {
          setFormValues({
            ...formValues,
            additionList: Array.from(new Set([...additionNew, ...kasanDefault, ...checkAddition])),
            startTimeStr: startTime,
            endTimeStr: endTime,
          });
        }
      } else {
        if (editAddition && isEditAddition) {
          setFormValues({
            ...formValues,
            additionList: Array.from(new Set([...additionNew, ...additionKasan, ...checkAddition, ...additionNotOffice])),
            startTimeStr: startTime,
            endTimeStr: endTime,
          });
        } else {
          setFormValues({
            ...formValues,
            additionList: Array.from(new Set([...additionNew, ...additionKasan, ...checkAddition, ...additionNotInOffice])),
            startTimeStr: startTime,
            endTimeStr: endTime,
          });
        }
      }
    }
  }

  useEffect(() => {
    if (props.officeInfo?.additionNameList) {
      checkTimeOffice(props.officeInfo?.additionNameList);
    }
  }, [props.officeInfo?.additionNameList]);

  useEffect(() => {
    if (props.serviceTypeSelect) {
      const endTimeFindByserviceCodeType = props.serviceNaiyou?.find(
        (e) => e.serviceType === formValues.serviceCodeType && e.serviceNaiyou === formValues.serviceContent,
      )?.calculationTime;

      const serviceContentFindByserviceCodeType = props.serviceNaiyou?.find(
        (e) => e.serviceType === formValues.serviceCodeType && e.serviceNaiyou === formValues.serviceContent,
      )?.serviceNaiyou;

      setServicesChecked(props.serviceTypeSelect);
      setFormValues({
        ...formValues,
        serviceCodeType: props.serviceTypeSelect,
        shuiruiCode: props.kaigoServiceSyuruiCode,
        riyouhyouId: parseInt(props.riyouhyouId),
        kaigodoName: props.riyouhyouForm ? props.riyouhyouForm.kaigodoName : null,
        endTime: endTimeFindByserviceCodeType || null,
        serviceContent: serviceContentFindByserviceCodeType || "",
        riyouhyouDate: props.riyouhyou ? props.riyouhyou.riyouhyouDate : null,
        nengetu: props.nengetu,
      });
    }
  }, [props.serviceTypeSelect]);

  useEffect(() => {
    setListServiceNaiyou(props.serviceNaiyou);
    if (props.dataInit.serviceType === SERVICE_TYPE_PTOT) {
      const listServiceNaiyouNew = props.serviceNaiyou.filter(
        (item) => item.serviceNaiyou.includes(serviceNaiyou.replace(/([0-9])/g, "")) || item.serviceNaiyou === "定期巡回訪看",
      );
      setListServiceNaiyou(listServiceNaiyouNew);
    } else {
      setListServiceNaiyou(props.serviceNaiyou);
    }

    if (props.isEditDialog && !props.isEditDialogType11 && props.serviceNaiyou && props.serviceNaiyou[0].kaigoServiceSyuruiCd === "11") {
      const serviceNaiyouNew = getServiceNaiyouInit(startTime, endTime, props.serviceNaiyou);
      let timeCal = props.serviceNaiyou.find((x) => x.serviceNaiyou == serviceNaiyouNew);
      setFormValues({
        ...formValues,
        endTime: timeCal ? parseInt(timeCal.calculationTime) : null,
        serviceContent: props.serviceNaiyou && props.serviceNaiyou[0] ? props.serviceNaiyou[0].serviceNaiyou : formValues.serviceContent,
      });
      setServiceNaiyou(serviceNaiyouNew);
    }
    if (!props.isEditDialog && !props.isDoubleDialog) {
      const serviceNaiyouNew = getServiceNaiyouInit(startTime, endTime, props.serviceNaiyou);
      setServiceNaiyou(serviceNaiyouNew);
      // set init syuruiCd for adding ServiceKikanMaster
      const serviceContent = _.find(props.serviceNaiyou, (e) => e.kaigo === 1);
      if (serviceContent) {
        if (serviceContent.kaigoServiceSyuruiCd) {
          const groupKaigoServiceSyuruiCd = serviceContent.kaigoServiceSyuruiCd.split(";");
          if (!groupKaigoServiceSyuruiCd.includes(serviceSyuruiCd)) {
            setServiceSyuruiCd(groupKaigoServiceSyuruiCd[0]);
          }
        } else {
          setServiceSyuruiCd("0");
        }
      }

      if (props.serviceNaiyou && !props.isDoubleDialog) {
        let timeCal = props.serviceNaiyou.find((x) => x.serviceNaiyou == serviceNaiyouNew);
        setFormValues({
          ...formValues,
          endTime: timeCal ? parseInt(timeCal.calculationTime) : null,
          serviceContent: timeCal ? timeCal.serviceNaiyou : "",
        });

        if (
          !KAIGO_CODE_SPECIAL.includes(formValues.shuiruiCode) &&
          !["15", "72", "78"].includes(formValues.shuiruiCode) &&
          formValues.sid &&
          !props.isDoubleDialog
        ) {
          props.getAdditionByOffice({
            serviceCodeType: formValues.serviceCodeType,
            shuiruiCode: formValues.shuiruiCode,
            sid: formValues.sid,
            serviceContent: props.serviceTypeSelect != "通院" ? serviceNaiyouNew : "通院",
            nengetu: props.nengetu,
          });
        }
      }
    }
    if (props.isDoubleDialog) {
      // const serviceNaiyouNew = getServiceNaiyouInit(startTime, endTime, props.serviceNaiyou);
      // let timeCal = props.serviceNaiyou.find((x) => x.serviceNaiyou == serviceNaiyouNew);
      const serviceNaiyouNew = getServiceNaiyouInit(startTime, endTime, props.serviceNaiyou, props.formOld.serviceContent);
      let timeCal = serviceNaiyouNew ? props.serviceNaiyou.find((x) => x.serviceNaiyou === serviceNaiyouNew) : "";
      let isDoubleTypeRental = props.isDoubleDialog && props.riyouhyouForm.formValuesDouble.shuiruiCode === "17";
      let isDouble = props.isDoubleDialog;
      let doubleData = props.riyouhyouForm.formValuesDouble;
      setFormValues({
        ...formValues,
        additionList: isDouble ? doubleData.additionList : formValues.additionList,
        jigyousyoNoServiceKikan: isDouble ? doubleData.jigyousyoNoServiceKikan : formValues.jigyousyoNoServiceKikan,
        ryakuServiceKikan: isDouble ? doubleData.ryakuServiceKikan : formValues.ryakuServiceKikan,
        sid: isDouble ? doubleData.sid : formValues.sid,
        contentRental: isDoubleTypeRental ? doubleData.contentRental : formValues.contentRental,
        productId: isDoubleTypeRental ? doubleData.productId : formValues.productId,
        productName: isDoubleTypeRental ? doubleData.productName : formValues.productName,
        tani: isDoubleTypeRental ? doubleData.tani : formValues.tani,
        endTime: isDouble && timeCal ? parseInt(timeCal.calculationTime) : formValues.endTime,
        serviceContent: isDouble ? doubleData.serviceContent : formValues.serviceContent,
      });
    }
  }, [props.serviceNaiyou]);

  useEffect(() => {
    if (props.dataInit) {
      setColorSelected(props.dataInit.swColorBak || "white");
    }
  }, [props.dataInit]);

  // useEffect(() => {
  //   let naiyouItem = props.serviceNaiyou?.find((x) => x.serviceNaiyou == serviceNaiyou);
  // if (naiyouItem && naiyouItem.serviceNaiyou && formValues) {
  //   const countMinute = countMinutes(formValues.startTimeStr, formValues.endTimeStr);
  //   const serviceNaiyouNew = getServiceNaiyouByTime(countMinute, props.serviceNaiyou);
  //   setFormValues({
  //     ...formValues,
  //     serviceTypeId: naiyouItem ? naiyouItem.id : "",
  //     serviceContent: naiyouItem.serviceNaiyou,
  //     endTime: serviceNaiyouNew ? parseInt(serviceNaiyouNew.calculationTime) : null,
  //   });
  // } else {
  // setFormValues({
  //   ...formValues,
  //   serviceTypeId: naiyouItem ? naiyouItem.id : "",
  // });
  // }
  // }, [serviceNaiyou]);

  useEffect(() => {
    if ((props.loadingServiceCode || formValues.additionList || !props.serviceCode) && !isLoading) {
      setIsLoading(true);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, [props.loadingServiceCode, formValues.additionList, props.serviceCode]);

  useEffect(() => {
    handelShowWarning();
    if (props.formOld) {
      props.handleGetNaiyou({
        shuiruiCode: props.formOld.shuiruiCode,
        serviceCodeType: props.formOld.serviceCodeType,
        endTime: props.formOld.endTime,
        additionList: props.formOld.additionList,
        code: props.formOld.code,
        sid: props.formOld.sid,
        riyouhyouId: props.formOld.riyouhyouId,
        serviceContent: props.formOld.serviceContent,
        kaigodoName: props.formOld.kaigodoName,
        riyouhyouDate: props.formOld.riyouhyouDate,
        nengetu: props.formOld.nengetu,
        requiredTime: props.formOld.requiredTime,
        furigana: props.formOld.furigana,
      });
      setSyuruiCd(props.formOld.shuiruiCode);
    }
  }, [props.isDoubleDialog, props.isEditDialog]);

  useEffect(() => {
    if (isCallGetServiceCode && formValues && formValues.shuiruiCode && (formValues.serviceContent || formValues.contentRental || serviceCd)) {
      props.getServiceCode(formValues);
      props.handleGetServiceAdditions(formValues.shuiruiCode);
    }

    if (formValues && formValues.shuiruiCode && formValues.sid) {
      additionAlreadyExist && additionAlreadyExist(formValues);
    }
  }, [formValues]);

  function additionAlreadyExist(formValue) {
    if (formValue && formValue.shuiruiCode && props.riyouhyouForm && !props.isEditDialog) {
      let listParent = props.riyouhyouForm.listDataService.filter((x) => x.isParent == true);
      listParent.some((e) => (e.dataRow.shuiruiCode === formValue.shuiruiCode && e.dataRow.sid == formValue.sid));
    }
  }

  function clearData() {
    setIsSaveKikanData(false);
    setIsEditAddtion(false);
    setEditAddition([]);
    setListServiceNaiyou([]);
    setListServiceAddition([]);
    setFormValues({
      shuiruiCode: null,
      serviceCodeType: null,
      endTime: null,
      additionList: [],
      code: null,
      sid: null,
      riyouhyouId: null,
      serviceContent: null,
      riyouhyouDate: null,
      contentRental: null,
      productName: null,
      startTimeStr: "00:00",
      endTimeStr: "00:00",
      ryakuServiceKikan: null,
      jigyousyoNoServiceKikan: null,
      productId: null,
      tani: null,
      is30DayOver: false,
    });
    setJigyousyoNoServiceKikan();
    setRyakuServiceKikan();
    setStartTime("00:00");
    setEndTime("00:00");
    setSyuruiCd("");
    setServiceCd(null);
    setServiceName("");
    setServiceTani("");
    if (props.serviceCode) {
      props.serviceCode.id = null;
      props.serviceCode.serviceCode = "";
      props.serviceCode.name = "";
      props.serviceCode.tani = "";
    }
    setRentalSyohinId(null);
    setAdditionKasan([]);
    setRentalSyohinName(null);
    props.resetMode();
    props.resetServiceType();
  }

  function handleCancel() {
    props.onHideAddMultiModal();
    clearData();
  }
  function clearCheckBox() {
    props.clearCheckBox();
  }

  function handleOk() {
    props.closeDialogAddMultiModal();
    setFormValues((prev) => ({
      ...prev,
      serviceContentType: props.listSyuruiList[formValues.shuiruiCode].ryaku,
    }));
    if (!props.isEditDialog) {
      setVisibleDateModal(true);
    } else {
      props.riyouhyouForm.listDataService.forEach((e) => {
        if (props.listDataChild.includes(e)) {
          e.price = props.officeInfo?.price;
          e.discount = props.officeInfo?.discount;
        }
      });

      handleDateOk(
        props.listPlanDate.map((item, index) => (item ? index + 1 : 0)),
        props.listPlanDate.length,
        false,
        false,
      );
    }
  }

  function handleChangeServiceNaiyou(value) {
    let naiyouItem = props.serviceNaiyou.find((x) => x.serviceNaiyou == value);
    setFormValues({
      ...formValues,
      endTime: naiyouItem ? parseInt(naiyouItem.calculationTime) : null,
      serviceContent: naiyouItem ? naiyouItem.serviceNaiyou : "",
    });
    setServiceNaiyou(value);
  }

  function handleOnClickTime(value) {
    const timeAddNumber = parseInt(value);
    let totalminutes;
    let result;
    if (startTime) {
      totalminutes = parseInt(startTime.substring(0, 2)) * 60 + parseInt(startTime.substring(3)) + timeAddNumber;
    } else {
      totalminutes = timeAddNumber;
    }
    const hours = totalminutes / 60 >= 10 ? "" + (parseInt(totalminutes / 60) % 24) : "0" + parseInt(totalminutes / 60);
    const minutes = totalminutes % 60 >= 10 ? "" + parseInt(totalminutes % 60) : "0" + parseInt(totalminutes % 60);
    result = hours + ":" + minutes;
    setEndTime(result);
    // set serviceNaiyou when change time
    const serviceNaiyouNew = getServiceNaiyou(startTime, result, listServiceNaiyou);
    const countMinute = countMinutes(startTime, result);
    let naiyouItem = props.serviceNaiyou.find((x) => x.serviceNaiyou == serviceNaiyouNew);
    if (isListMinNotEmpty(listServiceNaiyou)) {
      setServiceNaiyou(serviceNaiyouNew);
      setFormValues({
        ...formValues,
        endTime: naiyouItem ? parseInt(naiyouItem.calculationTime) : null,
        serviceContent: naiyouItem ? naiyouItem.serviceNaiyou : "",
        requiredTime: countMinute,
        endTimeStr: result,
      });
    } else {
      setFormValues({
        ...formValues,
        endTime: naiyouItem ? parseInt(naiyouItem.calculationTime) : null,
        requiredTime: countMinute,
        endTimeStr: result,
      });
    }
  }
  function handelShowWarning() {
    const minutesOfStartTime = startTime ? parseInt(startTime.substring(0, 2)) * 60 + parseInt(startTime.substring(3)) : 0;
    const minutesOfEndTime = endTime ? parseInt(endTime.substring(0, 2)) * 60 + parseInt(endTime.substring(3)) : 0;
    if (minutesOfStartTime >= minutesOfEndTime) {
      setIsErrorTime(true);
    } else {
      setIsErrorTime(false);
    }
  }

  function handleShowServiceKikan() {
    props.onHideAddMultiModal();
    if (props.kaigoServiceSyuruiCode == "17") {
      setVisibleServiceKikanRental(true);
    } else {
      setVisibleServiceKikan(true);
    }
    // setCheckStartTimes(false);
    handelShowWarning();
  }

  function handleOkService() {
    setServiceKikanMasterVisible(false);
  }

  function getChangeDataKikan(record, KasanDefault, changeData) {
    setKikanEditData(record);
    setFormValues({
      ...formValues,
      ryakuServiceKikan: record.ryaku,
    });
    setKasanDefault(KasanDefault);
    setHandleChangeData(changeData);
  }

  function handleCancelService() {
    setServiceKikanMasterVisible(false);
    // props.turnOffReload();
  }

  function getServiceNaiyou(startTime, endTime, listServiceNaiyou) {
    const minutes = countMinutes(startTime, endTime);
    const serviceNaiyouObj = getServiceNaiyouByTime(minutes, listServiceNaiyou);
    return serviceNaiyouObj ? serviceNaiyouObj.serviceNaiyou : "";
  }

  // function getServiceNaiyouInit(startTime, endTime, listServiceNaiyou) {
  //   if (listServiceNaiyou && listServiceNaiyou.find((item) => item.serviceNaiyou != "通院")) {
  //     const minutes = countMinutes(startTime, endTime);
  //     const serviceNaiyouObj = getServiceNaiyouByTime(minutes, listServiceNaiyou);
  //     return serviceNaiyouObj ? serviceNaiyouObj.serviceNaiyou : "";
  //   } else return listServiceNaiyou && listServiceNaiyou[0] ? listServiceNaiyou[0].serviceNaiyou : "";
  // }

  function getServiceNaiyouInit(startTime, endTime, listServiceNaiyou, serviceContent) {
    let serviceContentFormValue = serviceContent ? serviceContent : null;
    if (listServiceNaiyou && listServiceNaiyou.find((item) => item.serviceNaiyou != "通院")) {
      const minutes = countMinutes(startTime, endTime);
      const serviceNaiyouObj = serviceContentFormValue
        ? getServiceNaiyouByTime(minutes, listServiceNaiyou, serviceContentFormValue)
        : getServiceNaiyouByTime(minutes, listServiceNaiyou);
      const serviceNaiyou = serviceNaiyouObj && serviceNaiyouObj.serviceNaiyou ? serviceNaiyouObj.serviceNaiyou : "";
      return serviceNaiyou;
    } else return listServiceNaiyou && listServiceNaiyou[0] ? listServiceNaiyou[0].serviceNaiyou : "";
  }

  function getServiceNaiyouByTime(minutes, listServiceNaiyou) {
    const listServiceNaiyouSorted = _.orderBy(listServiceNaiyou, "min", "asc");
    if (
      listServiceNaiyou.length &&
      listServiceNaiyou[0].kaigoServiceSyuruiCd == "13" &&
      !listServiceNaiyou[0].serviceNaiyou.includes("PTOT") &&
      serviceNaiyou == "定期巡回訪看"
    ) {
      return listServiceNaiyouSorted.find((item) => item.serviceNaiyou == serviceNaiyou);
    } else {
      return listServiceNaiyouSorted.find((item) => item.min >= minutes);
    }
  }

  function countMinutes(startTime, endTime) {
    const minutesOfStartTime = startTime ? parseInt(startTime.substring(0, 2)) * 60 + parseInt(startTime.substring(3)) : 0;
    const minutesOfEndTime = endTime ? parseInt(endTime.substring(0, 2)) * 60 + parseInt(endTime.substring(3)) : endTime;
    return minutesOfEndTime - minutesOfStartTime;
  }

  function isListMinNotEmpty(list) {
    return list.some((item) => item.min);
  }

  function handleChangeStartTime(value) {
    // set serviceNaiyou when change time
    const serviceNaiyouNew = getServiceNaiyou(value, endTime, props.serviceNaiyou);
    const countMinute = countMinutes(value, endTime);
    let naiyouItem = props.serviceNaiyou.find((x) => x.serviceNaiyou == serviceNaiyouNew);
    if (isListMinNotEmpty(listServiceNaiyou)) {
      setServiceNaiyou(serviceNaiyouNew);
      setFormValues({
        ...formValues,
        endTime: naiyouItem ? parseInt(naiyouItem.calculationTime) : null,
        serviceContent: naiyouItem ? naiyouItem.serviceNaiyou : "",
        requiredTime: countMinute,
        startTimeStr: value,
      });
      // setRyaku(serviceNaiyouNew);
    } else {
      setFormValues({
        ...formValues,
        // endTime: naiyouItem ? parseInt(naiyouItem.calculationTime) : null,
        requiredTime: countMinute,
        startTimeStr: value,
      });
    }
    setStartTime(value);
  }

  function handleChangeEndTime(value) {
    // set serviceNaiyou when change time
    const serviceNaiyouNew = getServiceNaiyou(startTime, value, props.serviceNaiyou);
    const countMinute = countMinutes(startTime, value);
    let naiyouItem = props.serviceNaiyou.find((x) => x.serviceNaiyou == serviceNaiyouNew);
    if (isListMinNotEmpty(listServiceNaiyou)) {
      setServiceNaiyou(serviceNaiyouNew);
      setFormValues({
        ...formValues,
        endTime: naiyouItem ? parseInt(naiyouItem.calculationTime) : null,
        serviceContent: naiyouItem ? naiyouItem.serviceNaiyou : "",
        requiredTime: countMinute,
        endTimeStr: value,
      });
    } else {
      setFormValues({
        ...formValues,
        endTime: naiyouItem ? parseInt(naiyouItem.calculationTime) : null,
        requiredTime: countMinute,
        endTimeStr: value,
      });
    }
    setEndTime(value);
  }

  function handleChangeServiceAddition(e) {
    let serviceAdditionNew = [...formValues.additionList];
    let additionObject = props.serviceAddition?.find((x) => x.addition == e.target.value);
    let additionCheckNotPossible =
      additionObject && additionObject.duplicateCheckNotPossible ? additionObject.duplicateCheckNotPossible.split(",") : null;
    if (e.target.checked) {
      serviceAdditionNew.push(e.target.value);
      serviceAdditionNew = additionCheckNotPossible ? serviceAdditionNew.filter((x) => !additionCheckNotPossible.includes(x)) : serviceAdditionNew;
      // eslint-disable-next-line brace-style
    } else {
      serviceAdditionNew = serviceAdditionNew.filter((item) => item !== e.target.value);
    }
    setIsEditAddtion(true);
    const serviceAdditionOutOffice = serviceAdditionNew.filter((e) => !props.officeInfo?.additionNameList.includes(e));
    setEditAddition(serviceAdditionOutOffice);
    if (formValues.shuiruiCode != "17") {
      const listServiceNaiyouNew = processListServiceNaiyou(serviceAdditionNew, listServiceAddition);
      const checkExists = listServiceNaiyouNew.some((item) => item.serviceNaiyou == serviceNaiyou);
      const listServiceNaiyouOld = listServiceNaiyou;
      const isChangeListServiceNaiyou = listServiceNaiyouOld.length != listServiceNaiyouNew.length;
      if (isChangeListServiceNaiyou) {
        const serviceNaiyouNew = getServiceNaiyou(startTime, endTime, listServiceNaiyouNew);
        const countMinute = countMinutes(startTime, endTime);
        setServiceNaiyou(serviceNaiyouNew);
        // setRyaku(serviceNaiyouNew);
        let naiyouItem = props.serviceNaiyou.find((x) => x.serviceNaiyou == serviceNaiyouNew);
        setFormValues({
          ...formValues,
          endTime: naiyouItem ? parseInt(naiyouItem.calculationTime) : null,
          serviceContent: naiyouItem ? naiyouItem.serviceNaiyou : "",
          requiredTime: countMinute,
          additionList: [...serviceAdditionNew],
        });
      } else {
        setFormValues({ ...formValues, additionList: [...serviceAdditionNew] });
      }
      setListServiceNaiyou([...listServiceNaiyouNew]);
    } else {
      setFormValues({ ...formValues, additionList: [...serviceAdditionNew] });
    }
  }

  const onChangeServiceType = (e) => {
    setFormValues({ ...formValues, serviceCodeType: e.target.value });
    setServiceNaiyou(null);
    props.changeServiceTypeSelected(e.target.value, formValues.shuiruiCode, props.nengetu);
    setServicesChecked(e.target.value);
    setColorSelected(props.listColorMaster.find((x) => x.serviceType == e.target.value).swColorBak || "white");
  };

  const onChangeKaigoCode = (e) => {
    setServiceSyuruiCd(e.target.value);
    props.changeServiceTypeSelected(props.serviceTypeSelect, e.target.value, props.nengetu);
    if (KAIGO_CODE_SPECIAL.includes(e.target.value) || ["15", "72", "78"].includes(e.target.value)) {
      setJigyousyoNoServiceKikan();
      setRyakuServiceKikan();
      setFormValues({ ...formValues, ryakuServiceKikan: null });
    }
    if (e.target.value != 17 && e.target.value != 11 && !["11", "17"].includes(e.target.value)) {
      props.removeNaiyou();
    }
  };

  function getListAddition(list, syuruiCd) {
    if (syuruiCd == null) {
      return list;
    }
    return list.filter((item) => {
      const listSyuruiCd = item.kaigoServiceSyuruiCd ? item.kaigoServiceSyuruiCd.split(";") : [];
      if (listSyuruiCd.includes(syuruiCd)) return item;
    });
  }

  function handleDateOk(listDate, countDate, isKeepFirst, isKeepLast) {
    let propServiceCode = { ...props.serviceCode };
    let additionListMain = props.serviceAddition
      ? props.serviceAddition.filter((x) => formValues.additionList.includes(x.addition) && x.additionType == 0)
      : [];
    let isGetDataWeekly = false;
    let listDataRecord = [];
    listDataRecord.push({
      listDate,
      countDate,
      formValues,
      mainCode: propServiceCode,
      expandCode: props.expandCode.filter((x) => x.id != null),
      additionListMain,
      isKeepFirst,
      isKeepLast,
      isGetDataWeekly,
    });
    props.isEditDialog
      ? props.handleEdit(
        listDate,
        countDate,
        { ...formValues, serviceContentType: formValues.serviceContentType || props.syuruiCdRyaku },
        propServiceCode,
        props.expandCode.filter((x) => x.id != null),
        additionListMain,
      )
      : props.handleAddNewRecord(listDataRecord);
    setVisibleDateModal(false);
    clearData();
  }
  function handleDateCancel() {
    setVisibleDateModal(false);
    handleCancel();
  }

  function onChangeTani(e) {
    setServiceTani(!isNaN(e.target.value) ? +e.target.value : 0);
    setFormValues({
      ...formValues,
      tani: !isNaN(e.target.value) ? +e.target.value : 0,
    });
    props.serviceCode.tani = !isNaN(e.target.value) ? +e.target.value : 0;
  }

  const extractServicesKikanDefault = (kasanDefault) => {
    if (kasanDefault) {
      const startTimeArray = kasanDefault.match("<時間帯開始>(.*)</時間帯開始>");
      const endTimeArray = kasanDefault.match("<時間帯終了>(.*)</時間帯終了>");
      const startTimeValue = startTimeArray && startTimeArray[1] ? startTimeArray[1].substring(0, 2) + ":" + startTimeArray[1].substring(2) : "";
      const endTimeValue = endTimeArray && endTimeArray[1] ? endTimeArray[1].substring(0, 2) + ":" + endTimeArray[1].substring(2) : "";

      return {
        startTimeTemp: !startTimeValue ? addServicesContants.TIME_DEFAULT : startTimeValue,
        endTimeTemp: !endTimeValue ? addServicesContants.TIME_DEFAULT : endTimeValue,
      };
    }
    return {};
  };

  // handle ServiceKikan
  const onSaveServiceKikan = (value) => {
    if (value.kasanDefault && value?.id && startTime === addServicesContants.TIME_DEFAULT && endTime === addServicesContants.TIME_DEFAULT) {
      const { startTimeTemp, endTimeTemp } = extractServicesKikanDefault(value.kasanDefault);
      setIsCallGetServiceCode(false);
      setStartTime(startTimeTemp);
      setEndTime(endTimeTemp);
    }
    let formValuesTemp = { ...formValues };
    const { type, index } = visibleServiceKikan;
    const kasanDefault = value?.kasanDefault;
    const startTimeArray = kasanDefault.match("<時間帯開始>(.*)</時間帯開始>");
    const endTimeArray = kasanDefault.match("<時間帯終了>(.*)</時間帯終了>");
    const serviceAdditionStr = kasanDefault.match("<加算>(.*)</加算>");
    let serviceAdditionValue = serviceAdditionStr && serviceAdditionStr[1] ? serviceAdditionStr[1].split(",") : [];
    let startTimeValue = startTimeArray && startTimeArray[1] ? startTimeArray[1].substring(0, 2) + ":" + startTimeArray[1].substring(2) : "";
    let endTimeValue = endTimeArray && endTimeArray[1] ? endTimeArray[1].substring(0, 2) + ":" + endTimeArray[1].substring(2) : "";
    const listServiceAdditionNew = getListAddition(props.serviceAddition, value.syuruiCd);

    // delete serviceAddition not in list serviceAddition
    const listName = listServiceAdditionNew.map((item) => item.addition);
    serviceAdditionValue = serviceAdditionValue.filter((item) => listName.includes(item));

    const listServiceNaiyouNew = processListServiceNaiyou(serviceAdditionValue, listServiceAdditionNew);
    if (
      (startTimeValue === "" || startTimeValue === addServicesContants.TIME_DEFAULT) &&
      (endTimeValue === "" || endTimeValue === addServicesContants.TIME_DEFAULT)
    ) {
      startTimeValue = startTime;
      endTimeValue = endTime;
      // setCheckStartTimes(true);
      formValuesTemp = checkStartTime(formValuesTemp);
      handelShowWarning();
    } else {
      // setCheckStartTimes(false);
    }
    // const countMinute = countMinutes(startTimeValue, endTimeValue);
    const countMinute = formValues.requiredTime ? formValues.requiredTime : countMinutes(startTimeValue, endTimeValue);
    const serviceNaiyouNew = getServiceNaiyouByTime(countMinute, listServiceNaiyouNew);
    setSyuruiCd(value.syuruiCd);
    if (isListMinNotEmpty(listServiceNaiyouNew)) {
      setServiceNaiyou(serviceNaiyouNew ? serviceNaiyouNew.serviceNaiyou : "");
      let naiyouItem = props.serviceNaiyou?.find((x) => x.serviceNaiyou == (serviceNaiyouNew ? serviceNaiyouNew.serviceNaiyou : ""));
      formValuesTemp = {
        ...formValuesTemp,
        serviceTypeId: naiyouItem ? naiyouItem.id : "",
        endTime: serviceNaiyouNew ? parseInt(serviceNaiyouNew.calculationTime) : null,
        serviceContent: serviceNaiyouNew ? serviceNaiyouNew.serviceNaiyou : "",
        sid: parseInt(value.id),
        requiredTime: countMinute,
        additionList: [...serviceAdditionValue],
        ryakuServiceKikan: value.ryaku,
        jigyousyoNoServiceKikan: value.jigyousyoNo,
        jigyousyoId: value.id,
        furigana: value.furigana,
      };
      // setRyaku(serviceNaiyouNew ? serviceNaiyouNew.serviceNaiyou : "");
      // setFormValues({
      //   ...formValues,
      //   endTime: serviceNaiyouNew ? parseInt(serviceNaiyouNew.calculationTime) : null,
      //   serviceContent: serviceNaiyouNew ? serviceNaiyouNew.serviceNaiyou : "",
      //   sid: parseInt(value.id),
      //   requiredTime: countMinute,
      //   additionList: [...serviceAdditionValue],
      //   ryakuServiceKikan: value.ryaku,
      //   jigyousyoNoServiceKikan: value.jigyousyoNo,
      //   jigyousyoId: value.id,
      // });
      setIsCallGetServiceCode(false);
      setFormValues(formValuesTemp);
      props.getAdditionByOffice({
        serviceCodeType: formValuesTemp.serviceCodeType,
        shuiruiCode: formValuesTemp.shuiruiCode,
        sid: parseInt(value.id),
        serviceContent: serviceNaiyouNew ? serviceNaiyouNew.serviceNaiyou : null,
        nengetu: props.nengetu,
        requiredTime: countMinute,
      });
    } else {
      formValuesTemp = {
        ...formValuesTemp,
        sid: parseInt(value.id),
        additionList: [...serviceAdditionValue],
        ryakuServiceKikan: value.ryaku,
        furigana: value.furigana,
        jigyousyoNoServiceKikan: value.jigyousyoNo,
      };
      setIsCallGetServiceCode(false);
      setFormValues(formValuesTemp);

      props.getAdditionByOffice({
        serviceCodeType: formValuesTemp.serviceCodeType,
        shuiruiCode: formValuesTemp.shuiruiCode,
        sid: parseInt(value.id),
        serviceContent: serviceNaiyou,
        nengetu: props.nengetu,
        requiredTime: countMinute,
      });
    }
    setListServiceNaiyou(listServiceNaiyouNew);
    // setStartTime(startTimeValue);
    // setEndTime(endTimeValue);
    setAdditionKasan(serviceAdditionValue);
    setJigyousyoNoServiceKikan(value.jigyousyoNo);
    setRyakuServiceKikan(value.ryaku);
    // setSidSelected(value.id);
    setVisibleServiceKikan(false);
    setListServiceAddition(listServiceAdditionNew);
    props.onShowAddServicesModal();
    props.handleGetNaiyou({
      shuiruiCode: formValuesTemp.shuiruiCode,
      serviceCodeType: formValuesTemp.serviceCodeType,
      endTime: formValuesTemp.endTime,
      additionList: formValuesTemp.additionList,
      code: formValuesTemp.code,
      sid: value ? value.id : null,
      riyouhyouId: formValuesTemp.riyouhyouId,
      serviceContent: formValuesTemp.serviceContent,
      kaigodoName: formValuesTemp.kaigodoName,
      riyouhyouDate: formValuesTemp.riyouhyouDate,
      nengetu: formValuesTemp.nengetu,
      requiredTime: formValuesTemp.requiredTime,
    });
  };

  function handleClickProduct() {
    setProductMasterModalVisible(true);
    props.onShowAddServicesModal();
  }

  function processListServiceNaiyou(serviceAdditionSelected, serviceAdditionList) {
    // check PTOT
    const isPTOT = serviceAdditionList.some((item) => (item.addition ? item.addition.includes("PTOT") : false));
    let listSerViceNaiyouNew = props.serviceNaiyou;
    if (isPTOT) {
      let isPTOTSelected = false;
      serviceAdditionSelected.forEach((item) => {
        if (item.includes("PTOT")) {
          isPTOTSelected = true;
        }
      });

      if (isPTOTSelected) {
        listSerViceNaiyouNew = props.serviceNaiyou.filter((item) => item.serviceNaiyou.includes("PTOT") || item.serviceNaiyou === "定期巡回訪看");
      } else {
        listSerViceNaiyouNew = props.serviceNaiyou.filter((item) => !item.serviceNaiyou.includes("PTOT") || item.serviceNaiyou === "定期巡回訪看");
      }
    }
    // eslint-disable-next-line no-undef

    return [...listSerViceNaiyouNew];
  }
  function onSaveServiceKikanRental(id, record) {
    setFormValues({
      ...formValues,
      sid: parseInt(record.id),
      ryakuServiceKikan: record.ryaku,
      jigyousyoNoServiceKikan: record.jigyousyoNo,
      furigana: record.furigana,
    });
    props.getAdditionByOffice({
      serviceCodeType: formValues.serviceCodeType,
      shuiruiCode: formValues.shuiruiCode,
      sid: parseInt(record.id),
      serviceContent: formValues.contentRental,
      nengetu: props.nengetu,
    });
    setJigyousyoNoServiceKikan(record.jigyousyoNo);
    setRyakuServiceKikan(record.ryaku);
    setVisibleServiceKikanRental(false);
    props.onShowAddServicesModal();
  }
  return (
    <Fragment>
      <Modal
        open={props.visible}
        onCancel={() => handleCancel()}
        closable={false}
        destroyOnClose={true}
        maskClosable={false}
        className="add-service-code-modal"
        footer={[
          (
            <Button
              key="ok"
              type="primary"
              ref={btnRef}
              onClick={() => {
                if (btnRef.current.disabled) {
                  return;
                } else {
                  btnRef.current.disabled = true;
                }
                handleOk();
                setIsErrorTime(false);
                props.turnOffDialog();
                props.clearCheckBox();
                setTimeout(() => {
                  if (btnRef.current && btnRef.current.disabled) btnRef.current.disabled = false;
                }, 1000);
              }}
              disabled={!(formValues.ryakuServiceKikan != null && props.serviceCode && props.serviceCode.serviceCode) || props.loadingServiceCode || props.loadingServiceCodeExpand}
            >
              {props.isEditDialog ? "修正する" : "追加する"}
            </Button>
          ),
          <Button
            key="back"
            style={{
              backgroundColor: "#ffffff",
              color: "#616161",
              border: "1px solid #dbdbdb",
            }}
            onClick={() => {
              handleCancel();
              setIsErrorTime(false);
              props.turnOffDialog();
            }}
          >
            キャンセル
          </Button>,
        ]}
        title={<div>{props.isEditDialog ? "修正画面" : "追加画面"}</div>}
        width={1040}
        modalRender={(modal) =>
          shouldApplyTransform ? (
            <div style={{ transform: "translateY(-85px) scale(0.95)", transition: "none" }}>
              {modal}
            </div>
          ) : (
            modal
          )
        }
      >
        <div>
          <Row style={{ display: "flex" }}>
            <Col span={17}>
              <Row style={{ alignItems: "center" }}>
                <Col span={4}>要介護度区分</Col>
                <Col span={16}>{props.riyouhyouForm ? props.riyouhyouForm.kaigodoName : ""}</Col>
              </Row>
              {formValues.shuiruiCode && formValues.shuiruiCode == 11 ? (
                <Row style={{ marginTop: 10, marginBottom: 10 }}>
                  <Radio.Group onChange={onChangeServiceType} value={servicesChecked}>
                    {props.listServiceType
                      ? props.listServiceType.map((item, index) => (
                        <Radio value={item.serviceType} style={{ marginRight: "20px" }} key={index}>
                          {item.serviceType}
                        </Radio>
                      ))
                      : ""}
                  </Radio.Group>
                </Row>
              ) : (formValues.shuiruiCode && formValues.shuiruiCode.includes(["15", "72", "78"])) ||
                ["15", "72", "78"].includes(formValues.shuiruiCode) ? (
                <Row style={props.isEditDialog ? { display: "none", marginTop: 10, marginBottom: 10 } : { marginTop: 10, marginBottom: 10 }}>
                  <Radio.Group onChange={onChangeKaigoCode} value={formValues.shuiruiCode}>
                    <Radio value={"15"}>通所介護</Radio>
                    <Radio value={"72"}>認知通所</Radio>
                    <Radio value={"78"}>地域通所</Radio>
                  </Radio.Group>
                </Row>
              ) : (formValues.shuiruiCode && formValues.shuiruiCode.includes(KAIGO_CODE_SPECIAL)) ||
                KAIGO_CODE_SPECIAL.includes(formValues.shuiruiCode) ? (
                <Row style={props.isEditDialog ? { display: "none", marginTop: 10, marginBottom: 10 } : { marginTop: 10, marginBottom: 10 }}>
                  <Radio.Group onChange={onChangeKaigoCode} value={formValues.shuiruiCode}>
                    <Radio value={"21"}>短期生活</Radio>
                    <Radio value={"22"}>短期老健</Radio>
                    <Radio value={"23"}>短期療養</Radio>
                    <Radio value={"27"}>短期特定施設</Radio>
                    <Radio value={"28"}>短期地域特定</Radio>
                    <Radio value={"38"}>短期グループ</Radio>
                    <Radio value={"2A"}>介護医療院</Radio>
                    <Radio value={"68"}>短期小規模</Radio>
                    <Radio value={"79"}>短期看多機</Radio>
                  </Radio.Group>
                </Row>
              ) : (
                ""
              )}
              {formValues.shuiruiCode &&
                formValues.shuiruiCode != 17 &&
                !KAIGO_CODE_SPECIAL.includes(formValues.shuiruiCode) &&
                !(formValues.shuiruiCode == 76 && ["定期巡回随時I1", "定期巡回随時I2", "定期巡回随時II", "定期巡回随時III"].includes(formValues.serviceContent)) &&
                !(formValues.shuiruiCode == 71 && ["夜間訪問介護I", "夜間訪問介護Ⅱ"].includes(formValues.serviceContent)) ? (
                <Row style={{ marginTop: "10px" }}>
                  <Col span={4}>開始時間</Col>
                  <Col span={4}>
                    <TimeKBCus
                      timeString={startTime && startTime.length > 2 ? startTime : addServicesContants.TIME_DEFAULT}
                      handleInput={handleChangeStartTime}
                    />
                  </Col>
                  <Col span={14} style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ marginRight: 10 }}>終了時間</div>
                    <TimeKBCus
                      timeString={endTime && endTime.length > 2 ? endTime : addServicesContants.TIME_DEFAULT}
                      handleInput={handleChangeEndTime}
                    />
                    <AddTime timeSetting={props.timeSetting} onClickTime={handleOnClickTime} />
                    {isErrorTime ? <div style={{ marginLeft: 20, color: "red" }}>{"開始時間 < 終了時間"}</div> : ""}
                  </Col>
                </Row>
              ) : (
                ""
              )}
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <Col span={6}>サービス事業所</Col>
                <Col span={17}>
                  <div>
                    <Input
                      readOnly={true}
                      style={{ width: 280, backgroundColor: "#f5f5f5" }}
                      value={(jigyousyoNoServiceKikan || "") + "   " + (ryakuServiceKikan || "")}
                      onClick={() => {
                        if (!props.isEditDialog) {
                          handleShowServiceKikan();
                        } else {
                          setServiceKikanMasterVisible(true);
                        }
                      }}
                    />
                    <Button
                      type="primary"
                      onClick={() => {
                        if (!props.isEditDialog) {
                          handleShowServiceKikan();
                        } else {
                          setServiceKikanMasterVisible(true);
                        }
                      }}
                      style={props.isEditDialog ? { marginTop: "-2px" } : {}}
                    >
                      事
                    </Button>
                  </div>
                  <div
                    style={{
                      backgroundColor: "gainsboro",
                      width: "56%",
                      display: syuruiCd != 17 && syuruiCd != 11 && !["11", "17", ""].includes(syuruiCd) ? "flex" : "none",
                    }}
                  >
                    <span style={props.naiyou ? { paddingLeft: "8px", fontSize: "14px" } : {}}>{props.naiyou}</span>
                  </div>
                </Col>
              </Row>
              {formValues.shuiruiCode && formValues.shuiruiCode == 17 ? (
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Col span={6}>サービス内容</Col>
                  <Col span={17}>
                    <Select value={serviceCd} style={{ width: 170 }} onChange={handleChangeServiceCdRental}>
                      {props.listServiceNaiyouRental &&
                        props.listServiceNaiyouRental.map((item) => (
                          <Option key={item.id} value={item.kaigoServiceSyuruiCd + item.item}>
                            {item.serviceNaiyou}
                          </Option>
                        ))}
                    </Select>
                  </Col>
                </Row>
              ) : (
                ""
              )}

              {formValues.shuiruiCode && formValues.shuiruiCode != 17 ? (
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Col span={6}>サービス内容</Col>
                  <Col span={6}>
                    <Select value={serviceNaiyou} style={{ width: 150 }} onChange={handleChangeServiceNaiyou}>
                      {props.serviceNaiyou &&
                        props.serviceNaiyou.map((item, index) => (
                          <Option key={index} value={item.serviceNaiyou}>
                            {item.serviceNaiyou}
                          </Option>
                        ))}
                    </Select>
                  </Col>
                  <Col span={8}>
                    <Input
                      readOnly={true}
                      style={{
                        width: 150,
                        cursor: "not-allowed",
                        backgroundColor: colorSelected,
                      }}
                      value={props.syuruiCdRyaku || ""}
                    />
                  </Col>
                  {KAIGO_CODE_SPECIAL.includes(formValues.shuiruiCode) ? (
                    <Col span={4}>
                      <Checkbox checked={formValues.is30DayOver} onChange={(e) => setFormValues({ ...formValues, is30DayOver: e.target.checked })}>
                        30日超
                      </Checkbox>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
              ) : (
                ""
              )}

              {formValues.shuiruiCode && formValues.shuiruiCode == 17 ? (
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Col span={6}>商品名</Col>
                  <Col span={17} style={{ display: "flex" }}>
                    <Input style={{ width: "200px" }} value={rentalSyohinName} onClick={handleClickProduct} readOnly={true} />
                    <Button type="primary" onClick={handleClickProduct}>
                      商
                    </Button>
                  </Col>
                </Row>
              ) : (
                ""
              )}

              <Row style={{ marginTop: 10, display: "flex" }}>
                <Col span={6}>サービスコード</Col>
                <Col span={17}>
                  {rentalSyohinId ? (
                    <div>
                      <span style={{ width: "47px" }}>{serviceCd ? serviceCd : ""}</span>
                      <span style={{ marginLeft: "20px", width: "150px" }}>{serviceName ? serviceName : ""}</span>
                      <Input
                        style={
                          serviceName
                            ? { marginLeft: "30px", width: "70px", textOverflow: " ellipsis" }
                            : { marginLeft: "180px", width: "70px", textOverflow: " ellipsis" }
                        }
                        value={serviceTani ? serviceTani : ""}
                        onChange={onChangeTani}
                      />
                      <span>{rentalSyohinId != null ? " 単位" : ""}</span>
                    </div>
                  ) : formValues.shuiruiCode && formValues.shuiruiCode != 17 ? (
                    <div>
                      <span>{props.serviceCode && props.serviceCode.serviceCode !== "" ? props.serviceCode.serviceCode : ""}</span>
                      <span style={{ marginLeft: "20px" }}>{props.serviceCode && props.serviceCode.name !== "" ? props.serviceCode.name : ""}</span>
                      <span style={{ marginLeft: "30px" }}>{props.serviceCode && props.serviceCode.tani != null ? props.serviceCode.tani : ""}</span>
                      <span>{props.serviceCode && props.serviceCode.id != null ? " 単位" : ""}</span>
                    </div>
                  ) : (
                    <div>
                      <span style={{ width: "47px" }}>
                        {props.serviceCode && props.serviceCode.serviceCode != "" ? props.serviceCode.serviceCode : ""}
                      </span>
                      <span style={{ marginLeft: "20px", width: "150px" }}>
                        {props.serviceCode && props.serviceCode.name != "" ? props.serviceCode.name : ""}
                      </span>
                      {props?.serviceCode?.serviceCode && (
                        <Input
                          style={
                            props.serviceCode && props.serviceCode.name != ""
                              ? { marginLeft: "30px", width: "70px", textOverflow: " ellipsis" }
                              : { marginLeft: "180px", width: "70px", textOverflow: " ellipsis" }
                          }
                          value={props.serviceCode && props.serviceCode.tani != null ? props.serviceCode.tani : formValues.tani}
                          onChange={onChangeTani}
                        />
                      )}
                      <span>{props.serviceCode && props.serviceCode.id != null ? " 単位" : ""}</span>
                    </div>
                  )}
                </Col>
              </Row>
              <AddtionCodeList dataExpandCode={props.expandCode} visible={true}></AddtionCodeList>
            </Col>
            <Col span={7} style={{ display: "flex" }}>
              <div style={{ marginRight: 10 }}>加算</div>
              <div
                style={{
                  border: "2px solid #ccc",
                  width: "250px",
                  height: "520px",
                  overflowY: "scroll",
                }}
              >
                <ListServicesAddition
                  listServiceAddition={listServiceAddition}
                  formValues={formValues}
                  handleChangeServiceAddition={handleChangeServiceAddition}
                  loadingServiceCode={props.loadingServiceCode}
                  loadingServiceCodeExpand={props.loadingServiceCodeExpand}
                />
              </div>
            </Col>
          </Row>
        </div>
        {productMasterModalVisible && (
          <ProductMasterModal
            visible={productMasterModalVisible}
            serviceCd={serviceCd}
            serviceName={serviceName}
            serviceContentRental={props.listServiceNaiyouRental}
            hideModal={() => {
              props.onShowAddServicesModal();
              setProductMasterModalVisible(false);
            }}
            onOk={handleOkProduct}
            record={false}
          />
        )}
      </Modal>
      <ServiceKikanMasterModal
        checkIsSaveDataKikan={checkIsSaveDataKikan}
        visible={serviceKikanMasterVisible}
        checkHandleChangeData={checkHandleChangeData}
        startTime={startTime}
        endTime={endTime}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
        changeStartTime={handleChangeStartTime}
        changeEndTime={handleChangeEndTime}
        onOk={handleOkService}
        onCancel={handleCancelService}
        changeData={getChangeDataKikan}
        id={sid}
        syuruiCd={serviceSyuruiCd}
        modeModify={2}
        isEditDialog={props.isEditDialog}
      />
      <DatePickerUseSlipModal
        visible={visibleDateModal}
        nengetuStr={props.nengetuStr}
        handleDateCancel={handleDateCancel}
        handleDateOk={handleDateOk}
        nengetu={props.nengetu}
        formValues={formValues}
        isSelectTypeSort={isSelectTypeSort}
        listPlanDate={props.listPlanDate}
        listCheckDate={props.listCheckDate}
        isDoubleDialog={props.isDoubleDialog}
        clearCheckBox={clearCheckBox}
        setIsVisibleDialogConfirm={props.setIsVisibleDialogConfirm}
      />
      {formValues.shuiruiCode && formValues.shuiruiCode == 17 ? (
        <ServiceKikanRental
          visible={visibleServiceKikanRental}
          serviceType={servicesChecked}
          syuruiCd={"17"}
          hideModal={() => {
            props.onShowAddServicesModal();
            setVisibleServiceKikanRental(false);
          }}
          onSaveServiceKikan={onSaveServiceKikanRental}
          className="break-word-office"
        />
      ) : (
        <ServiceKikanWeekly
          startTime={startTime}
          endTime={endTime}
          changeStartTime={handleChangeStartTime}
          changeEndTime={handleChangeEndTime}
          visible={visibleServiceKikan}
          serviceType={servicesChecked}
          hideModal={() => {
            props.onShowAddServicesModal();
            setVisibleServiceKikan(false);
          }}
          onSaveServiceKikan={onSaveServiceKikan}
          syuruiCd={serviceSyuruiCd}
          kaigoCode={formValues.shuiruiCode}
          className="break-word-office"
          // idSelected={idSelected}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
        />
      )}
    </Fragment>
  );
}
const MemoAddServiceModal = memo(AddServicesModal);
export { MemoAddServiceModal };
