import axios from "axios";
import "whatwg-fetch";
import { ConfigName } from "../../../config-name";
import { apiUrls } from "../../common/configs/common-url";
import { localStorageClearCus } from "../../common/function_common/functionCommon";

const LogoutApi = {
  logout(isLoginPage) {
    return axios(apiUrls.INIT + apiUrls.LOGOUT, {
      method: "GET",
    })
      .then((res) => {
        localStorageClearCus();
        if (isLoginPage) {
          /**
           * redirect to login page
           */
          window.location.replace(`/mobile/login${localStorage.getItem("urlMoPrefix")}`);
        }
        else {
          return res.data;
        }
      })
      .catch((err) => {
        if (!err.response) {
          // network error
          // window.location.reload();
        }
        else {
          // Check Token Exist
          if (err.response.status === 403) {
            localStorage.removeItem(ConfigName.SWLIFE_TOKEN);
            localStorage.removeItem("doubleLogin");
            localStorage.removeItem("getAvatarResponse");
            localStorage.removeItem("getAvatarResponseData");
            window.location.replace(`/mobile/login${localStorage.getItem("urlMoPrefix")}`);
          }

          return err;
        }
      });
  },
};
export default LogoutApi;
