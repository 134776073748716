export const subsidyDetailAction = {
  GET_DETAIL_SUBSIDY: "GET_DETAIL_SUBSIDY",
  GET_DETAIL_SUBSIDY_SUCCESS: "GET_DETAIL_SUBSIDY_SUCCESS",
  GET_DETAIL_SUBSIDY_FAILURE: "GET_DETAIL_SUBSIDY_FAILURE",

  SAVE_DETAIL_SUBSIDY: "SAVE_DETAIL_SUBSIDY",
  SAVE_DETAIL_SUBSIDY_SUCCESS: "SAVE_DETAIL_SUBSIDY_SUCCESS",
  SAVE_DETAIL_SUBSIDY_FAILURE: "SAVE_DETAIL_SUBSIDY_FAILURE",

  GET_DATA_SUBSIDY: "GET_DATA_SUBSIDY",
  GET_DATA_SUBSIDY_SUCCESS: "GET_DATA_SUBSIDY_SUCCESS",
  GET_DATA_SUBSIDY_FAILURE: "GET_DATA_SUBSIDY_FAILURE",
  
  GET_ALL_SYURUI_MASTER: "GET_ALL_SYURUI_MASTER",
  GET_ALL_SYURUI_MASTER_SUCCESS: "GET_ALL_SYURUI_MASTER_SUCCESS",
  GET_ALL_SYURUI_MASTER_FAILURE: "GET_ALL_SYURUI_MASTER_FAILURE",

  GET_SERVICE_KIKAN_MASTER_ALL: "GET_SERVICE_KIKAN_MASTER_ALL",
  GET_SERVICE_KIKAN_MASTER_ALL_SUCCESS: "GET_SERVICE_KIKAN_MASTER_ALL_SUCCESS",
  GET_SERVICE_KIKAN_MASTER_ALL_FAILURE: "GET_SERVICE_KIKAN_MASTER_ALL_FAILURE",

  CREATE_KAIGOHOKEN_MASTER: "CREATE_KAIGOHOKEN_MASTER",
  CREATE_KAIGOHOKEN_MASTER_SUCCESS: "CREATE_KAIGOHOKEN_MASTER_SUCCESS",
  CREATE_KAIGOHOKEN_MASTER_FAILURE: "CREATE_KAIGOHOKEN_MASTER_FAILURE",

  UPDATE_KAIGOHOKEN_MASTER: "UPDATE_KAIGOHOKEN_MASTER",
  UPDATE_KAIGOHOKEN_MASTER_SUCCESS: "UPDATE_KAIGOHOKEN_MASTER_SUCCESS",
  UPDATE_KAIGOHOKEN_MASTER_FAILURE: "UPDATE_KAIGOHOKEN_MASTER_FAILURE",

  QUOTE_BUTTON: "QUOTE_BUTTON",
  QUOTE_BUTTON_SUCCESS: "QUOTE_BUTTON_SUCCESS",
  QUOTE_BUTTON_FAILURE: "QUOTE_BUTTON_FAILURE",


  REDIRECT_TO_TICKET: "REDIRECT_TO_TICKET",
  REDIRECT_TO_TICKET_SUCCESS: "REDIRECT_TO_TICKET_SUCCESS",
  REDIRECT_TO_TICKET_FAILURE:"REDIRECT_TO_TICKET_FAILURE",


  
  GET_RIYOU_KAIGO_UPDATE: "GET_RIYOU_KAIGO_UPDATE",
  GET_RIYOU_KAIGO_UPDATE_SUCCESS: "GET_RIYOU_KAIGO_UPDATE_SUCCESS",
  GET_RIYOU_KAIGO_UPDATE_FAILURE:"GET_RIYOU_KAIGO_UPDATE_FAILURE",

  UPDATE_SUBSIDY_DETAIL: "UPDATE_SUBSIDY_DETAIL",
};