import { checkTemplate } from "@pdfme/common";
import _ from "lodash";

import { life_JokusoPlan_JokusoPlanInputFormPDF } from "./life/JokusoPlan/JokusoPlanInputFormPDF";
import { life_JokusoPlan_JokusoPlanInputFormV3PDF } from "./life/JokusoPlan/JokusoPlanInputFormV3PDF";
import { life_KoKuTab_inputPDF } from "./life/KoKuTab/inputPDF";
import { life_KoKuTab_inputV3PDF } from "./life/KoKuTab/inputV3PDF";
import { life_Haisetsu_InputFormPDF } from "./life/HaisetsuTab/InputFormPDF";
import { life_Kobetsu_InputFormPDF } from "./life/KobetsuTab/InputFormPDF";
import { life_Rehabili_InputFormPDF } from "./life/RehabiliPlanTab/InputFormPDF";
import { management_Useslip_InputFormPDF } from "./management/useslip/InputFormUseslipPDF";
import { management_Attached_InputFormPDF } from "./management/Attached/InputFormAttachedPDF";
import { plan1_InputFormPDF } from "./management/Plan1Tab/InputFormPDF";
import { plan2_InputFormPDF } from "./management/Plan2Tab/InputFormPDF";
import { life_Haisetsu_InputFormV3PDF } from "./life/HaisetsuTab/InputFormV3PDF";
import { life_Seikatsu_InputFormPDF } from "./life/SeikatsuTab/InputFormPDF";
import { life_Kobetsu_InputFormV3PDF } from "./life/KobetsuTab/InputFormV3PDF";
import { life_Rehabili_InputFormV3PDF } from "./life/RehabiliPlanTab/InputFormV3PDF";
import { life_Tantokaigi_InputFormPDF } from "./life/TantokaigiTab/InputFormPDF";

export const readFile = (file, type) => {
  return new Promise((r) => {
    const fileReader = new FileReader();
    fileReader.addEventListener("load", (e) => {
      if (e && e.target && e.target.result && file !== null) {
        r(e.target.result);
      }
    });
    if (file !== null) {
      if (type === "text") {
        fileReader.readAsText(file);
      } else if (type === "dataURL") {
        fileReader.readAsDataURL(file);
      } else if (type === "arrayBuffer") {
        fileReader.readAsArrayBuffer(file);
      }
    }
  });
};

export const cloneDeep = (obj) => JSON.parse(JSON.stringify(obj));

export const getTemplateFromJsonFile = (file) => {
  return readFile(file, "text").then((jsonStr) => {
    const template = JSON.parse(jsonStr);
    try {
      checkTemplate(template);
      return template;
    } catch (e) {
      throw e;
    }
  });
};

export const downloadJsonFile = async (json, title, fileName) => {
  if (typeof window !== "undefined") {
    const blob = new Blob([JSON.stringify(json)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    if (fileName) {
      link.download = fileName;
    } else {
      const handle = await showSaveFilePicker({
        suggestedName: title,
        types: [
          {
            description: "JSON",
            accept: { "text/plain": [".json"] },
          },
        ],
      });
      const writableStream = await handle.createWritable();
      await writableStream.write(blob);
      await writableStream.close();
      link.download = handle.name;
    }
    link.click();
    URL.revokeObjectURL(url);
  }
};

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getTemplate = (pdfId, columns, data, jobList, tantoMaster, pageNumber = 0) => {
  const deleteSampledataNotExistColumns = (sampledata) => {
    const filterSchemas = Object.keys(sampledata[0]).filter((e) => !columns.includes(e));
    filterSchemas.forEach((e) => delete sampledata[0][e]);
    return filterSchemas;
  };

  // 褥瘡v0200
  if (pdfId === "3") {
    let sampledata = life_JokusoPlan_JokusoPlanInputFormPDF(data);
    deleteSampledataNotExistColumns(sampledata);
    return sampledata;
  }
  // 褥瘡v0300
  if (pdfId === "17") {
    let sampledata = life_JokusoPlan_JokusoPlanInputFormV3PDF(data);
    deleteSampledataNotExistColumns(sampledata);
    return sampledata;
  }
  // 口腔機能v0200
  if (pdfId === "4") {
    let sampledata = life_KoKuTab_inputPDF(data, jobList, tantoMaster);
    deleteSampledataNotExistColumns(sampledata);
    return sampledata;
  }
  // 口腔機能v0300
  if (pdfId === "22") {
    let sampledata = life_KoKuTab_inputV3PDF(data, jobList, tantoMaster);
    deleteSampledataNotExistColumns(sampledata);
    return sampledata;
  }
  //排せつv0200
  if (pdfId === "5") {
    let sampledata = life_Haisetsu_InputFormPDF(data);
    deleteSampledataNotExistColumns(sampledata);
    return sampledata;
  }
  //排せつv0300
  if (pdfId === "18") {
    let sampledata = life_Haisetsu_InputFormV3PDF(data);
    deleteSampledataNotExistColumns(sampledata);
    return sampledata;
  }
  if (pdfId === "6") {
    let sampledata = life_Kobetsu_InputFormPDF(data, jobList);
    deleteSampledataNotExistColumns(sampledata);
    return sampledata;
  }
  // リハビリv0200
  if (pdfId === "7") {
    let sampledata = life_Rehabili_InputFormPDF(data, jobList);
    deleteSampledataNotExistColumns(sampledata);
    return sampledata;
  }
  if (pdfId === "8" || pdfId === "10") {
    let sampledata = management_Useslip_InputFormPDF(data, jobList);
    deleteSampledataNotExistColumns(sampledata);
    return sampledata;
  }
  if (pdfId === "9" || pdfId === "11") {
    let sampledata = management_Attached_InputFormPDF(data, pageNumber);
    deleteSampledataNotExistColumns(sampledata);
    return sampledata;
  }
  if (pdfId === "12") {
    let sampledata = [{ ...data }];
    deleteSampledataNotExistColumns(sampledata);
    return sampledata;
  }

  // リハビリv0300
  if (pdfId === "21") {
    let sampledata = life_Rehabili_InputFormV3PDF(data, jobList);
    deleteSampledataNotExistColumns(sampledata);
    return sampledata;
  }
  // plan1
  if (pdfId === "13" || pdfId === "14") {
    let sampledata = plan1_InputFormPDF(data);
    deleteSampledataNotExistColumns(sampledata);
    return sampledata;
  }
  // plan2
  if (pdfId === "15" || pdfId === "16") {
    let sampledata = plan2_InputFormPDF(data);
    deleteSampledataNotExistColumns(sampledata);
    return sampledata;
  }
  // 個別機能
  if (pdfId === "19") {
    let sampledata = life_Kobetsu_InputFormV3PDF(data, jobList);
    deleteSampledataNotExistColumns(sampledata);
    return sampledata;
  }
  // 生活機能
  if (pdfId === "20") {
    let sampledata = life_Seikatsu_InputFormPDF(data, jobList);
    deleteSampledataNotExistColumns(sampledata);
    return sampledata;
  }

  // リハ担当会議
  if (pdfId === "23") {
    let sampledata = life_Tantokaigi_InputFormPDF(data, jobList);
    console.log(data, jobList);
    deleteSampledataNotExistColumns(sampledata);
    return sampledata;
  }
};
