export const ApiPaths = {
  Account: {
    SignIn: "/api/v1/mobile-login-t1",
    SignInM: "/api/v1/mobile-login-t2",
    SignOut: "/api/v1/logout",
    Mainainance: "/api/v1/maintenance-modeMOBILE",
  },

  mobile: {
    getQrcodeKeys: "/api/v1/mobile/userkanri/qrcodekeys",
    getUserDevices: "/api/v1/mobile/userkanri/userdevices", // {/userId}
    deleteUserDevice: "/api/v1/mobile/userkanri/deleteuserdevice", // {/id}
  },
  common: {
    getRoom: "/api/v1/mobole-get-room-by-url",
    getYougoMaster: "/api/v1/mobole-get-yougo-master",
    updateYougoMaster: "/api/v1/mobole-update-yougo-master",
    createYougoMaster: "/api/v1/mobole-create-yougo-master",
    getSettingMaster: "/api/v1/mobole-get-setting-master-all",
  },
  nyutaisyo: {
    getFirstData: "/api/v1/mobole-get-first-data",
    getRiyousyaList: "/api/v1/mobole-get-riyousya-list",
    getRooms: "/api/v1/mobile-get-rooms",
  },
  vital: {
    getRecords: "/api/v1/mobole-get-vital-records",
    getYougoMaster: "/api/v1/mobole-get-vital-yougo-master",
    getSettingMaster: "/api/v1/mobole-get-vital-setting-master",
    getPreviousData: "/api/v1/mobole-get-vital-previous-data",
    create: "/api/v1/mobole-create-vital",
    update: "/api/v1/mobole-update-vital",
  },
  haisetu: {
    getRecords: "/api/v1/mobile-get-haisetu-records",
    getYougoMaster: "/api/v1/mobile-get-haisetu-yougo-master",
    save: "/api/v1/mobile-save-haisetu",
    getLastShit: "/api/v1/mobile-get-last-bowel-movement-date",
    getPreviousData: "/api/v1/mobile-get-haisetu-previous-data",
  },
  meal: {
    getRecords: "/api/v1/mobole-get-meal-records",
    getYougoMaster: "/api/v1/mobole-get-meal-yougo-master",
    getSettingMaster: "/api/v1/mobole-get-meal-setting-master",
    create: "/api/v1/mobole-create-meal",
    update: "/api/v1/mobole-update-meal",
  },
  suibun: {
    getRecords: "/api/v1/mobole-get-suibun-records",
    getYougoMaster: "/api/v1/mobole-get-suibun-yougo-master",
    getSettingMaster: "/api/v1/mobole-get-suibun-setting-master",
    getTotalSuibun: "/api/v1/mobole-get-total-suibun",
    create: "/api/v1/mobole-create-suibun",
    update: "/api/v1/mobole-update-suibun",
  },
};
