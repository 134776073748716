import { NyutaisyoActionTypes } from "../action_types";
import { showToast } from "../../common/function_common/functionCommon";
import i18n from "../../i18n/config";

const initState = {
  firstLoading: false,
  loading: false,
  firstData: null,
  rooms: [],
};

function nyutaisyo(state = initState, action) {
  const { t } = i18n;

  switch (action.type) {
    case NyutaisyoActionTypes.LOAD_SPIN_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case NyutaisyoActionTypes.GET_FIRST_DATA_REQUEST: {
      return {
        ...state,
        firstLoading: true,
      };
    }
    case NyutaisyoActionTypes.GET_FIRST_DATA_SUCCESS: {
      return {
        ...state,
        firstLoading: false,
        firstData: action.data,
      };
    }
    case NyutaisyoActionTypes.GET_FIRST_DATA_ERROR: {
      return {
        ...state,
        firstLoading: false,
        firstData: null,
      };
    }

    case NyutaisyoActionTypes.GET_RIYOUSYA_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        riyousyaList: action.data,
      };
    }

    case NyutaisyoActionTypes.GET_RIYOUSYA_LIST_ERROR: {
      showToast("fail", t("message.利用者の取得に失敗しました"));
      return {
        ...state,
        loading: false,
        riyousyaList: [],
      };
    }

    case NyutaisyoActionTypes.GET_RIYOUSYA_LIST_EMPTY: {
      showToast("fail", t("message.利用者が見つかりませんでした"));
      return {
        ...state,
        loading: false,
        riyousyaList: [],
      };
    }

    case NyutaisyoActionTypes.GET_ROOMS_SUCCESS: {
      const data = action.data.map((value) => ({ value: value, label: value.includes(" ") ? value.split(" ")[1] : value }));
      data.push({ value: "", label: "全て" });
      return {
        ...state,
        rooms: data,
      };
    }

    case NyutaisyoActionTypes.GET_ROOMS_ERROR: {
      return {
        ...state,
        rooms: [],
      };
    }

    case NyutaisyoActionTypes.RESET_RIYOUSYA_DATA: {
      return {
        ...state,
        riyousyaList: [],
      };
    }

    default: {
      return { ...state };
    }
  }
}

export default nyutaisyo;
