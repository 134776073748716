export const General = {
  SecretKey: "idontknowwhatiamdoinghere",
  PathPrefix: "mobile",
  PathAdminPrefix: "userkanri",
  Logo: require("../assets/images/logo.png"),
  suibunImage: require("../assets/images/water.png"),
  haisetuImage: require("../assets/images/haisetu.png"),
  mealImage: require("../assets/images/meal.png"),
  vitalImage: require("../assets/images/vital.png"),
  FontIPAGP: require("../../fonts/examples/fonts/ipagp.ttf"),
};
