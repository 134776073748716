
import { CheckSquareFilled } from "@ant-design/icons";
import { formatSeireki, formatBirthday } from "../../../function_common/functionCommon";
import _ from "lodash";

export const life_Rehabili_InputFormV3PDF = (data, jobList) => {
  const { rehabiliPlan, assessMMSE, adlItems, adlitemAnswerNaiyou, adlKankyou, saisinRecord, icfMaster, needItem, IADL, sienNaiyou, menu, lifeYougoList } = data;


  const GENDER_OPTIONS = [
    {
      label: "男",
      value: 1,
    },
    {
      label: "女",
      value: 2,
    },
  ];

  const generateGenderString = (options, optionSelected) => {
    let result = "";
    options.forEach((item) => {
      if (item.value === optionSelected) {
        result += item.label + " ";
      }
    });
    return result;
  };

  const formatJob = (num) => {
    let result = "";

    if (num && jobList?.naiyou?.split(";").length > 0) {
      jobList.naiyou?.split(";").map((item) => {
        if (item?.split(":")[0] == num) {
          result = item?.split(":")[1];
        }
      });
    }
    return result ?? "";
  };


  const byoumeiGenerate = (string, options) => {
    let result = "";
    string.split(",").map((code) => {
      const obj = options?.find(item => item.ikosakiCd === code);
      if (obj) result += obj.syoubyoumei + "\n"
    })

    return result;
  }

  const getMMSE = (datas, items, option, key) => {
    let result = "";
    const obj = items?.filter((item) => option.includes(item.naiyou));

    obj?.map((i) => {
      datas?.map((j) => {
        if (i.id === j.rehaAssessAdlItemId) {
          
          if (key === "title") {
            option.split(",")?.forEach((text) => {
              if (text === i.naiyou) {
                result += "■" + text;
              } else {
                result += "□" + text;
              }
            })
          }
          if (key === "answer") {
            result = j.answer ?? "";
          }
          if (key === "naiyou") {
            result = j.comment ?? "";
          }
        }
      })
    })
    
    return result;
  }

  const getTitle = (items, num) => {
    let result = "";
    if (items?.length > 0) {
      items.map((item, index) => {
        if (num === index) {
          result = item.naiyou;
        }
      })
    }
    return result;
  }

  const getMMSENaiyou = (optins, text, data, type) => {
    let result = "";
    const id = optins.find((item) => text.includes(item.naiyou))?.id;
    if(id) {
      result = data.find((as) => as.rehaAssessAdlItemId === id);
    }
    if (text === "コミュニケーションの状況") {
      if (type === "answer") {
        return `${result?.answer ?? ""}`;
      } else if (type === "comment") {
        return `${result?.comment ?? ""}`;
      }
    } else if (text === "服薬管理") {
      if (type === "answer") {
        return `${result?.answer ?? ""}`;
      } else if (type === "comment") {
        return `${result?.comment ?? ""}`;
      }
    }
  }

  const getRehaAssessSub = (datas, items, naiyou, key) => {
    let result = null;
    if (datas?.length > 0 && items?.length > 0) {
      const item = items.filter(value => value.naiyou === naiyou);
      datas.map((data) => {
        if (data.rehaAssessAdlItemId === item[0]?.id) {
          result = data[key];
        }
      })
    }

    if (naiyou === "外出手段" && result != null) {
      result = result.substring(result.indexOf("(") + 1, result.lastIndexOf(")"));
    }
    return result;
  }

  const getRehaAssessmentSubNaiyou = (datas, items, key, index) => {
    let result = "";
    if (datas?.length > 0 && items?.length > 0) {
      datas.map((data) => {
        if (data.rehaAssessAdlItemId === items[index].id) {
          result = data[key];
        }
      })
    }
    return result;
  }

  const getMMSETitle = (items, num) => {
    let result = "";
    if (items?.length > 0) {
      items.map((item, index) => {
        if (num === index) {
          result = item.naiyou;
        }
      })
    }
    return result;
  }

  const getTotalPoint = (datas, items) => {
    let result = 0;
    if (datas?.length > 0 && items?.length > 0) {
      items.map((item) => {
        datas.map((data) => {
          if (data.rehaAssessAdlItemId === item.id) {
            //一致したらdata.answerをポイントに変えて足す
            const answerArr = item.answerNaiyou.split(",");
            const pointArr = item.point.split(",");
            for (let i = 0; i < answerArr.length; i++) {
              if (answerArr[i] === data.answer) {
                return result += parseInt(pointArr[i]);
              }
            }
          }
        })
      })
    }
    return result.toString();
  }

  const getMasterNaiyou = (string, items, naiyou) => {
    let result = "";
    if (string && items?.length > 0) {
      string.split(",").map((num) => {
        if (naiyou === "icf") {
          const obj = items.find((item) => item.icfCd === num)
          if (obj)  result += obj.koui + "\n"
        } else if (naiyou === "need") {
          const obj = items.find((item) => item.cd === num)
          if (obj)  result += obj.naiyou + "\n"
        }
      })
    }
    return result;
  }

  const getRehabiliPlanSub = (datas, key, number, index) => {
    const { sienNaiyou } = lifeYougoList;
    let result = "";

    const getSienNaiyou = (string, data, index) => {
      let sien = "";
      if (string && sienNaiyou) {
        let st = string?.split(",")[index]
        sienNaiyou.split(";").map((item) => {
          if (item.split(":")[0] === st) {
            sien = item.split(":")[1]
          }
        })
      }
      // if(sien) {
      //   sien += "\n" + data.sienNaiyou;
      // } else {
      //   sien = data.sienNaiyou;
      // }
      return sien;
    }

    const getIcf = (string) => {
      let icf = "";
      if(string && icfMaster?.length > 0) {
        icfMaster.map((item) => {
          if (item.icfCd === string) {
            icf = item.koui
          }
        })
      }
      return icf;
    }

    if (datas && datas.length > number) {
      let result2 = "";
      const obj = datas[number]
      if (obj) result = obj[key]?.toString() ?? "";
      if (key === "mokuhyou") {
        result = getIcf(obj.mokuhyouIcfcd) + "\n" + result;
      }
      if (key === "rehaProgram") {
        result.split(",").map((st) => {
          result2 += formatJob(st) + "\n"
        })
        result = result2;
      }
      if (key === "sienCds") {
        result = getSienNaiyou(result, obj, index) + "\n"
      }
      if (key === "hindo") {
        result = result.split(",")[index];
        result = result ? "週" + result + "回" : "";
      }
      if (key === "jikan") {
        result = result.split(",")[index];
        result = result ? result + "分/回" : "";
      }
      if (key === "kikanNum" && result) {
        result = result + "/月";
      }
    }
    return result;
  }

  const getSystemName = (sn) => {
    if (sn === "rehabili") {
      return "通所";
    } else if (sn === "visitreha") {
      return "訪問";
    } else if (sn === "rouken") {
      return "入所";
    }
  }

  const splitComplications = (array, type, other) => {
    const { gappeisyou, gappeisyouOther } = lifeYougoList;
    const dataList = array?.split(",");
    let resultList = null;
    if (type === "合併症" && dataList) {
      const gappeiList = gappeisyou.split(";");
      resultList = dataList.filter(item => gappeiList.includes(item));
      return resultList?.toString();
    } else if (type === "合併症他" && dataList) {
      const gappeiOtherList = gappeisyouOther.split(";");
      resultList = dataList.filter(item => gappeiOtherList.includes(item))
      if (resultList.includes("その他")) {
        const index = resultList.indexOf("その他");
        resultList.splice(index, 1, "その他（" + other + "）");
      }
      return resultList?.toString();
    }
  }

  const getSeminarStatus = (value) => {
    const { kensyuu } = lifeYougoList;
    if (value > 0) {
      return kensyuu.split(";").at(value - 1);
    }
  }

  const getKadaiYoinBunseki = (string, checks, type, index) => {
    let result = "";
    
    if (type === 2) {
      const checksArray = checks?.split(",");
      const check = checksArray?.at(index) === "1" ? "■" : "□";

      result = check + " " + string;
    } else if (type === 3) {
      const checksArray = checks?.split(",");
      const check = checksArray?.at(index) === "1" ? "■" : "□";

      result = check + " " + string;
    }

    return result;
  }

  if (typeof rehabiliPlan === 'object' && Array.isArray(rehabiliPlan)) {
    let sampledata = [];
    rehabiliPlan?.map((rehabiliPlan) => {
      sampledata.push({
        事業所番号: rehabiliPlan?.serviceKikanMaster?.jigyousyoNo ?? "",
        システム名: getSystemName(menu?.sn) ?? "",
        評価日: formatSeireki(rehabiliPlan?.date) ?? "",
        氏名1: rehabiliPlan?.riyousya?.name ?? "",
        性別: `${generateGenderString(GENDER_OPTIONS, rehabiliPlan?.riyousya?.gender ?? "")}`,
        生年月日: formatBirthday(rehabiliPlan?.riyousya?.birthday) ?? "",
        要介護度: rehabiliPlan?.riyouKaigohoken?.kaigodoMaster?.kaigodoName ?? "",
        リハビリテーション担当医: `${rehabiliPlan?.doctorMaster?.name ?? ""}　${rehabiliPlan?.doctorMaster?.hospital ?? ""}` ?? "",
        リハ担当者: `${rehabiliPlan?.tantoMaster?.name ?? ""}　${formatJob(rehabiliPlan?.tantoMaster?.jobCategory) ?? ""}` ?? "",
        "本人・家族等の希望": rehabiliPlan?.kazokuKibo ?? "",
        原因疾患: rehabiliPlan?.shikkan ?? "",
        "発症日・受傷日": rehabiliPlan.hasshoDate != "0001-01-01" && rehabiliPlan.hasshoDate != "1901-01-01" ? formatSeireki(rehabiliPlan.hasshoDate) ?? "" : "",
        直近の入院日: rehabiliPlan.nyuinDate != "0001-01-01" && rehabiliPlan.nyuinDate != "1901-01-01" ? formatSeireki(rehabiliPlan.nyuinDate) ?? "" : "",
        直近の退院日: rehabiliPlan.taiinDate != "0001-01-01" && rehabiliPlan.taiinDate != "1901-01-01" ? formatSeireki(rehabiliPlan.taiinDate) ?? "" : "",
        治療経過: rehabiliPlan?.keika ?? "",
        合併症: splitComplications(rehabiliPlan.complications, "合併症") ?? "",
        上記以外の疾患: splitComplications(rehabiliPlan.complications, "合併症他", rehabiliPlan.gapeiShipei) ?? "",
        コントロール状態: rehabiliPlan?.complicationsCtrl ?? "",
        これまでのリハビリテーション実施状況: rehabiliPlan?.jisshijoukyou ?? "",
        "目標設定・管理シート": rehabiliPlan?.mokuhyoKanriSheet ?? "",
        日常生活自立度: rehabiliPlan?.jiritudoSyougai ?? "",
        認知度: rehabiliPlan?.jirutudoNinti ?? "",
        "筋力低下(状況)": rehabiliPlan?.kinryokuTeika?.split(",")[0] ?? "",
        "筋力低下(支障)": rehabiliPlan?.kinryokuTeika?.split(",")[1] ?? "",
        "筋力低下(見込み)": rehabiliPlan?.kinryokuTeika?.split(",")[2] ?? "",
        "麻痺(状況)": rehabiliPlan?.mahi?.split(",")[0] ?? "",
        "麻痺(支障)": rehabiliPlan?.mahi?.split(",")[1] ?? "",
        "麻痺(見込み)": rehabiliPlan?.mahi?.split(",")[2] ?? "",
        "感覚機能障害(状況)": rehabiliPlan?.kankakukinoShogai?.split(",")[0] ?? "",
        "感覚機能障害(支障)": rehabiliPlan?.kankakukinoShogai?.split(",")[1] ?? "",
        "感覚機能障害(見込み)": rehabiliPlan?.kankakukinoShogai?.split(",")[2] ?? "",
        "関節可動域制限(状況)": rehabiliPlan?.kansetuKoushuku?.split(",")[0] ?? "",
        "関節可動域制限(支障)": rehabiliPlan?.kansetuKoushuku?.split(",")[1] ?? "",
        "関節可動域制限(見込み)": rehabiliPlan?.kansetuKoushuku?.split(",")[2] ?? "",
        "摂食嚥下障害(状況)": rehabiliPlan?.sesshokuEngeShogai?.split(",")[0] ?? "",
        "摂食嚥下障害(支障)": rehabiliPlan?.sesshokuEngeShogai?.split(",")[1] ?? "",
        "摂食嚥下障害(見込み)": rehabiliPlan?.sesshokuEngeShogai?.split(",")[2] ?? "",
        "失語症構音障害(状況)": rehabiliPlan?.situgoKoonShogai?.split(",")[0] ?? "",
        "失語症構音障害(支障)": rehabiliPlan?.situgoKoonShogai?.split(",")[1] ?? "",
        "失語症構音障害(見込み)": rehabiliPlan?.situgoKoonShogai?.split(",")[2] ?? "",
        "見当識障害(状況)": rehabiliPlan?.kentosikiShogai?.split(",")[0] ?? "",
        "見当識障害(支障)": rehabiliPlan?.kentosikiShogai?.split(",")[1] ?? "",
        "見当識障害(見込み)": rehabiliPlan?.kentosikiShogai?.split(",")[2] ?? "",
        "記憶障害(状況)": rehabiliPlan?.kiokuShogai?.split(",")[0] ?? "",
        "記憶障害(支障)": rehabiliPlan?.kiokuShogai?.split(",")[1] ?? "",
        "記憶障害(見込み)": rehabiliPlan?.kiokuShogai?.split(",")[2] ?? "",
        高次脳機能障害: rehabiliPlan?.kojinoShogai?.split(",")[3] ?? "",
        "高次脳機能障害(状況)": rehabiliPlan?.kojinoShogai?.split(",")[0] ?? "",
        "高次脳機能障害(支障)": rehabiliPlan?.kojinoShogai?.split(",")[1] ?? "",
        "高次脳機能障害(見込み)": rehabiliPlan?.kojinoShogai?.split(",")[2] ?? "",
        "栄養障害(状況)": rehabiliPlan?.eiyoShogai?.split(",")[0] ?? "",
        "栄養障害(支障)": rehabiliPlan?.eiyoShogai?.split(",")[1] ?? "",
        "栄養障害(見込み)": rehabiliPlan?.eiyoShogai?.split(",")[2] ?? "",
        "疼痛(状況)": rehabiliPlan?.totu?.split(",")[0] ?? "",
        "疼痛(支障)": rehabiliPlan?.totu?.split(",")[1] ?? "",
        "疼痛(見込み)": rehabiliPlan?.totu?.split(",")[2] ?? "",
        "精神行動障害(状況)": rehabiliPlan?.seisinkodoShogai?.split(",")[0] ?? "",
        "精神行動障害(支障)": rehabiliPlan?.seisinkodoShogai?.split(",")[1] ?? "",
        "精神行動障害(見込み)": rehabiliPlan?.seisinkodoShogai?.split(",")[2] ?? "",
        心身機能1: getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "6分間歩行試験,TimeUp&GoTest", "title") ?? "",
        "心身機能1(数値)": getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "6分間歩行試験,TimeUp&GoTest", "answer") ?? "",
        "心身機能1(内容)": getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "6分間歩行試験,TimeUp&GoTest", "naiyou") ?? "",
        服薬管理: getMMSENaiyou(assessMMSE, "服薬管理", rehabiliPlan?.rehaAssessSub, "answer") ?? "",
        "服薬管理(内容)": getMMSENaiyou(assessMMSE, "服薬管理", rehabiliPlan?.rehaAssessSub, "comment") ?? "",
        心身機能2: getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "MMSE,HDS-R", "title") ?? "",
        "心身機能2(数値)": getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "MMSE,HDS-R", "answer") ?? "",
        "心身機能2(内容)": getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "MMSE,HDS-R", "naiyou") ?? "",
        コミュニケーションの状況: getMMSENaiyou(assessMMSE, "コミュニケーションの状況", rehabiliPlan?.rehaAssessSub, "answer") ?? "",
        "コミュニケーションの状況(内容)": getMMSENaiyou(assessMMSE, "コミュニケーションの状況", rehabiliPlan?.rehaAssessSub, "comment") ?? "",
        MMSE1: `${getMMSETitle(adlitemAnswerNaiyou, 0)}`,
        "MMSE1(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 0) ?? "",
        "MMSE1(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 0) ?? "",
        "MMSE1(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 0) ?? "",
        MMSE2: getMMSETitle(adlitemAnswerNaiyou, 1),
        "MMSE2(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 1) ?? "",
        "MMSE2(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 1) ?? "",
        "MMSE2(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 1) ?? "",
        MMSE3: getMMSETitle(adlitemAnswerNaiyou, 2),
        "MMSE3(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 2) ?? "",
        "MMSE3(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 2) ?? "",
        "MMSE3(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 2) ?? "",
        MMSE4: getMMSETitle(adlitemAnswerNaiyou, 3),
        "MMSE4(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 3) ?? "",
        "MMSE4(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 3) ?? "",
        "MMSE4(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 3) ?? "",
        MMSE5: getMMSETitle(adlitemAnswerNaiyou, 4),
        "MMSE5(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 4) ?? "",
        "MMSE5(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 4) ?? "",
        "MMSE5(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 4) ?? "",
        ADL1: getTitle(adlItems, 0),
        "ADL1(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 0) ?? "",
        "ADL1(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 0) ?? "",
        "ADL1(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 0) ?? "",
        ADL2: getTitle(adlItems, 1),
        "ADL2(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 1) ?? "",
        "ADL2(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 1) ?? "",
        "ADL2(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 1) ?? "",
        ADL3: getTitle(adlItems, 2),
        "ADL3(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 2) ?? "",
        "ADL3(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 2) ?? "",
        "ADL3(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 2) ?? "",
        ADL4: getTitle(adlItems, 3),
        "ADL4(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 3) ?? "",
        "ADL4(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 3) ?? "",
        "ADL4(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 3) ?? "",
        ADL5: getTitle(adlItems, 4),
        "ADL5(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 4) ?? "",
        "ADL5(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 4) ?? "",
        "ADL5(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 4) ?? "",
        ADL6: getTitle(adlItems, 5),
        "ADL6(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 5) ?? "",
        "ADL6(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 5) ?? "",
        "ADL6(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 5) ?? "",
        ADL7: getTitle(adlItems, 6),
        "ADL7(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 6) ?? "",
        "ADL7(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 6) ?? "",
        "ADL7(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 6) ?? "",
        ADL8: getTitle(adlItems, 7),
        "ADL8(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 7) ?? "",
        "ADL8(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 7) ?? "",
        "ADL8(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 7) ?? "",
        ADL9: getTitle(adlItems, 8),
        "ADL9(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 8) ?? "",
        "ADL9(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 8) ?? "",
        "ADL9(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 8) ?? "",
        ADL10: getTitle(adlItems, 9),
        "ADL10(リハ開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems, "answer", 9) ?? "",
        "ADL10(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 9) ?? "",
        "ADL10(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 9) ?? "",
        "ADL合計点(リハ開始時)": getTotalPoint(rehabiliPlan?.saisinRecord?.rehaAssessSub, adlItems) ?? "",
        "ADL合計点(現在の状況)": getTotalPoint(rehabiliPlan?.rehaAssessSub, adlItems) ?? "",
        "リハビリテーション短期目標(心身機能)": getMasterNaiyou(rehabiliPlan?.shortMokuhyouFunction, icfMaster, "icf") ?? "",
        "短期目標内容(心身機能)": rehabiliPlan?.shortMokuhyouFunctionNaiyou ?? "",
        "リハビリテーション短期目標(活動)": getMasterNaiyou(rehabiliPlan?.shortMokuhyouActivity, icfMaster, "icf") ?? "",
        "短期目標内容(活動)": rehabiliPlan?.shortMokuhyouActivityNaiyou ?? "",
        "リハビリテーション短期目標(参加)": getMasterNaiyou(rehabiliPlan?.shortMokuhyouJoin, icfMaster, "icf") ?? "",
        "短期目標内容(参加)": rehabiliPlan?.shortMokuhyouJoinNaiyou ?? "",
        "リハビリテーション長期目標(心身機能)": getMasterNaiyou(rehabiliPlan?.longMokuhyouFunction, icfMaster, "icf") ?? "",
        "長期目標内容(心身機能)": rehabiliPlan?.longMokuhyouFunctionNaiyou ?? "",
        "リハビリテーション長期目標(活動)": getMasterNaiyou(rehabiliPlan?.longMokuhyouActivity, icfMaster, "icf") ?? "",
        "長期目標内容(活動)": rehabiliPlan?.longMokuhyouActivityNaiyou ?? "",
        "リハビリテーション長期目標(参加)": getMasterNaiyou(rehabiliPlan?.longMokuhyouJoin, icfMaster, "icf") ?? "",
        "長期目標内容(参加)": rehabiliPlan?.longMokuhyouJoinNaiyou ?? "",
        "リハビリテーションの方針(今後3ヶ月間)": rehabiliPlan?.rehaHosin ?? "",
        "本人・家族への生活指導の内容": rehabiliPlan?.lifeGuidance ?? "",
        リハビリテーション実施上の留意点: rehabiliPlan?.rehaRyui ?? "",
        "リハビリテーションの見通し・継続理由": rehabiliPlan?.review ?? "",
        "リハビリテーション(終了の目安となる時期)": rehabiliPlan?.timeEstimate?.toString() ?? "",
        "リハビリテーション終了の目安・時期": rehabiliPlan?.rehaMeyasu ?? "",
        特記事項: rehabiliPlan?.toki ?? "",
        //2ページ
        氏名2: rehabiliPlan?.riyousya?.name ?? "",
        "家族": getRehaAssessSub(rehabiliPlan?.rehaAssessSub, adlKankyou, "家族・介護者", "comment") ?? "",
        "住環境": getRehaAssessSub(rehabiliPlan?.rehaAssessSub, adlKankyou, "住環境", "comment") ?? "",
        "自宅周辺": getRehaAssessSub(rehabiliPlan?.rehaAssessSub, adlKankyou, "自宅周辺", "comment") ?? "",
        "外出手段": getRehaAssessSub(rehabiliPlan?.rehaAssessSub, adlKankyou, "外出手段", "comment") ?? "",
        "他サービスの利用": getRehaAssessSub(rehabiliPlan?.rehaAssessSub, adlKankyou, "サービスの利用", "comment") ?? "",
        "福祉用具等": getRehaAssessSub(rehabiliPlan?.rehaAssessSub, adlKankyou, "福祉用具等", "comment") ?? "",
        "社会参加の状況": getMasterNaiyou(rehabiliPlan?.socialParticipationCds, needItem, "need") ?? "",
        "食事の用意(開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 0) ?? "",
        "食事の用意(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 0) ?? "",
        "食事の用意(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 0) ?? "",
        "食事の片付け(開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 1) ?? "",
        "食事の片付け(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 1) ?? "",
        "食事の片付け(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 1) ?? "",
        "洗濯(開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 2) ?? "",
        "洗濯(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 2) ?? "",
        "洗濯(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 2) ?? "",
        "掃除や整頓(開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 3) ?? "",
        "掃除や整頓(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 3) ?? "",
        "掃除や整頓(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 3) ?? "",
        "力仕事(開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 4) ?? "",
        "力仕事(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 4) ?? "",
        "力仕事(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 4) ?? "",
        "買物(開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 5) ?? "",
        "買物(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 5) ?? "",
        "買物(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 5) ?? "",
        "外出(開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 6) ?? "",
        "外出(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 6) ?? "",
        "外出(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 6) ?? "",
        "屋外歩行(開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 7) ?? "",
        "屋外歩行(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 7) ?? "",
        "屋外歩行(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 7) ?? "",
        "趣味(開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 8) ?? "",
        "趣味(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 8) ?? "",
        "趣味(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 8) ?? "",
        "交通手段の利用(開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 9) ?? "",
        "交通手段の利用(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 9) ?? "",
        "交通手段の利用(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 9) ?? "",
        "旅行(開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 10) ?? "",
        "旅行(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 10) ?? "",
        "旅行(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 10) ?? "",
        "庭仕事(開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 11) ?? "",
        "庭仕事(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 11) ?? "",
        "庭仕事(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 11) ?? "",
        "家や車の手入れ(開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 12) ?? "",
        "家や車の手入れ(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 12) ?? "",
        "家や車の手入れ(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 12) ?? "",
        "読書(開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 13) ?? "",
        "読書(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 13) ?? "",
        "読書(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 13) ?? "",
        "仕事(開始時)": getRehaAssessmentSubNaiyou(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL, "answer", 14) ?? "",
        "仕事(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 14) ?? "",
        "仕事(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 14) ?? "",
        "IADL合計点(開始時)": getTotalPoint(rehabiliPlan?.saisinRecord?.rehaAssessSub, IADL) ?? "",
        "IADL合計点(現状)": getTotalPoint(rehabiliPlan?.rehaAssessSub, IADL) ?? "",
        活動と参加において重要性の高い課題: rehabiliPlan?.kadaiYoinBunseki ?? "",
        活動と参加に影響を及ぼす機能障害の課題1: getKadaiYoinBunseki(rehabiliPlan?.kadaiYoinBunseki2, rehabiliPlan?.kadaiYoinBunsekiChk2, 2, 0) ?? "",
        活動と参加に影響を及ぼす機能障害の課題2: getKadaiYoinBunseki(rehabiliPlan?.kadaiYoinBunseki22, rehabiliPlan?.kadaiYoinBunsekiChk2, 2, 1) ?? "",
        活動と参加に影響を及ぼす機能障害の課題3: getKadaiYoinBunseki(rehabiliPlan?.kadaiYoinBunseki23, rehabiliPlan?.kadaiYoinBunsekiChk2, 2, 2) ?? "",
        活動と参加に影響を及ぼす機能障害の課題4: getKadaiYoinBunseki(rehabiliPlan?.kadaiYoinBunseki24, rehabiliPlan?.kadaiYoinBunsekiChk2, 2, 3) ?? "",
        活動と参加に影響を及ぼす機能障害以外の要因1: getKadaiYoinBunseki(rehabiliPlan?.kadaiYoinBunseki3, rehabiliPlan?.kadaiYoinBunsekiChk3, 3, 0) ?? "",
        活動と参加に影響を及ぼす機能障害以外の要因2: getKadaiYoinBunseki(rehabiliPlan?.kadaiYoinBunseki32, rehabiliPlan?.kadaiYoinBunsekiChk3, 3, 1) ?? "",
        活動と参加に影響を及ぼす機能障害以外の要因3: getKadaiYoinBunseki(rehabiliPlan?.kadaiYoinBunseki33, rehabiliPlan?.kadaiYoinBunsekiChk3, 3, 2) ?? "",
        活動と参加に影響を及ぼす機能障害以外の要因4: getKadaiYoinBunseki(rehabiliPlan?.kadaiYoinBunseki34, rehabiliPlan?.kadaiYoinBunsekiChk3, 3, 3) ?? "",
        "訪問・通所頻度": rehabiliPlan?.rehabiliPlan[0]?.hindo ?? "",
        利用時間: rehabiliPlan?.rehabiliPlan[0]?.riyoJikan ?? "",
        "1No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 0) ?? "",
        "1目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 0) ?? "",
        "1期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikanNum", 0) ?? "",
        "1具体的支援内容1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 0, 0) ?? "",
        "1具体的支援内容2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 0, 1) ?? "",
        "1具体的支援内容3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 0, 2) ?? "",
        "1頻度1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 0, 0) ?? "",
        "1頻度2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 0, 1) ?? "",
        "1頻度3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 0, 2) ?? "",
        "1時間1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 0, 0) ?? "",
        "1時間2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 0, 1) ?? "",
        "1時間3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 0, 2) ?? "",
        "1本人・家族が実施すべきこと": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "routineWork", 0) ?? "",
        "2No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 1) ?? "",
        "2目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 1) ?? "",
        "2期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikanNum", 1) ?? "",
        "2具体的支援内容1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 1, 0) ?? "",
        "2具体的支援内容2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 1, 1) ?? "",
        "2具体的支援内容3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 1, 2) ?? "",
        "2頻度1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 1, 0) ?? "",
        "2頻度2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 1, 1) ?? "",
        "2頻度3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 1, 2) ?? "",
        "2時間1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 1, 0) ?? "",
        "2時間2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 1, 1) ?? "",
        "2時間3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 1, 2) ?? "",
        "2本人・家族が実施すべきこと": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "routineWork", 1) ?? "",
        "3No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 2) ?? "",
        "3目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 2) ?? "",
        "3期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikanNum", 2) ?? "",
        "3具体的支援内容1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 2, 0) ?? "",
        "3具体的支援内容2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 2, 1) ?? "",
        "3具体的支援内容3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 2, 2) ?? "",
        "3頻度1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 2, 0) ?? "",
        "3頻度2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 2, 1) ?? "",
        "3頻度3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 2, 2) ?? "",
        "3時間1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 2, 0) ?? "",
        "3時間2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 2, 1) ?? "",
        "3時間3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 2, 2) ?? "",
        "3本人・家族が実施すべきこと": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "routineWork", 2) ?? "",
        "4No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 3) ?? "",
        "4目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 3) ?? "",
        "4期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikanNum", 3) ?? "",
        "4具体的支援内容1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 3, 0) ?? "",
        "4具体的支援内容2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 3, 1) ?? "",
        "4具体的支援内容3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 3, 2) ?? "",
        "4頻度1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 3, 0) ?? "",
        "4頻度2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 3, 1) ?? "",
        "4頻度3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 3, 2) ?? "",
        "4時間1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 3, 0) ?? "",
        "4時間2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 3, 1) ?? "",
        "4時間3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 3, 2) ?? "",
        "4本人・家族が実施すべきこと": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "routineWork", 3) ?? "",
        他事業所の担当者と共有すべき事項: rehabiliPlan?.rehabiliPlan[0]?.careShare ?? "",
        介護支援専門員と共有すべき事項: rehabiliPlan?.rehabiliPlan[0]?.caremaneShare ?? "",
        "その他共有事項名称": rehabiliPlan?.rehabiliPlan[0]?.otherTitle ?? "",
        "その他、共有すべき事項": rehabiliPlan?.rehabiliPlan[0]?.otherShare ?? "",
        情報提供先: rehabiliPlan?.rehabiliPlan[0]?.informationProviding ?? "",
        "リハビリテーション開始時から比較して改善した・出来るようになった活動と参加": rehabiliPlan?.rehabiliPlan[0]?.improvement ?? "",
        "診療未実施減算の適用": menu?.sn === "visitreha" ?
          rehabiliPlan?.rehabiliPlan[0]?.nonMedical === 1 ? "あり" : "無し" : "",
        "情報提供を行った事業所外の医師の適切な研修": menu?.sn === "visitreha" ?
          getSeminarStatus(rehabiliPlan?.rehabiliPlan[0]?.seminarStatus) ?? "" : "",
      })
    })
    return sampledata
  } else if (typeof rehabiliPlan === 'object') {
    let sampledata = [
      {
        事業所番号: rehabiliPlan?.serviceKikanMaster?.jigyousyoNo ?? "",
        システム名: getSystemName(menu?.sn) ?? "",
        評価日: formatSeireki(rehabiliPlan?.date) ?? "",
        氏名1: rehabiliPlan?.riyousya?.name ?? "",
        性別: `${generateGenderString(GENDER_OPTIONS, rehabiliPlan?.riyousya?.gender ?? "")}`,
        生年月日: formatBirthday(rehabiliPlan?.riyousya?.birthday) ?? "",
        要介護度: rehabiliPlan?.riyouKaigohoken?.kaigodoMaster?.kaigodoName ?? "",
        リハビリテーション担当医: `${rehabiliPlan?.doctorMaster?.name ?? ""}　${rehabiliPlan?.doctorMaster?.hospital ?? ""}` ?? "",
        リハ担当者: `${rehabiliPlan?.tantoMaster?.name ?? ""}　${formatJob(rehabiliPlan?.tantoMaster?.jobCategory) ?? ""}` ?? "",
        "本人・家族等の希望": rehabiliPlan?.kazokuKibo ?? "",
        原因疾患: rehabiliPlan?.shikkan ?? "",
        "発症日・受傷日": formatSeireki(rehabiliPlan.hasshoDate) ?? "",
        直近の入院日: formatSeireki(rehabiliPlan.nyuinDate) ?? "",
        直近の退院日: formatSeireki(rehabiliPlan.taiinDate) ?? "",
        治療経過: rehabiliPlan?.keika ?? "",
        合併症: splitComplications(rehabiliPlan.complications, "合併症") ?? "",
        上記以外の疾患: splitComplications(rehabiliPlan.complications, "合併症他", rehabiliPlan.gapeiShipei) ?? "",
        コントロール状態: rehabiliPlan?.complicationsCtrl ?? "",
        これまでのリハビリテーション実施状況: rehabiliPlan?.jisshijoukyou ?? "",
        "目標設定・管理シート": rehabiliPlan?.mokuhyoKanriSheet ?? "",
        日常生活自立度: rehabiliPlan?.jiritudoSyougai ?? "",
        認知度: rehabiliPlan?.jirutudoNinti ?? "",
        "筋力低下(状況)": rehabiliPlan?.kinryokuTeika?.split(",")[0] ?? "",
        "筋力低下(支障)": rehabiliPlan?.kinryokuTeika?.split(",")[1] ?? "",
        "筋力低下(見込み)": rehabiliPlan?.kinryokuTeika?.split(",")[2] ?? "",
        "麻痺(状況)": rehabiliPlan?.mahi?.split(",")[0] ?? "",
        "麻痺(支障)": rehabiliPlan?.mahi?.split(",")[1] ?? "",
        "麻痺(見込み)": rehabiliPlan?.mahi?.split(",")[2] ?? "",
        "感覚機能障害(状況)": rehabiliPlan?.kankakukinoShogai?.split(",")[0] ?? "",
        "感覚機能障害(支障)": rehabiliPlan?.kankakukinoShogai?.split(",")[1] ?? "",
        "感覚機能障害(見込み)": rehabiliPlan?.kankakukinoShogai?.split(",")[2] ?? "",
        "関節可動域制限(状況)": rehabiliPlan?.kansetuKoushuku?.split(",")[0] ?? "",
        "関節可動域制限(支障)": rehabiliPlan?.kansetuKoushuku?.split(",")[1] ?? "",
        "関節可動域制限(見込み)": rehabiliPlan?.kansetuKoushuku?.split(",")[2] ?? "",
        "摂食嚥下障害(状況)": rehabiliPlan?.sesshokuEngeShogai?.split(",")[0] ?? "",
        "摂食嚥下障害(支障)": rehabiliPlan?.sesshokuEngeShogai?.split(",")[1] ?? "",
        "摂食嚥下障害(見込み)": rehabiliPlan?.sesshokuEngeShogai?.split(",")[2] ?? "",
        "失語症構音障害(状況)": rehabiliPlan?.situgoKoonShogai?.split(",")[0] ?? "",
        "失語症構音障害(支障)": rehabiliPlan?.situgoKoonShogai?.split(",")[1] ?? "",
        "失語症構音障害(見込み)": rehabiliPlan?.situgoKoonShogai?.split(",")[2] ?? "",
        "見当識障害(状況)": rehabiliPlan?.kentosikiShogai?.split(",")[0] ?? "",
        "見当識障害(支障)": rehabiliPlan?.kentosikiShogai?.split(",")[1] ?? "",
        "見当識障害(見込み)": rehabiliPlan?.kentosikiShogai?.split(",")[2] ?? "",
        "記憶障害(状況)": rehabiliPlan?.kiokuShogai?.split(",")[0] ?? "",
        "記憶障害(支障)": rehabiliPlan?.kiokuShogai?.split(",")[1] ?? "",
        "記憶障害(見込み)": rehabiliPlan?.kiokuShogai?.split(",")[2] ?? "",
        高次脳機能障害: rehabiliPlan?.kojinoShogai?.split(",")[3] ?? "",
        "高次脳機能障害(状況)": rehabiliPlan?.kojinoShogai?.split(",")[0] ?? "",
        "高次脳機能障害(支障)": rehabiliPlan?.kojinoShogai?.split(",")[1] ?? "",
        "高次脳機能障害(見込み)": rehabiliPlan?.kojinoShogai?.split(",")[2] ?? "",
        "栄養障害(状況)": rehabiliPlan?.eiyoShogai?.split(",")[0] ?? "",
        "栄養障害(支障)": rehabiliPlan?.eiyoShogai?.split(",")[1] ?? "",
        "栄養障害(見込み)": rehabiliPlan?.eiyoShogai?.split(",")[2] ?? "",
        "疼痛(状況)": rehabiliPlan?.totu?.split(",")[0] ?? "",
        "疼痛(支障)": rehabiliPlan?.totu?.split(",")[1] ?? "",
        "疼痛(見込み)": rehabiliPlan?.totu?.split(",")[2] ?? "",
        "精神行動障害(状況)": rehabiliPlan?.seisinkodoShogai?.split(",")[0] ?? "",
        "精神行動障害(支障)": rehabiliPlan?.seisinkodoShogai?.split(",")[1] ?? "",
        "精神行動障害(見込み)": rehabiliPlan?.seisinkodoShogai?.split(",")[2] ?? "",
        心身機能1: getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "6分間歩行試験,TimeUp&GoTest", "title") ?? "",
        "心身機能1(数値)": getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "6分間歩行試験,TimeUp&GoTest", "answer") ?? "",
        "心身機能1(内容)": getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "6分間歩行試験,TimeUp&GoTest", "naiyou") ?? "",
        服薬管理: getMMSENaiyou(assessMMSE, "服薬管理", rehabiliPlan?.rehaAssessSub, "answer") ?? "",
        "服薬管理(内容)": getMMSENaiyou(assessMMSE, "服薬管理", rehabiliPlan?.rehaAssessSub, "comment") ?? "",
        心身機能2: getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "MMSE,HDS-R", "title") ?? "",
        "心身機能2(数値)": getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "MMSE,HDS-R", "answer") ?? "",
        "心身機能2(内容)": getMMSE(rehabiliPlan?.rehaAssessSub, assessMMSE, "MMSE,HDS-R", "naiyou") ?? "",
        コミュニケーションの状況: getMMSENaiyou(assessMMSE, "コミュニケーションの状況", rehabiliPlan?.rehaAssessSub, "answer") ?? "",
        "コミュニケーションの状況(内容)": getMMSENaiyou(assessMMSE, "コミュニケーションの状況", rehabiliPlan?.rehaAssessSub, "comment") ?? "",
        MMSE1: `${getMMSETitle(adlitemAnswerNaiyou, 0)}`,
        "MMSE1(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 0) ?? "",
        "MMSE1(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 0) ?? "",
        "MMSE1(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 0) ?? "",
        MMSE2: getMMSETitle(adlitemAnswerNaiyou, 1),
        "MMSE2(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 1) ?? "",
        "MMSE2(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 1) ?? "",
        "MMSE2(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 1) ?? "",
        MMSE3: getMMSETitle(adlitemAnswerNaiyou, 2),
        "MMSE3(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 2) ?? "",
        "MMSE3(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 2) ?? "",
        "MMSE3(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 2) ?? "",
        MMSE4: getMMSETitle(adlitemAnswerNaiyou, 3),
        "MMSE4(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 3) ?? "",
        "MMSE4(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 3) ?? "",
        "MMSE4(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 3) ?? "",
        MMSE5: getMMSETitle(adlitemAnswerNaiyou, 4),
        "MMSE5(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 4) ?? "",
        "MMSE5(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "answer", 4) ?? "",
        "MMSE5(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlitemAnswerNaiyou, "comment", 4) ?? "",
        ADL1: getTitle(adlItems, 0),
        "ADL1(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 0) ?? "",
        "ADL1(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 0) ?? "",
        "ADL1(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 0) ?? "",
        ADL2: getTitle(adlItems, 1),
        "ADL2(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 1) ?? "",
        "ADL2(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 1) ?? "",
        "ADL2(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 1) ?? "",
        ADL3: getTitle(adlItems, 2),
        "ADL3(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 2) ?? "",
        "ADL3(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 2) ?? "",
        "ADL3(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 2) ?? "",
        ADL4: getTitle(adlItems, 3),
        "ADL4(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 3) ?? "",
        "ADL4(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 3) ?? "",
        "ADL4(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 3) ?? "",
        ADL5: getTitle(adlItems, 4),
        "ADL5(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 4) ?? "",
        "ADL5(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 4) ?? "",
        "ADL5(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 4) ?? "",
        ADL6: getTitle(adlItems, 5),
        "ADL6(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 5) ?? "",
        "ADL6(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 5) ?? "",
        "ADL6(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 5) ?? "",
        ADL7: getTitle(adlItems, 6),
        "ADL7(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 6) ?? "",
        "ADL7(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 6) ?? "",
        "ADL7(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 6) ?? "",
        ADL8: getTitle(adlItems, 7),
        "ADL8(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 7) ?? "",
        "ADL8(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 7) ?? "",
        "ADL8(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 7) ?? "",
        ADL9: getTitle(adlItems, 8),
        "ADL9(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 8) ?? "",
        "ADL9(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 8) ?? "",
        "ADL9(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 8) ?? "",
        ADL10: getTitle(adlItems, 9),
        "ADL10(リハ開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, adlItems, "answer", 9) ?? "",
        "ADL10(現在の状況)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "answer", 9) ?? "",
        "ADL10(将来の見込み)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, adlItems, "comment", 9) ?? "",
        "ADL合計点(リハ開始時)": getTotalPoint(saisinRecord?.rehaAssessSub, adlItems) ?? "",
        "ADL合計点(現在の状況)": getTotalPoint(rehabiliPlan?.rehaAssessSub, adlItems) ?? "",
        "リハビリテーション短期目標(心身機能)": getMasterNaiyou(rehabiliPlan?.shortMokuhyouFunction, icfMaster, "icf") ?? "",
        "短期目標内容(心身機能)": rehabiliPlan?.shortMokuhyouFunctionNaiyou ?? "",
        "リハビリテーション短期目標(活動)": getMasterNaiyou(rehabiliPlan?.shortMokuhyouActivity, icfMaster, "icf") ?? "",
        "短期目標内容(活動)": rehabiliPlan?.shortMokuhyouActivityNaiyou ?? "",
        "リハビリテーション短期目標(参加)": getMasterNaiyou(rehabiliPlan?.shortMokuhyouJoin, icfMaster, "icf") ?? "",
        "短期目標内容(参加)": rehabiliPlan?.shortMokuhyouJoinNaiyou ?? "",
        "リハビリテーション長期目標(心身機能)": getMasterNaiyou(rehabiliPlan?.longMokuhyouFunction, icfMaster, "icf") ?? "",
        "長期目標内容(心身機能)": rehabiliPlan?.longMokuhyouFunctionNaiyou ?? "",
        "リハビリテーション長期目標(活動)": getMasterNaiyou(rehabiliPlan?.longMokuhyouActivity, icfMaster, "icf") ?? "",
        "長期目標内容(活動)": rehabiliPlan?.longMokuhyouActivityNaiyou ?? "",
        "リハビリテーション長期目標(参加)": getMasterNaiyou(rehabiliPlan?.longMokuhyouJoin, icfMaster, "icf") ?? "",
        "長期目標内容(参加)": rehabiliPlan?.longMokuhyouJoinNaiyou ?? "",
        "リハビリテーションの方針(今後3ヶ月間)": rehabiliPlan?.rehaHosin ?? "",
        "本人・家族への生活指導の内容": rehabiliPlan?.lifeGuidance ?? "",
        リハビリテーション実施上の留意点: rehabiliPlan?.rehaRyui ?? "",
        "リハビリテーションの見通し・継続理由": rehabiliPlan?.review ?? "",
        "リハビリテーション(終了の目安となる時期)": rehabiliPlan?.timeEstimate?.toString() ?? "",
        "リハビリテーション終了の目安・時期": rehabiliPlan?.rehaMeyasu ?? "",
        特記事項: rehabiliPlan?.toki ?? "",
        //2ページ
        氏名2: rehabiliPlan?.riyousya?.name ?? "",
        "家族": getRehaAssessSub(rehabiliPlan?.rehaAssessSub, adlKankyou, "家族・介護者", "comment") ?? "",
        "住環境": getRehaAssessSub(rehabiliPlan?.rehaAssessSub, adlKankyou, "住環境", "comment") ?? "",
        "自宅周辺": getRehaAssessSub(rehabiliPlan?.rehaAssessSub, adlKankyou, "自宅周辺", "comment") ?? "",
        "外出手段": getRehaAssessSub(rehabiliPlan?.rehaAssessSub, adlKankyou, "外出手段", "comment") ?? "",
        "他サービスの利用": getRehaAssessSub(rehabiliPlan?.rehaAssessSub, adlKankyou, "サービスの利用", "comment") ?? "",
        "福祉用具等": getRehaAssessSub(rehabiliPlan?.rehaAssessSub, adlKankyou, "福祉用具等", "comment") ?? "",
        "社会参加の状況": getMasterNaiyou(rehabiliPlan?.socialParticipationCds, icfMaster, "icf") ?? "",
        "食事の用意(開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 0) ?? "",
        "食事の用意(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 0) ?? "",
        "食事の用意(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 0) ?? "",
        "食事の片付け(開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 1) ?? "",
        "食事の片付け(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 1) ?? "",
        "食事の片付け(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 1) ?? "",
        "洗濯(開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 2) ?? "",
        "洗濯(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 2) ?? "",
        "洗濯(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 2) ?? "",
        "掃除や整頓(開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 3) ?? "",
        "掃除や整頓(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 3) ?? "",
        "掃除や整頓(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 3) ?? "",
        "力仕事(開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 4) ?? "",
        "力仕事(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 4) ?? "",
        "力仕事(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 4) ?? "",
        "買物(開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 5) ?? "",
        "買物(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 5) ?? "",
        "買物(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 5) ?? "",
        "外出(開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 6) ?? "",
        "外出(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 6) ?? "",
        "外出(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 6) ?? "",
        "屋外歩行(開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 7) ?? "",
        "屋外歩行(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 7) ?? "",
        "屋外歩行(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 7) ?? "",
        "趣味(開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 8) ?? "",
        "趣味(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 8) ?? "",
        "趣味(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 8) ?? "",
        "交通手段の利用(開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 9) ?? "",
        "交通手段の利用(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 9) ?? "",
        "交通手段の利用(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 9) ?? "",
        "旅行(開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 10) ?? "",
        "旅行(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 10) ?? "",
        "旅行(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 10) ?? "",
        "庭仕事(開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 11) ?? "",
        "庭仕事(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 11) ?? "",
        "庭仕事(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 11) ?? "",
        "家や車の手入れ(開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 12) ?? "",
        "家や車の手入れ(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 12) ?? "",
        "家や車の手入れ(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 12) ?? "",
        "読書(開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 13) ?? "",
        "読書(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 13) ?? "",
        "読書(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 13) ?? "",
        "仕事(開始時)": getRehaAssessmentSubNaiyou(saisinRecord?.rehaAssessSub, IADL, "answer", 14) ?? "",
        "仕事(現状)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "answer", 14) ?? "",
        "仕事(特記事項)": getRehaAssessmentSubNaiyou(rehabiliPlan?.rehaAssessSub, IADL, "comment", 14) ?? "",
        "IADL合計点(開始時)": getTotalPoint(saisinRecord?.rehaAssessSub, IADL) ?? "",
        "IADL合計点(現状)": getTotalPoint(rehabiliPlan?.rehaAssessSub, IADL) ?? "",
        活動と参加において重要性の高い課題: rehabiliPlan?.kadaiYoinBunseki ?? "",
        活動と参加に影響を及ぼす機能障害の課題1: getKadaiYoinBunseki(rehabiliPlan?.kadaiYoinBunseki2, rehabiliPlan?.kadaiYoinBunsekiChk2, 2, 0) ?? "",
        活動と参加に影響を及ぼす機能障害の課題2: getKadaiYoinBunseki(rehabiliPlan?.kadaiYoinBunseki22, rehabiliPlan?.kadaiYoinBunsekiChk2, 2, 1) ?? "",
        活動と参加に影響を及ぼす機能障害の課題3: getKadaiYoinBunseki(rehabiliPlan?.kadaiYoinBunseki23, rehabiliPlan?.kadaiYoinBunsekiChk2, 2, 2) ?? "",
        活動と参加に影響を及ぼす機能障害の課題4: getKadaiYoinBunseki(rehabiliPlan?.kadaiYoinBunseki24, rehabiliPlan?.kadaiYoinBunsekiChk2, 2, 3) ?? "",
        活動と参加に影響を及ぼす機能障害以外の要因1: getKadaiYoinBunseki(rehabiliPlan?.kadaiYoinBunseki3, rehabiliPlan?.kadaiYoinBunsekiChk3, 3, 0) ?? "",
        活動と参加に影響を及ぼす機能障害以外の要因2: getKadaiYoinBunseki(rehabiliPlan?.kadaiYoinBunseki32, rehabiliPlan?.kadaiYoinBunsekiChk3, 3, 1) ?? "",
        活動と参加に影響を及ぼす機能障害以外の要因3: getKadaiYoinBunseki(rehabiliPlan?.kadaiYoinBunseki33, rehabiliPlan?.kadaiYoinBunsekiChk3, 3, 2) ?? "",
        活動と参加に影響を及ぼす機能障害以外の要因4: getKadaiYoinBunseki(rehabiliPlan?.kadaiYoinBunseki34, rehabiliPlan?.kadaiYoinBunsekiChk3, 3, 3) ?? "",
        "訪問・通所頻度": rehabiliPlan?.rehabiliPlan[0]?.hindo ?? "",
        利用時間: rehabiliPlan?.rehabiliPlan[0]?.riyoJikan ?? "",
        "1No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 0) ?? "",
        "1目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 0) ?? "",
        "1期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikanNum", 0) ?? "",
        "1具体的支援内容1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 0, 0) ?? "",
        "1具体的支援内容2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 0, 1) ?? "",
        "1具体的支援内容3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 0, 2) ?? "",
        "1頻度1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 0, 0) ?? "",
        "1頻度2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 0, 1) ?? "",
        "1頻度3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 0, 2) ?? "",
        "1時間1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 0, 0) ?? "",
        "1時間2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 0, 1) ?? "",
        "1時間3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 0, 2) ?? "",
        "1本人・家族が実施すべきこと": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "routineWork", 0) ?? "",
        "2No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 1) ?? "",
        "2目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 1) ?? "",
        "2期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikanNum", 1) ?? "",
        "2具体的支援内容1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 1, 0) ?? "",
        "2具体的支援内容2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 1, 1) ?? "",
        "2具体的支援内容3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 1, 2) ?? "",
        "2頻度1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 1, 0) ?? "",
        "2頻度2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 1, 1) ?? "",
        "2頻度3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 1, 2) ?? "",
        "2時間1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 1, 0) ?? "",
        "2時間2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 1, 1) ?? "",
        "2時間3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 1, 2) ?? "",
        "2本人・家族が実施すべきこと": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "routineWork", 1) ?? "",
        "3No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 2) ?? "",
        "3目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 2) ?? "",
        "3期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikanNum", 2) ?? "",
        "3具体的支援内容1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 2, 0) ?? "",
        "3具体的支援内容2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 2, 1) ?? "",
        "3具体的支援内容3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 2, 2) ?? "",
        "3頻度1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 2, 0) ?? "",
        "3頻度2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 2, 1) ?? "",
        "3頻度3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 2, 2) ?? "",
        "3時間1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 2, 0) ?? "",
        "3時間2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 2, 1) ?? "",
        "3時間3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 2, 2) ?? "",
        "3本人・家族が実施すべきこと": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "routineWork", 2) ?? "",
        "4No": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sortNum", 3) ?? "",
        "4目標(解決すべき課題)": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "mokuhyou", 3) ?? "",
        "4期間": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "kikanNum", 3) ?? "",
        "4具体的支援内容1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 3, 0) ?? "",
        "4具体的支援内容2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 3, 1) ?? "",
        "4具体的支援内容3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "sienCds", 3, 2) ?? "",
        "4頻度1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 3, 0) ?? "",
        "4頻度2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 3, 1) ?? "",
        "4頻度3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "hindo", 3, 2) ?? "",
        "4時間1": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 3, 0) ?? "",
        "4時間2": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 3, 1) ?? "",
        "4時間3": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "jikan", 3, 2) ?? "",
        "4本人・家族が実施すべきこと": getRehabiliPlanSub(rehabiliPlan?.rehabiliPlan[0]?.rehabiliPlanSub, "routineWork", 3) ?? "",
        他事業所の担当者と共有すべき事項: rehabiliPlan?.rehabiliPlan[0]?.careShare ?? "",
        介護支援専門員と共有すべき事項: rehabiliPlan?.rehabiliPlan[0]?.caremaneShare ?? "",
        "その他共有事項名称": rehabiliPlan?.rehabiliPlan[0]?.otherTitle ?? "",
        "その他、共有すべき事項": rehabiliPlan?.rehabiliPlan[0]?.otherShare ?? "",
        情報提供先: rehabiliPlan?.rehabiliPlan[0]?.informationProviding ?? "",
        "リハビリテーション開始時から比較して改善した・出来るようになった活動と参加": rehabiliPlan?.rehabiliPlan[0]?.improvement ?? "",
        "診療未実施減算の適用": menu?.sn === "visitreha" ?
          rehabiliPlan?.rehabiliPlan[0]?.nonMedical === 1 ? "あり" : "無し" : "",
        "情報提供を行った事業所外の医師の適切な研修": menu?.sn === "visitreha" ?
          getSeminarStatus(rehabiliPlan?.rehabiliPlan[0]?.seminarStatus) ?? "" : "",
      },
    ];
    return sampledata;
  } else {
    return [{}];
  }
};
