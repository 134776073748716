import React, { useState, useEffect } from "react";
import { Row, Button, Input, Col, Modal } from "antd";
// import { handleInputNumber } from '../function_common/functionCommon';

function NumberKB(props) {
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  function handleInput(text) {
    setValue(handleInputNumber(text, value));
  }

  function handleInputNumber(inputChar, value, checkMaxMin) {
    if (inputChar === "消去") {
      value = "";
    } else if (inputChar === ".") {
      if (!value) {
        value = "0.";
      } else {
        if (!value.includes(".")) {
          value = value + ".";
        }
      }
    } else {
      if (!value) {
        // if (inputChar !== '0')
        value = inputChar;
      } else {
        value = value + "" + inputChar;
      }
    }

    return value;
  }
  function handleOk() {
    props.onOk(props.recordSelected ? props.recordSelected.key : null, value);
    props.onHide();
  }

  function handleCancel() {
    props.onHide();
  }

  return (
    (<Modal
      open={props.visible}
      // title={props.title}
      // onCancel={() => handleCancel()}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      footer={[
        <Button
          key="back"
          type="primary"
          style={{
            backgroundColor: "#ffffff",
            color: "#616161",
            border: "1px solid #dbdbdb",
          }}
          onClick={handleCancel}
        >
          キャンセル
        </Button>,
        <Button
          key="back"
          type="primary"
          onClick={handleOk}
          //   disabled={
          //     frequency.every((element) => element === false) || isErrorTime
          //   }
        >
          OK
        </Button>,
      ]}
      width={400}
    >
      <Row>
        <div style={{ display: "flex" }}>
          <Col span={6}></Col>
          <Col span={12} style={{ textAlign: "center" }}>
            <Input
              value={value}
              style={{
                width: 200,
                border: "1px solid",
                color: "#333",
                fontSize: "24px",
                marginBottom: 8,
              }}
            />
          </Col>
          <Col span={6}></Col>
        </div>
        <div className="kim-row">
          <Button className={"hm-item"} onClick={() => handleInput("7")}>
            7
          </Button>
          <Button className={"hm-item"} onClick={() => handleInput("8")}>
            8
          </Button>
          <Button className={"hm-item"} onClick={() => handleInput("9")}>
            9
          </Button>
          <Button
            className={"hm-item hm-item-del"}
            onClick={() => handleInput("消去")}
          >
            消去
          </Button>
        </div>
        <div className="kim-row">
          <Button className={"hm-item"} onClick={() => handleInput("4")}>
            4
          </Button>
          <Button className={"hm-item"} onClick={() => handleInput("5")}>
            5
          </Button>
          <Button className={"hm-item"} onClick={() => handleInput("6")}>
            6
          </Button>
        </div>
        <div className="kim-row">
          <Button className={"hm-item"} onClick={() => handleInput("1")}>
            1
          </Button>
          <Button className={"hm-item"} onClick={() => handleInput("2")}>
            2
          </Button>
          <Button className={"hm-item"} onClick={() => handleInput("3")}>
            3
          </Button>
        </div>

        <div className="kim-row">
          <Button className={"hm-item"} onClick={() => handleInput("0")}>
            0
          </Button>
          <Button className={"hm-item"} onClick={() => handleInput(".")}>
            .
          </Button>
          <Button className={"hm-item"} disabled>
            -
          </Button>
        </div>
      </Row>
    </Modal>)
  );
}

export { NumberKB };
